import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

import SimpleDropdown from 'Components/SimpleDropdown'

const RootS = styled.ul`
  position: relative;
  display: flex;
  padding-left: 0;

  list-style: none;

  li + li {
    position: relative;
    margin-left: 2.5rem;
  }

  li + li::before {
    content: "";
    position: absolute;
    height: 0.7rem;
    width: 0.7rem;
    top: 0.45rem;
    left: -1.5rem;
    background-image: url(/images/chevron.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
`

interface ItemLinkI {
  active?: boolean
  to: string
}

const ItemLinkS = styled(Link)<ItemLinkI>`
  text-decoration: none;
  ${css(({ theme }) => theme.fonts.types.h4)};
  color: ${({ theme, active }) => active ? theme.colors.dark : theme.colors.gray_nickel_dark_hover};
  font-weight: ${({ theme, active }) => active ? 'bold' : 'normal'};
`

interface CrumbsI extends React.HTMLAttributes<HTMLAnchorElement> {
  name?: string
  to?: string
  active?: boolean
  items?: any
  isDropdown?: boolean
}

interface BreadcrumbsI {
  crumbs: CrumbsI[] | null
}

function Crumb ({ to, children, active }: CrumbsI) {
  return (
    to ? (
      <ItemLinkS to={to} active={active}>
        {children}
    </ItemLinkS>)
      : (<div>
        {children}
      </div>)

  )
}

export default function Breadcrumbs ({ crumbs }: BreadcrumbsI) {
  const modifiedCrumbs = crumbs && [...crumbs]

  if (modifiedCrumbs && modifiedCrumbs?.length > 3) {
    const spliceds = modifiedCrumbs.splice(1, crumbs.length - 3) // Remove os elementos intermediários.
    modifiedCrumbs.splice(1, 0, { isDropdown: true, items: spliceds })
  }

  return (
    <RootS>
      {modifiedCrumbs && modifiedCrumbs.map((crumb, key) => (
        crumb && <li key={key}>
          {
            crumb.isDropdown
              ? <SimpleDropdown items={crumb.items} alignment='center'>...</SimpleDropdown>
              : <Crumb to={crumb.to} active={key === modifiedCrumbs.length - 1}>
                {crumb.content ? crumb.content : crumb.name}
              </Crumb>
          }
        </li>
      ))}
    </RootS>
  )
}
