import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'
// import jscookie from 'js-cookie'

import { getLocale } from 'Helpers/Locale.helper'
import * as localStorage from 'Helpers/Localstorage.helper'

const configAPI = (headers = {}) => {
  const locale = getLocale()
  const AcceptLocale = locale === 'en-US' ? 'en' : locale
  // @ts-ignore
  const token = headers.token ?? localStorage.getItem('odash-app:user') ?? ''

  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10 * 60 * 1000,
    headers: {
      'accept-language': AcceptLocale,
      'content-type': 'application/json',
      accept: 'application/json',
      authorization: `Bearer ${token}`,
      ...headers
    },
    withCredentials: !!('withCredentials' in headers && headers.withCredentials),
    validateStatus: function (status) {
      return status < 500
    }
  }

  const clientAPI: AxiosInstance = axios.create(config)

  return clientAPI
}

export const apiPUT = async <T, >(url:string, data = {}, headers = {}) => {
  return await apiHandle<T>(url, data, configAPI(headers).put)
}

export const apiPOST = async <T, >(url:string, data = {}, headers = {}) => {
  return await apiHandle<T>(url, data, configAPI(headers).post)
}

export const apiGET = async <T, >(url:string, data = {}, headers = {}) => {
  return await apiHandle<T>(url, data, configAPI(headers).get)
}

export const apiDELETE = async <T, >(url:string, data = {}, headers = {}) => {
  return await apiHandle<T>(url, data, configAPI(headers).delete)
}

export const apiHandle = async <T, >(url:string, data = {}, method: AxiosInstance['post'] | AxiosInstance['get']) : Promise<T> => {
  const request = await method(url, data)
  const response = request.data
  await apiCallbackHeaders(request)

  if (request.status >= 200 && request.status < 300) {
    return response
  } else {
    throw await apiCallbackError(request.status, response)
  }
}

const apiCallbackError = async (status:number, response:any) => {
  if (status === 401 && localStorage.getItem('odash-app:user')) {
    localStorage.removeItem('odash-app:user')
    localStorage.removeItem('odash-app:user_info')
    window.location.href = `/login?next=${window.location.href}`
  } else if (response.fields) {
    const messageError = handleError(response.fields)

    return {
      status: 'error',
      message: messageError,
      fields: response.fields
    }
  } else if (response.message) {
    return {
      status: 'error',
      message: response.message
    }
  }
}

const handleError = (fields:any) => {
  let messageError = ''

  for (const key in fields) {
    messageError += `${fields[key]}`
  }

  return messageError
}

const apiCallbackHeaders = async (data:any) => {
  return new Promise((resolve) => {
    const tokenString = data?.headers?.authorization

    if (tokenString) {
      const token = tokenString.split(' ')[1]
      if (token) {
        localStorage.setItem('odash-app:user', token)
      // try {
      //   await apiPOST('/cookie', {
      //     cookieName: 'ttkn',
      //     cookieDomain: '.tetris.co',
      //     token
      //   }, { token, withCredentials: true })
      //     .catch((e) => {
      //       console.log('cookie error', e)
      //     })
      // } catch (e) {
      //   console.log(e)
      // }
      // jscookie.set(process.env.REACT_APP_TOKEN_COOKIE_NAME || '', token, {
      //   domain: process.env.REACT_APP_TOKEN_COOKIE_DOMAIN,
      //   expires: 1
      // })
      }
    }

    const domainSuffix = data?.headers['x-domain-suffix']

    if (domainSuffix) {
      localStorage.setItem('odash-app:domain-suffix', domainSuffix)
    }

    resolve(data)
  })
}
