import useSWR from 'swr'

import { createQuery } from 'Helpers/Url.helper'

import { fetcher } from './fetcher'
import type { RoutineI } from './useRoutineDetails.hook'

export interface RoutineRunI {
  id: string,
  state: string,
  start_date: string,
  end_date: string,
  run_type: string,
  external_trigger: boolean,
  duration: string,
  routine: RoutineI
}

// export interface LogI {
//   _id: string,
//   categoryJob: string,
//   content: {
//     params: any,
//     platform: string,
//   },
//   duration: string,
//   executionTime: string,
//   finished: string,
//   message: string,
//   nameJob: string,
//   started: string,
//   typeJob: string,
// }

interface OptionsI {
  company: string,
  routine: string,
  search?: string | null,
  orderBy?: string | null,
  rangeDates?: string[]
}

interface ReturnI {
  data: Array<RoutineRunI>,
  isLoading: boolean,
  isError: boolean
}

const useLogs = (options: OptionsI) : ReturnI => {
  const query = createQuery()

  if (options.search) {
    query.addParams('search', options.search)
  }

  if (options.orderBy) {
    query.addParams('orderBy', options.orderBy)
  }

  if (options.rangeDates) {
    query.addParams('rangeDate', options.rangeDates)
  }

  const { data, error } = useSWR<Array<RoutineRunI>>(`company/${options.company}/routines/${options.routine}/${query.getValue()}`, fetcher('GET'))

  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useLogs
