import { Modal } from 'DesignSystem'

import useContentManager from 'Hooks/useContentManager.hook'

import CompletePaymentModal from '../Components/CompletePaymentModal'

export interface CompletePaymentContentManagerI {
  pricePreChosen?: string | null
  planId?: string
  planName?: string,
  amount: number | null,
  interval: string | null,
  currency: string | null,
  onChangePlans?: () => void
}

export const useCompletePaymentContentManager = () => {
  const { start, content } = useContentManager<CompletePaymentContentManagerI>([
    CompletePaymentModal
  ], {
    dialog: Modal
  })

  return { start, content }
}
