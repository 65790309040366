import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

// import cronstrue from 'cronstrue/i18n'
// import { format, parseISO } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'

import IconSVG from 'Components/IconSVG'
import type { IconNameI } from 'Components/IconSVG/IconSVG.component'
import SearchAdvanced from 'Components/SearchAdvanced'
import SearchFilter from 'Components/SearchFilter'
import SelectFilter from 'Components/SelectFilter'
import SelectPlatformFilter from 'Components/SelectPlatformFilter'
import Tag from 'Components/Tag'
import TemplateDefault from 'Components/TemplateDefault'
import Text from 'Components/Text'

import useCompanies from 'Hooks/API/useCompanies.hook'
import useJobs from 'Hooks/API/useJobs.hook'
import useQuery from 'Hooks/useQuery.hook'

import { MicroData } from 'Helpers/MicroData.helper'

import CardRoutine from './Components/CardRoutine.component'
import CardWithoutRoutines from './Components/CardWithoutRoutines.component'
import ListRoutinesLoader from './Components/ListRoutinesLoader.component'
import { CardSingleS, FilterS, ListRoutines, TagsS, WrapperActionsHeaderS } from './Routines.styled'

interface RoutinesPropsI {
  isPocket?: boolean,
  idCompany?: string,
  isFullContainer?: boolean,
}

const Routines = (props: RoutinesPropsI) => {
  const { isPocket = false, isFullContainer = false, idCompany } = props
  const query = useQuery()
  const intl = useIntl()
  const history = useHistory()
  const [searchAdvancedIsOpen, setSearchAdvancedIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState(query.get('search') || '')

  /**
   * Advanced filters
   */
  const [idsCompanyFilters, setIdsCompanyFilters] = useState<Set<string>>(new Set(query.get('companies')?.split(' ') ?? []))
  const [plataformConnectionFilters, setPlatformConnectionFilters] = useState<Set<string>>(new Set(query.get('connections')?.split(' ') ?? []))
  const [rangeDateFilters, setRangeFilters] = useState<Set<string>>(new Set(query.get('rangeDates')?.split(' ') ?? []))

  /**
   * Load all datas
   */
  const { data: jobs, isLoading: isLoadingJobs } = useJobs({
    search: searchValue,
    companies: isPocket && idCompany ? [idCompany] : Array.from(idsCompanyFilters),
    platforms: Array.from(plataformConnectionFilters),
    rangeDate: Array.from(rangeDateFilters)
  })
  const { data: companies } = useCompanies()

  /**
   * Handlers
   */
  const handleOnChangeCompany = (value: string) => {
    const cloneIdsCompanyFilters = cloneDeep(idsCompanyFilters)

    cloneIdsCompanyFilters.add(value)
    setIdsCompanyFilters(cloneIdsCompanyFilters)
  }

  const handleOnCloseCompany = (value: string) => {
    const cloneIdsCompanyFilters = cloneDeep(idsCompanyFilters)

    cloneIdsCompanyFilters.delete(value)
    setIdsCompanyFilters(cloneIdsCompanyFilters)
  }

  const handleOnChangeConnections = (value: string) => {
    const clonePlataformConnectionFilters = cloneDeep(plataformConnectionFilters)

    clonePlataformConnectionFilters.add(value)
    setPlatformConnectionFilters(clonePlataformConnectionFilters)
  }

  const handleOnCloseConnections = (value: string) => {
    const clonePlataformConnectionFilters = cloneDeep(plataformConnectionFilters)

    clonePlataformConnectionFilters.delete(value)
    setPlatformConnectionFilters(clonePlataformConnectionFilters)
  }

  const handleToggleSearchAdvanced = () => setSearchAdvancedIsOpen(!searchAdvancedIsOpen)

  const handleOnClearSearchAdvanced = () => {
    setIdsCompanyFilters(new Set())
    setRangeFilters(new Set())
    setPlatformConnectionFilters(new Set())
  }

  const handleChangeSearch = (value: string) => {
    setSearchValue(value)
  }

  /**
   * Effects
   */
  useEffect(() => {
    let query = '?'

    if (searchValue) {
      query = `${query}search=${searchValue}`
    }

    if (idsCompanyFilters.size > 0) {
      query = `${query}&companies=${Array.from(idsCompanyFilters).join('+')}`
    }

    if (rangeDateFilters.size > 0) {
      query = `${query}&rangeDates=${Array.from(rangeDateFilters).join('+')}`
    }

    if (plataformConnectionFilters.size > 0) {
      query = `${query}&connections=${Array.from(plataformConnectionFilters).join('+')}`
    }

    history.replace({
      search: query
    })
  }, [idsCompanyFilters, plataformConnectionFilters, rangeDateFilters, searchValue])

  /**
   * Views
   */
  const iconToSearchAdvanced = (
    <FilterS className='ms-3' onClick={handleToggleSearchAdvanced}>
      <IconSVG icon='filter' />
    </FilterS>
  )

  const topExtra = (
    <SearchAdvanced isOpen={searchAdvancedIsOpen} onClear={handleOnClearSearchAdvanced} data-component-type={MicroData.CONTENT}>
      {isPocket && idCompany
        ? null
        : (
            <SelectFilter
              icon='company'
              placeholder={intl.formatMessage({ id: 'Selecionar workspace' })}
              onChange={handleOnChangeCompany}
            >
              {companies.map(company => (
                <Text key={company.id} id={company.id} data-search={company.name}>{company.name}</Text>
              ))}
            </SelectFilter>
          )
      }
      <SelectPlatformFilter onChange={handleOnChangeConnections} />
    </SearchAdvanced>
  )

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'Rotinas' })}</title>
      </Helmet>
      <TemplateDefault
        title={intl.formatMessage({ id: 'Rotinas' })}
        isPocket={isPocket}
        isFullContainer={isFullContainer}
      >
        <WrapperActionsHeaderS>
            <SearchFilter search={searchValue} onChangeSearch={handleChangeSearch} debounceTime={500} />
            {iconToSearchAdvanced}
          </WrapperActionsHeaderS>
          {topExtra}
          <TagsS>
            {Array.from(idsCompanyFilters).map(id => (
              <Tag key={id} onClose={() => handleOnCloseCompany(id)}>
                <IconSVG icon='company' />
                <Text>{companies.find(c => c.id === id)?.name}</Text>
              </Tag>
            ))}

            {Array.from(plataformConnectionFilters).map(platform => (
              <Tag key={platform} onClose={() => handleOnCloseConnections(platform)}>
                <IconSVG icon={platform as IconNameI} />
                <Text>{platform}</Text>
              </Tag>
            ))}
          </TagsS>

          {!isLoadingJobs && (searchValue?.length || 0) > 0 && jobs.length === 0
            ? (
              <CardSingleS>
                <Text>{intl.formatMessage({ id: 'Nenhuma rotina encontrada com' }, { search: searchValue })}</Text>
              </CardSingleS>
              )
            : null
          }
          {!isLoadingJobs && (searchValue?.length || 0) === 0 && jobs.length === 0 ? <CardWithoutRoutines idCompany={idCompany} /> : null}
          {isLoadingJobs ? <ListRoutinesLoader /> : null}

          <ListRoutines>
            {jobs.map((job, index) => {
              return (
                <CardRoutine key={`${job.platform}-${job.company.id}-${index}`} routineGrouped={job} />
              )
            })}
          </ListRoutines>
      </TemplateDefault>
    </>
  )
}

export default Routines
