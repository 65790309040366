import styled, { css } from 'styled-components/macro'

export interface RootI {
  size?: 'small' | 'normal' | 'big'
  error?: boolean
}

export const RootS = styled.div`
  max-width: 184px;
  max-height: 92px;
  cursor: pointer;
`

export const RootSelector = styled.div<RootI>`
  background-color: ${(props) => props.theme.colors.white};

  svg {
    max-width: 100%;
    object-fit: cover;
  }
`

export const InputS = styled.span`
  box-sizing: border-box;

  margin-left: 10px;
  margin-right: 10px;
  padding: 2px;

  background-color: none;
  border: 2px solid var(--yellow);
  border-radius: 6px;

  font-size: 11px;
  color: var(--font-gray);
`

interface PlaceholderI {
  size: RootI['size']
}

export const PlaceholderS = styled.div<PlaceholderI>`
  color: var(--font-gray);

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: var(--input-base-label-small-size);
        `
      case 'big':
        return css`
          font-size: var(--input-base-label-big-size);
        `
      default:
        return css`
          font-size: var(--input-base-label-size);
        `
    }
  }}
`

export const IconS = styled.img`
  position: absolute;
  right: 17px;
`
