import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import type { TabI } from 'Components/Tabs'
import Tabs from 'Components/Tabs'
import TemplateDefault from 'Components/TemplateDefault'

import Companies from './Components/Companies.component'
import Invites from './Components/Invites.component'
import Users from './Components/Users.component'

type StepTabsI = 'stats' | 'companies' | 'invites' | 'users'

const SuperAdmin = () => {
  const intl = useIntl()
  const [contentChoosed, setContentChoosed] = useState<StepTabsI>('stats')

  const allTabs = [{
    id: 'companies',
    title: intl.formatMessage({ id: 'Empresas' })
  }, {
    id: 'invites',
    title: intl.formatMessage({ id: 'Convites' })
  }, {
    id: 'users',
    title: intl.formatMessage({ id: 'Usuários' })
  }]

  const handleChangeTabs = (tab: TabI<StepTabsI>) => {
    setContentChoosed(tab.id as StepTabsI)
  }

  const topExtra = (
    <Tabs
      tabs={allTabs}
      onChange={handleChangeTabs}
      forceUpdateOnInitialTab
    />
  )

  const componentContentChoosed = (() => {
    switch (contentChoosed) {
      case 'stats':
        return <h1>stats</h1>
      case 'companies':
        return <Companies />
      case 'invites':
        return <Invites />
      case 'users':
        return <Users />
    }
  })()

  return (
    <TemplateDefault title={intl.formatMessage({ id: 'SuperAdmin' })} topExtra={topExtra}>
      {componentContentChoosed}
    </TemplateDefault>
  )
}

export default SuperAdmin
