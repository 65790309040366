import React from 'react'

import styled from 'styled-components'

const Dot = styled.div`

  @keyframes colorChange {
    0% { background-color: ${({ theme }) => theme.colors.primary_light};}
    33% { background-color: ${({ theme }) => theme.colors.primary_light_hover};}
    66% { background-color: ${({ theme }) => theme.colors.primary_light};}
    100% { background-color: ${({ theme }) => theme.colors.primary};}
  }

  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  display: inline-block;
  animation: colorChange 2s infinite;
`

const DotsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;

  & ${Dot}:nth-child(1) {
    animation-delay: -0.4s;
  }
  & ${Dot}:nth-child(2) {
    animation-delay: -0.2s;
  }
  & ${Dot}:nth-child(3) {
    animation-delay: 0s;
  }
`

const LoadingDots: React.FC = () => (
  <DotsContainer>
    <Dot />
    <Dot />
    <Dot />
  </DotsContainer>
)

export default LoadingDots
