import { useEffect, useState } from 'react'

interface WindowStateInterface {
  width: number,
  height: number
}

export default function useWindowSize () {
  const [windowSize, setWindowSize] = useState<WindowStateInterface | null>(null)

  useEffect(() => {
    function handleResize () {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
