import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import TextField from '@material-ui/core/TextField'

import { addAlert } from 'Store/Actions/alerts.action'
import { setCreateCompany } from 'Store/Actions/Company'
import { companyAvailability } from 'Store/Actions/Company/domain.action'

import IconSVG from 'Components/IconSVG'
import ListSimple from 'Components/List/listSimple.component'
import LoadingDefault from 'Components/Loadings/default.component'
import Text from 'Components/Text'
import Tooltip from 'Components/Tooltip/tooltip.component'

import useDebounce from 'Hooks/useDebounce.hook'

export default function FormUrl () {
  const intl = useIntl()
  const dispatch = useDispatch()
  // @ts-ignore: Unreachable code error
  const company = useSelector((state) => state.company.createCompany)
  const { slug, suggestionSlug } = company
  const [isLoading, setIsLoading] = useState(true)
  const [avaiableSlug, setAvaiableSlug] = useState<boolean | null>(null)
  const slugDebounce = useDebounce(slug, 500)

  const items = [
    intl.formatMessage({ id: 'A sua URL pode conter: letras minúsculas, números e traços' }),
    intl.formatMessage({ id: `Digite o nome da sua instância antes de “.${localStorage.getItem('odash-app:domain-suffix')}”` })
  ]

  async function checkSuggestion () {
    if ((slug === '' || slug === undefined) && (suggestionSlug !== '' && suggestionSlug !== undefined)) {
      const response = await companyAvailability(suggestionSlug)
      const isValid = response.status === 'success'

      if (isValid) {
        setAvaiableSlug(isValid)
        dispatch(setCreateCompany({
          ...company,
          slug: suggestionSlug,
          isValidSlug: isValid
        }))
      } else {
        checkAvailabilityDomain(suggestionSlug + '-1')
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    checkSuggestion()
  }, [])

  async function checkAvailabilityDomain (slug: string) {
    try {
      setAvaiableSlug(null)

      if (slug === '' || slug === undefined) {
        setAvaiableSlug(null)
        return
      }

      const response = await companyAvailability(slug)
      const isValid = response.status === 'success'
      setAvaiableSlug(isValid)
      dispatch(setCreateCompany({
        ...company,
        slug,
        isValidSlug: isValid
      }))
    } catch (e: any) {
      setAvaiableSlug(false)
      dispatch(
        addAlert({ title: e.message, type: 'error' })
      )
      dispatch(setCreateCompany({
        ...company,
        slug,
        isValidSlug: false
      }))
    }
  }

  useEffect(() => {
    checkAvailabilityDomain(slugDebounce)
  }, [slugDebounce])

  function setSlug (slug: string) {
    dispatch(setCreateCompany({
      ...company,
      slug,
      isValidSlug: slug === ''
    }))
  }

  return (
    <div className='page-new-company__form-url'>
      <ListSimple className='page-new-company__form-url__list' items={items} />
      {isLoading ? <LoadingDefault className='page-new-company__form-url__loading' /> : null}
      <form noValidate autoComplete="off" className='shortform'>
        <Tooltip isShowed={!(avaiableSlug === null || avaiableSlug === true)} position='top' text='Esta URL já está em uso, por favor escolha outra opção'>
          <div className='page-new-company__form-url__wrapper-form'>

            {isLoading ? null : <><TextField
              id="enterprise"
              type="text"
              className="page-new-company__form-url__input"
              label={intl.formatMessage({ id: 'URL da sua conta oDash' })}
              placeholder={intl.formatMessage({ id: 'Digite o nome da sua instância' })}
              defaultValue={slug}
              value={slug}
              onChange={event => setSlug(event?.target?.value.replaceAll(' ', ''))} />
              <Text>.{localStorage.getItem('odash-app:domain-suffix')}</Text></>}

            {avaiableSlug !== null ? <IconSVG icon={avaiableSlug ? 'check' : 'error'}/> : null}
          </div>
        </Tooltip>
      </form>
    </div>
  )
}
