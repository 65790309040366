import { LabelS, RootS } from './IconTooltip.styled'

interface IconTooltipPropsI {
  label: string
  children: React.ReactNode
}

const IconTooltip = (props: IconTooltipPropsI) => {
  const { label, children } = props
  return (
    <RootS>
      {children}
      <LabelS>{label}</LabelS>
    </RootS>
  )
}

export default IconTooltip
