import styled from 'styled-components/macro'

export const RootS = styled.a`
  width: 64px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  position: fixed;
  bottom: 20px;
  right: 20px;

  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;

  @media (max-width: 768px) {
    display: none;
  }
`
