import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

import Translate from 'Config/translate.conf'

interface LocaleInterface {
  children:ReactNode
}

interface LocaleContextInterface {
  locale: string,
  setLocale: (locale: any) => void
}

export const LocaleContext = React.createContext<LocaleContextInterface>({
  locale: 'pt-BR',
  setLocale: () => undefined
})

function Locale (props:LocaleInterface) {
  const user = useSelector((state:any) => state.user)
  const [locale, setLocale] = useState<'pt-BR' | 'en'>(user.lang)

  useEffect(() => {
    setLocale(user.lang)
  }, [user.lang])

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider messages={Translate(locale) || {}} locale={locale} defaultLocale="pt-BR" onError={() => undefined}>
        {props.children}
      </IntlProvider>
    </LocaleContext.Provider>
  )
}

export default Locale
