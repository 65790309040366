
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import Text from 'Components/Text'
import Title from 'Components/Text/title.component'

import { getPath } from 'Helpers/Route.helper'

import { DialogS } from './companySuccessful.styled'

export default function CompanySuccessful () {
  const history = useHistory()
  const intl = useIntl()

  const handleGoToNextStep = () => {
    history.replace(getPath('home'))
  }

  return (
    <DialogS isFit onClose={handleGoToNextStep}>
      <Title className='page-company-successful__title'>
        {intl.formatMessage({ id: 'Pronto!' })}<br/>
      </Title>
      <Text className='page-company-successful__text'>
        <FormattedMessage id={'Em breve sua instância estará disponível.'} />
      </Text>
      <Button onClick={handleGoToNextStep} size='large' variant='contained' className='btn btn-light'>
        <FormattedMessage id={'Entendi'} />
      </Button>
    </DialogS>
  )
}
