import { Warning } from '@phosphor-icons/react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button, Col, Input, Modal, Row } from 'DesignSystem'
import styled from 'styled-components'

import { addAlert } from 'Store/Actions/alerts.action'

import { ConnectionFormContext } from 'Components/ConnectionForm/ConnectionForm.context'

import useAccount from 'Hooks/API/useAccount.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

const TriggerButton = styled(Button)`
  background-color: transparent;
  border: none;

  :hover {
    background-color: transparent;
  }
`

function DeleteConnection () {
  const intl = useIntlShort()
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: company_id } = useParams<any>()
  const { data: account } = useContext(ConnectionFormContext)

  const [accountName, setAccountName] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [confirming, setConfirming] = useState<boolean>(false)
  const [valueTypedIsValid, setValueTypedIsValid] = useState(true)

  const { removeAll, removeOnly } = useAccount({
    id: (account && account.id) || undefined
  })

  const disableButton = confirming ? (!valueTypedIsValid || accountName === '') : false

  const validate = useCallback(() => {
    const isValid = account && accountName === account.name
    setValueTypedIsValid(isValid)
    return isValid
  }, [account, accountName])

  const handleDelete = useCallback(async () => {
    if (company_id) {
      await removeOnly(company_id)
    } else {
      await removeAll(account.companies.map((company: any) => company.id))
    }

    dispatch(addAlert({
      type: 'success',
      title: intl('Conexão removida com sucesso!')
    }))

    if (company_id) {
      history.push(generateLinkTo('companyEditStep', { id: company_id, step: 'connections' }))
    } else {
      history.push(generateLinkTo('connections'))
    }
  }, [company_id, account, dispatch, intl, removeAll, removeOnly])

  const handleSubmit = useCallback((e: any) => {
    e.preventDefault()
    if (!confirming) {
      setConfirming(true)
      return
    }

    if (!validate()) {
      return
    }

    handleDelete()
  }, [confirming, handleDelete, validate])

  useEffect(() => {
    if (!isOpen) {
      setConfirming(false)
      setAccountName('')
      setValueTypedIsValid(false)
    }
  }, [isOpen])

  return (
    <div>
      <Row className='align-items-center mt-3'>
        <Col>
          <TriggerButton className='p-0 text-error' color='white' onClick={() => setIsOpen(!isOpen)}>{intl('Deletar conexão')}</TriggerButton>
        </Col>
      </Row>
      <Modal isOpen={isOpen}>
        <form onSubmit={handleSubmit}>
          <div>
            <div className='text-center'>
              <Warning size={52} color='#F7C347' />
            </div>
            {confirming ? (
              <div>
                <h3 className='fw-bold text-center my-4'>
                  {intl('Para sua segurança, digite o nome da conexão para deletá-la.')}
                </h3>
                <Input placeholder={intl('Digite aqui')} value={accountName} onChange={(e: any) => {
                  setValueTypedIsValid(true)
                  setAccountName(e.target.value)
                }}/>
                {!valueTypedIsValid && accountName !== '' &&
                  <small className='text-error text-start'>
                      {intl('O nome digitado não é válido.')}
                  </small>
                }
              </div>
            ) : (
              <div>
                <h3 className='fw-bold text-center'>
                  {intl('Você está prestes a deletar uma conexão')}
                </h3>
                <p className='text-start my-4 px-4'>
                  {intl('Tem certeza que deseja deletar a conexão "X"? Isso fará com que seu workspace pare de receber dados dessa conexão.', { accountName: account && account.name })}
                </p>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-center mt-5'>
            <Button className='me-3' color='success' type='button' onClick={(e) => {
              e.preventDefault()
              setIsOpen(false)
            }}>
              {intl('Manter conexão')}
            </Button>
            <Button color={disableButton ? 'gray' : 'error'} disabled={disableButton} type='submit'>
              {confirming ? intl('Sim, deletar') : intl('Sim, deletar')}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default DeleteConnection
