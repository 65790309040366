import { CheckCircle } from '@phosphor-icons/react'

import type { DataCancelPlanContentManagerI } from 'ActionsContentManager/CancelPlan/Hooks/useStartCancelPlan.hook'
import { format, parseISO } from 'date-fns'
import { Button } from 'DesignSystem'
import theme from 'Theme'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

type CancelPlansModalPropsI = AuxStepContentManagerI<DataCancelPlanContentManagerI>

export const SuccessCancelModal = (props: CancelPlansModalPropsI) => {
  const { close, dataContentManager } = props
  const intl = useIntlShort()
  return (
    <div className='d-flex flex-column align-items-center'>
      <h3 className='fw-bold d-flex flex-row justify-content-center gap-3'><CheckCircle size={24} color={theme.colors.success} />{intl('Cancelamento concluído!')}</h3>
      <p className='text-start w-100 mt-3'>{intl('Você ainda poderá utilizar o NeoDash até DD de MM de AA.', { cancel_at: format(parseISO(dataContentManager.cancel_at || ''), 'dd/MM/yyyy') })}</p>
      <p className='text-start w-100 mt-3'>{intl('Após esse período, armazenaremos seus dados por 30 dias. Caso reative sua assinatura, será possível restaurá-los.')}</p>
      <p className='text-start w-100 mt-3 mb-3'>{intl('Se a assinatura não for reativada, todas as informações serão deletadas de nosso banco de dados.')}</p>
      <Button onClick={close}>{intl('Continuar')}</Button>
    </div>
  )
}

SuccessCancelModal.dialogProps = {
  style: {
    maxWidth: '454px'
  }
}

export default SuccessCancelModal
