import { combineReducers } from 'redux'

import AlertsReducer from './alerts.reducer'
import CompanyReducer from './company.reducer'
import usersReducer from './users.reducer'

export default combineReducers({
  user: usersReducer,
  alerts: AlertsReducer,
  company: CompanyReducer
})
