import styled, { css } from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'
import Text from 'Components/Text'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

export interface RootI {
  size: 'small' | 'normal' | 'big'
}

export const RootS = styled.div<RootI>`
  width: 100%;
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: var(--input-base-small-height);
        `
      case 'big':
        return css`
          height: var(--input-base-big-height);
        `
      default:
        return css`
          height: var(--input-base-height);
        `
    }
  }}

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  position: relative;
  margin: 0 0 var(--base-margin) 0;

  .MuiTextField-root {
    width: 100%;

    margin-bottom: 10px;

    label {
      left: 8px;

      // only using !import cuz label have many specificity on MUI.
      font-family: var(--input-base-label-family) !important;
      font-size: var(--input-base-label-size) !important;
    }

    label + .MuiInput-formControl {
      height: var(--input-base-height-inner);

      box-sizing: border-box;
    }

    label[data-shrink="false"] {
      transform: translate(0, 26px) scale(1);
    }

    .MuiFormLabel-filled,
    label.Mui-focused {
      z-index: 2;

      top: 0;
      padding: 4px 8px;

      background-color: #F7F8F9;

      font-size: 20px;
      color: var(--font-gray);
    }
  }

  button {
    margin-top: 30px;

    color: white;
  }

  input,
  label {
    font-weight: 500;
    color: var(--font-gray);
  }

  input {
    width: 100%;
    ${({ size }) => {
      switch (size) {
        case 'small':
          return css`
            height: var(--input-base-small-height-inner);

            margin-top: calc(var(--input-base-small-height) - var(--input-base-small-height-inner));

            font-size: var(--input-base-label-small-size);
          `
        case 'big':
          return css`
            height: var(--input-base-big-height-inner);

            margin-top: calc(var(--input-base-big-height) - var(--input-base-big-height-inner));

            font-size: var(--input-base-label-big-size);
          `
        default:
          return css`
            height: var(--input-base-height-inner);

            margin-top: calc(var(--input-base-height) - var(--input-base-height-inner));

            font-size: var(--input-base-label-size);
          `
      }
    }}

    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;

    padding: 0 8px;

    background-color: transparent;
    border: var(--input-base-border);
    border-radius: var(--input-base-border-radius);
  }
`

interface WrapperIconValidationI {
  size: SizeComponentI,
  isValid?: boolean | null,
}

export const WrapperIconValidationS = styled.div<WrapperIconValidationI>`
  width: 100%;
  height: var(--input-base-height);

  position: relative;

  ${IconSVG} {
    position: absolute;
    right: 17px;

    fill: ${props => props.isValid ? '#0B9F3E' : '#B2060F'};

    ${({ size }) => {
      switch (size) {
        case 'small':
          return css`
            top: calc(var(--input-base-small-height-inner) / 2);
          `
        case 'big':
          return css`
            top: calc(var(--input-base-big-height-inner) / 2);
          `
        default:
          return css`
            top: calc(var(--input-base-height-inner) / 2);
          `
      }
    }}
  }
`

interface LabelI {
  size: RootI['size']
}

export const LabelS = styled.label<LabelI>`
  position: absolute;
  top: 0;
  left: 8px;
  padding: 1px 8px;

  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;

  background-color: #F7F8F9;

  font-family: var(--input-base-label-family);
  font-weight: 500;
  color: var(--font-gray);

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: var(--input-base-label-small-size);
        `
      case 'big':
        return css`
          font-size: var(--input-base-label-big-size);
        `
      default:
        return css`
          font-size: var(--input-base-label-size);
        `
    }
  }}
`

export const CounterS = styled(Text)`
  width: fit-content;
  height: 16px;

  position: absolute;
  bottom: -19px;
  right: 0;
  margin-left: auto;

  color: var(--font-gray);
`
