import styled from 'styled-components/macro'

export const RootS = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

interface WrapperInnerPropsI {
  showMinimal: boolean
}

export const WrapperInnerS = styled.div<WrapperInnerPropsI>`
  width: 100%;

  display: flex;
  flex-direction: ${({ showMinimal }) => showMinimal ? 'row' : 'column'};
  align-items: center;
`
