import React, { useState, useEffect } from 'react'
import ContentLoader from 'react-content-loader'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

const ListConnectionLoader = (props: any) => {
  const [numberOfRectsPerRow, setNumberOfRectsPerRow] = useState(getNumberOfRectsPerRow())

  function getNumberOfRectsPerRow () {
    const screenWidth = window.innerWidth
    if (screenWidth >= 1440) {
      return 6
    } else if (screenWidth >= 768) {
      return 4
    } else {
      return 2
    }
  }

  useEffect(() => {
    function handleResize () {
      setNumberOfRectsPerRow(getNumberOfRectsPerRow())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const rects = []
  const rectWidth = `${100 / numberOfRectsPerRow - 1}%`
  const numberOfRows = 3

  for (let row = 0; row < numberOfRows; row++) {
    for (let i = 0; i < numberOfRectsPerRow; i++) {
      rects.push(
        <rect
          key={`${row}-${i}`}
          x={`${i * 100 / numberOfRectsPerRow}%`}
          y={`${row * 170}`}
          rx="26"
          ry="26"
          width={rectWidth}
          height="150"
        />
      )
    }
  }

  return (
    <ContentLoader
      speed={2}
      viewBox={`0 0 1920 ${numberOfRows * 170}`}
      backgroundColor="#d9d9d9"
      foregroundColor="#ededed"
      {...passAlongMicroData(props)}
    >
      {rects}
    </ContentLoader>
  )
}

export default ListConnectionLoader
