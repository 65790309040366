import styled, { css } from 'styled-components/macro'

export const RootS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    ${({ theme }) => css(theme.fonts.types.h3)}
    gap: 10px;
  }

  p {
    max-width: 366px;
    ${({ theme }) => css(theme.fonts.types.body2)}
  }
`
