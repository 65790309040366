import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import type { IconNameI } from 'Components/IconSVG/IconSVG.component'
import IconSVG from 'Components/IconSVG/IconSVG.component'

import type { RoutineGroupedI } from 'Hooks/API/useJobs.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

import { RootS, WrapperButtonS } from './CardRoutine.styled'

interface CardRoutinePropsI {
  routineGrouped: RoutineGroupedI
}

const CardRoutine = (props: CardRoutinePropsI) => {
  const { routineGrouped: job } = props
  const history = useHistory()
  const intl = useIntl()
  const handleClickCard = (type: string) => {
    const routine = job.routines.find(r => r.tags.includes(type))

    if (routine) history.push(generateLinkTo('logs', { routine: routine?.id, company: job.company.id }))
  }

  return (
    <RootS>
      <h4 className='fw-bold'>{intl.formatMessage({ id: job.platform })} {job.company.name}</h4>
      <IconSVG size='big' icon={(job.platform ?? job.platform) as IconNameI}/>
      <WrapperButtonS>
        <button onClick={() => handleClickCard('daily-dawn-random')}>
          {intl.formatMessage({ id: 'Rotinas diárias' })}
        </button>
        <button onClick={() => handleClickCard('hourly-days')}>
          {intl.formatMessage({ id: 'Rotinas horárias' })}
        </button>
      </WrapperButtonS>
    </RootS>
  )
}

export default CardRoutine
