import styled, { css } from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

interface RootI {
  size?: SizeComponentI
}

export const RootS = styled(IconSVG)<RootI>`
  ${props => {
    switch (props.size) {
      case 'big':
        return css`
          width: 67px;
          height: 67px;

          svg {
            width: 57px;
            height: 57px;
          }
        `
      default:
        return css`
          width: 40px;
          height: 40px;

          svg {
            width: 30px;
            height: 30px;
          }
        `
    }
  }}

  background-color: white;
  border: 0.5px solid #707070;
  border-radius: 5px;
`
