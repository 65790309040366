import styled, { css } from 'styled-components/macro'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

export interface RootI {
  size: 'small' | 'normal' | 'big'
}

export const RootS = styled.div<RootI>`
  width: 100%;
  /* height: var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height${({ size }) => size === 'normal' ? '-new-style' : ''}); */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  position: relative;
  margin: 0 0 var(--base-margin) 0;

  button {
    margin-top: 30px;

    color: white;
  }

  input,
  label {
    font-weight: 800;
    color: var(--font-gray);
  }

  input {
    height: var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height-inner);

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    padding: 0 13px;

    background-color: white;
    border: none;
    border-radius: var(--input-base-border-radius-new-style);

    font-size: 12px;
  }
`

interface LabelI {
  size: RootI['size']
}

export const LabelS = styled.label<LabelI>`
  padding: 1px 13px;

  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;

  background-color: transparent;

  font-family: var(--input-base-label-family-new-style);
  font-size: var(--input-base-label${({ size }) => size === 'normal' ? '' : '-' + size}-size);
  font-weight: 800;
  color: var(--font-gray);
`

interface OptionsI {
  size: SizeComponentI,
  open: boolean,
  label?: string,
}

export const OptionsS = styled.div<OptionsI>`
  width: 100%;
  max-height: 200px;

  display: none;
  flex-flow: column nowrap;
  overflow: auto;
  z-index: 1001;

  position: absolute;
  top: ${({ label }) => label ? '75px' : '50px'};
  left: 0;

  background-color: #F7F8F9;

  color: #000;

  ${({ open }) => {
    if (open) {
      return css`
        display: flex;
      `
    }
  }}
`

interface InnerI {
  size: SizeComponentI,
  open: boolean
}

export const InnerS = styled.div<InnerI>`
  height: var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height-inner);

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;

  padding: 0 13px;

  background-color: white;
  border: none;
  border-radius: var(--input-base-border-radius-new-style);

  font-size: 12px;
`

export const PlaceholderS = styled.div`
  font-family: "Manrope";
  font-size: 12px;
  color: var(--font-gray);
`

interface CoverPropsI {
  isSelected: boolean
}

export const CoverS = styled.div<CoverPropsI>`
  width: 100%;
  height: 100%;

  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;

  background-color: transparent;

  ${({ isSelected }) => {
    if (isSelected) {
      return css`
        background-color: #FEC34E30;
      `
    }
  }}
`

export const ItemS = styled.div`
  width: 100%;

  position: relative;
  padding: calc(var(--space-2) / 2) 20px;

  border-bottom: 1px solid #D0D0D0;

  font-family: "Manrope";
  font-size: 12px;

  .mark {
    background: #D0D0D0;
  }

  :hover ${CoverS} {
    background-color: #FEC34E10;
  }
`
