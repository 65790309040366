import React, { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useIntl } from 'react-intl'

import useOutsideClick from 'Hooks/useOutsideClick.hook'

import { passAlongMicroData } from 'Helpers/MicroData.helper'
import type { SizeComponentI } from 'Helpers/Styled.helpers'

import { CoverS, InnerS, ItemS, LabelS, OptionsS, PlaceholderS, RootS } from './SelectFieldNewStyle.styled'

interface SelectFieldNewStylePropsI {
  size?: SizeComponentI,
  label?: string,
  placeholder?: string,
  options: Array<{id: string, text: string}>
  value?: string,
  isSearchable?: boolean
  onChange?: (value?: string) => void
}

const SelectFieldNewStyle = (props: SelectFieldNewStylePropsI) => {
  const { size = 'normal', label, placeholder, options, value, onChange = () => undefined, isSearchable } = props
  const intl = useIntl()
  const [optionsIsOpen, setOptionsIsOpen] = useState(false)
  const [search, setSearch] = useState('')

  const optionsRef = useRef(null)
  const searchRef = useRef<HTMLInputElement>(null)

  const regex = new RegExp(search.split(' ').filter(v => v !== '').join('|'), 'gi')
  const optionsFiltered = options.filter(opt => {
    if (!isSearchable) return true
    return regex.test(opt.text.toLowerCase())
  }).sort((a, b) => {
    const countA = a.text.match(regex)?.length ?? 0
    const countB = b.text.match(regex)?.length ?? 0

    if (countA > countB) return -1
    if (countA < countB) return 1
    return 0
  })

  useOutsideClick(optionsRef, () => {
    if (optionsIsOpen) {
      setOptionsIsOpen(false)
    }
  })

  const handleToogleOptions = () => {
    setOptionsIsOpen(!optionsIsOpen)
  }

  const handleClick = (opt: {id: string, text: string}) => {
    if (value === opt.id) {
      onChange(undefined)
    } else {
      onChange(opt.id)
    }

    setSearch('')
    handleToogleOptions()
  }

  useEffect(() => {
    if (optionsIsOpen) searchRef?.current?.focus()
  }, [optionsIsOpen])

  return (
    <RootS onClick={handleToogleOptions} size={size} {...passAlongMicroData(props)} ref={optionsRef}>
      {label && <LabelS size={size}>{label}</LabelS>}
      {isSearchable && optionsIsOpen ? null : <InnerS size={size} open={optionsIsOpen}>
        {placeholder && value === undefined ? <PlaceholderS>{placeholder}</PlaceholderS> : null}
        {options.map(opt => {
          if (value === opt.id) {
            return opt.text
          }

          return (
            null
          )
        })}
      </InnerS>}
      {isSearchable && optionsIsOpen ? <input ref={searchRef} value={search} onChange={(e: any) => { setSearch(e.target.value) }} /> : null}
      <OptionsS size={size} open={optionsIsOpen} label={label}>
        {optionsFiltered.map(opt => {
          return (
            <ItemS key={opt.id} onClick={() => handleClick(opt)}>
              <CoverS isSelected={value === opt.id} />
              <span>
                <Highlighter
                  highlightClassName="mark"
                  searchWords={search.split(' ')}
                  autoEscape={true}
                  caseSensitive={false}
                  textToHighlight={opt.text}
                />
              </span>
            </ItemS>
          )
        })}
        {optionsFiltered.length === 0 ? (
          <ItemS key={'not-found'}>
            <span>{intl.formatMessage({ id: 'Nenhuma opção com essa busca' })}</span>
          </ItemS>
        ) : null}
      </OptionsS>
    </RootS>
  )
}

export default SelectFieldNewStyle
