import styled from 'styled-components/macro'

export const RootS = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const WrapperStepS = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

interface StepPropsI {
  active: boolean;
}

export const StepS = styled.div<StepPropsI>`
  width: 50px;
  height: 6px;
  max-width: 100%;

  margin: 0 10px;

  background-color: ${({ active, theme }) => active ? theme.colors.primary : theme.colors.gray};
  border-radius: 4px;
`
