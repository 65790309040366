import { ArrowLeft } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { format, parseISO } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'

import CalendarFilter from 'Components/CalendarFilter'
import CardContentLarge from 'Components/CardContentLarge/CardContentLarge.component'
import IconSVG from 'Components/IconSVG'
import type { IconNameI } from 'Components/IconSVG/IconSVG.component'
import SearchAdvanced from 'Components/SearchAdvanced'
import SearchFilter from 'Components/SearchFilter'
import Tag from 'Components/Tag'
import TemplateDefault from 'Components/TemplateDefault'
import Text from 'Components/Text'

import useCompany from 'Hooks/API/useCompany.hook'
import useLog from 'Hooks/API/useLog.hook'
import type { RoutineRunI } from 'Hooks/API/useLogs.hook'
import useLogs from 'Hooks/API/useLogs.hook'
import useRoutineDetails from 'Hooks/API/useRoutineDetails.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'
import useQuery from 'Hooks/useQuery.hook'

import { MicroData } from 'Helpers/MicroData.helper'
import { createQuery } from 'Helpers/Url.helper'

import { InfoDetailsS, InfoS } from './Components/CardLog.styled'
import { CardSingleS, DetailedLogS, FilterS, HeaderLogS, HeaderLogTextS, ListLogsS, StatusLogFlag, TagsS, WrapperActionsHeaderS, WrapperLogDetailedS, WrapperLogsS, WrapperPlatformLogoS, WrapperSearchAdvancedS } from './Logs.styled'

const Logs = () => {
  // Hooks
  const intl = useIntlShort()
  const query = useQuery()
  const history = useHistory()
  const { company, routine } = useParams<{company: string, routine: string}>()
  const [searchAdvancedIsOpen, setSearchAdvancedIsOpen] = useState(false)
  const [logSelected, setLogSelected] = useState<RoutineRunI | undefined>()
  const [searchValue, setSearchValue] = useState(query.get('search') || '')
  const [rangeDateFilters, setRangeFilters] = useState<Set<string>>(new Set(query.get('rangeDates')?.split(' ') ?? []))
  const { data: companyDetailed } = useCompany({ id: company })
  const { data: routineDetailed, isLoading: isLoadingRoutineDetailed } = useRoutineDetails({ company, routine })
  const { data: logs, isLoading } = useLogs({ company, routine, search: searchValue, rangeDates: Array.from(rangeDateFilters) })
  const { data: logDetailed } = useLog({ company: company, routine: routine, run: logSelected ? logSelected.id : undefined })

  // Flags
  const showPlatformLogo = !isLoadingRoutineDetailed && routineDetailed
  const showLogDetailed = logSelected && logDetailed

  // Attributes/Values
  const attributesValue = { }
  const attributesLabel = { className: 'fw-bold', ...attributesValue }
  const typeRoutineLabel = routine.includes('daily') ? intl('Rotina diária') : intl('Rotina horária')

  // Effects
  useEffect(() => {
    const query = createQuery()
    query.addParams('search', searchValue || '')
    if (rangeDateFilters.size > 0) query.addParams('rangeDates', Array.from(rangeDateFilters))
    history.replace({ search: query.getValue() })
  }, [rangeDateFilters, searchValue])

  useEffect(() => {
    if (logs.length > 0 && !logSelected) setLogSelected(logs[0])
  }, [logs])

  // Handlers
  const handleChangeSearch = (value: string) => setSearchValue(value)
  const handleToggleSearchAdvanced = () => setSearchAdvancedIsOpen(!searchAdvancedIsOpen)
  const handleOnClearSearchAdvanced = () => setRangeFilters(new Set())
  const handleOnChangeDateFilters = (rangeDate: Date[]) => {
    const cloneRangeDateFilters = cloneDeep(rangeDateFilters)
    cloneRangeDateFilters.add(rangeDate.map(date => format(date, 'dd/MM/yyyy')).join('-'))
    setRangeFilters(cloneRangeDateFilters)
  }

  const handleOnCloseRangeDate = (value: string) => {
    const cloneRangeDateFilters = cloneDeep(rangeDateFilters)

    cloneRangeDateFilters.delete(value)
    setRangeFilters(cloneRangeDateFilters)
  }

  // Views
  const iconToSearchAdvanced = (
    <FilterS onClick={handleToggleSearchAdvanced}>
      <IconSVG icon='filter' />
      <span>{intl('filter')}</span>
    </FilterS>
  )

  const topExtra = (
    <WrapperSearchAdvancedS>
      <SearchAdvanced isOpen={searchAdvancedIsOpen} onClear={handleOnClearSearchAdvanced}>
        <CalendarFilter
          placeholder={intl('Período de execução')}
          onChange={handleOnChangeDateFilters}
        />
      </SearchAdvanced>
      <TagsS>
        {Array.from(rangeDateFilters).map(rangeDate => (
          <Tag key={rangeDate} onClose={() => handleOnCloseRangeDate(rangeDate)}>
            <IconSVG icon='calendar' />
            <Text>{rangeDate}</Text>
          </Tag>
        ))}
      </TagsS>
    </WrapperSearchAdvancedS>
  )

  const viewPlatformLogo = showPlatformLogo && (
    <WrapperPlatformLogoS>
      <ArrowLeft size={24} cursor={'pointer'} onClick={() => history.goBack()} />
      <IconSVG className='icon-platform' size='big' icon={(routineDetailed?.platform) as IconNameI} />
      <div className="d-flex flex-column">
        <label className='fw-bold'>{intl(routineDetailed.platform)}</label>
        <span>{typeRoutineLabel}</span>
      </div>
    </WrapperPlatformLogoS>
  )

  return (
    <TemplateDefault
      title={intl('Logs')}
    >
      <CardContentLarge withoutPaddingOnSide={true} header={(
        <WrapperActionsHeaderS>
          {viewPlatformLogo}
          {iconToSearchAdvanced}
          <SearchFilter search={searchValue} onChangeSearch={handleChangeSearch} debounceTime={500} />
        </WrapperActionsHeaderS>
      )}>
        {topExtra}
        {!isLoading && (searchValue?.length || 0) > 0 && logs.length === 0
          ? (
            <CardSingleS>
              <Text>{intl('Nenhum log encontrado com', { search: searchValue })}</Text>
            </CardSingleS>
            )
          : null
        }
        {!isLoading && (searchValue?.length || 0) === 0 && logs.length === 0
          ? (
            <CardSingleS>
              <Text>{intl('Nenhum log encontrado')}</Text>
            </CardSingleS>
            )
          : null
        }
        <WrapperLogsS>
          <ListLogsS>
        {logs.map((log, index) => {
          return (
            <HeaderLogS key={`${log.id}-${index}`} onClick={() => setLogSelected(log)} isSelected={logSelected?.id === log.id}>
              <StatusLogFlag hasError={log.state !== 'success'} />
              <HeaderLogTextS>
                <label className='fw-bold'>{intl(routineDetailed?.platform ?? '')} {companyDetailed?.name}</label>
                <p>{intl('Horário de execução:')} {format(parseISO(log.start_date), 'dd/MM/yyyy HH:mm:ss')}</p>
              </HeaderLogTextS>
            </HeaderLogS>
          )
        })}
        </ListLogsS>
        <DetailedLogS>

          {showLogDetailed

            ? <WrapperLogDetailedS>
            <InfoDetailsS data-component-type={MicroData.INFO_FROM_MODEL}>
            <InfoS isExtended>
              <div className="d-flex flex-row align-items-center mt-2 gap-1">
                <label {...attributesLabel}>{intl('Nome')}:</label>
                <span {...attributesValue}>{logSelected.routine.id}</span>
              </div>

              <div className="d-flex flex-row align-items-center mt-2 gap-1">
                <label {...attributesLabel}>{intl('Início da requisição')}:</label>
                <span {...attributesValue}>{format(new Date(logSelected.start_date), 'dd/MM/yyyy HH:mm:ss')}</span>
              </div>

              <div className="d-flex flex-row align-items-center mt-2 gap-1">
                <label {...attributesLabel}>{intl('Duração')}:</label>
                <span {...attributesValue}>{logSelected.duration}</span>
              </div>

              <div className="d-flex flex-row align-items-center mt-2 gap-1">
                <label {...attributesLabel}>{intl('Tem erro?')}:</label>
                <span {...attributesValue}>{intl(logSelected.state !== 'success' ? 'Sim' : 'Não')}</span>
              </div>
            </InfoS>

            <InfoS>
              <div className="d-flex flex-row align-items-center mt-2 gap-1">
                <label {...attributesLabel}>{intl('ID')}:</label>
                <span {...attributesValue}>{logSelected.id}</span>
              </div>

              <div className="d-flex flex-row align-items-center mt-2 gap-1">
                <label {...attributesLabel}>{intl('Fim da requisição')}:</label>
                <span {...attributesValue}>{format(new Date(logSelected.end_date), 'dd/MM/yyyy HH:mm:ss')}</span>
              </div>

              <div className="d-flex flex-row align-items-center mt-2 gap-1">
                <label {...attributesLabel}>{intl('Tempo programado para executar')}:</label>
                <span {...attributesValue}>{logSelected.routine.schedule}</span>
              </div>
            </InfoS>

          </InfoDetailsS>

          <InfoS className='mt-2' isExtended>
              <label {...attributesLabel}>{intl('Conteúdo')}:</label>
              <span className='log' style={{ maxWidth: '800px' }} {...attributesValue}>{logDetailed.map(l => l.logs).join('\n')}</span>
            </InfoS>
          </WrapperLogDetailedS>
            : null}
        </DetailedLogS>
        </WrapperLogsS>
      </CardContentLarge>

    </TemplateDefault>
  )
}

export default Logs
