const permissions = {
  CREATE_COMPANY: 'CreateCompany',
  EDIT_COMPANY: 'admin_editcompany',
  MANAGE_TOKENS: 'admin_managetokens',
  EDIT_CAMPAIGN: 'campanha_read',
  EDIT_USERS: 'usuario_read',
  EDIT_GROUP: 'grupo_read',
  INVITE_USER_TO_COMPANY: 'CanInviteUserCompany',
  INVITE_USER_TO_APP: 'CanInviteUserApp',
  ADD_TOKEN_ADWORDS: 'AddTokenAdwords',
  ADD_TOKEN_ANALYTICS: 'AddTokenAnalytics',
  ADD_TOKEN_DOUBLE_CLICK: 'AddTokenDoubleClick',
  ADD_TOKEN_CAMPAIGN_MANAGER: 'AddTokenCampaignManager',
  ADD_TOKEN_FACEBOOK: 'AddTokenFacebook',
  ADD_TOKEN_TWITTER: 'AddTokenTwitter',
  ADD_TOKEN_VTEX: 'AddTokenVtex',
  ADD_TOKEN_LINKEDIN: 'AddTokenLinkedin',
  ADD_TOKEN_MICROSOFT: 'AddTokenMicrosoft',
  ADD_TOKEN_CRITEO: 'AddTokenCriteo',
  ADD_TOKEN_LINX: 'AddTokenLinx',
  ADD_TOKEN_TABOOLA: 'AddTokenTaboola',
  ADD_TOKEN_AMAZON_ADS: 'AddTokenAmazonAds',
  ADD_TOKEN_YOUTUBE: 'AddTokenYoutube',
  ADD_TOKEN_SALESFORCE: 'AddTokenSalesforce',
  ADD_TOKEN_RD_STATION: 'AddTokenRdStation',
  ADD_TOKEN_PIPEDRIVE: 'AddTokenPipedrive',
  ADD_TOKEN_HUBSPOT: 'AddTokenHubSpot',
  IS_SUPPORT: 'Support'
}

export default permissions
