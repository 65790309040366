import useSWR from 'swr'

import { fetcher } from './fetcher'

export interface TimezoneI {
  id: string,
}

interface ReturnI {
  data: Array<TimezoneI>,
  isLoading: boolean,
  isError: boolean
}

const useTimezones = () : ReturnI => {
  const { data, error } = useSWR<Array<TimezoneI>>('timezones', fetcher('GET'), {
    refreshInterval: 0
  })

  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useTimezones
