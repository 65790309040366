import { addAlert } from 'Store/Actions/alerts.action'

import { apiPOST } from 'Services/Api.service'

interface userDataInterface {
  newPassword: string,
  email: string,
  recoveryCode: string,
}

export const saveNewPassword = async (userData:userDataInterface) => {
  const request = await apiPOST<any>(`reset-password/${userData.recoveryCode}`, { password: userData.newPassword })
  const response = await request
  if (response.status === 'error') {
    return addAlert({ title: response.message, type: 'error' })
  } else {
    return addAlert({ title: 'Senha resetada com sucesso!', type: 'success' })
  }
}

export const recoverPassword = async (email:string, slug?: string) => {
  const response = await apiPOST<any>('recover-password', { email, slug })

  if (response.status === 'error') {
    return addAlert({ title: response.message, type: 'error' })
  } else {
    return { type: 'NOTHING', payload: { type: 'NOTHING' } }
  }
}
