import { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { Button, Col, Row } from 'DesignSystem'
import LoadingDots from 'DesignSystem/LoadingDots'

import { addAlert } from 'Store/Actions/alerts.action'

import ConnectionForm from 'Components/ConnectionForm'
import { ConnectionFormContext, ConnectionFormContextProvider } from 'Components/ConnectionForm/ConnectionForm.context'

function InformDataPlatform ({
  next,
  prev,
  setSizeDialog,
  dataContentManager,
  setShowFooter,
  close
}: any) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { isANewConnection } = dataContentManager
  const {
    data,
    isLoading,
    name,
    goUpdate
  } = useContext(ConnectionFormContext)
  const isDisabled = !name

  useEffect(() => {
    setSizeDialog('sm')
    setShowFooter(isANewConnection)
  }, [isLoading])

  const update = async () => {
    await goUpdate()

    dispatch(
      addAlert({
        title: intl.formatMessage({ id: 'Conexão criada!' }),
        type: 'success'
      })
    )

    next()
  }

  return (!isLoading
    ? <Row className="flex-column">
        <Col className="text-center mt-3">
          <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Configurar conexão' })}</h2>
          <p className='text-start body2'>
            {intl.formatMessage({ id: 'A conexão com' })}{' '}
            <b>
              {data?.platform}
            </b>
            {' '}
            {intl.formatMessage({ id: 'foi feita com sucesso. Preencha os campos abaixo para concluir a configuração.' })}
          </p>
        </Col>
      <ConnectionForm/>
      <Col className="mt-5 text-center">
        <Button color="gray" onClick={prev}>
          {intl.formatMessage({ id: 'Cancelar' })}
        </Button>
        <Button onClick={update} className="ms-3" disabled={isDisabled}>
          {intl.formatMessage({ id: 'Avançar' })}
        </Button>
      </Col>
    </Row>
    : <div className='my-5'>
      <LoadingDots />
    </div>
  )
}

const InformDataConnectionWithContext = (props: any) => {
  return (
    <ConnectionFormContextProvider>
      <InformDataPlatform {...props} />
    </ConnectionFormContextProvider>
  )
}

export default InformDataConnectionWithContext
