
const INITIAL_STATE = {
  current: {},
  createCompany: {
    isValidName: false,
    isValidSlug: false,
    isValidIcon: null,
    color: '#CCCCCC'
  }
}

interface reducerInterface {
  type: 'SET_CURRENT_COMPANY' | 'SET_CREATE_COMPANY' | 'CLEAR_CREATE_COMPANY',
  payload: any
}

function companyReducer (state = INITIAL_STATE, action:reducerInterface) {
  switch (action.type) {
    case 'SET_CURRENT_COMPANY':
      return {
        ...state,
        current: action.payload
      }
    case 'SET_CREATE_COMPANY':
      return {
        ...state,
        createCompany: action.payload
      }
    case 'CLEAR_CREATE_COMPANY':
      return {
        ...state,
        createCompany: INITIAL_STATE.createCompany
      }
    default:
      return state
  }
}

export default companyReducer
