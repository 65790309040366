import React from 'react'

import { SelectAdvanced } from 'DesignSystem'

import type { DataCompanyCreateContentManagerI } from 'Components/FirstSetupFlow'

import useTimezones from 'Hooks/API/useTimezones.hook'
import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'

type NameCompanyCreateDialogPropsI =
  AuxStepContentManagerI<DataCompanyCreateContentManagerI>;

const TimezoneWorkspaceField = ({
  setData,
  dataContentManager
}: NameCompanyCreateDialogPropsI) => {
  const { timezone } = dataContentManager
  const { data: dataTimezone } = useTimezones()

  return (
    <>
    <SelectAdvanced value={{ value: timezone, label: timezone }} onChange={(ops: any) => setData({ timezone: ops.value }) } className="mt-2" options={dataTimezone.map(t => ({ value: t.id, label: t.id })) }/>
    </>
  )
}

export default TimezoneWorkspaceField
