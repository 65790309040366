import React from 'react'

import styled from 'styled-components'

const ProgressBarContainer = styled.div`
  width: 100%;

  background-color: #f2f2f2;
  border-radius: 4px;
`

const ProgressBarFill = styled.div<any>`
  width: ${(props: any) => props.progress}%;
  height: 8px;

  transition: width 0.3s ease-in-out;

  background-color: #1e90ff;
  border-radius: 4px;
`

const ProgressBar = ({ progress } : any) => {
  return (
    <ProgressBarContainer>
      <ProgressBarFill progress={progress} />
    </ProgressBarContainer>
  )
}

export default ProgressBar
