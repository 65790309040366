import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { loadCompanyAccountLog } from 'Store/Actions/Company'

import LoadingDefault from 'Components/Loadings/default.component'

import useQuery from 'Hooks/useQuery.hook'

import HeaderButton from './components/headerButton'

const CompanyAccountLog = () => {
  const params = useParams<{ id: string, idLog: string }>()
  const query = useQuery()
  const [log, setLog] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { id: company, idLog } = params

  const loadLog = async () => {
    const log = await loadCompanyAccountLog(company, idLog)
    setLog(log)
    setLoading(false)
  }

  useEffect(() => {
    loadLog()
    setLoading(true)
  }, [])

  if (loading) {
    return (
      <div className='page-accounts'>
        <LoadingDefault />
      </div>
    )
  }

  return (
    <div className='page-accounts' style={{ color: 'black' }}>
      <HeaderButton tokenManager logs={query.get('typeJobs')} account={query.get('account')} company={company} />
      <br/>
      <div>
        <h2 className='h5'>Categoria</h2>
        <p>{log.categoryJob}</p>
      </div>
      <div>
        <h2 className='h5'>Nome</h2>
        <p>{log.nameJob}</p>
      </div>
      <div>
        <h2 className='h5'>Inicio da requisição</h2>
        <p>{ log.started }</p>
      </div>
      <div>
        <h2 className='h5'>Fim da requisição</h2>
        <p>{ log.finished }</p>
      </div>
      <div>
        <h2 className='h5'>Duração</h2>
        <p>{ log.duration }</p>
      </div>
      <div>
        <h2 className='h5'>Mensagem de retorno</h2>
        <pre>{log.message }</pre>
      </div>
      <div>
        <h2 className='h5'>Conteúdo do JOB</h2>
        <p>{ JSON.stringify(log.content) }</p>
      </div>
      <div>
        <h2 className='h5'>Tipo</h2>
        <p>{ log.typeJob }</p>
      </div>
      <div>
        <h2 className='h5'>Tempo programado para executar</h2>
        <p>{ log.executionTime }</p>
      </div>
    </div>
  )
}

export default CompanyAccountLog
