import { Modal } from 'DesignSystem'

import useContentManager from 'Hooks/useContentManager.hook'

import ChooseWorkspaceDowngradeModal from '../../DowngradePlan/Components/ChooseWorkspaceDowngradeModal'
import DowngradeCheckModal from '../../DowngradePlan/Components/DowngradeCheckModal'
import FeedbackDowngradeModal from '../../DowngradePlan/Components/FeedbackDowngradeModal'
import FinalFeedbackDowngradeModal from '../../DowngradePlan/Components/FinalFeedbackDowngradeModal'
// import SolutionModal from '../../DowngradePlan/Components/SolutionModal'

export interface DataStartDowngradePlanContentManagerI {
  planID?: string,
  isDowngrade?: boolean,
  downgradeInfo?: {
    workspaces: {
      from: number,
      to: number
    },
    connectionsOff: Array<string>
  },
  scheduled?: string,
  totalToSelect?: number
}

export const useStartDowngradePlan = (planID?: string) => {
  const { start, content } = useContentManager<DataStartDowngradePlanContentManagerI>([
    DowngradeCheckModal,
    FeedbackDowngradeModal,
    // SolutionModal,
    ChooseWorkspaceDowngradeModal,
    FinalFeedbackDowngradeModal
  ], {
    initialData: {
      planID
    },
    dialog: Modal,
    dialogProps: {
    }
  })

  return { start, content }
}
