import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { Button, Col, Row } from 'DesignSystem'

import Image from 'Components/Image'

function WorkspaceInfo ({ setSizeDialog, dataContentManager, setShowFooter, prev, next }: any) {
  const intl = useIntl()
  useEffect(() => {
    setSizeDialog('lg')
    setShowFooter(false)
  }, [setSizeDialog])

  return (
    <Row className="text-center flex-column">
      <Col>
        <img src="/images/isotype.png" width={32} alt="Logo" />
      </Col>
      <Col className="mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Quase Lá...' })}</h2>
      </Col>
      <Col className="mt-3">
        <p className="body1 text-center">
        {intl.formatMessage({ id: 'Verifique se está tudo certo para finalizar a configuração do seu workspace.' })}
        </p>
      </Col>
      <Col>
        <div className='mt-3'>
          {
            dataContentManager.icon
              ? <Image src={dataContentManager.icon} alt='Workspace' style={{
                maxWidth: '200px',
                borderRadius: '10px'
              }}/>
              : <Image src='/images/no-image.png' alt='No Image' style={{
                maxWidth: '200px',
                borderRadius: '10px'
              }}/>
          }
        </div>
        <div className='mt-2'>
          <h1 className='fw-bold'>{dataContentManager.name}</h1>
          <p className='fw-bold'>https://{dataContentManager.slug}.{localStorage.getItem('odash-app:domain-suffix')}</p>
        </div>
      </Col>
      <Col className="mt-5 text-center">
        <Button color="gray" onClick={prev}>
          {intl.formatMessage({ id: 'Voltar' })}
        </Button>
        <Button className="ms-3" onClick={next}>
          {intl.formatMessage({ id: 'Concluir' })}
        </Button>
      </Col>
    </Row>
  )
}

export default WorkspaceInfo
