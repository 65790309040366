import breakpoint from 'styled-components-breakpoint'
import styled, { css } from 'styled-components/macro'

import Title from 'Components/Title'

export const RootS = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  background-attachment: fixed;
  background-size: cover;
  position: relative;
`

export const HeaderS = styled.div`
  width: 100%;
  height: 70px;

  z-index: 99;

  position: sticky;
  top: var(--base-app-bar-height);

  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 50;

  background-color: ${(props) => props.theme.colors.gray60};
`

export const HeaderInnerS = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

interface WrapperTitleI{
  haveSomeChildrenWithFullContainer?: boolean,
}

export const WrapperTitleS = styled.div<WrapperTitleI>`
  ${props => {
    if (props.haveSomeChildrenWithFullContainer) {
      return css`
        width: var(--base-content-width);
        margin: 0 auto;
      `
    }
  }}
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    ${(props) => css(props.theme.fonts.types.h3)}
  }
`

export const TitleS = styled(Title)`
  margin-right: 30px;
`

interface ContentI {
  haveSomeChildrenWithFullContainer: boolean,
}

export const ContentS = styled.div<ContentI>`
  margin: 0;

  display: flex;
  box-sizing: content-box;
  flex: 1 1 auto;
  flex-direction: column;
`

interface ContentInnerI {
  isBaseContentWidth?: boolean
}

export const ContentInnerS = styled.div<ContentInnerI>`
  width: ${props => props.isBaseContentWidth ? 'var(--base-content-width)' : '100%'};

  display: flex;
  box-sizing: border-box;
  flex-direction: column;
`

interface HeaderPocketI {
  isBaseContentWidth?: boolean
}

export const HeaderPocketS = styled.div<HeaderPocketI>`
  width: ${props => props.isBaseContentWidth ? 'var(--base-content-width)' : '100%'};
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const TopExtraS = styled.div`
  width: 100%;
  height: 70px;

  z-index: 99;

  position: fixed;
  top: var(--base-app-bar-height);
  margin-left: 0;

  ${breakpoint('tablet')`
    margin-left: var(--base-menu-width);
  `}
`
