import styled, { css } from 'styled-components/macro'

export const RootS = styled.div`

`

export const HeaderS = styled.div<{ successful?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: ${({ theme, successful }) => successful ? theme.colors.success : theme.colors.error};
  color: white;

  h3 {
    color: white;
    margin: 0;
    ${({ theme }) => css(theme.fonts.types.h3)};
  }
`

export const ContentS = styled.div`
  padding: 0 24px 24px;
`

export const ContentPaymentS = styled.div`
  border-radius: 10px;
  border: 1px solid var(--Grey-Nickel, #C6C6C5);
  padding: 16px;
`
