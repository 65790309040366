import type { ReactNode } from 'react'
import React from 'react'

import styled from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

import { ContentS, RootS } from './Tag.styled'

interface TagPropsI {
  size?: SizeComponentI,
  onClose?: () => void,
  children?: ReactNode,
  className?: string,
}

const Tag = (props: TagPropsI) => {
  const { size, children, onClose = () => undefined, className } = props

  return (
    <RootS size={size} className={className}>
      <ContentS>
        {children}
      </ContentS>
      <IconSVG icon='close' onClick={onClose} />
    </RootS>
  )
}

export default styled(Tag)``
