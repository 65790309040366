import styled, { css } from 'styled-components/macro'

import Card from 'Components/Card'
import IconSVG from 'Components/IconSVG'
import Text from 'Components/Text'

interface CardI {
  hasError?: boolean,
}

export const CardS = styled(Card)<CardI>`
  ${props => {
    if (props.hasError) {
      return css`
        background-color: #A7292910;
      `
    }
  }}

  transition: height 500ms;
`

export const DivisorS = styled.div`
  width: 100%;
  height: 1px;

  background-color: #70747420;
`

interface DetailButtonI {
  isOpen: boolean
}

export const DetailButtonS = styled.button<DetailButtonI>`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  cursor: pointer;

  background-color: transparent;
  border: none;

  ${Text} {
    width: fit-content !important;

    display: block;
    cursor: pointer;

    color: var(--yellow);
    white-space: nowrap;
  }

  ${IconSVG} {
    svg {
      transform: rotate(${props => props.isOpen ? '-90deg' : '90deg'});
      transition: transform 500ms;
    }
  }

  ${Text} + ${IconSVG} {
    margin-left: var(--space-1);
  }
`

export const InfoHeaderS = styled.div`
  display: flex;
  flex-direction: row;

  ${Text},
  ${DetailButtonS} {
    width: 16.6%;
  }

  ${Text} {
    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap;
  }
`

interface InfoI {
  isExtended?: boolean
}

export const InfoS = styled.div<InfoI>`
  display: flex;
  flex-direction: column;

  label, span {
    ${({ theme }) => css(theme.fonts.types.subtitle3)}
  }

  span.log {
    height: 500px;
    overflow: auto;
  }
`

export const InfoDetailsS = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`
