import useSWR from 'swr'

import { fetcher } from './fetcher'

interface CompanyI {
  id: string,
  name: string,
  creation: string,
  timezone: string,
  icon: string,
  slug: string,
  color: string,
  status_instance: string,
}

export interface UserI {
  id: string,
  name: string,
  creation: string,
  locale: string,
  is_admin: string,
  email: string,
  email_confirmation: string,
  companyIsOwner: Array<CompanyI>,
  companyHaveAccess: Array<CompanyI>
}

interface ReturnI {
  data: Array<UserI>,
  isLoading: boolean,
  isError: boolean
}

const useCompaniesConsole = () : ReturnI => {
  const { data, error } = useSWR<{users: Array<UserI>}>('console/users', fetcher('GET'))

  return {
    data: data?.users ?? [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useCompaniesConsole
