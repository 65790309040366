import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import toUpper from 'lodash/toUpper'
import moment from 'moment'

import { loadCompanyAccount } from 'Store/Actions/Company'

import { generateLinkTo } from 'Helpers/Route.helper'

import HeaderButton from './components/headerButton'

function getIssuedAtFromToken (token: TokenAccountInterface) {
  if (!token) return null

  const { issued_at, created } = token

  if (!created && !issued_at) return null

  return created
    ? Number(created) * 1000
    : issued_at
}

interface TokenAccountInterface {
  oauth_token: string,
  oauth_token_secret: string,
  screen_name: string,
  issued_at: string,
  created: string,
  user_id: string,
}

interface AccountResponseInterface {
  id: string,
  emails: string[],
  companies: Array<{
    id: string,
    name: string,
  }>,
  platform: string,
  name: string,
  token: TokenAccountInterface,
  token_expiration: string,
  token_timestamp: string,
  external_id: string,
}

interface AccountTokenInterface {
  account: AccountResponseInterface
}

const AccountToken = (props: AccountTokenInterface) => {
  // const {account: {token, token_expiration, token_timestamp}}, {moment})
  const { token, token_expiration, token_timestamp } = props.account
  const issuedAt = getIssuedAtFromToken(token) || token_timestamp

  return (
    <div>
      <h4>
        Token de acesso
      </h4>

      <dl>
        <dt>
          Emitido
        </dt>
        <dd>{moment(issuedAt).fromNow()}</dd>
        <dt>
          Expira
        </dt>
        <dd>
          {token_expiration
            ? moment(token_expiration).fromNow()
            : '--'}
        </dd>
      </dl>

      <pre className='well' style={{ wordWrap: 'break-word' }}>
        {JSON.stringify(token, null, 2)}
      </pre>

    </div>
  )
}

const AccountEdit = () => {
  const { idAccount, id: idCompany } = useParams<{idAccount: string, id: string}>()
  const [account, setAccount] = useState<AccountResponseInterface>()
  const location = useLocation()
  const here = process.env.REACT_APP_FRONT_URL + location.pathname

  const loadAccountRedux = async () => {
    const response = await loadCompanyAccount(idAccount)
    setAccount(response)
  }

  useEffect(() => {
    loadAccountRedux()
  }, [])

  if (!account) {
    return (
      <div>Carregando...</div>
    )
  }

  const {
    id,
    emails,
    companies,
    platform,
    name,
    token,
    external_id
  } = account

  return (
    <div className='page-account-edit'>
      <HeaderButton tokenManager company={idCompany} />
      <div className='panel panel-default'>
        <div className='panel-heading'>
          {toUpper(platform)} :: {name || external_id}
        </div>
        <div className='panel-body'>
          <div className='row'>
            <div className='col-sm-6'>
              <h4>
                Informações da conta
              </h4>
              <dl>

                <dt>Plataforma</dt>
                <dd>{platform}</dd>

                {name && (
                  <dt>
                    Nome da conta
                  </dt>)}

                {name && <dd>{name}</dd>}

                {companies && <dt>Empresas associadas</dt>}
                {companies && <dd>{companies.map(c => c.name).join(', ')}</dd>}

                {emails && <dt>Email</dt>}
                {emails && <dd>{emails.join(', ')}</dd>}

                <dt>
                  ID Externo
                </dt>
                <dd>{external_id}</dd>
              </dl>
            </div>
            <div className='col-sm-6'>
              {token && <AccountToken account={account}/> }
            </div>
          </div>

          <hr/>
          <p>
            <a
              className='ladda-button pull-right'
              data-size='s'
              data-color='mint'
              href={`${process.env.REACT_APP_TKM_URL}/account/${id}/login/${platform}?next=${here}`}>

              <span className='ladda-label'>
                Renovar token
              </span>

            </a>
            <br />
            <Link
              className='ladda-button pull-right'
              data-size='s'
              data-color='mint'
              to={`${generateLinkTo('jobsByType', { id: idCompany, typeJobs: platform })}?account=${idAccount}`}>

              <span className='ladda-label'>
                Todas as rotinas
              </span>

            </Link>
            <br />
            <Link
              className='ladda-button pull-right'
              data-size='s'
              data-color='mint'
              to={`${generateLinkTo('logsByJobs', { id: idCompany, typeJobs: platform })}?account=${idAccount}`}>

              <span className='ladda-label'>
                Logs
              </span>

            </Link>

          </p>
        </div>
      </div>
    </div>
  )
}

export default AccountEdit
