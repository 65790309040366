import { ArrowRight } from '@phosphor-icons/react'

import Image from 'Components/Image'

import useIntlShort from 'Hooks/useIntlShort.hook'

import { QuestionsCardRoot } from '../PlansModal.styled'

function QuestionsCard ({ type }: any) {
  const intl = useIntlShort()
  return (
    <QuestionsCardRoot type={type}>
      <div>
        <Image
          src="/images/logos/isotype.png"
          alt="Logo"
          style={{ maxWidth: '44px' }}
          />
      </div>
      <a className='ms-2 text-decoration-none' href='https://calendly.com/lucas-vernieri' target='_blank' rel='noreferrer'>
        <h3 className='m-0 fw-bold'>{intl('Alguma dúvida?')}</h3>
        <p className='d-flex text-nowrap align-items-center text-decoration-underline' >
          {intl('Fale conosco')}
          <ArrowRight weight='bold' />
        </p>
      </a>
    </QuestionsCardRoot>
  )
}

export default QuestionsCard
