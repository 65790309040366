import styled from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'
import LoadingDefault from 'Components/Loadings/default.component'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

export interface RootI {
  size: 'small' | 'normal' | 'big'
}

export const RootS = styled.div<RootI>`
  width: 100%;
  /* height: var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height${({ size }) => size === 'normal' ? '-new-style' : ''}); */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  position: relative;
  margin: 0 0 var(--base-margin) 0;

  button {
    margin-top: 30px;

    color: white;
  }

  input,
  label {
    font-weight: 800;
    color: var(--font-gray);
  }

  input {
    width: 100%;
    height: var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height-inner);

    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;

    margin-top: calc(var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height) - var(--input-basei${({ size }) => size === 'normal' ? '' : '-' + size}-height-inner));

    background: transparent;
    border: none;
    outline: none;

    font-size: 12px;

    input::placeholder {
      font-family: var(--input-base-label-family-new-style);
      font-size: 12px;
      font-weight: 700;
    }
  }
`

interface WrapperIconValidationI {
  size: SizeComponentI,
  isValid?: boolean | null,
}

export const WrapperIconValidationS = styled.div<WrapperIconValidationI>`
  width: 100%;
  height: var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height-inner);

  position: relative;

  ${IconSVG},
  ${LoadingDefault} {
    position: absolute;
    top: calc(var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height-inner) / 2 - 10px);
    right: 17px;

    fill: ${props => props.isValid ? '#0B9F3E' : '#B2060F'};
  }

  ${LoadingDefault} {
    width: 20px !important;
    height: 20px !important;
  }
`

interface LabelI {
  size: RootI['size']
}

export const LabelS = styled.label<LabelI>`
  padding: 1px 13px;

  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;

  background-color: transparent;

  font-family: var(--input-base-label-family-new-style);
  font-size: var(--input-base-label${({ size }) => size === 'normal' ? '' : '-' + size}-size);
  font-weight: 800;
  color: var(--font-gray);
`

interface WrapperInputI {
  size: RootI['size']
}

export const WrapperInputS = styled.div<WrapperInputI>`
  height: var(--input-base${({ size }) => size === 'normal' ? '' : '-' + size}-height-inner);

  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  padding: 0 13px;

  background-color: white;
  border: none;
  border-radius: var(--input-base-border-radius-new-style);

  span {
    font-family: var(--input-base-label-family-new-style);
    font-size: 12px;
    font-weight: 700;
    color: #D0D0D0;
  }
`
