import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import snakeCase from 'lodash/snakeCase'

import { useWindowSize } from 'Hooks/useAspectRatio.hook'

import { IndicatorS, RootS, TabS, WrapperS } from './Tabs.styled'

export interface TabI<T> {
  id: number | string | T
  title: string
  onClick?: () => void
  href?: string
}

interface TabsPropsI<T> {
  forceUpdateOnInitialTab?: boolean
  initialTab?: string
  tabs: Array<TabI<T>>
  onChange?: (tab: TabI<T>) => void
}

const Tabs = <T, >(props: TabsPropsI<T>) => {
  const {
    initialTab,
    tabs,
    onChange = () => undefined,
    forceUpdateOnInitialTab
  } = props
  const initialIndex = initialTab
    ? tabs.findIndex((tab) => tab.id === initialTab)
    : 0
  const [active, setActive] = useState(initialIndex === -1 ? 0 : initialIndex)
  const windowSize = useWindowSize()
  const [positionIndicator, setPositionIndicator] = useState({
    left: '0px',
    width: '0px',
    scrollLeft: '0px'
  })

  const setNewActive = (index: number, triggerOnChange = true) => {
    const tab = tabs[index]
    const id = snakeCase(tab.title)
    const element = document.getElementById(id)

    if (tab.href) {
      return
    }

    if (tab.onClick) {
      tab.onClick()
      return
    }

    const elementWrapper = document.getElementById('wrapper-tab')
    const elementTabRoot = document.getElementById('tab-root')

    if (elementTabRoot) {
      elementTabRoot.scrollTo({
        left:
          (element?.offsetLeft ?? 0) -
          ((elementWrapper?.clientWidth ?? 0) - (element?.clientWidth ?? 0)) /
            2,
        behavior: 'smooth'
      })
    }

    setActive(index)
    setPositionIndicator({
      width: `${element?.clientWidth ?? '0'}px`,
      left: `${element?.offsetLeft ?? '0'}px`,
      scrollLeft: '0px'
    })

    if (triggerOnChange) onChange(tab)
  }

  useEffect(() => {
    setNewActive(active, false)
  }, [windowSize])

  useEffect(() => {
    if (forceUpdateOnInitialTab) {
      setNewActive(initialIndex === -1 ? 0 : initialIndex)
    }
  }, [initialTab, forceUpdateOnInitialTab])

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 500)
  }, [active])

  return (
    <WrapperS id="wrapper-tab">
      <RootS id="tab-root">
        {tabs.map((tab, index) => (
          <TabS
            as={tab.href ? 'a' : 'div'}
            href={tab.href}
            target="_blank"
            id={snakeCase(tab.title)}
            key={index}
            onClick={() => setNewActive(index)}
            tabIndex={active === index ? 0 : -1}
          >
            <h4 className='fw-bold no-margin'>
              {tab.title}
            </h4>
          </TabS>
        ))}
        <IndicatorS
          style={{
            left: positionIndicator.left,
            width: positionIndicator.width
          }}
        />
      </RootS>
    </WrapperS>
  )
}

export default Tabs
