
export const getItem = (target:string) => {
  return localStorage.getItem(target)
}

export const setItem = (target:string, value:string) => {
  localStorage.removeItem(target)
  return localStorage.setItem(target, value)
}

export const removeItem = (target:string) => {
  return localStorage.removeItem(target)
}
