import { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { Col, Input, Row } from 'DesignSystem'
import TextArea from 'DesignSystem/Input/TextArea'
import LoadingDots from 'DesignSystem/LoadingDots'

import { ConnectionFormContext } from './ConnectionForm.context'

function ConnectionForm () {
  const intl = useIntl()
  const {
    data,
    isLoading,
    name,
    description,
    setName,
    setDescription
  } = useContext(ConnectionFormContext)

  useEffect(() => {
    if (data) {
      setName(data?.name || '')
      setDescription(data?.description || '')
    }
  }, [data])

  return (
    !isLoading
      ? <Row className="flex-column">
        <Col>
          <div className='mt-4'>
            <label className='fw-bold mb-1 d-block'>
              {intl.formatMessage({ id: 'Nome da conexão:' })}
            </label>
            <Input
              placeholder={`${data?.platform} ${intl.formatMessage({ id: 'da empresa A' })}`}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </div>
          <div className='mt-4'>
            <label className='fw-bold mb-1 d-block'>
              {intl.formatMessage({ id: 'Descrição da conexão:' })}
            </label>
            <TextArea
              rows="5"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              placeholder={intl.formatMessage({ id: 'Digite aqui' })}
            />
          </div>
        </Col>
    </Row>
      : (
      <div className='my-5'>
        <LoadingDots />
      </div>
        )
  )
}

export default ConnectionForm
