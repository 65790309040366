import { useState } from 'react'

import type { DataStartDowngradePlanContentManagerI } from 'ActionsContentManager/DowngradePlan/Hooks/useStartDowngradePlan.hook'
import { Button, Input } from 'DesignSystem'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { apiPOST } from 'Services/Api.service'

import { ContentS } from './FeedbackDowngradeModal.styled'

type FeedbackDowngradeModalPropsI = AuxStepContentManagerI<DataStartDowngradePlanContentManagerI>

const FeedbackDowngradeModal = (props: FeedbackDowngradeModalPropsI) => {
  const { close, next, dataContentManager, setData, currentStep } = props
  const intl = useIntlShort()
  const [isLoading, setIsLoading] = useState(false)
  const [isSelectedRadio, setIsSelectedRadio] = useState(false)
  const [feedbackSelected, setFeedbackSelected] = useState('')
  const [otherMotive, setOtherMotive] = useState('')
  const option1 = intl('Esse plano é muito caro para mim.')
  const option2 = intl('Estou tendo problemas ou dificuldades para usar o NeoDash.')
  const option3 = intl('Eu não uso todos os recursos disponíveis no meu plano atual.')
  const option4 = intl('Outro motivo.')

  const handleOnChangeFeedbackSelect = (value: string) => {
    setFeedbackSelected(value)
    setIsSelectedRadio(true)
  }

  const handleSelectRadio = (evt: any) => {
    const target = evt.target as HTMLInputElement
    handleOnChangeFeedbackSelect(target.value)
  }

  const handleOnChangeOtherMotive = (evt: any) => {
    const target = evt.target as HTMLInputElement
    setOtherMotive(target.value)
  }

  const handleNextStep = async () => {
    setIsLoading(true)
    try {
      await apiPOST('feedback', {
        commentary: (() => {
          switch (feedbackSelected) {
            case '1': return option1
            case '2': return option2
            case '3': return option3
            case '4': return option4 + ' - ' + otherMotive
          }
        })(),
        origin: 'downgrade_plan'
      })

      if (dataContentManager.totalToSelect && dataContentManager.totalToSelect < 1) {
        handleScheduleDowngrade()
      } else {
        next()
      }
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const handleScheduleDowngrade = async () => {
    const response : any = await apiPOST('subscription/downgrade/schedule', {
      plan: dataContentManager.planID,
      workspaces: []
    })

    setData({
      scheduled: response.scheduled
    })

    next(currentStep + 2)
  }

  return (
    <div className='d-flex flex-column'>
      <h3 className='fw-bold mt-3'>{intl('Por qual motivo você está fazendo o downgrade?')}</h3>
      <ContentS>
        <div className='d-flex flex-row gap-2 mt-2' onClick={() => handleOnChangeFeedbackSelect('1')}>
          <input type="radio" name="feedback" value="1" checked={feedbackSelected === '1'} onChange={handleSelectRadio} /> {option1}
        </div>
        <div className='d-flex flex-row gap-2 mt-2' onClick={() => handleOnChangeFeedbackSelect('2')}>
          <input type="radio" name="feedback" value="2" checked={feedbackSelected === '2'} onChange={handleSelectRadio} /> {option2}
        </div>
        <div className='d-flex flex-row gap-2 mt-2' onClick={() => handleOnChangeFeedbackSelect('3')}>
          <input type="radio" name="feedback" value="3" checked={feedbackSelected === '3'} onChange={handleSelectRadio} /> {option3}
        </div>
        <div className='d-flex flex-column gap-2 mt-2' onClick={() => handleOnChangeFeedbackSelect('4')}>
          <div className='d-flex flex-row gap-2'>
            <input type="radio" name="feedback" value="4" checked={feedbackSelected === '4'} onChange={handleSelectRadio} /> {option4}
          </div>
          {feedbackSelected === '4' ? <Input placeholder={intl('Digite brevemente o motivo')} onChange={handleOnChangeOtherMotive}/> : null}
        </div>
      </ContentS>
      <div className='d-flex flex-row gap-4 mt-3  align-items-center justify-content-center'>
        <Button onClick={close} color='success'>{intl('Manter plano atual')}</Button>
        <Button disabled={!isSelectedRadio} isLoading={isLoading} color='error' onClick={handleNextStep}>{intl('Avançar')}</Button>
      </div>
    </div>
  )
}

FeedbackDowngradeModal.dialogProps = {
  style: {
    maxWidth: 'fit-content',
    padding: '44px'
  }
}

export default FeedbackDowngradeModal
