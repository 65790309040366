import styled, { css } from 'styled-components/macro'

interface RootI {
  isOpen: boolean
}

export const RootS = styled.div<RootI>`
  width: 100%;
  max-height: 0;

  padding-top: 0;
  padding-bottom: 0;

  border-radius: 0.5rem;

  overflow: hidden;

  background-color: ${(props) => props.theme.colors.gray40};

  transition: max-height 0.3s, padding 0.3s ease-out;

  ${props => {
    if (props.isOpen) {
      return css`
        max-height: 1000px;

        overflow: initial;

        padding-bottom: var(--space-content-box-v);
      `
    }
  }}
`

export const InnerS = styled.div`
  width: 100%;
`

export const FiltersS = styled.div`
  display: flex;
  flex-direction: row;

  > * {
    margin-left: 10px;
    margin-right: 10px;

    :first-child {
      margin-left: 0;
    }

    :last-child {
      margin-right: 0;
    }
  }
`

export const ActionS = styled.div`
  display: flex;
  flex-direction: row;
`
