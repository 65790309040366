import { useIntl } from 'react-intl'

import styled from 'styled-components'

import useIntlShort from 'Hooks/useIntlShort.hook'

const PriceMessageAfterRoot = styled.div`
  .equivalent {
    min-height: 32px;
  }
  @media(max-width: 768px) {
    .equivalent {
      min-height: auto;
    }
  }
`

const EconomyBadge = styled.h4`
  padding: 0.5rem;
  background-color: #EBFCFF;
  font-weight: 800;
  font-size: 10px !important;
  border-radius: 0.5rem;

  @media(max-width: 768px) {
    font-size: 0.9rem;
  }
`

interface PriceMessageAfterI {
  paymentPeriod: any
  price: any
}

function PriceMessageAfter ({ paymentPeriod, price }: PriceMessageAfterI) {
  const intl = useIntlShort()
  const { formatNumber } = useIntl()

  const format = (value: number) => formatNumber(value || 0, {
    style: 'currency',
    currency: price?.currency,
    minimumFractionDigits: 2
  })

  const monthlyPrice = format(price?.amount / 12)
  const valueEconomized = format(price?.amountWithoutDiscount - price?.amount)

  return (
    <PriceMessageAfterRoot className='d-flex flex-column align-items-start'>
      <h4>
        {paymentPeriod === 'month' ? (
          intl('pagando mensalmente')
        ) : (
          <div>
            <div>
              {intl('Pago uma vez por ano')}
            </div>
            <div className='my-3 equivalent'>
              {intl('Equivalente a {monthlyPrice} por mês', { monthlyPrice })}
            </div>
          </div>
        )}
      </h4>
      {paymentPeriod === 'year' && (
        <EconomyBadge className='mt-auto'>
          {intl('Economize {valueEconomized} em relação ao plano mensal.', {
            valueEconomized
          })}
        </EconomyBadge>
      )}
    </PriceMessageAfterRoot>
  )
}

export default PriceMessageAfter
