import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'

interface HeaderButtonInterface {
  company?: string,
  tokenManager?: boolean,
  account?: string | null,
  logs?: string | null
}

export const HeaderButton = (props: HeaderButtonInterface) => {
  const history = useHistory()

  return (
    <div>
      <Button onClick={() => history.push('/home')}>
        Voltar para todas as empresa
      </Button>
      {props.company && <Button onClick={() => history.push(`/company/${props.company}/edit/advanced`)}>
        Voltar para a empresa
      </Button>}
      {props.tokenManager && <Button onClick={() => history.push(`/company/${props.company}/accounts`)}>
        Voltar para Token Manager
      </Button>}
      {props.account && <Button onClick={() => history.push(`/company/${props.company}/account/${props.account}`)}>
        Voltar para Conta do token manager
      </Button>}
      {props.logs && <Button onClick={() => history.push(`/company/${props.company}/logs/${props.logs}${props.account ? `?account=${props.account}` : ''}`)}>
        Voltar para os logs da Conta
      </Button>}
    </div>
  )
}

export default HeaderButton
