import type { ReactNode } from 'react'
import React from 'react'
import { useIntl } from 'react-intl'

import Button from 'Components/Button'
import Text from 'Components/Text'

import { MicroData, passAlongMicroData } from 'Helpers/MicroData.helper'

import { ActionS, FiltersS, RootS } from './SearchAdvanced.styled'

interface SearchAdvancedPropsI {
  isOpen?: boolean,
  children?: ReactNode,
  label?: string,
  onClear?: () => void
}

const SearchAdvanced = (props: SearchAdvancedPropsI) => {
  const intl = useIntl()
  const { isOpen = false, children, onClear, label = intl.formatMessage({ id: 'Filtros avançados' }) } = props

  const handleOnClear = () => {
    onClear && onClear()
  }

  return (
    <RootS className='mt-3' isOpen={isOpen} {...passAlongMicroData(props)}>
      <div className='p-3 pb-0'>
        <Text data-component-type={MicroData.HEADER}>{label}</Text>
        <ActionS data-component-type={MicroData.CONTENT}>
          <FiltersS>
            {children}
            {onClear && <Button type='link' isFit onClick={handleOnClear} label={intl.formatMessage({ id: 'Limpar' })} />}
          </FiltersS>
        </ActionS>
      </div>
    </RootS>
  )
}

export default SearchAdvanced
