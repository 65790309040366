
import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components/macro'

interface LoadingInterface {
  className?: string
}

const LoadingDefault = (props:LoadingInterface) => {
  return (
    <CircularProgress className={['dark', props.className].join(' ')} />
  )
}

export default styled(LoadingDefault)``
