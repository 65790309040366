import React from 'react'
import { useIntl } from 'react-intl'

import permissions from 'Constants/permissions.constants'

import Text from 'Components/Text'

import useCheckPermission from 'Hooks/API/useCheckPermission.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

import { CardS, LinkS } from './CardWithoutRoutines.styled'

interface CardWithoutRoutinesPropsI {
  idCompany?: string
}

const CardWithoutRoutines = (props: CardWithoutRoutinesPropsI) => {
  const { idCompany } = props
  const intl = useIntl()
  const { data: checkPermissionToManageTokens } = useCheckPermission([permissions.MANAGE_TOKENS], idCompany)

  const propsCreateConnection = idCompany
    ? {
        isDisabled: checkPermissionToManageTokens.some(p => !p.canUse),
        'data-for': checkPermissionToManageTokens.some(p => !p.canUse) ? 'tip-global-permission' : undefined,
        'data-tip': checkPermissionToManageTokens.some(p => !p.canUse) ? JSON.stringify(checkPermissionToManageTokens) : undefined
      }
    : {}

  return (
    <CardS>
      <Text>{intl.formatMessage({ id: 'Você ainda não possui rotinas.' })}</Text>
      <LinkS to={
        propsCreateConnection.isDisabled ? '#' : (idCompany ? generateLinkTo('companyEditStepNew', { id: idCompany, step: 'connections' }) : generateLinkTo('connectionNew'))
      } {...propsCreateConnection}>{intl.formatMessage({ id: 'Clique aqui e crie sua primeira conexão para começar!' })}</LinkS>
    </CardS>
  )
}

export default CardWithoutRoutines
