import { useIntl } from 'react-intl'

import Button from 'DesignSystem/Button'

import { RootS } from './NotFound.styled'

const NotFound = () => {
  const intl = useIntl()

  return (
    <RootS>
      <svg width="200" height="200" viewBox="0 0 384 384" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M253.2 283.051L259.05 318.451" stroke="#0A1A28" strokeWidth="16" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M192 288V336" stroke="#0A1A28" strokeWidth="16" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M130.8 283.051L124.95 318.451" stroke="#0A1A28" strokeWidth="16" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M265.5 103.199C321.45 114.899 360 139.499 360 167.999C360 207.749 284.85 239.999 192 239.999C99.15 239.999 24 207.749 24 167.999C24 139.499 62.85 114.749 119.1 103.199" stroke="#0A1A28" strokeWidth="16" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M108 149.849C107.976 155.266 109.809 160.529 113.193 164.759C116.578 168.99 121.309 171.933 126.6 173.099C148.064 177.896 170.008 180.211 192 179.999C213.992 180.211 235.936 177.896 257.4 173.099C262.691 171.933 267.422 168.99 270.807 164.759C274.191 160.529 276.024 155.266 276 149.849V143.999C276.001 132.879 273.794 121.869 269.507 111.609C265.22 101.349 258.939 92.0414 251.027 84.2274C243.115 76.4134 233.731 70.2481 223.418 66.089C213.105 61.9298 202.069 59.8598 190.95 59.9988C144.75 60.5988 108 99.1488 108 145.199V149.849Z" stroke="#0A1A28" strokeWidth="16" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      <svg width="400" height="100" viewBox="0 0 1440 257" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M782 107.5C782 122.25 780.833 135.458 778.5 147.125C776.25 158.708 772.667 168.542 767.75 176.625C762.833 184.708 756.417 190.875 748.5 195.125C740.583 199.375 731 201.5 719.75 201.5C705.667 201.5 694.042 197.792 684.875 190.375C675.708 182.875 668.917 172.125 664.5 158.125C660.083 144.042 657.875 127.167 657.875 107.5C657.875 87.8333 659.875 71 663.875 57C667.958 42.9167 674.542 32.125 683.625 24.625C692.708 17.125 704.75 13.375 719.75 13.375C733.917 13.375 745.583 17.125 754.75 24.625C764 32.0417 770.833 42.7917 775.25 56.875C779.75 70.875 782 87.75 782 107.5ZM687.5 107.5C687.5 122.917 688.5 135.792 690.5 146.125C692.583 156.458 696 164.208 700.75 169.375C705.5 174.458 711.833 177 719.75 177C727.667 177 734 174.458 738.75 169.375C743.5 164.292 746.917 156.583 749 146.25C751.167 135.917 752.25 123 752.25 107.5C752.25 92.1667 751.208 79.3333 749.125 69C747.042 58.6667 743.625 50.9167 738.875 45.75C734.125 40.5 727.75 37.875 719.75 37.875C711.75 37.875 705.375 40.5 700.625 45.75C695.958 50.9167 692.583 58.6667 690.5 69C688.5 79.3333 687.5 92.1667 687.5 107.5Z" fill="#0A1A28"/>
        <path d="M582.375 216.875H557.625V257H528.625V216.875H445.5V194.625L529 73.75H557.625V192.5H582.375V216.875ZM528.625 192.5V147.125C528.625 143.375 528.667 139.583 528.75 135.75C528.917 131.833 529.083 128.083 529.25 124.5C529.417 120.917 529.583 117.625 529.75 114.625C529.917 111.542 530.042 109 530.125 107H529.125C527.542 110.333 525.792 113.75 523.875 117.25C521.958 120.667 519.917 124 517.75 127.25L472.75 192.5H528.625ZM994.875 216.875H970.125V257H941.125V216.875H858V194.625L941.5 73.75H970.125V192.5H994.875V216.875ZM941.125 192.5V147.125C941.125 143.375 941.167 139.583 941.25 135.75C941.417 131.833 941.583 128.083 941.75 124.5C941.917 120.917 942.083 117.625 942.25 114.625C942.417 111.542 942.542 109 942.625 107H941.625C940.042 110.333 938.292 113.75 936.375 117.25C934.458 120.667 932.417 124 930.25 127.25L885.25 192.5H941.125Z" fill="#0A1A28"/>
      </svg>
      <h3 className="fw-bold mt-3">{intl.formatMessage({ id: 'notfound' })}</h3>
      <div className="d-grid mt-3">
              <Button color="primary" href="/home">
                {intl.formatMessage({ id: 'Homepage' })}
              </Button>
            </div>
    </RootS>
  )
}

export default NotFound
