import React, { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'

import useOutsideClick from 'Hooks/useOutsideClick.hook'

interface InputColorInterface {
  label: string,
  placeholder?: string,
  onChange?: (color: string) => void,
  color: string,
}

function InputColor (props: InputColorInterface) {
  const { label, placeholder, color, onChange = () => undefined } = props
  const [isOpen, setIsOpen] = useState(false)
  const [timeoutPicker, setTimeoutPicker] = useState(false)
  const colorPickerRef = useRef(null)

  useOutsideClick(colorPickerRef, () => {
    if (timeoutPicker && isOpen) {
      setTimeoutPicker(false)
      setIsOpen(false)
    }
  })

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setTimeoutPicker(true)
      }, 500)
    }
  }, [isOpen])

  return (
    <section className="input-color">
      <label className='input-color__label' >{label}</label>
      <div className='input-color__inner' onClick={() => setIsOpen(true)}>
        <span className='input-color__input' style={{ backgroundColor: color }}></span>
        <div className='input-color__placeholder'>{placeholder}</div>
      </div>
      {isOpen
        ? <div ref={colorPickerRef} className='input-color__color-picker'>
        <SketchPicker color={color} onChangeComplete={(color) => onChange(color.hex)} />
      </div>
        : null}
    </section>
  )
}

export default InputColor
