import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { Button, Col, Row } from 'DesignSystem'

import { generateLinkTo } from 'Helpers/Route.helper'

function Finish ({
  prev,
  setSizeDialog,
  setShowFooter,
  next
}: any) {
  const intl = useIntl()
  const history = useHistory()

  useEffect(() => {
    setSizeDialog('sm')
    setShowFooter(false)
  }, [])

  return (
    <Row className="flex-column">
      <Col className="text-center mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Conexão configurada!' })}</h2>
        <p className='text-start body2'>
          {intl.formatMessage({ id: 'Você já pode utilizar sua nova conexão em seus grupos de dados e importar os dados de mídia disponíveis nela para o NeoDash.' })}
        </p>
      </Col>
      <Col className="mt-5 text-center">
        <Button color="white" onClick={() => {
          next()
          history.push(generateLinkTo('connectionNew'))
        }}>
          {intl.formatMessage({ id: 'Adicionar nova conexão' })}
        </Button>
        <Button onClick={next} className="ms-3">
          {intl.formatMessage({ id: 'Concluir' })}
        </Button>
      </Col>
    </Row>
  )
}

export default Finish
