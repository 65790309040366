import React from 'react'

class ErrorBoundary extends React.Component {
  constructor (props: any) {
    super(props)
    this.state = { error: '' }
  }

  componentDidCatch (error: any) {
    this.setState({ error: `${error.name}: ${error.message}` })
  }

  render () {
    // @ts-ignore
    const { error } = this.state
    if (error) {
      console.error(error)
      return (
        <div />
      )
    } else {
      return <>{this.props.children}</>
    }
  }
}

export default ErrorBoundary
