import { MagnifyingGlass, X } from '@phosphor-icons/react'
import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import useDebounce from 'Hooks/useDebounce.hook'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

import type { RootPropsI } from './SearchFilter.styled'
import { RootS } from './SearchFilter.styled'

interface SearchFilterPropsI {
  className?: string,
  size?: RootPropsI['size']
  search?: string
  onChangeSearch?: (value: string) => void
  iconOnTheRight?: ReactNode,
  collectOnMobile?: boolean,
  debounceTime?: number
}

const SearchFilter = (props: SearchFilterPropsI) => {
  const { size = 'normal', className, search: searchProps = '', iconOnTheRight, collectOnMobile, debounceTime, onChangeSearch } = props
  const intl = useIntl()
  const [search, setSearch] = useState(searchProps)
  const [isSearchOpenInMobile, setSearchOpenInMobile] = useState(false)
  const valueDebounced = useDebounce(search || '', debounceTime || 0)

  useEffect(() => {
    onChangeSearch && onChangeSearch(valueDebounced)
  }, [valueDebounced])

  const handleIconClick = () => {
    setSearchOpenInMobile(!isSearchOpenInMobile)
  }

  return (
    <RootS size={size} collectOnMobile={collectOnMobile} className={`${className} ${isSearchOpenInMobile && collectOnMobile ? 'show' : ''}`} {...passAlongMicroData(props)}>
      <span onClick={handleIconClick}>
        <MagnifyingGlass size={20} />
      </span>
      <input
        placeholder={intl.formatMessage({ id: 'Pesquisar' })}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {iconOnTheRight ?? null}
      {isSearchOpenInMobile && collectOnMobile &&
        <span className='d-block d-lg-none' onClick={handleIconClick}>
          <X size={20}/>
        </span>
      }
    </RootS>
  )
}

export default SearchFilter
