import React from 'react'

interface ListSimpleInterface {
  items: string[],
  className?: string,
}

export default function ListSimple (props: ListSimpleInterface) {
  const { className } = props

  return (
    <ul className={`list-simple ${className}`}>
      {props.items.map((item, key) => <li className="list-simple__item" key={item + key}>{item}</li>)}
    </ul>
  )
}
