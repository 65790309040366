import useSWR from 'swr'

import { createQuery } from 'Helpers/Url.helper'

import { fetcher } from './fetcher'

export interface RoutineLogI {
  id: string,
  state: string,
  start_date: string,
  end_date: string,
  logs: string
}

interface OptionsI {
  company: string,
  routine: string,
  run?: string,
  search?: string | null,
  orderBy?: string | null,
  rangeDates?: string[]
}

interface ReturnI {
  data: Array<RoutineLogI>,
  isLoading: boolean,
  isError: boolean
}

const useLog = (options: OptionsI) : ReturnI => {
  const query = createQuery()

  if (options.search) {
    query.addParams('search', options.search)
  }

  if (options.orderBy) {
    query.addParams('orderBy', options.orderBy)
  }

  if (options.rangeDates) {
    query.addParams('rangeDate', options.rangeDates)
  }

  const { data, error } = useSWR<Array<RoutineLogI>>(options.run ? `company/${options.company}/routines/${options.routine}/runs/${options.run}/logs${query.getValue()}` : null, fetcher('GET'))

  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useLog
