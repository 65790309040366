import { useEffect, useRef } from 'react'

export default function usePrevious<T> (value: T): T | null {
  const ref = useRef<T>()
  useEffect(() => {
    // @ts-ignore: Unreachable code error
    ref.current = value
  })
  return ref.current || null
}
