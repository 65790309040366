import styled, { css } from 'styled-components/macro'

export interface RootPropsI {
  size: 'small' | 'normal' | 'big',
  collectOnMobile?: boolean
}

export const RootS = styled.div<RootPropsI>`
  width: 100%;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: var(--input-base-small-height-inner);
        `
      case 'big':
        return css`
          height: var(--input-base-big-height-inner);
        `
      default:
        return css`
          height: var(--input-base-height-inner);
        `
    }
  }}

  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;

  padding: 0 20px;

  background-color: white;
  border-radius: 30px;
  transition: all 0.2s ease-out;

  > input {
    width: 100%;
    height: 100%;

    margin: 0 0 0 15px;
    padding: 0;

    background-color: transparent;
    border: 0;
    outline: none;

    ${({ size }) => {
      switch (size) {
        case 'small':
          return css`
            font-size: var(--input-base-label-small-size);
          `
        case 'big':
          return css`
            font-size: var(--input-base-label-big-size);
          `
        default:
          return css`
            font-size: var(--input-base-label-size);
          `
      }
    }}
  }

  ${({ collectOnMobile }) => (collectOnMobile &&
    css`
      @media (max-width: 768px) {
        width: 10px;
        background-color: transparent;
        padding: 0;

        &.show {
          position: absolute;
          width: 95vw;
          padding: 0 20px;
          left: 8px;
          top: 20%;
          background-color: white;
          transform: translateY(0);
        }
      }
  `
  )}
`
