import React from 'react'
import DataGrid from 'react-data-grid'

import useInvites from 'Hooks/API/useInvites.hook'

const Invites = () => {
  const { data: invitesRow } = useInvites()

  const invitesRowFormatted = invitesRow.map(r => ({
    ...r,
    companyName: r.company?.name ?? ''
  }))

  const columns = [{
    key: 'email',
    name: 'Email'
  }, {
    key: 'platform',
    name: 'Plataforma'
  }, {
    key: 'creation',
    name: 'Criação'
  }, {
    key: 'link',
    name: 'Link'
  }, {
    key: 'companyName',
    name: 'Compania'
  }]

  return (<DataGrid
    columns={columns}
    rowGetter={(i: number) => invitesRowFormatted[i]}
    rowsCount={invitesRowFormatted.length}
    minHeight={400} />)
}

export default Invites
