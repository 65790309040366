import React from 'react'
import DataGrid from 'react-data-grid'

import useUsersConsole from 'Hooks/API/useUsersConsole.hook'

const Users = () => {
  const { data: usersRow } = useUsersConsole()

  const columns = [{
    key: 'id',
    name: 'ID'
  }, {
    key: 'name',
    name: 'Nome'
  }, {
    key: 'email',
    name: 'Email'
  }, {
    key: 'locale',
    name: 'Locale'
  }, {
    key: 'is_admin',
    name: 'É admin?'
  }]

  return (<DataGrid
    columns={columns}
    rowGetter={(i: number) => usersRow[i]}
    rowsCount={usersRow.length}
    minHeight={400} />)
}

export default Users
