import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { isEmpty } from 'lodash'

import Support from 'Components/Support'

import PrivateRoutes from './privates.routes'
import PublicRoutes from './publics.routes'

function Routes () {
  const location = useLocation()
  const user = useSelector((state:any) => state.user)
  const [logged, setLogged] = useState<boolean>(!isEmpty(user.data))

  console.log('user', user)

  useEffect(() => {
    try {
      // @ts-ignore
      window.dataLayer.push({
        event: 'odash-event',
        neoDashPage: location.pathname,
        userCreation: user?.data?.creation || '',
        userName: user?.data?.name || '',
        userEmail: user?.data?.email || '',
        userEmailConfirmation: user?.data?.confirmation || '',
        userCustomerStriper: user?.data?.customer_id || '',
        userParametersTracking: user?.data?.parametersTracking || null,
        userPlan: user?.data?.plan || null,
        userTrialDate: user?.data?.trialStartDate || null,
        userTrailDays: user?.data?.trialDays || null
      })
    } catch (error) {
      console.log(error)
    }
  }, [location])

  useEffect(() => {
    if (!isEmpty(user.data)) setLogged(true)

    const params = (new URL(window.location.href)).searchParams
    const next = params.get('next')

    if (next && !isEmpty(user.data)) {
      window.location.href = next
    }
  }, [user.data])

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 500)
  }, [location, logged])

  return logged ? <><PrivateRoutes/><Support/></> : <PublicRoutes/>
}

export default Routes
