import type { RefObject } from 'react'
import { useState, useLayoutEffect, useRef } from 'react'

type WindowSize = {
  readonly innerHeight: number;
  readonly innerWidth: number;
  readonly outerHeight: number;
  readonly outerWidth: number;
};

function getWindowSize (): WindowSize {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth
  }
}

/**
 * Retrieves the current size of the window
 * @param  {Function} callback
 * @return {WindowSize}
 */
export function useWindowSize (callback?: (size: WindowSize) => void): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>(getWindowSize())

  const resize = () => {
    window.requestAnimationFrame(() => {
      const size = getWindowSize()

      callback && callback(size)
      setWindowSize(size)
    })
  }

  useLayoutEffect(
    () => {
      callback && callback(windowSize)

      window.addEventListener('resize', resize, false)
      return () => window.removeEventListener('resize', resize, false)
    },
    []
  )

  return windowSize
}

export function useAspectRatio<T extends HTMLElement> (ratio: number): [RefObject<T>, string] {
  const ref = useRef<T>(null)
  const [mediaWidth, setMediaWidth] = useState(600)

  const heightCalculated = `${Math.round(mediaWidth / ratio)}px`
  useWindowSize(() => ref.current && setMediaWidth(ref.current.clientWidth))

  useLayoutEffect(
    () => {
      if (ref.current) {
        ref.current.style.height = heightCalculated
      }
    },
    [mediaWidth]
  )

  return [
    ref,
    heightCalculated
  ]
}
