import React from 'react'

import IconSVG from 'Components/IconSVG'
import type { IconNameI } from 'Components/IconSVG/IconSVG.component'

interface InputReadOnlyInterface {
  value: string,
  icon?: IconNameI | null,
  className?: string,
}

export default function InputReadOnly (props: InputReadOnlyInterface) {
  const { value, icon = null, className: classNameExtend } = props

  const className = ['input-read-only', classNameExtend].join(' ')

  return (
    <div className={className}>
      {icon ? <IconSVG icon={icon} /> : null}
      {value}
    </div>
  )
}
