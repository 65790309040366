import { CheckCircle, Warning } from '@phosphor-icons/react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button } from 'DesignSystem'
import type { SubscriptionResponseI } from 'Pages/Privates/Subscription/Subscription.page'
import useSWR from 'swr'

import { addAlert } from 'Store/Actions/alerts.action'

import IconCardBrand from 'Components/IconCardBrand'

import { fetcher } from 'Hooks/API/fetcher'
import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { ContentPaymentS, ContentS, HeaderS, RootS } from './PastDuePlan.styled'

export const PastDuePlan = (props: AuxStepContentManagerI<any>) => {
  const { close } = props
  const intl = useIntlShort()
  const dispatch = useDispatch()
  const [paySuccess, setPaySuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRetry, setIsLoadingRetry] = useState(false)
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [alreadyRetry, setAlreadyRetry] = useState(false)
  const { data: dataSubscription, mutate: mutateSubscription } = useSWR<SubscriptionResponseI>('/subscription', fetcher('GET'))

  const subscription = dataSubscription?.plan?.subscription
  const totalInvoicesPending = dataSubscription?.invoicesPending ? dataSubscription?.invoicesPending?.reduce((acc, c) => {
    if (c.status === 'open') {
      return acc + c.amount_due
    }
    return acc
  }, 0) : 0

  const handleRetry = async () => {
    setIsLoading(true)
    setIsLoadingRetry(true)
    setAlreadyRetry(true)
    try {
      const response : any = await fetcher('POST')('/subscription/payment/retry')

      if (response.status === 200) {
        setPaySuccess(true)
        dispatch(
          addAlert({
            title: intl('Tentativa de pagamento realizada com sucesso'),
            type: 'success'
          })
        )
        mutateSubscription()
      } else {
        dispatch(
          addAlert({
            title: intl('Erro ao tentar realizar pagamento'),
            type: 'error',
            content: intl('Não foi possível realizar a tentativa de pagamento.')
          })
        )
      }
    } catch (error) {
      dispatch(
        addAlert({
          title: intl('Erro ao tentar realizar pagamento'),
          type: 'error',
          content: intl('Não foi possível realizar a tentativa de pagamento.')
        })
      )
    } finally {
      setIsLoading(false)
      setIsLoadingRetry(false)
    }
  }

  const handleUpdatePayment = async () => {
    setIsLoading(true)
    setIsLoadingUpdate(true)
    try {
      const response : any = await fetcher('POST')('/subscription/payment/change')

      if (response.url) {
        window.location.href = response.url
      } else {
        dispatch(
          addAlert({
            title: intl('Assinatura atualizada'),
            type: 'success',
            content: intl('Sua assinatura foi atualizada com sucesso!')
          })
        )
      }
    } finally {
      setIsLoading(false)
      setIsLoadingUpdate(false)
    }
  }

  return (
    <RootS>
      <HeaderS successful={paySuccess}>
        {paySuccess ? <CheckCircle color='white' size={24}/> : <Warning color='white' size={24} />}
        <h3 className='fw-bold'>{paySuccess ? intl('Confirmação de pagamento') : intl('Erro de pagamento')}</h3>
      </HeaderS>
      <ContentS className='mt-3'>
        <p className='mt-3'>{intl('Durante a tentativa de cobrança do seu plano, presenciamos um problema com os dados de pagamento atuais. Confira os dados abaixo:')}</p>
        {subscription
          ? (
              <ContentPaymentS>
                <label className='fw-bold mt-2'>{intl('Cartão de crédito')}</label><br/>
                <span className='mt-2 d-flex flex-row gap-2 align-items-center'><IconCardBrand brand={subscription.default_payment_method.card} /> **** {subscription.default_payment_method.last4}</span><br/>
                <label className='fw-bold mt-2'>{intl('Data de validade (MM/AA)')}</label><br/>
                <span className='mt-2'>{subscription.default_payment_method.exp_month}/{subscription.default_payment_method.exp_year}</span><br/>
                <label className='fw-bold mt-2'>{intl('Valor')}</label><br/>
                <span className='mt-2'>{totalInvoicesPending}</span><br/>
              </ContentPaymentS>
            )
          : null}
        <p className='mt-3'>{intl('Realizaremos mais 2 tentativas de cobrança nos próximos dias e caso não obtenhamos sucesso em alguma delas, seu acesso ao NeoDash será restringido até que o pagamento seja regularizado.')}</p>
        <div className='d-flex flex-row justify-content-center align-items-center mt-3 gap-3'>
          {paySuccess ? (
            <Button onClick={close}>
              {intl('Continuar')}
            </Button>
          ) : (
            <>
              <Button isLoading={isLoadingRetry} disabled={isLoading || alreadyRetry} onClick={handleRetry}>
                {intl('Fazer nova tentativa')}
              </Button>
              <Button isLoading={isLoadingUpdate} disabled={isLoading} onClick={handleUpdatePayment}>
                {intl('Atualizar dados de pagamento')}
              </Button>
            </>
          )}
        </div>
      </ContentS>
    </RootS>
  )
}

PastDuePlan.dialogProps = {
  style: {
    maxWidth: '637px'
  }
}

export default PastDuePlan
