import { useState } from 'react'

import { randomString } from 'Helpers/String.helper'

const useID = (nameBase: string) => {
  const [ID] = useState(`${nameBase}${randomString()}`)

  return ID
}

export default useID
