import { CurrencyCircleDollar, EnvelopeSimple, GraduationCap, SignOut, User } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'

import { useEndTrialsPeriodContentManager } from 'ActionsContentManager/EndTrialsPeriod'
import { useStartSignPlanContentManager } from 'ActionsContentManager/SignPlan'
import permissions from 'Constants/permissions.constants'
import { Dropdown } from 'DesignSystem'
import type { SubscriptionResponseI } from 'Pages/Privates/Subscription/Subscription.page'
import useSWR from 'swr'

import DevTag from 'Components/DevTag'
import IconTooltip from 'Components/IconTooltip/IconTooltip.component'
import Image from 'Components/Image'
import TrialPeriodTag from 'Components/TrialPeriodTag'

import { fetcher } from 'Hooks/API/fetcher'
import useCheckPermission from 'Hooks/API/useCheckPermission.hook'
import useUser from 'Hooks/API/useUser.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

import { MenuS, RootS } from './AppBar.styled'
import useInviteUserContentManager from './Hooks/useInviteUser.hook'

const AppBar = () => {
  const intl = useIntl()
  const { data: user, isLoading: isLoadingUser } = useUser()
  const history = useHistory()

  const handleLogout = () => {
    window.localStorage.clear()
    window.location.href = '/'
  }

  const { content, start } = useInviteUserContentManager()
  const { asserts } = useCheckPermission([permissions.IS_SUPPORT])
  const { start: startSignPlan, content: contentSignPlan } = useStartSignPlanContentManager()
  const { start: startEndTrialsPeriod, content: contentEndTrialsPeriod } = useEndTrialsPeriodContentManager({
    onStartAPlan: () => {
      startSignPlan()
    }
  })
  const { data: dataSubscription } = useSWR<SubscriptionResponseI>('/subscription', fetcher('GET'))

  const haveSubscription = !isLoadingUser && (user?.subscription?.status === 'pending' || !!dataSubscription?.plan?.subscription)

  useEffect(() => {
    startEndTrialsPeriod()
  }, [])

  return (
    <>
      <RootS>
        <Link to='/'>
          <Image
            src="/images/logos/logo.png"
            alt="Logo"
            style={{ maxWidth: '100%', height: '32px' }}
          />
        </Link>

        <MenuS>
          {!user?.ignore_payments && <TrialPeriodTag />}
          <IconTooltip label={intl.formatMessage({ id: 'Acessar o Wiki' })}>
            <a href="https://wiki.neodash.ai/" target="_blank" rel="noreferrer">
              <GraduationCap color="black" size={24} />
            </a>
          </IconTooltip>
          {process.env.REACT_APP_ENV === 'development' && (
            <div className='d-none d-sm-block'>
              <IconTooltip label={intl.formatMessage({ id: 'Modo desenvolvimento' })}>
                <DevTag />
              </IconTooltip>
            </div>
          )}
          {asserts?.everyTrue && (
            <IconTooltip label={intl.formatMessage({ id: 'Enviar convite' })}>
              <EnvelopeSimple color="black" size={24} onClick={() => start()}/>
            </IconTooltip>
          )}
          <div className="d-none d-md-block">
            <Dropdown>
              <Dropdown.Trigger> <User weight='bold' size={24} /> <div className='mx-2' style={{ width: '150px', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left' }}> {user?.name}</div> <Dropdown.Caret /> </Dropdown.Trigger>
              <Dropdown.Items items={[
                {
                  content: intl.formatMessage({ id: haveSubscription ? 'Meu plano' : 'Assinar um plano' }),
                  onClick: () => haveSubscription ? history.push(generateLinkTo('subscription')) : startSignPlan(),
                  iconComponent: <CurrencyCircleDollar size={24} />
                }
              ]} />
            </Dropdown>
          </div>
          <div className="d-block d-md-none">
            <Dropdown>
              <Dropdown.Trigger>
                <Dropdown.MenuXIcon />
              </Dropdown.Trigger>
              <Dropdown.Items items={[
                {
                  content: intl.formatMessage({ id: haveSubscription ? 'Meu plano' : 'Assinar um plano' }),
                  onClick: () => haveSubscription ? history.push(generateLinkTo('subscription')) : startSignPlan(),
                  iconComponent: <CurrencyCircleDollar size={24} />
                },
                {
                  content: 'Sair',
                  onClick: handleLogout,
                  iconComponent: <SignOut size={24} />
                }
              ]} />
            </Dropdown>
          </div>
          <div className='d-none d-md-block'>
            <IconTooltip label={intl.formatMessage({ id: 'Deslogar' })}>
              <SignOut color="black" size={24} onClick={handleLogout} />
            </IconTooltip>
          </div>
        </MenuS>

      </RootS>
      {content}
      {contentSignPlan}
      {contentEndTrialsPeriod}
    </>
  )
}

export default AppBar
