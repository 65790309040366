import React from 'react'
import ContentLoader from 'react-content-loader'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

const ListCompanyLoader = (props: any) => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 400 240"
      backgroundColor="#C6C6C5"
      foregroundColor="#1A1A1A"
      {...passAlongMicroData(props)}
    >
      {/* <rect x="200" y="6" rx="4" ry="4" width="288" height="288" /> */}
      {/* <rect x="200" y="20" rx="4" ry="4" width="200" height="13" /> */}
      <rect x="0" y="6" rx="4" ry="4" width="65" height="65" />
      <rect x="70" y="6" rx="4" ry="4" width="65" height="65" />
      <rect x="140" y="6" rx="4" ry="4" width="65" height="65" />
      <rect x="210" y="6" rx="4" ry="4" width="65" height="65" />
      <rect x="0" y="75" rx="4" ry="4" width="65" height="65" />
      <rect x="70" y="75" rx="4" ry="4" width="65" height="65" />
      <rect x="0" y="145" rx="4" ry="4" width="65" height="65" />

      {/* <rect x="200" y="55" rx="4" ry="4" width="115" height="10" />
      <rect x="200" y="69" rx="4" ry="4" width="200" height="13" /> */}
 {/*
      <rect x="200" y="104" rx="4" ry="4" width="115" height="10" />
      <rect x="200" y="118" rx="4" ry="4" width="200" height="13" />
      <rect x="0" y="104" rx="4" ry="4" width="190" height="38" />

      <rect x="200" y="153" rx="4" ry="4" width="115" height="10" />
      <rect x="200" y="167" rx="4" ry="4" width="200" height="13" />
      <rect x="0" y="153" rx="4" ry="4" width="190" height="38" />

      <rect x="200" y="202" rx="4" ry="4" width="115" height="10" />
      <rect x="200" y="216" rx="4" ry="4" width="200" height="13" />
      <rect x="0" y="202" rx="4" ry="4" width="190" height="38" /> */}
    </ContentLoader>
  )
}

export default ListCompanyLoader
