import styled, { css } from 'styled-components/macro'

interface RootWorkspaceCardI {
  bgColor: string
}

export const RootWorkspaceCard = styled.div<RootWorkspaceCardI>`
  width: 18rem;
  height: 18rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  position: relative;
  padding: 1rem;

  background-color: ${(props) => props.theme.colors.white};
  border-radius: 1.206rem;
  box-shadow: 1px 1px 4px gray;

  .action-btn-wrapper {
    width: 100%;
    height: 100%;

    opacity: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;

    background-color: #1414148d;

    transition: opacity 0.5s;

    h4 {
      color: white;
    }
  }

  h4 {
    position: absolute;
    top: 32px;

    ${(props) => css(props.theme.fonts.types.h4)}
  }

  button, a {
    width: 100px;
    height: 100px;

    opacity: 0;

    overflow: hidden;

    position: relative;

    transition: opacity 0.5s;

    background-color: transparent;
    border: none;

    span {
      z-index: 2;
      cursor: pointer;

      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }

  img {
    max-width: 100%;

    margin: 0 auto;
  }

  :hover {
    button, a,
    .action-btn-wrapper {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    width: 45vw;
    height: 45vw;
  }
`

export const ActionsS = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  position: absolute;
  bottom: 15px;
  padding: 0 15px;

  color: white;

  button, a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-decoration: none;

    ${(props) => css(props.theme.fonts.types.h4)}

    cursor: pointer;

    border-radius: 5px;

    color: white;

    transition: background-color 0.5s;

    &:hover {
      background-color: #1A1A1A;
    }
  }
`
