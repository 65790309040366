import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'

import type DefaultProps from 'DesignSystem/Types'
import styled from 'styled-components'

interface BackdropProps extends DefaultProps {
  backdropColor?: 'primary' | 'secondary' | 'darkCelurean' | 'dark' | 'black' | 'white' | 'error' | 'success' | 'alert' | 'gray' | 'gray80' | 'gray60' | 'gray40' | 'gray20';
  isOpen?: boolean;
  animation?: boolean
}

interface ModalProps extends BackdropProps {
  size?: 'sm' | 'lg' | 'xlg';
  onClose?: () => void;
  noPadding?: boolean;
}

const sizes = {
  sm: '650px',
  lg: '890px',
  xlg: '1190px'
}

const BackdropStyled = styled.div<BackdropProps>`
  display: flex;
  justify-content: center;
  z-index: 9999;
  overflow-y: auto;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};

  opacity: ${({ animation }) => (animation ? 1 : 0)};

  transition: opacity 0.6s;

  background-color: ${({ theme, backdropColor }) => backdropColor ? theme.colors[backdropColor] : 'rgba(0, 0, 0, 0.8)'};
  padding: 1rem 0;
`

const ModalStyled = styled.div<ModalProps>`
  position: relative;
  width: ${({ size }) => size ? sizes[size] : '500px'};
  height: fit-content;

  max-width: 100%;

  opacity: ${({ animation }) => (animation ? 1 : 0)};

  padding: ${({ noPadding }) => noPadding ? '0' : '2rem'};
  margin: auto;

  transform: ${({ animation }) => (!animation ? 'translateY(-10%)' : 'translateY(0)')};
  transition: transform 0.5s ease-in-out, opacity 0.2s;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.metrics.radius};
`

export const IconCloseS = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: 0;
  width: 32px;
  height: 32px;
  stroke: #000;
  stroke-width: 4;
  border: none;
  background-color: transparent;

  cursor: pointer;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 2px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.dark};
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

function Modal ({ children, size = 'sm', noPadding = false, backdropColor, isOpen, onClose, ...props }: ModalProps) {
  const [animation, setAnimation] = useState(false)

  const closeModal = useCallback(() => {
    if (onClose) {
      setAnimation(false)
      setTimeout(() => {
        isOpen && onClose()
      }, 200)
    }
  }, [onClose, isOpen])

  const addBlockScroll = () => {
    document.getElementById('root')?.classList.add('block-scroll')
  }

  const removeBlockScroll = () => {
    document.getElementById('root')?.classList.remove('block-scroll')
  }

  useEffect(() => {
    if (isOpen) {
      addBlockScroll()
      const handleKeyDown = (event: any) => {
        if (event.keyCode === 27) closeModal()
      }
      window.addEventListener('keydown', handleKeyDown)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
        removeBlockScroll()
      }
    }
    removeBlockScroll()
  }, [isOpen, closeModal])

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 500)
  }, [isOpen, children])

  useEffect(() => {
    setTimeout(() => {
      isOpen && setAnimation(true)
    }, 100)
    return () => setAnimation(false)
  }, [isOpen])

  return (
    ReactDOM.createPortal(
      <BackdropStyled backdropColor={backdropColor} isOpen={isOpen} animation={animation}>
        <ModalStyled size={size} noPadding={noPadding} {...props} animation={animation}>
          {onClose && <IconCloseS onClick={closeModal} />}
          {children}
        </ModalStyled>
      </BackdropStyled>
      , document.getElementById('root') || document.createElement('div'))
  )
}

export default Modal
