import { addAlert } from 'Store/Actions/alerts.action'

import { apiPOST } from 'Services/Api.service'

const defaultDomain = localStorage.getItem('odash-app:domain-suffix')

export const companyAvailability = async (slug:string) => {
  return handlerCompanyDomain('company/check/domain', { domain: `${slug}` })
}

export const companyCreateRoute = async (slug:string, companyID: string) => {
  return handlerCompanyDomain(`company/${companyID}/create/domain`, { domain: `${slug}.${defaultDomain}`, slug })
}

interface handlerInterface {
  domain: string,
  slug?:string
}

const handlerCompanyDomain = async (url:string, data: handlerInterface) => {
  try {
    const apiRequest = await apiPOST<any>(url, data)
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}
