import pick from 'lodash/pick'

export const MicroData = {
  multi: (values: string[]) => {
    return values.join(',')
  },
  MAIN_CONTENT: 'main-content',
  MODEL: 'model',
  HEAVY_MODEL: 'heavy-model',
  GROUPED_MODEL: 'grouped-model',
  INFO_FROM_MODEL: 'info-from-model',
  ACTION_FROM_MODEL: 'action-from-model',
  HEADER: 'header',
  CONTENT: 'content',
  COLUMNS: 'columns',
  INPUT: 'input',
  ACTION: 'action-normal'
}

export const passAlongMicroData = (value: any, tag?: string) => {
  let itemsData = [
    'data-search',
    'data-component-type',
    'data-show-only',
    'data-show-up',
    'data-show-only',
    'data-component-apply-only',
    'data-component-apply-up',
    'data-component-apply-down',
    'data-tip',
    'data-for',
    'data-cy'
  ]

  if (tag) {
    itemsData = itemsData.map((item) => {
      const itemArray = item.split('-')
      itemArray.splice(1, 0, tag)
      return itemArray.join('-')
    })
  }

  return pick(value, itemsData)
}
