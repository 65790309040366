import React from 'react'
// import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineClear } from 'react-icons/ai'

import { format } from 'date-fns'
import { ptBR, enUS } from 'date-fns/locale'

import IconSVG from 'Components/IconSVG'

import { nextMonth, oldMonth } from '../Calendar.helper'
import { ButtonMonthS, HeaderSectionS, MonthAndYearSectionS } from './Header.styled'

export interface HeaderPropsI {
  currentDate: Date
  language?: 'pt-BR' | 'en-US'
  fontWeightMonthAndYear:'bold'
  | 'normal'
  | 'bolder'
  | 'lighter'
  | 'initial'
  | 'inherit';
  colorTextHeader: string
  setCurrentDate: (date: Date) => void
  clearSelection: () => void
  enableLeftArrow?: boolean,
  enableRightArrow?: boolean,
}

const Header = (props: HeaderPropsI) => {
  const {
    currentDate,
    language,
    colorTextHeader,
    fontWeightMonthAndYear,
    setCurrentDate,
    enableLeftArrow = true,
    enableRightArrow = true
  } = props

  return (
    <HeaderSectionS>
      {enableLeftArrow
        ? (
            <ButtonMonthS
              onClick={() => {
                setCurrentDate(oldMonth(currentDate))
              }}
            >
              <IconSVG icon='chrevon' rotate={180} />
            </ButtonMonthS>
          )
        : null
      }
      <MonthAndYearSectionS
        fontWeightMonthAndYear={fontWeightMonthAndYear}
        color={colorTextHeader}
      >
        <h1>
          {format(currentDate, 'MMMM', {
            locale: language === 'pt-BR' ? ptBR : enUS
          })}
        </h1>
        <h1>{format(currentDate, 'yyyy')}</h1>
      </MonthAndYearSectionS>
      {enableRightArrow
        ? (
            <ButtonMonthS
              isRight
              onClick={() => {
                const next = nextMonth(currentDate)
                setCurrentDate(next)
              }}
            >
              <IconSVG icon='chrevon' />
            </ButtonMonthS>
          )
        : null
      }
    </HeaderSectionS>
  )
}

export default Header
