import styled from 'styled-components/macro'

export const RootS = styled.div`
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-transform: uppercase;

  margin: 15px 0;

  font-size: ${({ theme }) => theme.fonts.types.body1};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`

export const Diviser = styled.span`
  width: 100%;
  height: 1px;

  margin: 0 5px;

  background-color: gray;
`
