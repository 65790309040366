import React from 'react'

import { Modal } from 'DesignSystem'

import useContentManager from 'Hooks/useContentManager.hook'

import { getLocale } from 'Helpers/Locale.helper'
import { MicroData } from 'Helpers/MicroData.helper'

import StepsVisual from '../StepsVisual'
import Charging from './Steps/Charging'
import CreateWorkspace from './Steps/CreateWorkspace'
import Finish from './Steps/Finish'
import Welcome from './Steps/Welcome'
import WorkspaceInfo from './Steps/WorkspaceInfo'

export interface DataCompanyCreateContentManagerI {
  name?: string,
  isValidName?: boolean,
  slug?: string,
  isValidSlug?: boolean,
  iconOriginal?: string | null,
  zoom?: number,
  icon?: string | null,
  isValidIcon?: boolean,
  positionAreaPixels?: any,
  positionComponent?: any,
  color?: string,
  locale?: string | null,
  timezone?: string,
  currency_write?: string,
  currency_read?: string,
  setEnableNext?: () => null
}

interface ParamsI {
  onClose?: () => void,
  isFirstTime?: boolean
}

const useFirstSetupFlowManager = (params: ParamsI = {}) => {
  const { onClose, isFirstTime } = params
  const systemLocale = getLocale()

  const locale = (() => {
    switch (systemLocale) {
      case 'en':
        return 'en-US'
      case 'pt':
        return 'pt-BR'
      case 'pt-BR':
        return 'pt-BR'
      case 'en-US':
        return 'en-US'
      default:
        return 'pt-BR'
    }
  })()

  const currency = (locale === 'en-US') ? 'USD' : 'BRL'

  const isFirstTimeLocal = isFirstTime && localStorage.getItem('odash-app:show-welcome-create-workspace') !== 'true'

  const handleOnClose = () => {
    localStorage.setItem('odash-app:show-welcome-create-workspace', 'true')
    if (onClose) {
      onClose()
    }
  }

  const { start, content } = useContentManager<DataCompanyCreateContentManagerI>(isFirstTimeLocal
    ? [
        Welcome,
        CreateWorkspace,
        WorkspaceInfo,
        Charging,
        Finish
      ] : [
        CreateWorkspace,
        WorkspaceInfo,
        Charging,
        Finish
      ], {
    header: <></>,
    footer: (currentDialog) => <StepsVisual data-component-type={MicroData.ACTION_FROM_MODEL} steps={3} current={currentDialog} />,
    dialog: Modal,
    isFit: true,
    onClose: handleOnClose,
    initialData: {
      name: '',
      color: '#CCCCCC',
      locale: locale,
      timezone: 'America/Sao_Paulo',
      currency_read: currency,
      currency_write: currency
    }
  })

  return {
    start,
    content
  }
}

export default useFirstSetupFlowManager
