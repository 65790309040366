import type { ReactNode } from 'react'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'

import { BackButtonS, BackdropS, IconCloseS, RootS } from './Dialog.styled'

interface DialogPropsI {
  children?: ReactNode,
  isFit?: boolean,
  isShow?: boolean,
  onBack?: () => void,
  onClose?: () => void,
  className?: string,
}

const Dialog = (props: DialogPropsI) => {
  const { children, onBack, onClose, isShow = true, isFit = false, className } = props

  useEffect(() => {
    if (isShow) {
      document.getElementById('root')?.classList.add('block-scroll')
    } else {
      document.getElementById('root')?.classList.remove('block-scroll')
    }

    return () => {
      document.getElementById('root')?.classList.remove('block-scroll')
    }
  }, [isShow])

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 500)
  }, [isShow, children])

  return (
    isShow
      ? ReactDOM.createPortal(
        <BackdropS>
          <RootS isFit={isFit} className={className}>
            {onBack && <BackButtonS label={'Voltar'} onClick={onBack} />}
            {onClose && <IconCloseS icon='close' onClick={onClose} />}
            {children}
          </RootS>
        </BackdropS>,
        document.getElementById('root') || document.createElement('div')
      )
      : null
  )
}

export default Dialog
