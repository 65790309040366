import { useState } from 'react'

import type { DataCancelPlanContentManagerI } from 'ActionsContentManager/CancelPlan/Hooks/useStartCancelPlan.hook'
import { Button, Input } from 'DesignSystem'

import { fetcher } from 'Hooks/API/fetcher'
import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { apiPOST } from 'Services/Api.service'

import { delay } from 'Helpers/Time.helpers'

import { ContentS } from './FeedbackCancelModal.styled'

const FeedbackCancelModal = (props: AuxStepContentManagerI<DataCancelPlanContentManagerI>) => {
  const { prev, next, setData, dataContentManager } = props
  const intl = useIntlShort()
  const [isSelectedRadio, setIsSelectedRadio] = useState(false)
  const [feedbackSelected, setFeedbackSelected] = useState('')
  const [otherMotive, setOtherMotive] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const option1 = intl('O NeoDash é muito caro para mim.')
  const option2 = intl('Eu não uso muito o NeoDash.')
  const option3 = intl('Encontrei um produto que me atenderá melhor.')
  const option4 = intl('Outro motivo.')

  const handleOnChangeFeedbackSelect = (value: string) => {
    setFeedbackSelected(value)
    setIsSelectedRadio(true)
  }

  const handleSelectRadio = (evt: any) => {
    const target = evt.target as HTMLInputElement
    handleOnChangeFeedbackSelect(target.value)
  }

  const handleOnChangeOtherMotive = (evt: any) => {
    const target = evt.target as HTMLInputElement
    setOtherMotive(target.value)
  }

  const handleNextStep = async () => {
    setIsLoading(true)
    try {
      await apiPOST('feedback', {
        commentary: (() => {
          switch (feedbackSelected) {
            case '1': return option1
            case '2': return option2
            case '3': return option3
            case '4': return option4 + ' - ' + otherMotive
          }
        })(),
        origin: 'cancellation_plan'
      })

      const response : any = await fetcher('DELETE')('/subscription')
      setData({
        cancel_at: response.cancel_at
      })
      await delay(1000)
      dataContentManager.onMutateSubscription && dataContentManager.onMutateSubscription()

      next()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <h3 className='fw-bold mt-3'>{intl('Por que você está cancelando o NeoDash?')}</h3>
      <ContentS>
        <div className='d-flex flex-row gap-2 mt-2' onClick={() => handleOnChangeFeedbackSelect('1')}>
          <input type="radio" name="feedback" value="1" checked={feedbackSelected === '1'} onChange={handleSelectRadio} /> {option1}
        </div>
        <div className='d-flex flex-row gap-2 mt-2' onClick={() => handleOnChangeFeedbackSelect('2')}>
          <input type="radio" name="feedback" value="2" checked={feedbackSelected === '2'} onChange={handleSelectRadio} /> {option2}
        </div>
        <div className='d-flex flex-row gap-2 mt-2' onClick={() => handleOnChangeFeedbackSelect('3')}>
          <input type="radio" name="feedback" value="3" checked={feedbackSelected === '3'} onChange={handleSelectRadio} /> {option3}
        </div>
        <div className='d-flex flex-column gap-2 mt-2' onClick={() => handleOnChangeFeedbackSelect('4')}>
          <div className='d-flex flex-row gap-2'>
            <input type="radio" name="feedback" value="4" checked={feedbackSelected === '4'} onChange={handleSelectRadio} /> {option4}
          </div>
          {feedbackSelected === '4' ? <Input placeholder={intl('Digite brevemente o motivo')} onChange={handleOnChangeOtherMotive} /> : null}
        </div>
      </ContentS>
      <div className='d-flex flex-row gap-4 mt-3 align-items-center justify-content-center'>
        <Button disabled={isLoading} onClick={() => prev(0)} color='success'>{intl('Voltar')}</Button>
        <Button isLoading={isLoading} disabled={!isSelectedRadio} color='error' onClick={handleNextStep}>{intl('Avançar')}</Button>
      </div>
    </div>
  )
}

FeedbackCancelModal.dialogProps = {
  style: {
    maxWidth: 'fit-content',
    padding: '44px'
  }
}

export default FeedbackCancelModal
