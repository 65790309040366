import styled from 'styled-components/macro'

export const QuestionsCardRoot = styled.div`
  /* height: 150px; */
  display: flex;
  /* align-items: center; */
  background-color: ${({ theme }) => (theme.colors.gray60)};
  border-radius: 1rem;
  padding: 10px 1rem;
  margin-bottom: 2rem;
  flex: 1;

  @media(max-width: 768px) {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`
