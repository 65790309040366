import styled, { css } from 'styled-components/macro'

import Text from 'Components/Text'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

export const WrapperS = styled.div`
  position: relative;
`

interface RootI {
  size?: SizeComponentI
}

export const ItemS = styled.div`
  cursor: pointer;

  :hover {
    background-color: #FEC34E10;
  }

  ${Text} {
    cursor: pointer;
  }
`

interface WrapperItemsI {
  isOpen: boolean,
  size?: SizeComponentI
}

export const WrapperItemsS = styled.div<WrapperItemsI>`
  width: 100%;
  max-height: ${props => props.isOpen ? '300px' : '0'};

  position: absolute;
  overflow: ${props => props.isOpen ? 'auto' : 'hidden'};
  z-index: 5;

  padding: 30px;
  padding-bottom: ${props => props.isOpen ? '30px' : '0'};

  background-color: white;

  transition: max-height 500ms, padding-bottom 500ms;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar{
    display: none;
    width: 0 !important
  }

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          top: calc(var(--input-base-small-height-inner) / 2);

          padding-top: calc(var(--input-base-small-height-inner) / 2);
        `
      case 'big':
        return css`
          top: calc(var(--input-base-big-height-inner) / 2);

          padding-top: calc(var(--input-base-big-height-inner) / 2);
        `
      default:
        return css`
          top: calc(var(--input-base-height-inner) / 2);

          padding-top: calc(var(--input-base-height-inner) / 2);
        `
    }
  }}

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  ${props => {
    if (props.isOpen) {
      return css`
        box-shadow: 0 3px 6px #00000016;
      `
    }
  }}
`

export const RootS = styled.div<RootI>`
  width: 100%;

  position: relative;
  z-index: 10;
  cursor: pointer;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: var(--input-base-small-height-inner);
        `
      case 'big':
        return css`
          height: var(--input-base-big-height-inner);
        `
      default:
        return css`
          height: var(--input-base-height-inner);
        `
    }
  }}

  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;

  padding: 0 30px;

  background-color: white;
  border-radius: 30px;
  box-shadow: inset 0 2px 3px #00000020;

  ${Text},
  input {
    margin: 0 0 0 15px;
  }
`

export const InputS = styled.input`
  cursor: pointer;

  border: none;
  outline: none;
`
