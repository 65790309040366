import styled from 'styled-components/macro'

interface CalendarSectionProps {
  backgroudColor?: string;
  padding?: string;
  borderRadius?: string;
}

interface MonthAndYearSectionProps {
  color?: string;
  fontWeightMonthAndYear?:
    | 'bold'
    | 'normal'
    | 'bolder'
    | 'lighter'
    | 'initial'
    | 'inherit';
}

export const RootS = styled.div`
  display: flex;

  font-family: var(--font-base-family-normal);
`

export const CalendarSectionS = styled.div<CalendarSectionProps>`
  padding: ${(props) => props.padding || '10px'};

  background: ${(props) => props.backgroudColor || '#FFF'};
  border-radius: ${(props) => props.borderRadius || '5px'};
`

export const HeaderSectionS = styled.div`
  width: 100%;
  max-width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;
`

export const MonthAndYearSectionS = styled.div<MonthAndYearSectionProps>`
  width: 100%;

  display: flex;
  align-items: center;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;
    margin-right: 15px;

    font-size: 1.2rem;
    font-weight: ${(props) => props.fontWeightMonthAndYear || 'bold'};
    color: ${(props) => props.color || '#000'};
  }
`

export const ButtonsSectionS = styled.div`
  display: flex;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    background: transparent;
    border: none;
  }
`

export const DayWeeksSectionS = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`

export const WeeksSectionS = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`

interface DayWeekProps {
  color?: string;
}

export const DayWeekS = styled.div<DayWeekProps>`
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1rem;
  color: ${(props) => props.color || '#000'};
`

interface MonthProps {
  BgColor?: string;
}

export const MonthS = styled.div<MonthProps>`
  margin-top: 10px;

  background-color: ${(props) => props.BgColor || '#FFF'};
  border-radius: 4px;
`
interface CircleProps {
  color?: string;
  bgColor?: string;
  borderColor?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  display?: string;
}

interface ContinuosBackgroudProps {
  color?: string;
  bgColor?: string;
  borderColor?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  display?: string;
  background?: string;
}

export const CircleS = styled.div<CircleProps>`
  width: 25px;
  height: 25px;

  display: ${(props) => props.display || ''};
  justify-content: center;
  align-items: center;
  z-index: 1;

  position: absolute;

  background-color: ${(props) => props.bgColor || '#FFF'};
  border-radius: 50%;
`

export const ContinuosBackgroundS = styled.div<ContinuosBackgroudProps>`
  width: 100%;
  height: 35px;

  display: ${(props) => props.display || ''};
  justify-content: center;
  align-items: center;
  z-index: 1;

  position: absolute;

  background-color: #FEC34E20;
  border-radius: ${(props) => props.borderRadius || '0px'};

  &:first-child {
    border-top-left-radius: ${(props) => props.borderRadius || '0px'};
    border-top-right-radius: ${(props) => props.borderRadius || '0px'};
  }
`
interface DayI {
  isDaySelected: boolean
}

export const DayS = styled.div<DayI>`
  width: 60px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  position: relative;
  padding: 0 10px;

  font-size: ${props => props.isDaySelected ? '1.3rem' : '1rem'};
  font-weight: ${props => props.isDaySelected ? 'bold' : 'normal'};

  .selectedDay {
    background-color: #FFF;

    color: #000;
  }
`

interface TextDayI {
  isDaySelected: boolean
}

export const TextDayS = styled.p<TextDayI>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  font-size: 1rem;
  font-weight: ${props => props.isDaySelected ? 'bold' : 'normal'};
  color: #707474;
`
