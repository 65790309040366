import { CheckCircle } from '@phosphor-icons/react'

import type { DataStartDowngradePlanContentManagerI } from 'ActionsContentManager/DowngradePlan/Hooks/useStartDowngradePlan.hook'
import { Button } from 'DesignSystem'
import theme from 'Theme'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

const FinalFeedbackDowngradeModal = (props: AuxStepContentManagerI<DataStartDowngradePlanContentManagerI>) => {
  const { close, dataContentManager } = props
  const intl = useIntlShort()
  return (
    <div className='d-flex flex-column align-items-center'>
      <h3 className='fw-bold d-flex flex-row justify-content-center gap-3'><CheckCircle size={24} color={theme.colors.success} /> {intl('Solicitação concluída')}</h3>
      <p className='text-start w-100 mt-3'>{intl('Você ainda poderá utilizar seu plano atual e os recursos inclusos nele até DD/MM/AA. 5 dias antes dessa data, você poderá revisar sua decisão a respeito dos workspaces e conexões que serão deletados.', { date: dataContentManager.scheduled })}</p>
      <p className='text-start w-100 mt-3'>{intl('Após esse período, eles serão deletados e apenas poderão ser restaurados caso você faça um upgrade de plano ou delete o workspace ou alguma das conexões ativas.')}</p>
      <p className='text-start w-100 mt-3 mb-3'>{intl('Caso isso não ocorra, as informações referentes aos workspaces e conexões em questão serão deletados permanentemente de nossa base.')}</p>
      <Button onClick={close}>{intl('Continuar')}</Button>
    </div>
  )
}

FinalFeedbackDowngradeModal.dialogProps = {
  style: {
    maxWidth: '454px'
  }
}

export default FinalFeedbackDowngradeModal
