import { Warning } from '@phosphor-icons/react'
import { useState } from 'react'

import type { DataStartDowngradePlanContentManagerI } from 'ActionsContentManager/DowngradePlan/Hooks/useStartDowngradePlan.hook'
import { Button } from 'DesignSystem'

import useCompanies from 'Hooks/API/useCompanies.hook'
import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { apiPOST } from 'Services/Api.service'

const ChooseWorkspaceDowngradeModal = (props: AuxStepContentManagerI<DataStartDowngradePlanContentManagerI>) => {
  const { close, next, dataContentManager, setData } = props
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntlShort()
  const [selecteds, setSelecteds] = useState<Array<string>>([])
  const { data: companies, isLoading: isLoadingCompanies } = useCompanies({ onlyOwner: true })

  const totalToSelect = dataContentManager.downgradeInfo?.workspaces ? companies.length - dataContentManager.downgradeInfo?.workspaces.to : 0
  const isAllSelected = selecteds.length >= totalToSelect

  // create a function to handle the click on the row
  // if the row is already selected, remove it from the selecteds array
  // if the row is not selected, add it to the selecteds array
  const handleRowClick = (id: string) => {
    if (selecteds.includes(id)) {
      setSelecteds(selecteds.filter((s) => s !== id))
    } else {
      setSelecteds([...selecteds, id])
    }
  }

  const handleScheduleDowngrade = async () => {
    setIsLoading(true)
    try {
      const response : any = await apiPOST('subscription/downgrade/schedule', {
        plan: dataContentManager.planID,
        workspaces: selecteds
      })

      setData({
        scheduled: response.scheduled
      })

      next()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <Warning size={64} color='#F7C347' />
      <h3 className='fw-bold'>{intl('Antes de concluir seu downgrade')}</h3>
      <p>{intl('Devido a redução de workspaces que você poderá ter, será necessário deletar alguns dos workspaces que foram criados. Por favor, selecione os que serão deletados.')}</p>
      <div className='d-flex flex-column justify-content-start align-items-start w-100 mt-3'>
        <label className='fw-bold'>{intl('Workspaces selecionados: N/N', { selected: selecteds.length, total: totalToSelect })}</label>
        {isLoadingCompanies ? null : (
          <table className='mt-3'>
            <thead>
              <tr>
                <th></th>
                <th className='text-start'>{intl('Workspace')}</th>
                <th className='text-start'>{intl('Qtde. de Usuários')}</th>
                <th className='text-start'>{intl('Conectores configurados')}</th>
              </tr>
            </thead>
            <tbody>
              {companies?.map((c, i: any) => {
                return (
                  <tr key={i} onClick={() => handleRowClick(c.id)} style={{ cursor: 'pointer' }} className='mt-2'>
                    <td><input type="checkbox" checked={selecteds.includes(c.id)}/></td>
                    <td>{c.name}</td>
                    <td>{c.count_users} {intl('usuários')}</td>
                    <td>{c.count_accounts} {intl('conectores')}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className='d-flex flex-column align-items-start w-100 mt-3'>
        <p>{intl('*Os usuários com acesso aos workspaces deletados serão notificados sobre sua exclusão.')}</p>
        <p className='mt-3'>{intl('**Os workspaces deletados serão arquivados e poderão ser restaurados em até 30 dias caso você faça um upgrade de plano ou delete o workspace ativo atualmente.')}</p>
      </div>
      <div className='d-flex flex-row gap-4 mt-4'>
        <Button onClick={close} color='success'>{intl('Manter plano atual')}</Button>
        <Button disabled={!isAllSelected} isLoading={isLoading} color='error' onClick={handleScheduleDowngrade}>{intl('Avançar')}</Button>
      </div>
    </div>
  )
}

export default ChooseWorkspaceDowngradeModal
