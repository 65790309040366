import React from 'react'

import { Select } from 'DesignSystem'

import type { DataCompanyCreateContentManagerI } from 'Components/FirstSetupFlow'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'

type NameCompanyCreateDialogPropsI =
  AuxStepContentManagerI<DataCompanyCreateContentManagerI>;

const options = [
  {
    value: 'pt-BR',
    content: 'Português (Brasil)'
  },
  {
    value: 'en-US',
    content: 'English'
  }
]

const LocaleWorkspaceField = ({
  setData,
  dataContentManager
}: NameCompanyCreateDialogPropsI) => {
  const { locale } = dataContentManager

  return (
    <Select
      className="mt-2"
      value={locale}
      onChange={({ target }: any) => setData({ locale: target.value })}
    >
      {options.map((opt, key) => (
        <option value={opt.value} key={key}>
          {opt.content}
        </option>
      ))}
    </Select>
  )
}

export default LocaleWorkspaceField
