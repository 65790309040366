import type { HTMLAttributes } from 'react'

import type DefaultProps from 'DesignSystem/Types'
import styled, { css } from 'styled-components'

interface Props extends DefaultProps, HTMLAttributes<HTMLBaseElement> {
  color?: 'primary' | 'gray' | 'white' | 'error' | 'success' | 'alert';
  onClick?: () => void;
}

const TagStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css(theme.fonts.types.body1)};

  padding: ${({ theme }) => theme.metrics.space1} ${({ theme }) => theme.metrics.space5};

  background-color: ${({ theme, color }) => color !== undefined && theme.colors[color]};
  border-radius: ${({ theme }) => theme.metrics.radius};

  ${({ color, theme }) => {
    if (color === 'white') {
      return css({
        border: `1px solid ${theme.colors.gray}`
      })
    }
  }}

  font-weight: 600;
  color: ${({ theme, color }) => {
    if (`${color}_text` in theme.colors && color !== undefined) {
      // @ts-ignore
      return theme.colors[`${color}_text`] ?? theme.colors.dark
    }

    return theme.colors.dark
  }};

  line-height: 32px;
`

const Tag: React.FC<Props> = ({ children, color = 'primary', onClick, className }) => {
  return (
    <TagStyled color={color} onClick={onClick} className={className}>
      {children}
    </TagStyled>
  )
}

export default styled(Tag)``
