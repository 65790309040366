import styled from 'styled-components/macro'

export interface RootI {
  size: 'small' | 'normal' | 'big'
}

export const RootS = styled.div<RootI>`
  display: inline-block;
  cursor: pointer;
`

interface WrapperInnerI {
  size: RootI['size']
}

export const WrapperInnerS = styled.div<WrapperInnerI>`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
`

export const BadgeColorS = styled.span`
  width: 24px;
  height: 24px;

  margin-left: 10px;
  margin-right: 10px;

  background-color: ${({ color }: any) => color};
  border-radius: 5px;
`

export const PlaceholderS = styled.div`
  color: var(--font-gray);
`

export const PickerS = styled.div`
  width: 220px;

  z-index: 10;

  position: absolute;
  top: 56px;
`
