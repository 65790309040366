import styled from 'styled-components/macro'

export const RootS = styled.div`
  width: 95%;
`

export const HeaderS = styled.div`
  width: 100%;
  height: 95px;

  padding: 37px 44px;

  background-color: ${(props) => props.theme.colors.gray60};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

interface ContentPropsI {
  withoutPaddingOnSide?: boolean
}

export const ContentS = styled.div<ContentPropsI>`
  width: 100%;

  ${({ withoutPaddingOnSide }) => {
    if (withoutPaddingOnSide) {
      return `
        padding: 1.5rem 0px;
      `
    }

    return `
      padding: 1.5rem;
    `
  }}

  background-color: ${(props) => props.theme.colors.white};
  border-radius: 1rem;

  ${HeaderS} + & {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`
