import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'

import StyledHelpers from 'Helpers/Styled.helpers'

export interface RootPropsI {
  typeButton: 'primary' | 'outline' | 'naked' | 'soft' | 'link'
  size: 'small' | 'normal' | 'big',
  disabled: boolean,
  isFit?: boolean,
}

const Base = css<RootPropsI>`
  ${({ isFit }) => {
    if (isFit) {
      return css`
        width: fit-content;
      `
    }

    return css`
      width: 100%;
    `
  }}

  ${StyledHelpers.BaseButtonHeight}

  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  padding: 0 30px;

  border-radius: var(--button-base-border-radius);

  white-space: nowrap;

  :disabled,
  [disabled=""] {
    opacity: var(--base-disabled-opacity);

    cursor: not-allowed;
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        opacity: var(--base-disabled-opacity);

        cursor: not-allowed;
      `
    }
  }}

  ${IconSVG} {
    fill: var(--font-black);
  }

  ${IconSVG} + label {
    margin: 0 0 0 15px;
  }

  label {
    cursor: pointer;

    ${StyledHelpers.BaseButtonLabel}
  }

  ${({ typeButton }) => {
    switch (typeButton) {
      case 'primary':
        return css`
          background-color:  ${(props) => props.theme.colors.primary};
          border: none;
          color: ${(props) => props.theme.colors.dark};
        `
      case 'outline':
        return css`
          background-color: transparent;
          border: var(--button-base-border-outline);
          color: var(--button-base-color-outline);

          ${IconSVG} {
            fill: var(--yellow);
          }

          :hover {
            background-color:  ${(props) => props.theme.colors.primary};

            color: ${(props) => props.theme.colors.dark};

            * {
              fill: white;
            }
          }
        `
      case 'naked':
        return css`
          background-color: transparent;
          border: var(--button-base-border-outline);
          border-color: transparent;

          color: var(--button-base-color-outline);
        `
      case 'soft':
        return css`
          background-color: #F7F8F9;
          border: var(--button-base-border-outline);
          border-color: #707474;

          color: #707474;
        `
      case 'link':
        return css`
          background-color: transparent;
          border: var(--button-base-border-outline);
          border-color: transparent;

          color: #707474;
          text-decoration: underline;

        `
    }
  }}
`

export const RootS = styled.button`
  ${Base}
`

export const RootLinkS = styled(Link)`
  ${Base}
`

export const RootAS = styled.a`
  ${Base}
`
