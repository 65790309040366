import useSWR from 'swr'

import { apiPOST, apiPUT } from 'Services/Api.service'

import { fetcher } from './fetcher'

export interface CompanyI {
  id: string,
  name: string,
  icon: string,
  slug: string,
  color: string,
  currency_read: string,
  locale: string,
  is_completed: boolean,
  config_advanced: string,
}

interface OptionsI {
  id: string
}

interface CompanyUpdateI {
  name?: string,
  color?: string,
  icon?: string,
  locale?: string,
  currency_read?: string,
  config_advanced?: string,
}

const useCompany = (options: OptionsI) => {
  const { id } = options

  const { data, error, mutate } = useSWR<CompanyI>(`company/${id}`, fetcher('GET'))

  const update = async (params: CompanyUpdateI) => {
    await apiPUT(`company/${id}`, {
      name: params.name,
      color: params.color,
      currency_read: params.currency_read,
      locale: params.locale,
      config_advanced: params.config_advanced
    })

    if (params.icon) {
      await apiPOST(`company/${id}/icon-odash`, { base: params.icon, path: `logo-${id}`, id })
    }

    mutate()
  }

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    update,
    mutate
  }
}

export default useCompany
