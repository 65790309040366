import Editor, { DiffEditor } from '@monaco-editor/react'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

import { format, parseISO } from 'date-fns'

import Button from 'Components/Button'
import SelectFieldNewStyle from 'Components/SelectField/SelectFieldNewStyle.component'
import Text from 'Components/Text'

import useCompany from 'Hooks/API/useCompany.hook'
import useConfigAdvanceds from 'Hooks/API/useConfigAdvanceds.hook'

import { MicroData } from 'Helpers/MicroData.helper'

import { ButtonS } from './ConfigAdvanced.styled'

const ConfigAdvanced = () => {
  const intl = useIntl()
  const { id } = useParams<{id: string}>()
  const { data, update, mutate } = useCompany({ id })
  const [showDiff, setShowDiff] = useState(false)
  const [value, setValue] = useState(JSON.stringify(data?.config_advanced, null, 2) ?? '{}')
  const [version, setVersion] = useState('current' as string | undefined)
  const [versionValue, setVersionValue] = useState(value)
  const valueIsChanged = value !== JSON.stringify(data?.config_advanced, null, 2)
  const { data: dataConfigAdvanceds } = useConfigAdvanceds({ id })
  const [canUpdate, setCanUpdate] = useState(false)

  const handleSave = () => {
    setCanUpdate(true)
    update({ config_advanced: value })
    setTimeout(() => {
      mutate()
    }, 1000)
  }

  useEffect(() => {
    if (canUpdate) {
      setValue(JSON.stringify(data?.config_advanced, null, 2) ?? '{}')
    }
  }, [data?.config_advanced])

  const handleShowDiff = () => {
    setShowDiff(!showDiff)
  }

  const handleEditorChange = (value: string | undefined) => {
    setCanUpdate(false)
    setValue(value ?? '{}')
  }

  const handleChangeVersion = (id?: string) => {
    if (!id) return

    setVersion(id)
    if (id !== 'current') {
      const configAdvanced = dataConfigAdvanceds.find((configAdvanced) => configAdvanced.id === id)
      setVersionValue(JSON.stringify(configAdvanced?.config_advanced, null, 2) ?? '{}')
    } else {
      setVersionValue(JSON.stringify(data?.config_advanced, null, 2) ?? '{}')
    }
  }

  return (
    <div>
      <ButtonS data-component-type={MicroData.INFO_FROM_MODEL}>
        <Button isFit isDisabled={!valueIsChanged} label={intl.formatMessage({ id: 'Salvar' })} onClick={handleSave} />
        <Button isFit label={!showDiff ? intl.formatMessage({ id: 'Mostrar diferenças' }) : intl.formatMessage({ id: 'Mostrar o atual valor' })} onClick={handleShowDiff}/>
        {showDiff && <SelectFieldNewStyle
          placeholder={intl.formatMessage({ id: 'Selecione uma versão da configuração avançada' })}
          value={version}
          onChange={handleChangeVersion}
          options={[{
            id: 'current', text: 'Atual'
          }, ...dataConfigAdvanceds.map((configAdvanced) => ({
            id: configAdvanced.id, text: format(parseISO(configAdvanced.creation), 'dd/MM/yyyy HH:mm')
          }))]}
        />}
      </ButtonS>
      <Text data-component-type={MicroData.INFO_FROM_MODEL}>
        {intl.formatMessage({ id: 'Caso tenha uma propriedade "HARD_MANUAL" com valor true, no primeiro nível da configuração, nenhum valor será alterado dinâmica pelo sistema (como: odashInstance.slug, odashInstance.brand, odashInstance.dbName, odashInstance.locale, odashInstance.logotype, odashInstance.baseColor, odashInstance.companyId, odashInstance.clientName, odashInstance.imgDefault, odashInstance.currencyRead, odashInstance.currencyWrite).' })}
      </Text>
      <div data-component-type={MicroData.INFO_FROM_MODEL}>
        {showDiff ? (
          <DiffEditor
            height="90vh"
            language="json"
            original={versionValue}
            modified={value}
          />) : (
      <Editor
        height="90vh"
        defaultLanguage="json"
        value={value}
        onChange={handleEditorChange}
      />
        )}
      </div>
    </div>
  )
}

export default ConfigAdvanced
