function ImageSelectIconSvg () {
  return (
    <svg width="176" height="84" viewBox="0 0 176 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="16.5" y1="2.18557e-08" x2="16.5" y2="84" stroke="#E8E8E8"/>
      <line x1="64.5" y1="2.18557e-08" x2="64.5" y2="84" stroke="#E8E8E8"/>
      <line x1="112.5" y1="2.18557e-08" x2="112.5" y2="84" stroke="#E8E8E8"/>
      <line x1="40.5" y1="2.18557e-08" x2="40.5" y2="84" stroke="#E8E8E8"/>
      <line x1="88.5" y1="2.18557e-08" x2="88.5" y2="84" stroke="#E8E8E8"/>
      <line x1="136.5" y1="2.18557e-08" x2="136.5" y2="84" stroke="#E8E8E8"/>
      <line x1="160.5" y1="2.18557e-08" x2="160.5" y2="84" stroke="#E8E8E8"/>
      <line x1="9.15858e-08" y1="7.13635" x2="176" y2="7.13636" stroke="#E8E8E8"/>
      <line x1="9.15858e-08" y1="30.0454" x2="176" y2="30.0454" stroke="#E8E8E8"/>
      <line x1="9.15858e-08" y1="52.9546" x2="176" y2="52.9546" stroke="#E8E8E8"/>
      <line x1="9.15858e-08" y1="18.5909" x2="176" y2="18.591" stroke="#E8E8E8"/>
      <line x1="9.15858e-08" y1="41.5" x2="176" y2="41.5" stroke="#E8E8E8"/>
      <line x1="9.15858e-08" y1="64.4091" x2="176" y2="64.4091" stroke="#E8E8E8"/>
      <line x1="9.15858e-08" y1="75.8636" x2="176" y2="75.8637" stroke="#E8E8E8"/>
      <path d="M88 54C94.6274 54 100 48.6274 100 42C100 35.3726 94.6274 30 88 30C81.3726 30 76 35.3726 76 42C76 48.6274 81.3726 54 88 54Z" stroke="#0A1A28" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M83 42H93" stroke="#0A1A28" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M88 37V47" stroke="#0A1A28" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ImageSelectIconSvg
