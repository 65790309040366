import React from 'react'
import { useIntl } from 'react-intl'

import { MicroData, passAlongMicroData } from 'Helpers/MicroData.helper'

import { RootS, StepS, WrapperStepS } from './StepsVisual.styled'

interface StepsVisualPropsI {
  steps: number;
  current: number;
}

const StepsVisual = (props: StepsVisualPropsI) => {
  const { steps, current } = props
  const intl = useIntl()

  return (
    <RootS {...passAlongMicroData(props)}>
      <h3
        className="subtitle2 fw-bold my-4"
        data-component-type={MicroData.INFO_FROM_MODEL}
      >
        {intl.formatMessage(
          { id: 'Etapa X de X' },
          { steps, current: current + 1 }
        )}
      </h3>
      <WrapperStepS data-component-type={MicroData.INFO_FROM_MODEL}>
        {Array(steps)
          .fill('')
          .map((_, i) => (
            <StepS key={i} active={current >= i} />
          ))}
      </WrapperStepS>
    </RootS>
  )
}

export default StepsVisual
