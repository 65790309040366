import { useEffect, useState } from 'react'

import { Modal } from 'DesignSystem'

import useUser from 'Hooks/API/useUser.hook'
import useContentManager from 'Hooks/useContentManager.hook'

import PastDuePlan from '../Components/PastDuePlan'

export const usePastDuePlanContentManager = () => {
  const { data: user, isLoading } = useUser()
  const [isStarted, setIsStarted] = useState(false)
  const { start, content } = useContentManager([
    PastDuePlan
  ], {
    dialog: Modal,
    dialogProps: {
      noPadding: true
    },
    onClose: () => {
      if (!user) return
      if (user?.subscription?.status === 'past_due') {
        localStorage.setItem('odash-app:notify-past-due-payment', 'true')
      }
    }
  })

  useEffect(() => {
    if (isStarted) return
    if (!user) return
    if (isLoading) return

    const flagNotifyPastDuePayment = localStorage.getItem('odash-app:notify-past-due-payment') === 'true'

    if (user?.subscription?.status === 'past_due' && !flagNotifyPastDuePayment) {
      setIsStarted(true)
      start()
    }
  }, [user, isLoading, isStarted])

  return {
    start: start,
    content
  }
}
