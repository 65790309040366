import { CaretDown, List, X } from '@phosphor-icons/react'
import React, { useState, useContext } from 'react'

import styled from 'styled-components'

const DropdownContext = React.createContext({})

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const DropdownButton = styled.button`
  color: ${props => props.theme.colors.dark};
  background-color: ${props => props.theme.colors.white};
  ${props => props.theme.fonts.types.h4};
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;

  @media (max-width: 768px){
    padding: 5px;
  }
`

const DropdownItems = styled.ul<any>`
  display: ${(props : any) => (props.open ? 'block' : 'none')};
  position: absolute;
  overflow: hidden;
  right: 0;
  padding: 0;
  list-style: none;
  background-color: ${props => props.theme.colors.white};
  min-width: 100%;
  border-radius: 0 0 10px 10px;
  z-index: 1;

  @media (max-width: 768px){
    width: 100vw;
    right: -10px;
  }

`

const DropdownItemStyled = styled.li`
  padding: 12px 16px;
  cursor: pointer;

  display: flex;
  align-items: center;

  svg {
    margin-right: 0.6rem;
  }

  &:hover {
    background-color: ${props => props.theme.colors.hover};
  }

  @media (max-width: 768px){
    justify-content: end;
  }

`

const DropdownCaretStyled = styled.span<any>`
  svg {
    transition: transform 0.5s;
    transform: rotate(${(props : any) => (props.open ? '-180deg' : '0deg')});
  }
`

const Dropdown = ({ children } : any) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <DropdownWrapper>
      <DropdownContext.Provider value={{ isOpen, setIsOpen }}>
        {children}
      </DropdownContext.Provider>
    </DropdownWrapper>
  )
}

const Caret = ({ children } : any) => {
  const { isOpen } : any = useContext(DropdownContext)
  return <DropdownCaretStyled open={isOpen}><CaretDown weight='bold' size={24} /></DropdownCaretStyled>
}

const MenuXIcon = ({ children } : any) => {
  const { isOpen } : any = useContext(DropdownContext)
  return isOpen ? <X weight='bold' size={24}/> : <List weight='bold' size={24} />
}

const Trigger = ({ children } : any) => {
  const { setIsOpen } : any = useContext(DropdownContext)
  return <DropdownButton onClick={() => setIsOpen((prev : any) => !prev)}>{children}</DropdownButton>
}

const Items = ({ items } : any) => {
  const { isOpen, setIsOpen } : any = useContext(DropdownContext)

  const handleOnClick = (item: any) => {
    item.onClick()
    setIsOpen(false)
  }

  return (
    <DropdownItems open={isOpen}>
      {items.map((item : any, index : number) => (
        <DropdownItemStyled key={index} onClick={() => handleOnClick(item)}>
          {item.iconComponent} {item.content}
        </DropdownItemStyled>
      ))}
    </DropdownItems>
  )
}

Dropdown.Trigger = Trigger
Dropdown.Caret = Caret
Dropdown.MenuXIcon = MenuXIcon
Dropdown.Items = Items

export default Dropdown
