import styled from 'styled-components/macro'

export const RootS = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.2rem;

  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: ${({ theme }) => theme.metrics.radius};

  height: 100%;

  img {
    max-width: 32px;
  }

  span, svg {
    width: 1.8rem !important;
    height: 1.8rem !important;
    transition: fill 0.5s ease-in-out;
  }

  svg:hover {
    fill: ${({ theme }) => theme.colors.dark};
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .text-wrap {
    word-break: break-all;
  }
`

export const ActionsS = styled.div`
`
