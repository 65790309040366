export const isBase64 = (str: string): boolean => {
  const notBase64 = /[^A-Z0-9+\/=]/i
  const len = str.length
  if (!len || len % 4 !== 0 || notBase64.test(str)) {
    return false
  }
  const firstPaddingChar = str.indexOf('=')
  return firstPaddingChar === -1 ||
    firstPaddingChar === len - 1 ||
    (firstPaddingChar === len - 2 && str[len - 1] === '=')
}

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => {
      reject(error)
    })
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg (
  imageSrc : string,
  pixelCrop : any,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) : Promise<string | null> {
  const image = await createImage(imageSrc)
  const canvas : HTMLCanvasElement | null = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx || !image) {
    return null
  }

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(image.width / 2, image.height / 2)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height)

  // As Base64 string
  // return canvas.toDataURL('image/png')

  const newImage = canvas.toDataURL('image/png')
  const objimage = await createImage(newImage)

  const newCanvas = document.createElement('canvas')

  newCanvas.width = 512
  newCanvas.height = 256

  // let canvas = document.getElementById("canvas");
  const newctx = newCanvas.getContext('2d')

  if (!newctx || !objimage) {
    return null
  }

  // Actual resizing
  newctx.drawImage(objimage, 0, 0, 512, 256)

  // Show resized image in preview element
  return newCanvas.toDataURL('image/png')
}
