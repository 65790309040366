import useSWR from 'swr'

import { createQuery } from 'Helpers/Url.helper'

import { fetcher } from './fetcher'

interface PermissionCheckI {
  id: string,
  canUse: boolean,
  companyId?: string,
  count?: number,
  countEnabled?: number
}

interface PropsCheckI {
  isDisabled: boolean,
  'data-for'?: string,
  'data-tip'?: string
}

type UseCheckPermissionReturnI = {
  data: Array<PermissionCheckI>,
  isLoading: boolean,
  isError: any,
  asserts?: {
    someFalse: boolean,
    someTrue: boolean,
    everyFalse: boolean,
    everyTrue: boolean
  },
  allProps?: {
    propsSomeFalse: PropsCheckI,
    propsSomeTrue: PropsCheckI,
    propsEveryFalse: PropsCheckI,
    propsEveryTrue: PropsCheckI,
  }
}

const useCheckPermission = (permissions: string[] = [], companyId?: string | string[]) : UseCheckPermissionReturnI => {
  const query = createQuery()

  query.addParams('permission', permissions)

  if (companyId) {
    query.addParams('companyId', companyId)
  }

  const { data, error } = useSWR<Array<PermissionCheckI>>(`/check/permission${query.getValue()}`, fetcher('GET'))

  const someFalse = (data || []).some(p => !p.canUse)
  const propsSomeFalse = {
    isDisabled: someFalse,
    'data-for': someFalse ? 'tip-global-permission' : undefined,
    'data-tip': someFalse ? JSON.stringify(data) : undefined
  }

  const someTrue = !(data || []).some(p => p.canUse)
  const propsSomeTrue = {
    isDisabled: someTrue,
    'data-for': someTrue ? 'tip-global-permission' : undefined,
    'data-tip': someTrue ? JSON.stringify(data) : undefined
  }

  const everyFalse = (data || []).every(p => !p.canUse)
  const propsEveryFalse = {
    isDisabled: everyFalse,
    'data-for': everyFalse ? 'tip-global-permission' : undefined,
    'data-tip': everyFalse ? JSON.stringify(data) : undefined
  }

  const everyTrue = (data || []).every(p => p.canUse)
  const propsEveryTrue = {
    isDisabled: everyTrue,
    'data-for': everyTrue ? 'tip-global-permission' : undefined,
    'data-tip': everyTrue ? JSON.stringify(data) : undefined
  }

  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error,
    asserts: data ? {
      someFalse,
      someTrue,
      everyFalse,
      everyTrue
    } : undefined,
    allProps: data ? {
      propsSomeFalse,
      propsSomeTrue,
      propsEveryFalse,
      propsEveryTrue
    } : undefined
  }
}

export default useCheckPermission
