import useSWR from 'swr'

import { fetcher } from './fetcher'

export interface CheckInviteI {
  status: string,
  platform: string,
  email?: string,
  isMigration: boolean,
}

interface OptionsI {
  invite: string | null
}

const useCheckInvite = (options: OptionsI) => {
  const { invite } = options

  const { data, error } = useSWR<CheckInviteI>(invite ? `check/invite/${invite}` : null, fetcher('GET'))

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}

export default useCheckInvite
