import type { DataStartDowngradePlanContentManagerI } from 'ActionsContentManager/DowngradePlan/Hooks/useStartDowngradePlan.hook'
import { Modal } from 'DesignSystem'

import useContentManager from 'Hooks/useContentManager.hook'

import BeforeCancelModal from '../Components/BeforeCancelModal'
import FeedbackCancelModal from '../Components/FeedbackCancelModal'
import FirstModalCancelPlan from '../Components/FirstModal'
import SuccessCancelModal from '../Components/SuccessCancelModal'

interface ParamsI {
  onWantChangePlan?: (initialData: DataStartDowngradePlanContentManagerI) => void,
  onWantChoosePlan?: () => void,
  onMutateSubscription?: () => void,
  is_trialing?: boolean

}

export interface DataCancelPlanContentManagerI {
  onWantChangePlan?: (initialData: DataStartDowngradePlanContentManagerI) => void,
  onWantChoosePlan?: () => void,
  onMutateSubscription?: () => void,
  cancel_at?: string,
  is_trialing?: boolean
}

export const useCancelPlanContentManager = (params: ParamsI = {}) => {
  const { start, content } = useContentManager<DataCancelPlanContentManagerI>([
    FirstModalCancelPlan,
    BeforeCancelModal,
    FeedbackCancelModal,
    SuccessCancelModal
  ], {
    dialog: Modal,
    initialData: {
      onWantChangePlan: params.onWantChangePlan,
      onWantChoosePlan: params.onWantChoosePlan,
      onMutateSubscription: params.onMutateSubscription,
      is_trialing: params.is_trialing
    }
  })

  return { start, content }
}
