import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { InputWithPrefixSufix } from 'DesignSystem'

import { addAlert } from 'Store/Actions/alerts.action'
import { companyAvailability } from 'Store/Actions/Company/domain.action'

import type { DataCompanyCreateContentManagerI } from 'Components/FirstSetupFlow'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useDebounce from 'Hooks/useDebounce.hook'

import { flat } from 'Helpers/String.helper'
import { cleanSubdomain } from 'Helpers/Url.helper'

type NameCompanyCreateDialogPropsI =
  AuxStepContentManagerI<DataCompanyCreateContentManagerI>;

const SlugWorkspaceField = ({
  setData,
  dataContentManager,
  setEnableNext
}: NameCompanyCreateDialogPropsI) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const suggestionSlug = flat((dataContentManager.name || '').trim())

  const ERROR_UNAVAIABLE = intl.formatMessage({ id: 'Este slug já está em uso, por favor escolha outra opção.' })

  const { slug, isValidSlug } = dataContentManager
  const [isLoading, setIsLoading] = useState(true)
  const [isAvailable, setIsAvailable] = useState<boolean | null>(isValidSlug || null)
  const [errorMessage, setErrorMessage] = useState(ERROR_UNAVAIABLE)
  const [alreadyTyping, setAlreadyTyping] = useState(false)
  const [slugLocal, setSlugLocal] = useState('')
  const slugDebounce = useDebounce(slugLocal ?? '', 500)

  const checkSuggestion = useCallback(async () => {
    if (alreadyTyping) return
    // if (
    //   (slug === '' || slug === undefined) &&
    //   suggestionSlug !== '' &&
    //   suggestionSlug !== undefined
    // ) {
    const response = await companyAvailability(suggestionSlug)
    const isValid = response.status === 'success'

    setErrorMessage('')
    setIsAvailable(isValid)

    if (isValid) {
      setErrorMessage('')
      setData({
        slug: suggestionSlug,
        isValidSlug: isValid
      })
      setSlugLocal(suggestionSlug)
    } else {
      checkAvailabilityDomain(suggestionSlug + '-1')
      setErrorMessage(ERROR_UNAVAIABLE)
    }
    // }
    setIsLoading(false)
  }, [dataContentManager.name])

  useEffect(() => {
    checkSuggestion()
  }, [dataContentManager.name])

  async function checkAvailabilityDomain (slug: string) {
    try {
      setIsAvailable(null)

      if (slug === '' || slug === undefined) {
        setIsAvailable(null)
        return
      }

      const response = await companyAvailability(slug)
      const isValid = response.status === 'success'
      setData({
        slug,
        isValidSlug: isValid
      })
    } catch (e: any) {
      dispatch(addAlert({ title: e.message, type: 'error' }))
      setData({
        slug,
        isValidSlug: false
      })
    }
  }

  useEffect(() => {
    setIsAvailable(isValidSlug === true)
    setSlugLocal(slug || '')
  }, [isValidSlug, slug])

  useEffect(() => {
    checkAvailabilityDomain(slugDebounce)
  }, [slugDebounce])

  const setSlug = (slug: string) => {
    console.log(slug)
    console.log(cleanSubdomain(slug))
    setSlugLocal(cleanSubdomain(slug))
  }

  console.log({ slugLocal, slugDebounce, slug })

  return (
    <div>
      <label className="fw-bold">
        {intl.formatMessage({ id: 'Url do Workspace:' })}{' '}
        <span className="text-error">*</span>:
      </label>
      <InputWithPrefixSufix
        error={isAvailable === false}
        className="mt-2"
        label={intl.formatMessage({ id: 'URL' })}
        value={slugLocal}
        onChange={(event: any) => {
          setAlreadyTyping(true)
          setSlug(event?.target?.value.replaceAll(' ', ''))
        }}
        preffix={'https://'}
        suffix={'.' + localStorage.getItem('odash-app:domain-suffix')}
        isLoading={isLoading}
        data-cy="company-domain"
      />
      {errorMessage && isAvailable === false ? (
        <small className='text-error'>{errorMessage}</small>
      ) : (
        <small className="caption1">
          {intl.formatMessage({ id: 'Apenas letras minúsculas, números ou hifens' })}
        </small>
      )
        }
    </div>
  )
}

export default SlugWorkspaceField
