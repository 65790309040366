import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { loadCompanyAccounts } from 'Store/Actions/Company'

import LoadingDefault from 'Components/Loadings/default.component'

import { generateLinkTo } from 'Helpers/Route.helper'

import type { AccountInterface } from './components/companyAccountRow'
import CompanyAccountRow from './components/companyAccountRow'
import HeaderButton from './components/headerButton'

const CompanyAccounts = () => {
  const params = useParams<{ id: string }>()
  const location = useLocation()
  const [accounts, setAccounts] = useState<AccountInterface[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { id: company } = params

  const tableHeaders = (
    <tr>
      <th>ID Externo</th>
      <th>Nome da conta</th>
      <th>Plataforma</th>
      <th>Status do token</th>
      <th/>
    </tr>
  )

  const here = process.env.REACT_APP_FRONT_URL + location.pathname

  const loadAccounts = async () => {
    const accounts = await loadCompanyAccounts(company)
    setAccounts(accounts)
    setLoading(false)
  }

  useEffect(() => {
    loadAccounts()
    setLoading(true)
  }, [])

  if (loading) {
    return (
      <div className='page-accounts'>
        <LoadingDefault />
      </div>
    )
  }

  return (
    <div className='page-accounts'>
      <HeaderButton company={company} />
      <br/>
      <table className='table'>
        <thead>{tableHeaders}</thead>
        <tbody>

          {accounts.map((account, index) =>
            <CompanyAccountRow
              key={account.id}
              account={account}
              onUpdate={() => loadAccounts()}
              company={company}/>)}

        </tbody>
      </table>

      <Link to={generateLinkTo('jobs', { id: company })}>Todas as rotinas</Link>
      <br/>
      <Link to={generateLinkTo('logsByCompany', { id: company })}>Todos os logs</Link>

      <br/>
      <section className='text-right'>
        <h4>
          Vincular nova conta
        </h4>
        <div className='btn-group'>
          <a className='btn btn-success' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/adwords?company=${company}&next=${here}&activateJob=true`}>
            Adwords
          </a>
          <a className='btn btn-default' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/analytics?company=${company}&next=${here}&activateJob=true`}>
            Google Analytics
          </a>
          <a className='btn btn-warning' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/doubleclick?company=${company}&next=${here}&activateJob=true`}>
            Double Click
          </a>
          <a className='btn btn-primary' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/facebook?company=${company}&next=${here}&activateJob=true`}>
            Facebook
          </a>
          <a className='btn btn-info' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/twitter?company=${company}&next=${here}&activateJob=true`}>
            Twitter
          </a>
          <a className='btn btn-success' href={`${process.env.REACT_APP_TKM_URL}/account/vtex/?company=${company}&next=${here}&activateJob=true`}>
            VTEX
          </a>
          <a className='btn btn-default' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/linkedin?company=${company}&next=${here}&activateJob=true`}>
            Linkedin
          </a>
          <a className='btn btn-warning' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/microsoft?company=${company}&next=${here}&activateJob=true`}>
            Microsoft
          </a>
          <a className='btn btn-primary' href={`${process.env.REACT_APP_TKM_URL}/account/criteo/?company=${company}&next=${here}&activateJob=true`}>
            Criteo
          </a>
          {/* <a className='btn btn-info' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/youtube?company=${company}&next=${here}&activateJob=true`}>
            Youtube
          </a> */}
          <a className='btn btn-success' href={`${process.env.REACT_APP_TKM_URL}/account/linx/?company=${company}&next=${here}&activateJob=true`}>
            Linx
          </a>
          <a className='btn btn-default' href={`${process.env.REACT_APP_TKM_URL}/account/taboola/?company=${company}&next=${here}&activateJob=true`}>
            Taboola
          </a>
          <a className='btn btn-warning' href={`${process.env.REACT_APP_TKM_URL}/company/${company}/link/amazon?company=${company}&next=${here}&activateJob=true`}>
            Amazon
          </a>
        </div>
      </section>
    </div>
  )
}

export default CompanyAccounts
