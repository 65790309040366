import type { RefObject } from 'react'
import { useEffect } from 'react'

function useOutsideClick (ref: RefObject<HTMLElement> | Array<RefObject<HTMLElement>>, callback: () => void) {
  // @ts-ignore: ignore for a while
  const handleClick = (e) => {
    if (Array.isArray(ref)) {
      const value = ref.filter(r => r.current).reduce((resultFinal, cur) => {
        if (!resultFinal) {
          return resultFinal
        }
        if (!cur.current) {
          return false
        }

        if (cur.current && !cur.current.contains(e.target)) {
          return true
        }

        return false
      }, true)

      if (value) {
        callback()
      }
    } else if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export default useOutsideClick
