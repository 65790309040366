import styled, { css } from 'styled-components/macro'

import type { FontSize } from 'Helpers/Styled.helpers'
import StyledHelpers from 'Helpers/Styled.helpers'

export interface RootPropsI {
  isSerif: boolean,
  isBold: boolean,
  fontSize: FontSize,
  weight?: 'regular' | 'semibold' | 'bold' | 'extrabold'
}

export const RootS = styled.p<RootPropsI>`
  font-family: ${props => props.isSerif ? 'var(--font-base-family-serif)' : 'var(--font-base-family-normal)'};
  font-weight: normal;
  color: var(--font-gray);

  h1&,
  h2& {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${StyledHelpers.BaseFontSize}

  ${({ isBold }) => {
    if (isBold) {
      return css`
        font-weight: bold;
      `
    }
  }}
`
