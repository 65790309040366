import { useEffect, useRef } from 'react'

import styled from 'styled-components'

import { getInputStyled } from '.'

const DivWithInputStyle: any = styled.div`
  ${getInputStyled}

  cursor: text;
`
const ClearInput = styled.div`
  min-width: 15px;

  padding-left: 4px;
  overflow-x: hidden;

  border: 0;

  &:focus-visible {
    border: 0;
    box-shadow: none;
    outline: 0;
  }
`

function getCaret (el: any) {
  try {
    let caretAt = 0
    const sel = window.getSelection()

    if (sel == null) { return caretAt }

    if (sel.rangeCount === 0) { return caretAt }

    const range = sel.getRangeAt(0)
    const preRange = range.cloneRange()
    preRange.selectNodeContents(el)
    preRange.setEnd(range.endContainer, range.endOffset)
    caretAt = preRange.toString().length

    return caretAt
  } catch (error) {
    console.log(error)
    return 0
  }
}

function setCaret (el: any, offset: any) {
  try {
    const sel = window.getSelection()

    if (sel == null) { return }
    const range = document.createRange()

    range.setStart(el.childNodes[0], offset)
    range.collapse(true)
    sel.removeAllRanges()
    sel.addRange(range)
  } catch (error) {
    console.log(error)
  }
}

function InputWithPrefixSufix (props: any) {
  const { className, suffix, preffix, value, onChange = () => undefined, ...rest } = props

  const inputRef = useRef<any>(null)
  const caretPos = useRef(0)

  const onClickToFocus = (event: any) => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  useEffect(() => {
    if (inputRef.current && caretPos.current) {
      setCaret(inputRef.current, caretPos.current)
      inputRef.current.focus()
    }
  }, [value])

  return (
    <DivWithInputStyle
      className={`d-flex align-items-center ${className}`}
      onClick={onClickToFocus}
      {...rest}
    >
      <span className="text-gray fw-bold">{preffix}</span>
      <ClearInput
        ref={inputRef}
        className="fw-bold"
        contentEditable={true}
        suppressContentEditableWarning={true}
        onInput={e => {
          caretPos.current = getCaret(inputRef.current)
          onChange({ target: { value: e.currentTarget.textContent } })
        }}
      >{value}</ClearInput>
      <span className="text-gray fw-bold">{suffix}</span>
    </DivWithInputStyle>
  )
}

export default InputWithPrefixSufix
