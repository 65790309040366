import type { ReactNode } from 'react'
import React from 'react'

interface TitleInterface {
  children?: ReactNode,
  className?: string,
}

export default function Title (props: TitleInterface) {
  const className = ['title_component', props.className].join(' ')

  return (
    <div className={className}>
      {props.children}
    </div>
  )
}
