import styled from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'

import StyledHelpers from 'Helpers/Styled.helpers'

export const ContentS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${IconSVG} {
    margin-right: 15px;
  }
`

export const RootS = styled.div`
  width: fit-content;
  ${StyledHelpers.BaseComponentHeight}

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  padding: 0 30px;

  border: var(--button-base-border-outline);
  border-radius: 30px;
  border-color: ${props => props.theme.colors.primary};

  ${ContentS} + ${IconSVG} {
    margin-left: 10px;
  }
`
