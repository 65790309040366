import { Clock } from '@phosphor-icons/react'

import { Button } from 'DesignSystem'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { RootS } from './PendingPlanModal.styled'

const PendingPlanModal = (props: AuxStepContentManagerI<any>) => {
  const { close } = props
  const intl = useIntlShort()
  return (
    <RootS>
      <h3 className='fw-bold d-flex align-items-center'><Clock size={24} /> {intl('Assinatura em Processamento...')}</h3>
      <p className='mb-3 mt-3'>{intl('Recebemos sua solicitação de assinatura do NeoDash e ela está sendo processada. Dentro de instantes seu plano estará ativo e você poderá usufruir todos os recursos disponíveis nele.')}</p>
      <Button onClick={() => close()}>{intl('Continuar')}</Button>
    </RootS>
  )
}

PendingPlanModal.dialogProps = {
  style: {
    maxWidth: '508px'
  }
}

export default PendingPlanModal
