import useSWR from 'swr'

import { fetcher } from './fetcher'

export interface ConfigAdvancedVersionI {
  id: string,
  creation: string,
  config_advanced: any,
}

interface ReturnI {
  data: Array<ConfigAdvancedVersionI>,
  isLoading: boolean,
  isError: boolean
}

interface OptionsI {
  id: string
}

const useConfigAdvanceds = (options: OptionsI) : ReturnI => {
  const { data, error } = useSWR<Array<ConfigAdvancedVersionI>>(`console/company/${options.id}/config_advanceds`, fetcher('GET'))

  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useConfigAdvanceds
