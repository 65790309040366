import styled, { css } from 'styled-components/macro'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

interface RootI {
  size: SizeComponentI
  rotate: number,
}

export const RootS = styled.span<RootI>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          width: var(--base-icon-size-small);
          height: var(--base-icon-size-small);
        `
      case 'big':
        return css`
          width: var(--base-icon-size-big);
          height: var(--base-icon-size-big);
        `
      default:
        return css`
          width: var(--base-icon-size);
          height: var(--base-icon-size);
        `
    }
  }}

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    ${({ size }) => {
      switch (size) {
        case 'small':
          return css`
            width: var(--base-icon-size-small);
            height: var(--base-icon-size-small);
          `
        case 'big':
          return css`
            width: var(--base-icon-size-big);
            height: var(--base-icon-size-big);
          `
        default:
          return css`
            width: var(--base-icon-size);
            height: var(--base-icon-size);
          `
      }
    }}

    transform: rotate(${props => `${props.rotate}deg`});
  }

  fill: ${(props) => props.theme.colors.dark};

  ${({ onClick }) => {
    if (onClick) {
      return css`
        cursor: pointer;
      `
    }
  }}
`
