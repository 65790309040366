import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import 'aos/dist/aos.css'
import { BrowserRouter } from 'react-router-dom'

import Routes from 'Routes'
import { ThemeProvider } from 'styled-components'
import theme from 'Theme'

import Store from 'Store'

import Alerts from 'Components/Alerts'
import GlobalStyle from 'Components/GlobalStyle'
import Locale from 'Components/Locale'
import TooltipPermissionGlobal from 'Components/TooltipPermissionGlobal'

function App () {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet titleTemplate='%s - NeoDash' />
      <Provider store={Store}>
        <Locale>
          <TooltipPermissionGlobal />
          <BrowserRouter>
            <Alerts />
            <Routes />
          </BrowserRouter>
        </Locale>
      </Provider>
    </ThemeProvider>
  )
}

export default App
