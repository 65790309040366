import permissions from 'Constants/permissions.constants'

export interface PlatformI {
  platform: string;
  permission: string;
  name: string;
  link: (company: string, here: string) => string
  tags: {
    value: string[];
  };
}

const platforms: PlatformI[] = [{
  platform: 'adwords',
  permission: permissions.ADD_TOKEN_ADWORDS,
  name: 'Google Ads',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/adwords?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['more_used'] }
}, {
  platform: 'analytics',
  permission: permissions.ADD_TOKEN_ANALYTICS,
  name: 'Google Analytics',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/analytics?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['more_used'] }
}, {
  platform: 'displayVideo360',
  permission: permissions.ADD_TOKEN_DOUBLE_CLICK,
  name: 'Display & Video 360',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/displayVideo360?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['display_remarketing'] }
}, {
  platform: 'campaignmanager',
  permission: permissions.ADD_TOKEN_CAMPAIGN_MANAGER,
  name: 'Campaign Manager 360',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/campaignmanager?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['display_remarketing'] }
}, {
  platform: 'facebook',
  permission: permissions.ADD_TOKEN_FACEBOOK,
  name: 'Facebook',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/facebook?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['more_used'] }
}, {
  platform: 'twitter',
  permission: permissions.ADD_TOKEN_TWITTER,
  name: 'Twitter',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/twitter?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&activateJob=true&next=${encodeURIComponent(here)}`)}`,
  tags: { value: ['social'] }
}, {
  platform: 'tiktok',
  permission: 'AddTokenTiktok',
  name: 'TikTok',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/tiktok?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['social'] }
// }, {
//   platform: 'vtex',
//   permission: 'AddTokenVtex',
//   name: 'VTEX',
//   link: (company: string, here: string) => `${process.env.REACT_APP_TKM_URL}/account/vtex/?company=${company}&next=${here}&activateJob=true`
}, {
  platform: 'tiktok-social',
  permission: 'AddTokenTiktok',
  name: 'TikTok Social',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/tiktok-social?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['social'] }
}, {
  platform: 'linkedin',
  permission: permissions.ADD_TOKEN_LINKEDIN,
  name: 'LinkedIn',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/linkedin?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['social'] }
}, {
  platform: 'microsoft',
  permission: permissions.ADD_TOKEN_MICROSOFT,
  name: 'Microsoft',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/microsoft?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&activateJob=true`)}&next=${encodeURIComponent(here)}`,
  tags: { value: ['search'] }
}, {
  platform: 'criteo',
  permission: permissions.ADD_TOKEN_CRITEO,
  name: 'Criteo',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/account/criteo/?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['display_remarketing'] }
}, {
  platform: 'linx',
  permission: permissions.ADD_TOKEN_LINX,
  name: 'Linx',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/account/linx/?company=${company}&next=${here}&activateJob=true`)}`,
  tags: { value: ['ecommerce'] }
}, {
  platform: 'taboola',
  permission: permissions.ADD_TOKEN_TABOOLA,
  name: 'Taboola',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/account/taboola/?token=${localStorage.getItem('odash-app:user') ?? ''}&company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['display_remarketing'] }
}, {
  platform: 'amazon',
  permission: permissions.ADD_TOKEN_AMAZON_ADS,
  name: 'Amazon',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/amazon?company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['more_used'] }
}, {
  platform: 'youtube',
  permission: permissions.ADD_TOKEN_YOUTUBE,
  name: 'YouTube',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/youtube?company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['social'] }
}, {
  platform: 'salesforce',
  permission: permissions.ADD_TOKEN_SALESFORCE,
  name: 'Salesforce',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/salesforce?company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['crm'] }
}, {
  platform: 'rdstation',
  permission: permissions.ADD_TOKEN_RD_STATION,
  name: 'RD Station',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/rdstation?company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['crm'] }
}, {
  platform: 'pipedrive',
  permission: permissions.ADD_TOKEN_PIPEDRIVE,
  name: 'Pipedrive',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/account/pipedrive/?company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['crm'] }
}, {
  platform: 'hubspot',
  permission: permissions.ADD_TOKEN_HUBSPOT,
  name: 'HubSpot',
  link: (company: string, here: string) => `${process.env.REACT_APP_API_URL}token-manager?token=${localStorage.getItem('odash-app:user')}&url=${encodeURIComponent(`${process.env.REACT_APP_TKM_URL}/company/${company}/link/hubspot?company=${company}&next=${encodeURIComponent(here)}&activateJob=true`)}`,
  tags: { value: ['crm'] }
}]

export default platforms
