import React from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

import permissions from 'Constants/permissions.constants'
import { Col, Row } from 'DesignSystem'
import useSWR from 'swr'

import Breadcrumbs from 'Components/Breadcrumbs'
import LoadingDefault from 'Components/Loadings/default.component'
import { MenuVertical } from 'Components/MenuVertical'
import TemplateDefault from 'Components/TemplateDefault'

import { fetcher } from 'Hooks/API/fetcher'
import useCheckPermission from 'Hooks/API/useCheckPermission.hook'
import useCompany from 'Hooks/API/useCompany.hook'
import useUser from 'Hooks/API/useUser.hook'

import { generateLinkToInstance } from 'Helpers/Company.helper'
import { generateLinkTo } from 'Helpers/Route.helper'

import Connections from '../Connections'
import ConnectionsShow from '../ConnectionsShow'
import Routines from '../Routines'
import ConfigAdvanced from './Components/ConfigAdvanced.component'
import EditWorkspaceForm from './Components/EditWorkspaceForm'

type StateStepI = 'advanced' | 'campaign' | 'connections' | 'routines' | 'users'

const CompanyEdit = () => {
  const intl = useIntl()
  const { id, step, account_id } = useParams<{id: string, step?: StateStepI, account_id: string}>()
  const { data, isLoading } = useCompany({ id })
  const { data: dataToken } = useSWR<{token: string}>(data ? `odash/${data.id}/token` : undefined, fetcher('GET'))

  const { data: dataUser } = useUser()
  const { data: checkPermissionToEditUser } = useCheckPermission([permissions.EDIT_USERS], id)
  const { data: checkPermissionToEditCampaign } = useCheckPermission([permissions.EDIT_CAMPAIGN], id)

  const generateLinkOdash = (path?: string) => {
    if (!data || !dataToken) return '#'

    const linkWithToken = (() => {
      if (path) {
        return `${generateLinkToInstance({ slug: data.slug })}/admin/index.php/site/login?api_token=${dataToken.token}&url_redirect=${generateLinkToInstance({ slug: data.slug })}/admin/index.php/${path}`
      }

      return `${generateLinkToInstance({ slug: data.slug })}/login?admin_token=${dataToken.token}`
    })()

    return linkWithToken
  }

  const contentsSteps = {
    connectionShow: <ConnectionsShow />,
    advanced: <ConfigAdvanced />,
    basic: <EditWorkspaceForm />,
    campaign: <LoadingDefault />,
    connections: <Connections isPocket isFullContainer idCompany={id} />,
    routines: <Routines isPocket isFullContainer idCompany={id} />,
    users: <></>
  }

  const crumbs: any = [
    {
      name: intl.formatMessage({ id: 'Workspaces' }),
      to: generateLinkTo('companies')
    },
    {
      name: `${intl.formatMessage({ id: 'Editar' })} ${data?.name}`,
      to: generateLinkTo('companyEdit', { id })
    }
  ]

  const stepsCrumbs = {
    advanced: {
      name: intl.formatMessage({ id: 'Avançado' }),
      to: generateLinkTo('companyEditStep', { id, step: 'advanced' })
    },
    campaign: {
      name: intl.formatMessage({ id: 'Campanha' }),
      to: generateLinkTo('companyEditStep', { id, step: 'campaign' })
    },
    connections: {
      name: intl.formatMessage({ id: 'Conexões' }),
      to: generateLinkTo('companyEditStep', { id, step: 'connections' })
    },
    routines: {
      name: intl.formatMessage({ id: 'Rotinas' }),
      to: generateLinkTo('companyEditStep', { id, step: 'routines' })
    },
    users: {
      name: intl.formatMessage({ id: 'Users' }),
      to: generateLinkTo('companyEditStep', { id, step: 'users' })
    }
  }

  const tabs = [
    {
      title: intl.formatMessage({ id: 'Visualizar empresa' }),
      to: generateLinkTo('companyEdit', { id })
    },
    {
      title: intl.formatMessage({ id: 'Minhas conexões' }),
      to: generateLinkTo('companyEditStep', { id, step: 'connections' }),
      routeMatch: [
        generateLinkTo('companyEditStep', { id, step: 'connections' }),
        generateLinkTo('companyConnectionEdit')
      ]
    },
    {
      title: intl.formatMessage({ id: 'Rotinas' }),
      to: generateLinkTo('companyEditStep', { id, step: 'routines' })
    },
    {
      title: intl.formatMessage({ id: 'oDash' }),
      href: generateLinkOdash()
    }
  ]

  if (checkPermissionToEditUser.every(p => p.canUse)) {
    tabs.push({
      title: intl.formatMessage({ id: 'Usuários' }),
      href: generateLinkOdash('usuario/index')
    })
  }

  if (checkPermissionToEditCampaign.every(p => p.canUse)) {
    tabs.push({
      title: intl.formatMessage({ id: 'Grupo de Dados' }),
      href: generateLinkOdash('campanha/index')
    })
  }

  if (dataUser?.is_admin) {
    tabs.push({
      title: intl.formatMessage({ id: 'Configurações Avançadas' }),
      to: generateLinkTo('companyEditStep', { id, step: 'advanced' })
    })
  }

  if (step) {
    crumbs.push(stepsCrumbs[step])
  }

  if (account_id) {
    crumbs.push(stepsCrumbs.connections)
    crumbs.push({
      name: intl.formatMessage({ id: 'Editar conexão' }),
      to: generateLinkTo('companyConnectionEdit', { id, account_id })
    })
  }

  return (
    <TemplateDefault
    leftHeader={ <Breadcrumbs crumbs={crumbs} />}
    >
      <Row>
        <Col className='ps-0' xs={12} md={3} xl={2}>
          <MenuVertical items={tabs} />
        </Col>
        {account_id ? (
          <Col className='px-0' xs={12} md={9} xl={10}>
            <ConnectionsShow isPocket={true}/>
          </Col>
        ) : (
          <Col className='px-0'>
            {isLoading ? <LoadingDefault /> : contentsSteps[step ?? 'basic']}
          </Col>
        )}
      </Row>
    </TemplateDefault>
  )
}

export default CompanyEdit
