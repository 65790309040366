import styled, { css } from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'

import StyledHelpers from 'Helpers/Styled.helpers'

interface ListI {
  isOpen: boolean
}

export const ListStyled = styled.ul<ListI>`
  display: none;
  z-index: 100;
  overflow: hidden;

  position: absolute;
  left: -24px;
  margin: 0;
  padding: ${(props) => props.theme.metrics.space2} 0;

  list-style: none;

  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: ${(props) => props.theme.metrics.radius};

  white-space: nowrap;
  text-align: start;

  label {
    padding-right: ${(props) => props.theme.metrics.space5};
  }
`

export const RootStyled = styled.div`
  position: relative;

  ${StyledHelpers.BaseComponentHeight}

  label {
    color: #000;

    ${(props) => css(props.theme.fonts.types.subtitle2)}
  }

  ${IconSVG} {
    margin-right: 10px;
  }

  :hover {
    ${ListStyled} {
      display: block;
      ${({ size }) => {
        switch (size) {
          case 'small':
            return css`
              top: var(--base-height-component-small);
            `
          case 'big':
            return css`
              top: var(--base-height-component-big);
            `
          default:
            return css`
              top: var(--base-height-component);
            `
        }
      }}
    }
  }
`

export const InnerButtonStyled = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;

    color: ${(props) => props.theme.colors.dark};
  }
`

interface ItemListStyledPropsInterface {
  isActive?: boolean
}

export const ItemListStyled = styled.li<ItemListStyledPropsInterface>`
  padding: 4px 20px;
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.theme.colors.hover};
  }

  ${props => {
      if (props.isActive) {
        return css`background-color: ${(props) => props.theme.colors.hover};`
      }
    }}

  label {
    cursor: pointer;
  }

  & + li {
    margin-top: 10px;
  }
`
