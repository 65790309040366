import type { ReactNode } from 'react'
import React from 'react'

import type { CSSProperties } from 'styled-components'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

import { RootS } from './Card.styled'

interface CardPropsI {
  id?: string,
  style?: CSSProperties
  className?: string,
  children?: ReactNode,
  onClick?: () => void,
}

const Card = React.forwardRef<null, CardPropsI>((props, ref) => {
  const { children, className, onClick, id, style } = props
  return (
    <RootS ref={ref} id={id} style={style} className={className} onClick={onClick} {...passAlongMicroData(props)}>
      {children}
    </RootS>
  )
})

export default Card
