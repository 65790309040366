import { useIntl } from 'react-intl'

import { Row, Col } from 'DesignSystem'
import type DefaultProps from 'DesignSystem/Types'
import styled from 'styled-components'

import HelpLinks from './Components/HelpLinks'

const MainContainer = styled.div`
  height: 100vh;

  margin: 0 auto;

  background-color: ${(props) => props.theme.colors.gray40};

  max-width: 1920px;
`

const SplitedImage = styled.div`
  min-height: 100vh;

  background-image: url("/images/auth-bg.webp");
  background-size: cover;
  box-shadow: inset 100vh 100vh 100vh rgba(0, 0, 0, 60%);
`

const H1 = styled.h1`
  font-size: 3.5rem;
  line-height: 4.5rem;
`

function AuthLayout ({ children }: DefaultProps) {
  const intl = useIntl()
  return (
    <MainContainer>
      <Row className='align-items-center'>
        <Col className="d-none d-md-block p-0">
          <SplitedImage className="px-5 d-flex justify-content-center align-items-center">
            <H1 className="m-0 fw-bold text-white">
              {intl.formatMessage({ id: 'Tenha controle total sob suas campanhas.' })}
            </H1>
          </SplitedImage>
        </Col>
        <Col style={{ overflow: 'auto', maxHeight: '100vh' }}>
          {children}
          <HelpLinks />
        </Col>
      </Row>
    </MainContainer>
  )
}

export default AuthLayout
