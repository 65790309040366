import type { ItemAlertInterface } from 'Interfaces/alerts.interface'

export const addAlert = (message:ItemAlertInterface) => {
  return {
    type: 'SET_ITEM_ALERT',
    payload: message
  }
}

export const remodeAlert = (index:number) => {
  return {
    type: 'REMOVE_ITEM_ALERT',
    payload: index
  }
}
