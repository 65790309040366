import React from 'react'

import { Modal } from 'DesignSystem'

import useContentManager from 'Hooks/useContentManager.hook'

import StepsVisual from '../StepsVisual'
import Finish from './Steps/Finish'
import InformDataPlatform from './Steps/InformDataConnection'
import WaitingToSave from './Steps/WaitingToSave'

export interface DataCompanyCreateContentManagerI {
  id?: string,
  isANewConnection?: boolean,
  companies?: string[]
  platform?: string
  setEnableNext?: () => null
}

interface ParamsI {
  idCompany?: string,
  onClose?: () => void,
  isFirstTime?: boolean
}

const useEditConnectionFlowManager = (params: ParamsI = {}) => {
  const { onClose } = params

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const steps = [
    InformDataPlatform,
    WaitingToSave,
    Finish
  ]

  const { start, content } = useContentManager<DataCompanyCreateContentManagerI>(steps, {
    footer: (currentDialog) => <StepsVisual steps={steps.length} current={currentDialog} />,
    dialog: Modal,
    isFit: true,
    onClose: handleOnClose
  })

  return {
    start,
    content
  }
}

export default useEditConnectionFlowManager
