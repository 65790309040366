import React, { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import AOS from 'aos'
import Login from 'Pages/Publics/Login'
import LoginOdash from 'Pages/Publics/LoginOdash'
import ForgotPassword from 'Pages/Publics/Passwords/Forgot.page'
import ResetPassword from 'Pages/Publics/Passwords/reset.page'
import Signup from 'Pages/Publics/Signup/Signup.page'
import Token from 'Pages/Publics/Token'

import { getPath } from 'Helpers/Route.helper'

const RoutePrivateRedirect = () => {
  const history = useHistory()

  useEffect(() => {
    if (window.location.pathname) {
      history.replace(`/login?next=${window.location.pathname}`)
    } else {
      history.replace('/login')
    }
  })

  return null
}

function PublicRoutes () {
  useEffect(() => {
    AOS.init({
      duration: 800
    })
  }, [])

  return (
      <Switch>
        <Route path={getPath('signupWithInvite')}>
          <Signup />
        </Route>

        <Route path={getPath('signupSimple')} exact={true} >
          <Signup />
        </Route>

        <Route path={getPath('welcome')}>
          <Route path={getPath('signup')} exact={true} >
            <Signup />
          </Route>
        </Route>

        <Route path={getPath('waitingConfirmation')}>
          <Signup isWaitingConfirmation />
        </Route>

        <Route path={getPath('activateCode')}>
          <Login isActiveCode />
        </Route>

        <Route path={getPath('login')}>
          <Login />
        </Route>

        <Route path={getPath('forgotPassword')}>
          <ForgotPassword />
        </Route>

        <Route path={getPath('resetPassword')}>
          <ResetPassword />
        </Route>

        <Route path={getPath('token')}>
          <Token />
        </Route>

        <Route path={getPath('odashLogin')}>
          <LoginOdash />
        </Route>

        <Route path={getPath('odashForgotPassword')}>
          <LoginOdash />
        </Route>

        <Route path={getPath('odashInvite')}>
          <LoginOdash />
        </Route>

        <Route path="*">
          <RoutePrivateRedirect />
        </Route>
      </Switch>
  )
}

export default PublicRoutes
