import breakpoint from 'styled-components-breakpoint'
import styled from 'styled-components/macro'

import Button from 'Components/Button'
import Card from 'Components/Card'
import IconPlatform from 'Components/IconPlatform'
import SearchFilter from 'Components/SearchFilter'
import Tag from 'Components/Tag'

export const WrapperActionsS = styled.div`
  width: 100%;
  height: 100%;

  ${breakpoint('desktop')`
    width: 900px;
  `}

  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SearchFilterS = styled(SearchFilter)`
  width: 100%;

  margin-right: 20px;
`

export const ButtonS = styled(Button)`
  label {
    display: none;
  }

  ${breakpoint('desktop')`
    label {
      display: block;
    }
  `}
`

export const CardSingleS = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LinkS = styled.a`
  cursor: pointer;

  text-decoration: underline;
`

export const ListConnectionsS = styled.div`
  width: 100%;

  ${breakpoint('desktop')`
    width: 50%;

    & + & {
      margin-left: 40px;
    }
  `}
`

export const HeaderPlatformS = styled.div`
  width: 100%;

  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
`

export const IconHeaderPlatformS = styled(IconPlatform)`
  margin-right: 20px;
`

export const TagsS = styled.div`
  display: flex;
  flex-flow: row wrap;

  margin-bottom: 15px;

  ${Tag} {
    margin-right: 5px;
  }
`

export const FilterS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
