import type { ReactNode } from 'react'
import React from 'react'

import styled from 'styled-components'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

import { RootS } from './Text.styled'
import type { RootPropsI } from './Text.styled'

export interface TextPropsI {
  id?: string;
  as?: 'p' | 'label' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  fontSize?: RootPropsI['fontSize'];
  isSerif?: boolean;
  children?: ReactNode;
  className?: string;
  isBold?: boolean;
  weight?: 'regular' | 'semibold' | 'bold' | 'extrabold'
  onClick?: () => void;
}

const Text = React.forwardRef<HTMLElement>((props: TextPropsI, ref) => {
  const {
    as = 'label',
    children,
    className,
    isBold = false,
    fontSize = 'normal',
    isSerif = false,
    weight = 'regular',
    id,
    onClick
  } = props

  return (
    <RootS
      id={id}
      as={as}
      fontSize={fontSize}
      className={className}
      isBold={isBold}
      isSerif={isSerif}
      weight={weight}
      onClick={onClick}
      ref={ref}
      {...passAlongMicroData(props)}
    >
      {children}
    </RootS>
  )
})

export default styled(Text)<TextPropsI>``
