import styled, { css } from 'styled-components/macro'

import Card from 'Components/Card'
import Text from 'Components/Text'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

export const WrapperS = styled.div`
  position: relative;
`

export const CardCalendarS = styled(Card)`
  width: fit-content;

  display: flex;
  flex-direction: row;
  z-index: 10;

  position: absolute;

  background-color: white;
`

export interface RootPropsI {
  size?: SizeComponentI,
}

export const RootS = styled.div<RootPropsI>`
  width: 100%;

  cursor: pointer;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: var(--input-base-small-height-inner);
        `
      case 'big':
        return css`
          height: var(--input-base-big-height-inner);
        `
      default:
        return css`
          height: var(--input-base-height-inner);
        `
    }
  }}

  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;

  padding: 0 30px;

  background-color: white;
  border-radius: 30px;
  box-shadow: inset 0 2px 3px #00000020;

  ${Text} {
    margin: 0 0 0 15px;

    ${({ size }) => {
      switch (size) {
        case 'small':
          return css`
            font-size: var(--input-base-label-small-size);
          `
        case 'big':
          return css`
            font-size: var(--input-base-label-big-size);
          `
        default:
          return css`
            font-size: var(--input-base-label-size);
          `
      }
    }}
  }

  ${CardCalendarS} {
    ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          top: var(--input-base-small-height-inner);
        `
      case 'big':
        return css`
          top: var(--input-base-big-height-inner);
        `
      default:
        return css`
          top: var(--input-base-height-inner);
        `
    }
  }}
  }
`

export const WrapperActionsS = styled.div`
  display: flex;
  flex-direction: column;
`
