import { GearSix } from '@phosphor-icons/react'
import React from 'react'
// import { useHistory } from 'react-router-dom'

import type { IconSVGPropsI } from 'Components/IconSVG'
import IconSVG from 'Components/IconSVG'

// import { generateLinkTo } from 'Helpers/Route.helper'

import useIntlShort from 'Hooks/useIntlShort.hook'

import { RootS } from './ConnectionCard.styled'

interface ConnectionCardPropsI {
  connectionName: string
  connectionID: string
  companyID?: string
  platform?: string
  logoSrc: string
  onDetail: () => void
}

const ConnectionCard = (props: ConnectionCardPropsI) => {
  const intl = useIntlShort()
  const { platform } = props

  return (
    <RootS>
      <div className='d-flex justify-content-between'>
        <div>
          <IconSVG icon={props.logoSrc as IconSVGPropsI['icon']} />
        </div>
        <div className='text-end'>
          <button onClick={props.onDetail}>
            <GearSix size={24} color='#C6C6C5' />
          </button>
        </div>
      </div>
      <div className='mt-2'>
        <h4 className='fw-bold mb-0'>{platform && intl(platform)}</h4>
        <p className='body2 text-wrap'>{props.connectionName === 'Conexão sem nome' ? intl(props.connectionName) : props.connectionName}</p>
      </div>
    </RootS>
  )
}

export default ConnectionCard
