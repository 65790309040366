import breakpoint from 'styled-components-breakpoint'
import styled from 'styled-components/macro'

import BackButton from 'Components/BackButton'
import IconSVG from 'Components/IconSVG'

export const BackdropS = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 100;

  position: absolute;
  top: 0;
  left: 0;

  background-color: rgba(18, 31, 50, 95%);
`

interface RootI {
  isFit: boolean
}

export const RootS = styled.div<RootI>`
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  overflow: hidden;

  position: relative;
  padding: var(--space-content-box);
  padding-top: calc(var(--space-3) * 3);

  background-color: var(--color-background-main-content);

  ${props => {
    if (props.isFit) {
      return breakpoint('tablet')`
        width: 800px;
        height: fit-content;

        border-radius: 20px;
      `
    }

    return breakpoint('tablet')`
      width: 800px;
      height: 90vh;

      border-radius: 20px;
    `
  }}

  ${breakpoint('desktop')`
    padding-top: calc(var(--space-3) * 2);
  `}
`

export const IconCloseS = styled(IconSVG)`
  position: absolute;
  top: var(--space-3);
  right: var(--space-3);
  margin: 0;

  stroke: #000;
  stroke-width: 4;
`

export const BackButtonS = styled(BackButton)`
  height: auto;

  position: absolute;
  top: var(--space-3);
  left: var(--space-3);
  margin: 0;
`
