import { getLocale } from 'Helpers/Locale.helper'
import * as localStorage from 'Helpers/Localstorage.helper'

const storageUser = (() => {
  try {
    const value = localStorage.getItem('odash-app:user_info')
    return value ? JSON.parse(value) : undefined
  } catch (error) {
    return undefined
  }
})()

const INITIAL_STATE = {
  data: storageUser ?? '',
  lang: getLocale()
}

interface reducerInterface {
  type: 'USER_DATA' | 'USER_LANG' | 'USER_CLEAR',
  payload: any
}

function usersReducer (state = INITIAL_STATE, action:reducerInterface) {
  switch (action.type) {
    case 'USER_DATA':
      return {
        ...state,
        data: action.payload
      }
    case 'USER_LANG':
      return {
        ...state,
        lang: action.payload
      }
    case 'USER_CLEAR':
      return {
        data: '',
        lang: getLocale()
      }
    default:
      return state
  }
}

export default usersReducer
