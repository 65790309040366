import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { setCreateCompany } from 'Store/Actions/Company'

import InputColor from 'Components/Inputs/inputColor.component'

export default function FormColor () {
  // @ts-ignore: Unreachable code error
  const company = useSelector((state) => state.company.createCompany)
  const { color } = company
  const intl = useIntl()
  const dispatch = useDispatch()

  function setColor (color: string) {
    dispatch(setCreateCompany({
      ...company,
      color: color
    }))
  }

  return (
    <form noValidate autoComplete="off" className='shortform'>
      <InputColor
        label={intl.formatMessage({ id: 'Cor da sua empresa' })}
        placeholder={intl.formatMessage({ id: 'Escolha a cor da sua empresa' })}
        color={color}
        onChange={color => setColor(color)} />
    </form>
  )
}
