import styled from 'styled-components/macro'

import Dialog from 'Components/Dialog'

export const DialogS = styled(Dialog)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`
