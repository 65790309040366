import React from 'react'

import styled from 'styled-components'

import { getInputStyled } from '.'

const SelectStyled = styled.select`
  ${getInputStyled}

  cursor: pointer;

  position: relative;

  appearance: none;
`

function Select ({ children, ...rest }: any) {
  return (<SelectStyled {...rest}>{children}</SelectStyled>)
}

export default Select
