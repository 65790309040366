import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import cronstrue from 'cronstrue'
import moment from 'moment'

import { loadCompanyAccountJobs } from 'Store/Actions/Company'

import LoadingDefault from 'Components/Loadings/default.component'

import useQuery from 'Hooks/useQuery.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

import HeaderButton from './components/headerButton'

const CompanyAccountJobs = () => {
  const params = useParams<{ id: string, typeJobs?: string }>()
  const query = useQuery()
  const [jobs, setJobs] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { id: company, typeJobs } = params

  const loadJobs = async () => {
    const logs = await loadCompanyAccountJobs(company, typeJobs)
    setJobs(logs)
    setLoading(false)
  }

  useEffect(() => {
    loadJobs()
    setLoading(true)
  }, [])

  const getStatus = (job: any) => {
    switch (true) {
      case job.failed:
        return 'Falhado'
      case job.completed:
        return 'Completado com sucesso'
      case job.running:
        return 'Executando nesse momento'
    }
  }

  if (loading) {
    return (
      <div className='page-accounts'>
        <LoadingDefault />
      </div>
    )
  }

  return (
    <div className='page-accounts'>
      <HeaderButton tokenManager account={query.get('account')} company={company} />
      <br/>
      <table className='table'>
        <thead>
          <tr>
            <th/>
            <th>Rotina</th>
            <th>Status</th>
            <th>Categoria</th>
            <th>Regra de execução</th>
            <th>Última vez que executou</th>
            <th>Próxima execução</th>
            <th>Plataforma</th>
            <th>Agendado</th>
            <th>Na fila</th>

          </tr>
        </thead>
        <tbody>

            {jobs.map((job, index) =>
            <tr key={index} className={job.failed ? 'table-danger' : ''}>
              <td className='text-right'>
                <Link to={`${generateLinkTo('logsByJobs', { id: company, typeJobs: job.job.data.job.name })}${query.get('account') ? `?account=${query.get('account')}` : ''}`}>Logs</Link>
              </td>
              <td>{job.job.data.job.name}</td>
              <td>
                {getStatus(job)}
              </td>
              <td>{job.job.data.job.category}</td>
              <td>{(() => {
                try {
                  return cronstrue.toString(job.job.data.job.execTime || '* * * * *')
                } catch (e) {
                  return job.job.data.job.execTime
                }
              })()}</td>
              <td>{moment(job.job.lastRunAt).format('DD/MM/YY hh:mm:ss')}</td>
              <td>{moment(job.job.nextRunAt).format('DD/MM/YY hh:mm:ss')}</td>
              <td>{job.job.data.config.platform}</td>
              <td>{job.scheduled ? 'Sim' : 'Não'}</td>
              <td>{job.queued ? 'Sim' : 'Não'}</td>

            </tr>)}

        </tbody>
      </table>
    </div>
  )
}

export default CompanyAccountJobs
