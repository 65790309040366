import styled from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'

import StyledHelpers from 'Helpers/Styled.helpers'

export const RootS = styled.button`
  ${StyledHelpers.BaseButtonHeight}

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  background: transparent;
  border: none;

  label {
    cursor: pointer;

    color: var(--font-yellow);

    ${StyledHelpers.BaseButtonLabel}
  }

  ${IconSVG} {
    margin-right: 10px;
  }
`
