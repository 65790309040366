import useSWR from 'swr'

import { fetcher } from './fetcher'

export interface UserI {
  id: string,
  name: string,
  locale: string,
  is_admin: boolean,
  email: string,
  isOnTrialPeriod: boolean,
  isNewerUserRaw: boolean,
  isTrialPeriodExpired: boolean,
  havePricePreChosen: boolean,
  pricePreChoosen: string | null,
  planPreChosen?: {
    id: string,
    name: string
  },
  intervalPreChosen: string | null,
  amountPreChosen: number | null,
  currencyPreChosen: string | null,
  daysToExpired: number,
  isTrialPeriodNextToEnd: boolean,
  subscription: {
    status: string
  } | null,
  ignore_payments: boolean
}

interface ReturnI {
  data?: UserI | null,
  isLoading: boolean,
  isError: boolean
}

const useUser = () : ReturnI => {
  const { data, error } = useSWR<UserI>('/', fetcher('GET'), {
    refreshInterval: 0
  })

  return {
    data: data ?? null,
    isLoading: !error && !data,
    isError: error
  }
}

export default useUser
