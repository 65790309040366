import type { ReactNode } from 'react'
import React from 'react'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

interface CardInterface {
    children?: ReactNode,
    isCentered?: boolean,
    className?: string,
    radius?: 'all' | 'left' | 'right',
}

export default function Card (props: CardInterface) {
  const { children, isCentered = false, className: classNameExtern = '', radius = 'all' } = props

  const className = ['card_component']

  if (isCentered) className.push('card--is-centered')

  if (radius !== 'all') className.push(`card--radius-only-${radius}`)

  className.push(classNameExtern)

  return (
    <div className={className.join(' ')} {...passAlongMicroData(props)}>
        {children}
    </div>
  )
}
