import type { DefaultBreakpointName } from 'styled-components-breakpoint'
import breakpoint from 'styled-components-breakpoint'
import type { FlattenInterpolation, ThemeProps } from 'styled-components/macro'
import { createGlobalStyle, css } from 'styled-components/macro'
import { generateCssWithSizes, generateFontColorsCSSClasses, generateFontTypesCSSClasses } from 'utils'

const DEBUG = false

const generateMicroDataBreakpoint = (
  microDataName: string,
  value: string,
  targetComponent: string,
  breakpoint1: DefaultBreakpointName,
  breakpoint2: DefaultBreakpointName | undefined,
  attrs: { [key: string]: string }
) => {
  return css`
    &[${microDataName}="${value}"] + [data-component-type*="${targetComponent}"] {
      ${breakpoint(breakpoint1, breakpoint2)`
        ${Object.entries(attrs).map(([key]) => `${key}: 0;`)}
      `}
    }

    & + [data-component-type*="${targetComponent}"][${microDataName}="${value}"] {
      ${breakpoint(breakpoint1, breakpoint2)`
        ${Object.entries(attrs).map(([key]) => `${key}: 0;`)}
      `}
    }
  `
}

const generateMicroDataSpace = (
  components: string[],
  attrs: { [key: string]: string },
  options: { debug: string; reversed?: boolean } = {
    debug: 'red',
    reversed: false
  }
): FlattenInterpolation<ThemeProps<any>> => {
  const [mainComponent, targetComponent] = components
  return css`
    [data-component-type*="${mainComponent}"] {
      & + [data-component-type*="${targetComponent}"] {
        ${() => {
          if (DEBUG) {
            return css`
              border: 1px solid ${options.debug};
            `
          }
        }}

        ${Object.entries(attrs).map(([key, value]) => `${key}: ${value};`)}
      }

      ${generateMicroDataBreakpoint(
        'data-component-apply-only',
        'mobile',
        targetComponent,
        'tablet',
        undefined,
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-component-apply-only',
        'tablet',
        targetComponent,
        'tablet',
        'desktop',
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-component-apply-only',
        'desktop',
        targetComponent,
        'mobile',
        'desktop',
        attrs
      )}

      ${generateMicroDataBreakpoint(
        'data-component-apply-only',
        `${mainComponent}-mobile`,
        targetComponent,
        'tablet',
        undefined,
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-component-apply-only',
        `${mainComponent}-tablet`,
        targetComponent,
        'tablet',
        'desktop',
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-component-apply-only',
        `${mainComponent}-desktop`,
        targetComponent,
        'mobile',
        'desktop',
        attrs
      )}

      ${generateMicroDataBreakpoint(
        'data-component-apply-up',
        'tablet',
        targetComponent,
        'mobile',
        'tablet',
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-component-apply-up',
        'desktop',
        targetComponent,
        'mobile',
        'desktop',
        attrs
      )}

      ${generateMicroDataBreakpoint(
        'data-component-apply-up',
        `${mainComponent}-tablet`,
        targetComponent,
        'mobile',
        'tablet',
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-component-apply-up',
        `${mainComponent}-desktop`,
        targetComponent,
        'mobile',
        'desktop',
        attrs
      )}

      ${generateMicroDataBreakpoint(
        'data-component-apply-down',
        'tablet',
        targetComponent,
        'tablet',
        undefined,
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-component-apply-down',
        'desktop',
        targetComponent,
        'desktop',
        undefined,
        attrs
      )}

      ${generateMicroDataBreakpoint(
        'data-component-apply-down',
        `${mainComponent}-tablet`,
        targetComponent,
        'tablet',
        undefined,
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-component-apply-down',
        `${mainComponent}-desktop`,
        targetComponent,
        'desktop',
        undefined,
        attrs
      )}

      ${generateMicroDataBreakpoint(
        'data-show-only',
        'mobile',
        targetComponent,
        'tablet',
        undefined,
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-show-only',
        'tablet',
        targetComponent,
        'tablet',
        'desktop',
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-show-only',
        'desktop',
        targetComponent,
        'mobile',
        'desktop',
        attrs
      )}

      ${generateMicroDataBreakpoint(
        'data-show-up',
        'tablet',
        targetComponent,
        'mobile',
        'tablet',
        attrs
      )}
      ${generateMicroDataBreakpoint(
        'data-show-up',
        'desktop',
        targetComponent,
        'mobile',
        'desktop',
        attrs
      )}
    }

    ${() => {
      if (options.reversed) {
        return generateMicroDataSpace([targetComponent, mainComponent], attrs)
      }
    }}
  `
}

const GlobalStyle = createGlobalStyle`
  :root {
    // General Config
    --base-content-padding: 53px 0;
    --base-content-width: 95%;
    --base-header-height: 73px;
    --base-menu-width: 80px;
    --base-app-bar-height: 80px;

    // I don't what name puts here
    --color-background-main-content: #F5F5F5;
    --font-yellow: #F7C347;
    --font-gray: #707474;
    --font-white: #FFF;
    --font-black: #0A1B29;
    --yellow: #F7C347;
    --gray: #707474;
    --font-normal: "Inter";

    // Element Space Base
    --space-1: 7.5px;
    --space-2: 15px;
    --space-3: 20px;
    --space-4: 20px;
    --space-5: 30px;
    --space-main-content: var(--space-4);
    --space-content-box-h: var(--space-3);
    --space-content-box-v: var(--space-3);
    --space-content-box: var(--space-content-box-v) var(--space-content-box-h);
    --space-content-similar-heavy-model: var(--space-1);
    --space-between-similar-model: var(--space-2);
    --space-between-header-and-content: var(--space-2);
    --space-between-grouped-models: var(--space-5);
    --space-between-fields-of-same-model: var(--space-1);
    --space-between-input-fields: var(--space-3);
    --space-between-columns: var(--space-4);
    --space-between-input: var(--space-3);

    // Element Base
    --base-margin: 10px;
    --base-margin-new-style: 6px;
    --base-disabled-opacity: 0.61;
    --base-height-component: 48px;
    --base-height-component-new-style: 32px;
    --base-height-component-small: 35px;
    --base-height-component-big: 65px;
    --base-icon-size: calc(var(--base-size-component) + 2px);
    --base-icon-size-small: calc(var(--base-size-component-small) + 2px);
    --base-icon-size-big: calc(var(--base-size-component-big) + 2px);

    // Element Base Font
    --base-size-component: 0.844rem;
    --base-size-component-small: 0.75rem;
    --base-size-component-big: 1.5rem;

    // Font Base
    --font-base-size: 16px;
    --font-base-size-normal: 0.844rem;
    --font-base-size-large: 0.938rem;
    --font-base-size-extra-large: 1.125rem;
    --font-base-family-normal: "Manrope";
    --font-base-family-serif: "Roboto Slab", serif;

    // Input Base
    --input-base-label-size: var(--base-size-component);
    --input-base-label-small-size: var(--base-size-component-small);
    --input-base-label-big-size: var(--base-size-component-big);
    --input-base-label-family: Roboto;
    --input-base-label-family-new-style: "Manrope";

    // Input Base Height
    --input-base-height: calc(var(--base-height-component) + var(--base-margin));
    --input-base-height-new-style: calc(var(--base-height-component-new-style) + var(--base-margin-new-style));
    --input-base-small-height: calc(var(--base-height-component-small) + var(--base-margin));
    --input-base-big-height: calc(var(--base-height-component-big) + var(--base-margin));

    // Input Base Inner - use base-inner as base when input doesn't have label outside
    --input-base-height-inner: calc(var(--base-height-component));
    --input-base-height-inner-new-style: calc(var(--base-height-component-new-styled));
    --input-base-small-height-inner: calc(var(--base-height-component-small));
    --input-base-big-height-inner: calc(var(--base-height-component-big));

    // Input Base Colors
    --input-base-border: 2px solid var(--font-gray);
    --input-base-border-radius: 8px;
    --input-base-border-radius-new-style: 26px;

    // Button Base
    --button-base-label-size: var(--base-size-component);
    --button-base-label-small-size: var(--base-size-component-small);
    --button-base-label-big-size: var(--base-size-component-big);

    // Button Base Height
    --button-base-height: var(--base-height-component);
    --button-base-small-height: var(--base-height-component-small);
    --button-base-big-height: var(--base-height-component-big);

    // Button Base Colors
    --button-base-background-primary: var(--yellow);
    --button-base-border-outline: 2px solid var(--yellow);
    --button-base-border-radius: 26px;
    --button-base-color: var(--font-black);
    --button-base-color-outline: var(--font-yellow);

    ${breakpoint('desktop')`
      // General Config
      --base-content-padding: 53px 0;
      --base-content-width: 95%;
      --base-header-height: 106px;
      --base-menu-width: 80px;

      // I don't what name puts here
      --color-background-main-content: #F7F8F9;
      --font-yellow: #fec34e;
      --font-gray: #707474;
      --font-white: #FFF;
      --yellow: #fec34e;
      --gray: #707474;
      --font-normal: "Manrope";

      // Element Space Base
      --space-1: 15px;
      --space-2: 25px;
      --space-3: 40px;
      --space-4: 53px;
      --space-5: 60px;
      --space-main-content: var(--space-2);
      --space-content-box-h: var(--space-3);
      --space-content-box-v: var(--space-3);
      --space-content-box: var(--space-content-box-v) var(--space-content-box-h);
      --space-content-similar-heavy-model: var(--space-1);
      --space-between-similar-model: var(--space-2);
      --space-between-header-and-content: var(--space-2);
      --space-between-grouped-models: var(--space-5);
      --space-between-fields-of-same-model: var(--space-1);
      --space-between-input-fields: var(--space-3);
      --space-between-columns: var(--space-4);
      --space-between-input: var(--space-3);

      // Element Base
      --base-margin: 10px;
      --base-disabled-opacity: 0.61;
      --base-height-component: 48px;
      --base-height-component-new-style: 32px;
      --base-height-component-small: 35px;
      --base-height-component-big: 65px;
      --base-icon-size: calc(var(--base-size-component) + 2px);
      --base-icon-size-small: calc(var(--base-size-component-small) + 2px);
      --base-icon-size-big: calc(var(--base-size-component-big) + 2px);

      // Element Base Font
      --base-size-component: 1.125rem;
      --base-size-component-small: 1rem;
      --base-size-component-big: 1.375rem;

      // Font Base
      --font-base-size: 16px;
      --font-base-size-normal: 1.125rem;
      --font-base-size-large: 1.375rem;
      --font-base-size-extra-large: 1.75rem;
      --font-base-family-normal: "Manrope";
      --font-base-family-serif: "Roboto Slab", serif;

      // Input Base
      --input-base-label-size: var(--base-size-component);
      --input-base-label-small-size: var(--base-size-component-small);
      --input-base-label-big-size: var(--base-size-component-big);
      --input-base-label-family: Roboto;

      // Input Base Height
      --input-base-height: calc(var(--base-height-component) + var(--base-margin));
      --input-base-small-height: calc(var(--base-height-component-small) + var(--base-margin));
      --input-base-big-height: calc(var(--base-height-component-big) + var(--base-margin));

      // Input Base Inner - use base-inner as base when input doesn't have label outside
      --input-base-height-inner: calc(var(--base-height-component));
      --input-base-height-inner-new-style: calc(var(--base-height-component-new-styled));
      --input-base-small-height-inner: calc(var(--base-height-component-small));
      --input-base-big-height-inner: calc(var(--base-height-component-big));

      // Input Base Colors
      --input-base-border: 2px solid var(--font-gray);
      --input-base-border-radius: 8px;
      --input-base-border-radius-new-style: 26px;

      // Button Base
      --button-base-label-size: var(--base-size-component);
      --button-base-label-small-size: var(--base-size-component-small);
      --button-base-label-big-size: var(--base-size-component-big);

      // Button Base Height
      --button-base-height: var(--base-height-component);
      --button-base-small-height: var(--base-height-component-small);
      --button-base-big-height: var(--base-height-component-big);

      // Button Base Colors
      --button-base-background-primary: var(--yellow);
      --button-base-border-outline: 2px solid var(--yellow);
      --button-base-border-radius: 26px;
      --button-base-color: var(--font-black);
      --button-base-color-outline: var(--font-yellow);
    `}
  }

  * {
    box-sizing: border-box;

    margin: 0;
    padding: 0;

    font-family: "Manrope", sans-serif;
  }

  html {
    font-size: 16px;
  }

  html,
  body,
  #root {
    height: 100%;

    position: relative;
  }

  .block-scroll {
    overflow: hidden;
  }

  .red {
    color: red;
  }

  #tip-global-permission {
    z-index: 100000;
  }

  [data-component-type*="main-content"] {
    padding-top: var(--space-main-content);
    padding-bottom: var(--space-main-content);
  }

  ${generateMicroDataSpace(
    ['model', 'model'],
    {
      'margin-top': 'var(--space-between-similar-model)'
    },
    {
      debug: 'red'
    }
  )}

  ${generateMicroDataSpace(
    ['heavy-model', 'heavy-model'],
    {
      'margin-top': 'var(--space-content-similar-heavy-model)'
    },
    {
      debug: 'red'
    }
  )}

  ${generateMicroDataSpace(
    ['grouped-model', 'grouped-model'],
    {
      'margin-top': 'var(--space-between-grouped-models)'
    },
    {
      debug: 'yellow'
    }
  )}

  ${generateMicroDataSpace(
    ['header', 'content'],
    {
      'margin-top': 'var(--space-between-header-and-content)'
    },
    {
      debug: 'green'
    }
  )}

  ${generateMicroDataSpace(
    ['content', 'content'],
    {
      'margin-top': 'var(--space-between-header-and-content)'
    },
    {
      debug: 'green'
    }
  )}

  ${generateMicroDataSpace(
    ['content', 'action-from-model'],
    {
      'margin-top': 'var(--space-between-fields-of-same-model)'
    },
    {
      debug: 'purple',
      reversed: true
    }
  )}

  ${generateMicroDataSpace(
    ['info-from-model', 'info-from-model'],
    {
      'margin-top': 'var(--space-between-fields-of-same-model)'
    },
    {
      debug: 'blue'
    }
  )}

  ${generateMicroDataSpace(
    ['info-from-model', 'action-from-model'],
    {
      'margin-top': 'var(--space-between-fields-of-same-model)'
    },
    {
      debug: 'purple',
      reversed: true
    }
  )}

  ${generateMicroDataSpace(
    ['info-from-model', 'action-normal'],
    {
      'margin-top': 'var(--space-between-fields-of-same-model)'
    },
    {
      debug: 'purple',
      reversed: true
    }
  )}

  ${generateMicroDataSpace(
    ['columns', 'columns'],
    {
      'margin-left': 'var(--space-between-columns)'
    },
    {
      debug: 'orange'
    }
  )}

  ${generateMicroDataSpace(
    ['input', 'input'],
    {
      'margin-top': 'var(--space-between-input)'
    },
    {
      debug: 'green'
    }
  )}

  ${generateMicroDataSpace(
    ['input', 'action-from-model'],
    {
      'margin-top': 'var(--space-between-input)'
    },
    {
      debug: 'green',
      reversed: true
    }
  )}

  ${generateMicroDataSpace(
    ['input', 'action-normal'],
    {
      'margin-top': 'var(--space-between-input)'
    },
    {
      debug: 'green',
      reversed: true
    }
  )}

  [data-show-only="mobile"] {
    ${breakpoint('tablet')`
      display: none;
      visibility: hidden;
    `}
  }

  [data-show-only="tablet"] {
    ${breakpoint('mobile', 'tablet')`
      display: none;
      visibility: hidden;
    `}

    ${breakpoint('desktop')`
      display: none;
      visibility: hidden;
    `}
  }

  [data-show-only="desktop"] {
    ${breakpoint('mobile', 'desktop')`
      display: none;
      visibility: hidden;
    `}
  }

  [data-show-up="tablet"] {
    ${breakpoint('mobile', 'tablet')`
      display: none;
      visibility: hidden;
    `}
  }

  [data-show-down="tablet"] {
    ${breakpoint('desktop')`
      display: none;
      visibility: hidden;
    `}
  }

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;

    font-weight: 400;
    color: ${props => props.theme.colors.black};
  }

  ${({ theme }) => generateFontTypesCSSClasses(theme.fonts.types)}

  ${({ theme }) => generateFontColorsCSSClasses(theme.colors)}

  .w-100 {
    width: 100% !important;
  }

  .fw-lighter {
    font-weight: lighter !important;
  }

  .fw-light {
    font-weight: 300 !important;
  }

  .fw-normal {
    font-weight: 400 !important;
  }

  .fw-medium {
    font-weight: 500 !important;
  }

  .fw-semibold {
    font-weight: 600 !important;
  }

  .fw-bold {
    font-weight: 700 !important;
  }

  .fw-extrabold {
    font-weight: 800 !important;
  }

  .fw-bolder {
    font-weight: bolder !important;
  }

  .text-start {
    text-align: left !important;
  }

  .text-end {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-decoration-none {
    text-decoration: none !important;
  }

  .text-decoration-underline {
    text-decoration: underline !important;
  }

  .text-decoration-line-through {
    text-decoration: line-through !important;
  }

  .text-lowercase {
    text-transform: lowercase !important;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

  .text-capitalize {
    text-transform: capitalize !important;
  }

  .text-wrap {
    white-space: normal !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  /* rtl:begin:remove */
  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }

  .d-inline {
    display: inline !important;
  }

  .d-inline-block {
    display: inline-block !important;
  }

  .d-block {
    display: block !important;
  }

  .d-grid {
    display: grid !important;
  }

  .d-inline-grid {
    display: inline-grid !important;
  }

  .d-table {
    display: table !important;
  }

  .d-table-row {
    display: table-row !important;
  }

  .d-table-cell {
    display: table-cell !important;
  }

  .d-flex {
    display: flex !important;
  }

  .d-inline-flex {
    display: inline-flex !important;
  }

  .d-none {
    display: none !important;
  }

  .flex-fill {
    flex: 1 1 auto !important;
  }

  .flex-row {
    flex-direction: row !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-start {
    justify-content: flex-start !important;
  }

  .justify-content-end {
    justify-content: flex-end !important;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .justify-content-around {
    justify-content: space-around !important;
  }

  .justify-content-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-start {
    align-items: flex-start !important;
  }

  .align-items-end {
    align-items: flex-end !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .align-items-baseline {
    align-items: baseline !important;
  }

  .align-items-stretch {
    align-items: stretch !important;
  }

  .align-content-start {
    align-content: flex-start !important;
  }

  .align-content-end {
    align-content: flex-end !important;
  }

  .align-content-center {
    align-content: center !important;
  }

  .align-content-between {
    align-content: space-between !important;
  }

  .align-content-around {
    align-content: space-around !important;
  }

  .align-content-stretch {
    align-content: stretch !important;
  }

  .align-self-auto {
    align-self: auto !important;
  }

  .align-self-start {
    align-self: flex-start !important;
  }

  .align-self-end {
    align-self: flex-end !important;
  }

  .align-self-center {
    align-self: center !important;
  }

  .align-self-baseline {
    align-self: baseline !important;
  }

  .align-self-stretch {
    align-self: stretch !important;
  }

  ${css(generateCssWithSizes({ prefix: 'gap', properties: ['gap'] }))}

  ${css(generateCssWithSizes({ prefix: 'm', properties: ['margin'] }))}

  ${css(generateCssWithSizes({ prefix: 'mx', properties: ['margin-left', 'margin-right'] }))}

  ${css(generateCssWithSizes({ prefix: 'my', properties: ['margin-top', 'margin-bottom'] }))}

  ${css(generateCssWithSizes({ prefix: 'mt', properties: ['margin-top'] }))}

  ${css(generateCssWithSizes({ prefix: 'me', properties: ['margin-right'] }))}

  ${css(generateCssWithSizes({ prefix: 'mb', properties: ['margin-bottom'] }))}

  ${css(generateCssWithSizes({ prefix: 'ms', properties: ['margin-left'] }))}

  ${css(generateCssWithSizes({ prefix: 'p', properties: ['padding'] }))}

  ${css(generateCssWithSizes({ prefix: 'px', properties: ['padding-left', 'padding-right'] }))}

  ${css(generateCssWithSizes({ prefix: 'py', properties: ['padding-top', 'padding-bottom'] }))}

  ${css(generateCssWithSizes({ prefix: 'pt', properties: ['padding-top'] }))}

  ${css(generateCssWithSizes({ prefix: 'pe', properties: ['padding-right'] }))}

  ${css(generateCssWithSizes({ prefix: 'pb', properties: ['padding-bottom'] }))}

  ${css(generateCssWithSizes({ prefix: 'ps', properties: ['padding-left'] }))}

  .m-auto {
    margin: auto !important;
  }

  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-auto {
    margin-top: auto !important;
  }

  .me-auto {
    margin-right: auto !important;
  }

  .mb-auto {
    margin-bottom: auto !important;
  }

  .ms-auto {
    margin-left: auto !important;
  }

  .no-margin {
    margin: 0 !important;
  }

  .tooltilp-customize {
    line-height: 1.5rem;
  }

  @media (min-width: 576px) {
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-grid {
      display: grid !important;
    }
    .d-sm-inline-grid {
      display: inline-grid !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
    .d-sm-none {
      display: none !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .justify-content-sm-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
    ${css(generateCssWithSizes({ prefix: 'm-sm', properties: ['margin'] }))}

    ${css(generateCssWithSizes({ prefix: 'mx-sm', properties: ['margin-left', 'margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'my-sm', properties: ['margin-top', 'margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'mt-sm', properties: ['margin-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'me-sm', properties: ['margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'mb-sm', properties: ['margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ms-sm', properties: ['margin-left'] }))}

    ${css(generateCssWithSizes({ prefix: 'p-sm', properties: ['padding'] }))}

    ${css(generateCssWithSizes({ prefix: 'px-sm', properties: ['padding-left', 'padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'py-sm', properties: ['padding-top', 'padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'pt-sm', properties: ['padding-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'pe-sm', properties: ['padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'pb-sm', properties: ['padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ps-sm', properties: ['padding-left'] }))}
    .m-sm-auto {
      margin: auto !important;
    }
    .mx-sm-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-sm-auto {
      margin-top: auto !important;
    }
    .me-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto {
      margin-bottom: auto !important;
    }
    .ms-sm-auto {
      margin-left: auto !important;
    }
  }

  @media (min-width: 768px) {
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-grid {
      display: grid !important;
    }
    .d-md-inline-grid {
      display: inline-grid !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
    .d-md-none {
      display: none !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .justify-content-md-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
    .order-md-first {
      order: -1 !important;
    }
    .order-md-0 {
      order: 0 !important;
    }
    .order-md-1 {
      order: 1 !important;
    }
    .order-md-2 {
      order: 2 !important;
    }
    .order-md-3 {
      order: 3 !important;
    }
    .order-md-4 {
      order: 4 !important;
    }
    .order-md-5 {
      order: 5 !important;
    }
    .order-md-last {
      order: 6 !important;
    }
    ${css(generateCssWithSizes({ prefix: 'm-md', properties: ['margin'] }))}

    ${css(generateCssWithSizes({ prefix: 'mx-md', properties: ['margin-left', 'margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'my-md', properties: ['margin-top', 'margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'mt-md', properties: ['margin-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'me-md', properties: ['margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'mb-md', properties: ['margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ms-md', properties: ['margin-left'] }))}

    ${css(generateCssWithSizes({ prefix: 'p-md', properties: ['padding'] }))}

    ${css(generateCssWithSizes({ prefix: 'px-md', properties: ['padding-left', 'padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'py-md', properties: ['padding-top', 'padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'pt-md', properties: ['padding-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'pe-md', properties: ['padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'pb-md', properties: ['padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ps-md', properties: ['padding-left'] }))}
    .m-md-auto {
      margin: auto !important;
    }
    .mx-md-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-md-auto {
      margin-top: auto !important;
    }
    .me-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto {
      margin-bottom: auto !important;
    }
    .ms-md-auto {
      margin-left: auto !important;
    }
  }

  @media (min-width: 992px) {
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-grid {
      display: grid !important;
    }
    .d-lg-inline-grid {
      display: inline-grid !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
    .d-lg-none {
      display: none !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .justify-content-lg-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
    .order-lg-first {
      order: -1 !important;
    }
    .order-lg-0 {
      order: 0 !important;
    }
    .order-lg-1 {
      order: 1 !important;
    }
    .order-lg-2 {
      order: 2 !important;
    }
    .order-lg-3 {
      order: 3 !important;
    }
    .order-lg-4 {
      order: 4 !important;
    }
    .order-lg-5 {
      order: 5 !important;
    }
    .order-lg-last {
      order: 6 !important;
    }
    ${css(generateCssWithSizes({ prefix: 'm-lg', properties: ['margin'] }))}

    ${css(generateCssWithSizes({ prefix: 'mx-lg', properties: ['margin-left', 'margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'my-lg', properties: ['margin-top', 'margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'mt-lg', properties: ['margin-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'me-lg', properties: ['margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'mb-lg', properties: ['margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ms-lg', properties: ['margin-left'] }))}

    ${css(generateCssWithSizes({ prefix: 'p-lg', properties: ['padding'] }))}

    ${css(generateCssWithSizes({ prefix: 'px-lg', properties: ['padding-left', 'padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'py-lg', properties: ['padding-top', 'padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'pt-lg', properties: ['padding-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'pe-lg', properties: ['padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'pb-lg', properties: ['padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ps-lg', properties: ['padding-left'] }))}
    .m-lg-auto {
      margin: auto !important;
    }
    .mx-lg-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-lg-auto {
      margin-top: auto !important;
    }
    .me-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto {
      margin-bottom: auto !important;
    }
    .ms-lg-auto {
      margin-left: auto !important;
    }
  }

  @media (min-width: 1200px) {
    html {
      font-size: 16px;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-grid {
      display: grid !important;
    }
    .d-xl-inline-grid {
      display: inline-grid !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
    .d-xl-none {
      display: none !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .justify-content-xl-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
    .order-xl-first {
      order: -1 !important;
    }
    .order-xl-0 {
      order: 0 !important;
    }
    .order-xl-1 {
      order: 1 !important;
    }
    .order-xl-2 {
      order: 2 !important;
    }
    .order-xl-3 {
      order: 3 !important;
    }
    .order-xl-4 {
      order: 4 !important;
    }
    .order-xl-5 {
      order: 5 !important;
    }
    .order-xl-last {
      order: 6 !important;
    }
    ${css(generateCssWithSizes({ prefix: 'm-xl', properties: ['margin'] }))}

    ${css(generateCssWithSizes({ prefix: 'mx-xl', properties: ['margin-left', 'margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'my-xl', properties: ['margin-top', 'margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'mt-xl', properties: ['margin-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'me-xl', properties: ['margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'mb-xl', properties: ['margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ms-xl', properties: ['margin-left'] }))}

    ${css(generateCssWithSizes({ prefix: 'p-xl', properties: ['padding'] }))}

    ${css(generateCssWithSizes({ prefix: 'px-xl', properties: ['padding-left', 'padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'py-xl', properties: ['padding-top', 'padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'pt-xl', properties: ['padding-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'pe-xl', properties: ['padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'pb-xl', properties: ['padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ps-xl', properties: ['padding-left'] }))}
    .m-xl-auto {
      margin: auto !important;
    }
    .mx-xl-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xl-auto {
      margin-top: auto !important;
    }
    .me-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto {
      margin-bottom: auto !important;
    }
    .ms-xl-auto {
      margin-left: auto !important;
    }
  }

  @media (min-width: 1400px) {
    html {
      font-size: 16px;
    }
    .d-xxl-inline {
      display: inline !important;
    }
    .d-xxl-inline-block {
      display: inline-block !important;
    }
    .d-xxl-block {
      display: block !important;
    }
    .d-xxl-grid {
      display: grid !important;
    }
    .d-xxl-inline-grid {
      display: inline-grid !important;
    }
    .d-xxl-table {
      display: table !important;
    }
    .d-xxl-table-row {
      display: table-row !important;
    }
    .d-xxl-table-cell {
      display: table-cell !important;
    }
    .d-xxl-flex {
      display: flex !important;
    }
    .d-xxl-inline-flex {
      display: inline-flex !important;
    }
    .d-xxl-none {
      display: none !important;
    }
    .flex-xxl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xxl-row {
      flex-direction: row !important;
    }
    .flex-xxl-column {
      flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xxl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-xxl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-xxl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
      justify-content: center !important;
    }
    .justify-content-xxl-between {
      justify-content: space-between !important;
    }
    .justify-content-xxl-around {
      justify-content: space-around !important;
    }
    .justify-content-xxl-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-xxl-start {
      align-items: flex-start !important;
    }
    .align-items-xxl-end {
      align-items: flex-end !important;
    }
    .align-items-xxl-center {
      align-items: center !important;
    }
    .align-items-xxl-baseline {
      align-items: baseline !important;
    }
    .align-items-xxl-stretch {
      align-items: stretch !important;
    }
    .align-content-xxl-start {
      align-content: flex-start !important;
    }
    .align-content-xxl-end {
      align-content: flex-end !important;
    }
    .align-content-xxl-center {
      align-content: center !important;
    }
    .align-content-xxl-between {
      align-content: space-between !important;
    }
    .align-content-xxl-around {
      align-content: space-around !important;
    }
    .align-content-xxl-stretch {
      align-content: stretch !important;
    }
    .align-self-xxl-auto {
      align-self: auto !important;
    }
    .align-self-xxl-start {
      align-self: flex-start !important;
    }
    .align-self-xxl-end {
      align-self: flex-end !important;
    }
    .align-self-xxl-center {
      align-self: center !important;
    }
    .align-self-xxl-baseline {
      align-self: baseline !important;
    }
    .align-self-xxl-stretch {
      align-self: stretch !important;
    }
    .order-xxl-first {
      order: -1 !important;
    }
    .order-xxl-0 {
      order: 0 !important;
    }
    .order-xxl-1 {
      order: 1 !important;
    }
    .order-xxl-2 {
      order: 2 !important;
    }
    .order-xxl-3 {
      order: 3 !important;
    }
    .order-xxl-4 {
      order: 4 !important;
    }
    .order-xxl-5 {
      order: 5 !important;
    }
    .order-xxl-last {
      order: 6 !important;
    }
    ${css(generateCssWithSizes({ prefix: 'm-xxl', properties: ['margin'] }))}

    ${css(generateCssWithSizes({ prefix: 'mx-xxl', properties: ['margin-left', 'margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'my-xxl', properties: ['margin-top', 'margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'mt-xxl', properties: ['margin-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'me-xxl', properties: ['margin-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'mb-xxl', properties: ['margin-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ms-xxl', properties: ['margin-left'] }))}

    ${css(generateCssWithSizes({ prefix: 'p-xxl', properties: ['padding'] }))}

    ${css(generateCssWithSizes({ prefix: 'px-xxl', properties: ['padding-left', 'padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'py-xxl', properties: ['padding-top', 'padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'pt-xxl', properties: ['padding-top'] }))}

    ${css(generateCssWithSizes({ prefix: 'pe-xxl', properties: ['padding-right'] }))}

    ${css(generateCssWithSizes({ prefix: 'pb-xxl', properties: ['padding-bottom'] }))}

    ${css(generateCssWithSizes({ prefix: 'ps-xxl', properties: ['padding-left'] }))}
    .m-xxl-auto {
      margin: auto !important;
    }
    .mx-xxl-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xxl-auto {
      margin-top: auto !important;
    }
    .me-xxl-auto {
      margin-right: auto !important;
    }
    .mb-xxl-auto {
      margin-bottom: auto !important;
    }
    .ms-xxl-auto {
      margin-left: auto !important;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 13.5px;
    }
  }

  iframe {
    pointer-events: none !important;
  }
`

export default GlobalStyle
