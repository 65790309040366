import React, { useCallback } from 'react'

import { format, addDays } from 'date-fns'

import { takeMonth } from './Calendar.helper'
import { CalendarSectionS, CircleS, RootS, ContinuosBackgroundS, DayS, DayWeekS, DayWeeksSectionS, MonthS, TextDayS, WeeksSectionS } from './Calendar.styled'
import Header from './Components/Header.component'
import type { HeaderPropsI } from './Components/Header.component'
import languages from './languages.json'

interface CalendarPropsI {
  language?: 'pt-BR' | 'en-US',
  sizeArrow?: string,
  currentDate?: Date,
  onChangeCurrentDate?: (date: Date) => void,
  initialDateLimit?: Date,
  endDateLimit?: Date,
  isContinuous?: boolean,
  rangeDate: Date[],
  onChange?: (rangeDate: Date[]) => void,
  enableLeftArrow?: HeaderPropsI['enableLeftArrow'],
  enableRightArrow?: HeaderPropsI['enableRightArrow'],
}

const Calendar = (props: CalendarPropsI) => {
  const {
    language = 'pt-BR',
    currentDate = new Date(),
    initialDateLimit,
    endDateLimit,
    isContinuous = true,
    rangeDate = [],
    onChange = () => undefined,
    onChangeCurrentDate = () => undefined,
    enableLeftArrow,
    enableRightArrow
  } = props

  // @ts-ignore
  const daysWeek = language === 'en-US' ? languages['en-US'] : languages['pt-BR']

  const data = takeMonth(currentDate)()

  function getSelectedMultipleDates (date: Date) {
    const dateExists = rangeDate.find(
      (d) => format(d, 'dd/MM/yyyy') === format(date, 'dd/MM/yyyy')
    )

    if ((initialDateLimit && date < initialDateLimit) || (endDateLimit && date > addDays(endDateLimit, 1))) {
      return false
    }

    if (isContinuous) {
      if (rangeDate.length >= 2) {
        if (date > rangeDate[0]) {
          onChange([rangeDate[0], date])
        } else if (date < rangeDate[1]) {
          onChange([date, rangeDate[1]])
        } else {
          return undefined
        }
      } else {
        if (dateExists) {
          const newDates = rangeDate.filter(
            (d) => format(d, 'dd/MM/yyyy') !== format(date, 'dd/MM/yyyy')
          )
          onChange(newDates)
        } else {
          onChange([...rangeDate, date])
        }
      }

      return undefined
    }

    if (dateExists) {
      const newDates = rangeDate.filter(
        (d) => format(d, 'dd/MM/yyyy') !== format(date, 'dd/MM/yyyy')
      )
      onChange(newDates)
    } else {
      onChange([...rangeDate, date])
    }
  }

  const clearSelection = useCallback(() => {
    onChange([])
  }, [onChange])

  function getDates (initialDate: Date, stopDate: Date) {
    const dateArray = []
    let initial = initialDate
    while (initial <= stopDate) {
      dateArray.push(initial)
      initial = addDays(initial, 1)
    }

    return dateArray
  }

  function backgroundColorDateMultiple (date: Date) {
    if (isContinuous) {
      const arr = getDates(rangeDate[0], rangeDate[1])
      const dateExists = arr.find(
        (d) => format(d, 'dd/MM/yyyy') === format(date, 'dd/MM/yyyy')
      )

      if (dateExists) {
        return 'day-selected'
      }
    }
    const dateExists = rangeDate.find(
      (d) => format(d, 'dd/MM/yyyy') === format(date, 'dd/MM/yyyy')
    )

    if (dateExists) {
      return 'day-selected'
    }
  }

  function isFistOfMultipleDates (day: Date) {
    if (rangeDate.length === 1) {
      return 'one-item'
    }

    if (String(day) === String(rangeDate[0])) {
      return true
    }
  }

  function isLastOfMultipleDates (day: Date) {
    if (String(day) === String(rangeDate[rangeDate.length - 1])) {
      return true
    }
  }

  return (
    <RootS>
      <CalendarSectionS>
        <Header
          colorTextHeader={'#000'}
          currentDate={currentDate}
          language={language}
          fontWeightMonthAndYear={'normal'}
          setCurrentDate={onChangeCurrentDate}
          clearSelection={clearSelection}
          enableLeftArrow={enableLeftArrow}
          enableRightArrow={enableRightArrow}
        />
        <DayWeeksSectionS>
          {/* @ts-ignore */}
          {daysWeek.map((dayName, i) => (
            <DayWeekS key={dayName}>
              {dayName}
            </DayWeekS>
          ))}
        </DayWeeksSectionS>
        <MonthS>
          {data.map((week: any) => (
            <WeeksSectionS key={week}>
              {week.map((day: Date) => (
                <DayS
                  key={String(day)}
                  isDaySelected={backgroundColorDateMultiple(day) === 'day-selected'}
                  onClick={async () => {
                    getSelectedMultipleDates(day)
                  }}
                >
                  <TextDayS
                    isDaySelected={backgroundColorDateMultiple(day) === 'day-selected'}
                    onClick={() =>
                      getSelectedMultipleDates(day)
                    }
                  >
                    {format(day, 'dd')}
                  </TextDayS>
                  {!isContinuous && (
                    <CircleS
                      display={`${
                        backgroundColorDateMultiple(day) === 'day-selected'
                          ? ''
                          : 'none'
                      }`}
                    >
                      {' '}
                    </CircleS>
                  )}
                  {isContinuous && (
                    <ContinuosBackgroundS
                      display={`${
                        backgroundColorDateMultiple(day) === 'day-selected'
                          ? ''
                          : 'none'
                      }`}
                      borderRadius={
                        isFistOfMultipleDates(day)
                          ? '20px 0px 0 20px'
                          : '0' && isLastOfMultipleDates(day)
                            ? '0 20px 20px 0'
                            : '0'
                      }
                      style={{
                        borderRadius: `${
                          isFistOfMultipleDates(day) === 'one-item'
                            ? '20px'
                            : ''
                        }`
                      }}
                    >
                      {' '}
                    </ContinuosBackgroundS>
                  )}
                </DayS>
              ))}
            </WeeksSectionS>
          ))}
        </MonthS>
      </CalendarSectionS>
    </RootS>
  )
}

export default Calendar
