import {
  MagnifyingGlassMinus,
  MagnifyingGlassPlus
} from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import type { Area, Point } from 'react-easy-crop/types'
import { useIntl } from 'react-intl'

import { Button } from 'DesignSystem'

import LoadingDefault from 'Components/Loadings/default.component'
import UploadField from 'Components/UploadField'

import { createImage, getCroppedImg, isBase64 } from 'Helpers/Image.helper'
import { MicroData, passAlongMicroData } from 'Helpers/MicroData.helper'

import { ButtonsS, CropS, RootS } from './UploadImageField.styled'

interface UploadFieldPropsI {
  label: string;
  placeholder?: string;
  aspect?: number;
  positionArePixels?: Area;
  src?: string;
  initialImage?: string;
  zoom?: number;
  sizeImage?: {
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
  };
  isCropped?: boolean;
  withoutInitialAreaPixels?: boolean;
  onChange?: (
    newImage: string | null,
    croppedAreaPixels: Area | null,
    zoom: number | null,
    isValidSize: boolean | null
  ) => void;
  onClickChangeLogo?: () => void;
  onChangeCache?: (newImage: string | null) => void;
}

const UploadImageField = (props: UploadFieldPropsI) => {
  const {
    src,
    label,
    placeholder,
    aspect = 2 / 1,
    isCropped = false,
    sizeImage,
    zoom,
    positionArePixels = { width: 184, height: 92, x: 0, y: 0 },
    withoutInitialAreaPixels = false,
    onChange = () => undefined,
    onChangeCache = () => undefined,
    onClickChangeLogo = () => undefined,
    initialImage = null
  } = props

  const intl = useIntl()
  const [isValidSize, setIsValidSize] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [crop, setCrop] = useState<Point>(positionArePixels)
  const [zoomLocal, setZoomLocal] = useState(zoom || 0)
  const [retry, setRetry] = useState(true)
  const [countRetry, setCountRetry] = useState(0)
  const [srcCached, setSrcCached] = useState<string>('')
  const [croppedAreaPixelsState, setCroppedAreaPixelsState] = useState<Area | null>(null)
  const iconOriginProxied = isCropped
    ? srcCached
    : `${process.env.REACT_APP_API_URL}proxy/img?url=${src}`

  const handleCropComplete = async (croppedArea?: any, croppedAreaPixels?: any) => {
    if (croppedAreaPixels) setCroppedAreaPixelsState(croppedAreaPixels)
    if (!croppedAreaPixels) croppedAreaPixels = croppedAreaPixelsState

    if (!isFinite(croppedAreaPixels.width) || !isFinite(croppedAreaPixels.height)) {
      return
    }

    try {
      const croppedImage = await getCroppedImg(
        iconOriginProxied,
        croppedAreaPixels
      )

      if (isCropped) onChange(croppedImage, croppedAreaPixels, zoomLocal, isValidSize)
    } catch (e) {
      console.error(e)
    }
  }

  async function onDropFiles (fileData: any) {
    setRetry(false)
    try {
      const src = fileData?.base
      setIsLoading(true)
      if (!isCropped) onChange(src, null, null, isValidSize)
      if (isCropped) setSrcCached(src)
      onChangeCache(src)
    } finally {
      setIsLoading(false)
      setTimeout(() => {
        setCrop({ x: 0, y: 0 })
        setZoomLocal(1)
      }, 1000)
    }
  }

  const handleClickZoom = (zoom: number) => setZoomLocal(zoom < 0.2 ? 0.2 : zoom)

  useEffect(() => {
    async function convertToBase64 () {
      try {
        if (!initialImage) return

        if (isBase64(initialImage)) {
          setSrcCached(initialImage)
          setIsValidSize(true)
          setRetry(false)
          return
        }

        // Create an Image object
        const img = await createImage(`${process.env.REACT_APP_API_URL}proxy/img?url=${initialImage}`)

        // Wait for the image to load
        // Create a canvas element
        const canvas = document.createElement('canvas')

        // Set the canvas dimensions to match the image
        canvas.width = img.width
        canvas.height = img.height

        // Get the 2D context of the canvas
        const ctx = canvas.getContext('2d')

        if (!ctx) return

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0)

        // Get the base64 representation of the image
        const base64Image = canvas.toDataURL('image/png')

        // Log the base64 value
        setSrcCached(base64Image)
        setIsValidSize(true)
        setRetry(false)
        setTimeout(() => {
          setCrop(positionArePixels)
          setZoomLocal(zoom || 1)
        }, 1000)
      } catch (e) {
        if (!initialImage) return

        setSrcCached(initialImage)
        setIsValidSize(true)
        setRetry(false)
        // setTimeout(() => {
        //   setCrop(positionArePixels)
        //   setZoomLocal(zoom || 1)
        // }, 1000)
      }
    }
    convertToBase64()
  }, [])

  return (
    <RootS {...passAlongMicroData(props)}>
      {isCropped && isLoading ? (
        <LoadingDefault className="page-new-company__form-icon__loading" />
      ) : null}
      <div className="d-flex align-items-center">
        <div className='d-flex flex-column'>
        <CropS>
          {!iconOriginProxied || retry ? (
            <UploadField
              onValidate={setIsValidSize}
              sizeImage={sizeImage}
              label={label}
              placeholder={placeholder}
              onDrop={onDropFiles}
              startOpen={countRetry > 0 && retry}
            />
          ) : isCropped && !isLoading && isValidSize ? (
            <Cropper
              image={iconOriginProxied}
              crop={crop}
              initialCroppedAreaPixels={withoutInitialAreaPixels ? undefined : positionArePixels}
              zoom={zoomLocal}
              aspect={aspect}
              cropSize={{
                width: 184,
                height: 92
              }}
              restrictPosition={false}
              showGrid={true}
              onCropChange={setCrop}
              style={{
                containerStyle: { width: '100%', backgroundColor: 'transparent' }
              }}
              onCropComplete={handleCropComplete}
              // objectFit="horizontal-cover"
            />
          ) : null}
        </CropS>
        {isCropped && !isLoading && isValidSize && !retry && (
            <Button className='mt-2' onClick={() => {
              onClickChangeLogo()
              setCountRetry(countRetry + 1)
              setRetry(!retry)
              onChange(null, null, null, false)
            }}>
              {intl.formatMessage({ id: 'Alterar logo' })}
            </Button>
        )}
        </div>
        <ButtonsS className="ms-3" data-component-type={MicroData.INFO_FROM_MODEL}>
          <button onClick={() => handleClickZoom(zoomLocal + 0.1)}>
            <MagnifyingGlassPlus size={30} />
          </button>
          <button className='mt-3' onClick={() => handleClickZoom(zoomLocal - 0.1)}>
            <MagnifyingGlassMinus size={30} />
          </button>

        </ButtonsS>
      </div>
    </RootS>
  )
}

export default UploadImageField
