import styled from 'styled-components/macro'

export const RootS = styled.div`
  width: 100%;

  box-sizing: border-box;

  padding: var(--space-content-box);

  background-color: var(--color-background-main-content);
  border-radius: 30px;
  box-shadow: 0 3px 6px #00000016;
`
