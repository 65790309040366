import styled, { css } from 'styled-components'

export const getInputStyled = ({ theme, error, icon } : any) => css`
  width: 100%;

  padding: ${theme.metrics.space1} ${theme.metrics.space5};

  ${props => css(props.theme.fonts.types.h4)};

  background-color: white;
  border: 1px solid ${error ? theme.colors.error : theme.colors.gray};
  border-radius: ${theme.metrics.radius};

  line-height: 32px;

  ::placeholder {
    font-weight: 800;
    color: ${theme.colors.gray80};
    opacity: 1;
  }

  ${icon && css`
    padding-left: 2.5rem;
  `}

  @media (max-width: 768px) {
    ${props => css(props.theme.fonts.types.subtitle1)};
  }
`

const InputWrapperStyled = styled.div`
  position : relative;
  &::before {
    content: "";
    top: 0.8rem;
    left: 1rem;
    position: absolute;
    height: 1rem;
    width: 1rem;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFkSURBVHgBpZPBbYMwFIZttxQlUiW6AdkAjsChMEFGICO0EyTZoJ0gyQRNJ0AqQlwZwd2Aa5DA+Q0hwUC45F38MPyf//f8IOTBoP2NJPlbUcpCIYhFKTWwlSHPiqLYBkHA7wKiKDJ0XfvBlj92EiA5Y+zDcZxDd5+1yU1MeVWJz9ls/ua6HoXQhvxAKTGEqPZxHPsDB43tp50Uw3aAUzgZlJZsAFkj5QAvFAeMPYeNTbEdE8vwPG8jy0Bqdl3UAFi25IpGHcl01PXDpaUAZH1yRZfzaX2VN98LQwHA+r9c+w0aBqtPLkuRKQAwa+u4pvCeFNdswulS5pqmqQAIv2SD8MEK3V6PiXX9JWrsk3230ddBghBXSXaXRw7gr6wZDXvvDRc/nYqgnUpllNM09oWQ8yDMvgsAvwFbXt5dIYN/oQWVJbWabgs+n78ebdvO0zQ10fCohciBGgVMxQ1CiOu6C/JonAFMLJ34HMnUtwAAAABJRU5ErkJggg==");
    background-size: contain;
  }
`

const InputStyled = styled('input')(getInputStyled)

function Input (props:any) {
  if (props.icon) {
    return (
      <InputWrapperStyled>
        <InputStyled {...props} />
      </InputWrapperStyled>
    )
  }
  return <InputStyled {...props} />
}

export default Input
