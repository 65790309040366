import React from 'react'

import IconSVG from 'Components/IconSVG'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

import { RootS } from './BackButton.styled'

interface BackButtonInterface {
  onClick: () => void,
  label: string,
  className?: string,
  size?: SizeComponentI
}

const BackButton = (props: BackButtonInterface) => {
  const { size = 'normal', className, onClick, label } = props

  return (
    <RootS size={size} onClick={onClick} className={className}>
      <IconSVG icon='back' />
      <label>{label}</label>
    </RootS>
  )
}

export default BackButton
