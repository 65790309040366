import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from 'DesignSystem'

import { addAlert } from 'Store/Actions/alerts.action'

import Breadcrumbs from 'Components/Breadcrumbs'
import ConnectionForm from 'Components/ConnectionForm'
import { ConnectionFormContext, ConnectionFormContextProvider } from 'Components/ConnectionForm/ConnectionForm.context'
import TemplateDefault from 'Components/TemplateDefault'

import useIntlShort from 'Hooks/useIntlShort.hook'
import useQuery from 'Hooks/useQuery.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

import DeleteConnection from './Components/DeleteConnection.component'
import DetailsConnection from './Components/DetailConnection.component'
import { CardConnectionS } from './ConnectionsShow.styled'

interface ConnectionsPropsI {
  isPocket?: boolean,
  idCompany?: string,
  isFullContainer?: boolean,
}

const ConnectionsShow = ({ isPocket, isFullContainer } : ConnectionsPropsI) => {
  const query = useQuery()
  const dispatch = useDispatch()
  const history = useHistory()
  const { account_id } = useParams<any>()
  const { goUpdate, data } = useContext(ConnectionFormContext)
  const intl = useIntlShort()

  const crumbs: any = [
    {
      name: intl('Workspaces'),
      to: generateLinkTo('companies')
    },
    {
      name: intl('Connections'),
      to: generateLinkTo('connections')
    },
    {
      name: data && data.name ? data.name : intl('Conexão sem nome'),
      to: generateLinkTo('connectionsShow', { account_id })
    }
  ]

  const update = async () => {
    await goUpdate()

    dispatch(
      addAlert({
        title: intl('Conexão editada!'),
        type: 'success'
      })
    )
  }

  useEffect(() => {
    const value = query.get('renew')
    if (value === 'true') {
      dispatch(addAlert({
        type: 'success',
        title: intl('Conexão renovada com successo')
      }))
    }
  }, [])

  return (
    <TemplateDefault
      isPocket={isPocket}
      isFullContainer={isFullContainer}
      leftHeader={ !isPocket && <Breadcrumbs crumbs={crumbs} />}
    >
      <h2 className='fw-bold mb-3'>{intl('Configurações da conexão')}</h2>
      <CardConnectionS style={{ minHeight: '350px' }}>
        <div>
          <ConnectionForm />
        </div>
        <div>
          <DetailsConnection />
        </div>
        <div>
          <DeleteConnection />
        </div>
      </CardConnectionS>
      <div className='d-flex mt-4'>
        <div>
          <Button onClick={() => history.goBack()} color='gray'>{intl('Voltar')}</Button>
        </div>
        <div className='ms-2'>
          <Button onClick={update}>{intl('Salvar alterações')}</Button>
        </div>
      </div>
    </TemplateDefault>
  )
}

const ConnectionsShowWithFormConnectionProvider = (props: ConnectionsPropsI) => {
  return (
    <ConnectionFormContextProvider>
      <ConnectionsShow {...props} />
    </ConnectionFormContextProvider>
  )
}

export default ConnectionsShowWithFormConnectionProvider
