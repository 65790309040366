import styled, { css } from 'styled-components/macro'

export const RootS = styled.div`
  width: 100%;
  height: var(--base-app-bar-height);

  z-index: 100;

  position: fixed;
  /* top: 0; */
  /* left: 0; */

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 26px;

  background-color: ${props => props.theme.colors.white};

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`

export const MenuS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 24px;

  p {
    ${(props) => css(props.theme.fonts.types.subtitle2)}
  }

  img {
    width: 24px;
  }

  .logout {
    width: 24px;
  }
`
