import styled, { css } from 'styled-components/macro'

import Card from 'Components/Card'
import Tag from 'Components/Tag'
import Text from 'Components/Text'

export const WrapperActionsHeaderS = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .icon-platform {
    width: 46px;
    height: 46px;

    svg {
      width: 46px;
      height: 46px;
    }
  }
`

export const WrapperPlatformLogoS = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin-right: 16px;

  label {
    ${({ theme }) => css(theme.fonts.types.subtitle3)}
  }

  span {
    ${({ theme }) => css(theme.fonts.types.caption2)}
    white-space: nowrap;
  }
`

export const HeaderS = styled.div`
  display: flex;
  flex-direction: row;

  padding: var(--space-content-box);
  padding-top: 0;

  ${Text} {
    width: 16.6%;

    display: block;
  }
`

export const TagsS = styled.div`
  display: flex;
  flex-flow: row wrap;

  margin-bottom: 15px;

  ${Tag} {
    margin-right: 5px;
  }
`

export const CardSingleS = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FilterS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  margin-right: 10px;
`

export const WrapperLogsS = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

export const ListLogsS = styled.div`
  max-height: 800px;
  overflow: auto;
  width: 20%;
  flex: 1;
`

export const DetailedLogS = styled.div`
  flex: 3;
`

export const WrapperSearchAdvancedS = styled.div`
  padding: 0 44px;
`

interface HeaderLogPropsI {
  isSelected: boolean
}

export const HeaderLogS = styled.div<HeaderLogPropsI>`
  height: 64px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 20px 44px;

  ${({ isSelected }) => {
    if (isSelected) {
      return css({
        backgroundColor: '#EBFCFF'
      })
    }
  }}

  label {
    ${({ theme }) => css(theme.fonts.types.subtitle2)}
  }

  p {
    ${({ theme }) => css(theme.fonts.types.caption2)}
  }
`

export const HeaderLogTextS = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperLogDetailedS = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 55px;
`

interface StatusLogFlagPropsI {
  hasError: boolean
}
export const StatusLogFlag = styled.div<StatusLogFlagPropsI>`
  width: 24px;
  height: 24px;
  border-radius: 50%;

  background-color: ${({ hasError, theme }) => hasError ? theme.colors.error : theme.colors.success};

`
