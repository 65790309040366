import React from 'react'
import ContentLoader from 'react-content-loader'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

const ListRoutinesLoader = (props: any) => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 400 240"
      backgroundColor="#d9d9d9"
      foregroundColor="#ededed"
      {...passAlongMicroData(props)}
    >
      <rect x="0" y="0" rx="4" ry="4" width="400" height="20" />
      <rect x="0" y="25" rx="4" ry="4" width="400" height="20" />
      <rect x="0" y="50" rx="4" ry="4" width="400" height="20" />
      <rect x="0" y="75" rx="4" ry="4" width="400" height="20" />
    </ContentLoader>
  )
}

export default ListRoutinesLoader
