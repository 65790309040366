import styled from 'styled-components/macro'

import IconPlatform from 'Components/IconPlatform'

export const ItemS = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  ${IconPlatform} {
    margin-right: 10px;
  }
`
