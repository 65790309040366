import styled, { css } from 'styled-components'

export const getTextAreaStyled = ({ theme, error } : any) => css`
  width: 100%;

  padding: ${theme.metrics.space1} ${theme.metrics.space5};

  ${props => css(props.theme.fonts.types.h4)};

  background-color: white;
  border: 1px solid ${error ? theme.colors.error : theme.colors.gray};
  border-radius: ${theme.metrics.radius};

  line-height: 32px;

  ::placeholder {
    font-weight: 800;
    color: ${theme.colors.gray80};
    opacity: 1;
  }

  @media (max-width: 768px) {
    ${props => css(props.theme.fonts.types.subtitle1)};
  }
`

const TextAreaStyled = styled('textarea')(getTextAreaStyled)

function TextArea (props:any) {
  return <TextAreaStyled {...props} />
}

export default TextArea
