import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import IconSVG from 'Components/IconSVG'
import Text from 'Components/Text'

interface RootStyledI {
  isActive?: boolean;
}

export const LinkS = styled(Link)<RootStyledI>`
  width: 100%;
  padding: 20px 0px;
  margin-top: 10px;

  display: flex;
  align-items: center;
  border-radius: 16px;

  text-decoration: none;

  background-color: ${({ theme, isActive }) => isActive ? theme.colors.white_hover : 'transparent'};

  transition: background-color 0.5s;

  color: ${(props) => props.theme.colors.dark};
`

// @TODO: rever esses width
interface WrapperIconI {
  isActive: boolean;
}

export const WrapperIconS = styled.div<WrapperIconI>`
  width: calc(var(--base-menu-width) - 0.7rem);

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  ${IconSVG} {
    fill: ${(props) => props.theme.colors.dark};
  }
`

interface TextI {
  isActive: boolean;
}

export const TextS = styled(Text)<TextI>`
  color: ${(props) => props.theme.colors.dark};
  white-space: nowrap;
  font-weight: ${({ isActive }) => isActive ? '700' : '500'};
  cursor: pointer;

  margin-left: 0.2rem;

  transition: font-weight 0.2s ease-in;
`
