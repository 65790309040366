import enUs from '../../translates/en-US.json'
import ptBr from '../../translates/pt-BR.json'

export default function Translate (currentTranslates: string) {
  switch (currentTranslates) {
    case 'pt-BR':
      return ptBr
    case 'pt':
      return ptBr
    case 'en-US':
      return enUs
    case 'en':
      return enUs
    default:
      return ptBr
  }
}
