
const INITIAL_STATE = {
  items: []
}

interface ActionInterface {
  type: string,
  payload: any
}

function AlertsReducer (state = INITIAL_STATE, action:ActionInterface) {
  switch (action.type) {
    case 'SET_ITEM_ALERT':
      return {
        ...state,
        items: [...state.items, action.payload]
      }
    case 'REMOVE_ITEM_ALERT':
      return {
        ...state,
        items: state.items.filter((item, key) => key !== action.payload)
      }
    default:
      return state
  }
}

export default AlertsReducer
