import { useState } from 'react'
import { useIntl } from 'react-intl'

import type { CompletePaymentContentManagerI } from 'ActionsContentManager/CompletePayment/Hooks/useCompletePaymentContentManager'
import { Button } from 'DesignSystem'

import usePlans from 'Hooks/API/usePlans.hook'
import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

const CompletePaymentModal = (props: AuxStepContentManagerI<CompletePaymentContentManagerI>) => {
  const intl = useIntlShort()
  const intlUtil = useIntl()
  const { dataContentManager, close } = props
  const { actions: { signAPlan } } = usePlans()
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePlans = () => {
    dataContentManager.onChangePlans && dataContentManager.onChangePlans()
    close()
  }

  const handleCompletePayment = () => {
    try {
      if (!dataContentManager.pricePreChosen) return
      setIsLoading(true)
      signAPlan(dataContentManager.pricePreChosen)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  const paramsIntl = {
    planName: dataContentManager.planName,
    interval: intl(dataContentManager.interval || ''),
    price: intlUtil.formatNumber(dataContentManager.amount || 0, {
      style: 'currency',
      currency: dataContentManager?.currency || 'BRL',
      minimumFractionDigits: 2
    })
  }

  return (
    <div>
      <h3 className='fw-bold text-center'>{intl('Antes de começarmos...')}</h3>
      <p className='mt-3' dangerouslySetInnerHTML={{ __html: intl('Para iniciar sua avaliação gratuita do plano “Nome do Plano”, é necessário preencher os dados para pagamento. Dessa forma, quando seu período de avaliação for concluído, teremos tudo pronto para você continuar utilizando o NeoDash.', paramsIntl) }} />
      <p className='mt-3' dangerouslySetInnerHTML={{ __html: intl('Lembramos que você pode cancelar sua assinatura a qualquer instante acessando a opção "Meu plano" no menu superior.') }}></p>
      <div className='d-flex justify-content-center mt-3 gap-3'>
        <Button color="white" onClick={handleChangePlans} >{intl('Alterar o plano de teste')}</Button>
        <Button isLoading={isLoading} color="primary" onClick={handleCompletePayment} >{intl('Preencher os dados')}</Button>
      </div>
    </div>
  )
}

CompletePaymentModal.dialogProps = {
  style: {
    maxWidth: '500px'
  }
}

export default CompletePaymentModal
