import React from 'react'

import { Select } from 'DesignSystem'

import type { DataCompanyCreateContentManagerI } from 'Components/FirstSetupFlow'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

type NameCompanyCreateDialogPropsI =
  AuxStepContentManagerI<DataCompanyCreateContentManagerI>;

const options = [
  { value: 'BRL', content: 'Real', symbol: 'R$' },
  { value: 'USD', content: 'Dólar', symbol: '$' },
  { value: 'EUR', content: 'Euro', symbol: '€' },
  { value: 'CHF', content: 'Franco suiço', symbol: 'CHF' },
  { value: 'INR', content: 'Rupia Indiana', symbol: '₹' },
  { value: 'GBP', content: 'Libra Esterlina', symbol: '£' },
  { value: 'CAD', content: 'Dólar Canadense', symbol: 'CA$' },
  { value: 'AED', content: 'Dirham dos Emirados Árabes Unidos', symbol: 'AED' },
  { value: 'MXN', content: 'Peso Mexicano', symbol: '$' },
  { value: 'CLP', content: 'Peso Chileno', symbol: '$' },
  { value: 'UYU', content: 'Peso Uruguaio', symbol: '$' },
  { value: 'ARS', content: 'Peso Argentino', symbol: '$' },
  { value: 'COP', content: 'Peso Colombiano', symbol: '$' },
  { value: 'CRC', content: 'Colón costa-riquenho', symbol: '₡' },
  { value: 'PAB', content: 'Balboa Panamenho', symbol: 'B/.' },
  { value: 'PEN', content: 'Novo Sol Peruano', symbol: 'S/.' },
  { value: 'AUD', content: 'Dólar Australiano', symbol: '$' }
]

const CurrencyWorkspaceField = ({
  setData,
  dataContentManager
}: NameCompanyCreateDialogPropsI) => {
  const { currency_read } = dataContentManager
  const intl = useIntlShort()

  return (
    <Select
      className="mt-2"
      value={currency_read}
      onChange={({ target }: any) => setData({ currency_read: target.value })}
    >
      {options.map((opt, key) => (
        <option value={opt.value} key={key}>
          {intl(opt.content)}
        </option>
      ))}
    </Select>
  )
}

export default CurrencyWorkspaceField
