import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { Button, Col, Row } from 'DesignSystem'

function Welcome ({ setSizeDialog, setShowFooter, next }: any) {
  const intl = useIntl()
  useEffect(() => {
    setSizeDialog('sm')
    setShowFooter(false)
  }, [setSizeDialog])

  return (
    <Row className="text-center flex-column">
      <Col>
        <img src="/images/isotype.png" width={32} alt="Logo" />
      </Col>
      <Col className="mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Te desejamos as boas-vindas!' })}</h2>
      </Col>
      <Col className="mt-3">
        <p className="body1">
          {intl.formatMessage({ id: 'Agora você faz parte do NeoDash. Antes de começar a utilizar a plataforma, vamos criar seu primeiro Workspace, onde você poderá conectar seus dados de mídias digitais e analisar seus resultados.' })}
        </p>
        <p className="mt-5">{intl.formatMessage({ id: 'Para começar, clique no botão abaixo.' })}</p>
      </Col>
      <Col className="mt-5">
        <Button onClick={next}>{intl.formatMessage({ id: 'Vamos lá' })}</Button>
      </Col>
    </Row>
  )
}

Welcome.noDisplayCloseButton = true

export default Welcome
