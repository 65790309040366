import React, { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'

import useOutsideClick from 'Hooks/useOutsideClick.hook'

import type { RootI } from './ColorField.styled'
import { BadgeColorS, PickerS, RootS, WrapperInnerS } from './ColorField.styled'

interface ColorFieldPropsI {
  size?: RootI['size']
  placeholder?: string,
  onChange?: (color: string) => void,
  value?: string,
}

const ColorField = (props: ColorFieldPropsI) => {
  // @TODO: remove this hard-code color to some file about config
  const { placeholder, value = '#BBC6FF', onChange = () => undefined, size = 'normal' } = props
  const [isOpen, setIsOpen] = useState(false)
  const [timeoutPicker, setTimeoutPicker] = useState(false)
  const colorPickerRef = useRef(null)

  useOutsideClick(colorPickerRef, () => {
    if (timeoutPicker && isOpen) {
      setTimeoutPicker(false)
      setIsOpen(false)
    }
  })

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setTimeoutPicker(true)
      }, 500)
    }
  }, [isOpen])

  return (
    <RootS size={size}>
      <WrapperInnerS className='p-3' size={size} onClick={() => setIsOpen(true)}>
        <BadgeColorS color={value} />
        <label className='subtitle2'>{placeholder}</label>
      </WrapperInnerS>
      {isOpen
        ? <PickerS ref={colorPickerRef}>
            <SketchPicker color={value} onChangeComplete={(color) => onChange(color.hex)} />
          </PickerS>
        : null}
    </RootS>
  )
}

export default ColorField
