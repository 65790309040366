import React from 'react'

import { ContentS, HeaderS, RootS } from './CardContentLarge.styled'

interface CardContentLargePropsI {
  header?: React.ReactNode
  children?: React.ReactNode
  style?: React.CSSProperties,
  withoutPaddingOnSide?: boolean,
}

const CardContentLarge = (props: CardContentLargePropsI) => {
  const { header, children, style, withoutPaddingOnSide = false } = props

  return (
    <RootS style={style}>
      {header ? (
        <HeaderS>
          {header}
        </HeaderS>
      ) : null}
      <ContentS withoutPaddingOnSide={withoutPaddingOnSide}>
        {children}
      </ContentS>

    </RootS>
  )
}

export default CardContentLarge
