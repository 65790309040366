import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import useQuery from 'Hooks/useQuery.hook'

import { apiPOST } from 'Services/Api.service'

import { generateLinkTo } from 'Helpers/Route.helper'

const Token = () => {
  const query = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const token = query.get('v')
  const is_signup = query.get('is_signup') === 'true'

  const verifyLogin = async () => {
    const response: any = await apiPOST('login-with-user', undefined, {
      token: token
    })

    localStorage.setItem('odash-app:user_info', JSON.stringify(response))
    dispatch({ type: 'USER_DATA', payload: { token, ...response } })

    history.replace(`${generateLinkTo('home')}${is_signup ? '?is_signup=true' : ''}`)
  }

  useEffect(() => {
    if (token) {
      verifyLogin()
    } else {
      history.replace(generateLinkTo('login'))
    }
  }, [])

  return null
}

export default Token
