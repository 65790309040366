import React from 'react'
import ReactDOM from 'react-dom'
import { useIntl } from 'react-intl'

import useUser from 'Hooks/API/useUser.hook'

import { generateLinkTo, getPath, getPaths } from 'Helpers/Route.helper'

import type { ItemMenuPropsI } from './Components/ItemMenu.component'
import ItemMenu from './Components/ItemMenu.component'
import { ContentS, MenuWrapperS, RootS } from './GlobalMenu.styled'

interface GlobalMenuPropsI {
  children?: React.ReactNode
}

const GlobalMenu = (props: GlobalMenuPropsI) => {
  const intl = useIntl()
  // const history = useHistory()
  const { data: dataUser } = useUser()

  const confMenu: Array<ItemMenuPropsI> = [{
    path: getPaths(['home', 'companyEdit', 'companyCreateStep', 'companies']),
    icon: 'company',
    name: intl.formatMessage({ id: 'workspaces' }),
    linkTo: generateLinkTo('home')
  }, {
    path: getPath('connections'),
    icon: 'connection',
    name: intl.formatMessage({ id: 'connections' }),
    linkTo: generateLinkTo('connections')
  }, {
    path: getPath('routines'),
    icon: 'routines',
    name: intl.formatMessage({ id: 'routines' }),
    linkTo: generateLinkTo('routines')
  }]
  // }, {
  //   icon: <Archive size={24} color='black' />,
  //   name: intl.formatMessage({ id: 'Arquivados' }),
  //   linkTo: generateLinkTo('archiveds')
  // }]

  // const handleGoToHome = () => {
  //   history.push(generateLinkTo('home'))
  // }

  /**
   * Only for test
   */
  const confMenuDynamic : typeof confMenu = dataUser?.is_admin ? [...confMenu] : confMenu

  // const confMenuDynamic : typeof confMenu = dataUser?.is_admin ? [{
  //   path: getPaths(['superAdmin']),
  //   icon: 'admin',
  //   name: 'Super Admin',Context
  //   linkTo: generateLinkTo('superAdmin')
  // }, ...confMenu] : confMenu

  return (
    <>
    <RootS>
      <MenuWrapperS>
        {confMenuDynamic.map((conf, index) => (
          <ItemMenu key={`${conf.icon}${index}`} {...conf} />
        ))}
      </MenuWrapperS>
      <ContentS>
        {props.children}
      </ContentS>
    </RootS>
    {ReactDOM.createPortal(
        <RootS className="menu-mobile">
          <MenuWrapperS>
            {confMenuDynamic.map((conf, index) => (
              <ItemMenu key={`${conf.icon}${index}`} {...conf} />
            ))}
          </MenuWrapperS>
        </RootS>
        , document.getElementById('root') || document.createElement('div')
    )}
    </>
  )
}

export default GlobalMenu
