
import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'

import { Button, Col, InputPassword, Row } from 'DesignSystem'
import type formatMessageInterface from 'Interfaces/formatmessage.interface'
import AuthLayout from 'Layouts/AuthLayout'
import { isEmpty } from 'lodash'

import { addAlert } from 'Store/Actions/alerts.action'
import { saveNewPassword } from 'Store/Actions/User/password.action'

import Image from 'Components/Image'

import useQuery from 'Hooks/useQuery.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

interface paramsInterface {
  email: string,
  recoveryCode: string
}

function ResetPassword (props:formatMessageInterface) {
  const [loading, setLoading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [repeat, setRepeat] = useState<string>('')
  const dispatch = useDispatch()
  const params:paramsInterface = useParams()
  const history = useHistory()
  const intl = useIntl()
  const location = useLocation()
  const query = useQuery()
  const slug = query.get('slug')

  function clearState () {
    setPassword('')
    setRepeat('')
  }

  function redirectPage () {
    if (slug) {
      history.push(`${generateLinkTo('odashLogin')}?slug=${slug}`)
    } else {
      history.push(`/login/${location.search}`)
    }
  }

  function validFields () {
    if (!isEmpty(password) && !isEmpty(repeat)) {
      return true
    } else {
      dispatch(
        addAlert({ title: 'Preencha os campos por favor!', type: 'error' })
      )
    }
  }

  function validPasswords () {
    if (password === repeat) {
      return true
    } else {
      dispatch(
        addAlert({ title: 'Os campos devem ser exatamente iguais!', type: 'error' })
      )
    }
  }

  async function requestAPI () {
    setLoading(true)
    try {
      const { email, recoveryCode } = params
      const resAction = await saveNewPassword({ newPassword: password, email, recoveryCode })
      dispatch(resAction)
      setLoading(false)

      if (resAction?.payload?.type !== 'error') {
        clearState()
        redirectPage()
      }
    } catch (e:any) {
      dispatch(
        addAlert({ title: e.message, type: 'error' })
      )
    }
  }

  async function resetingPassword () {
    if (validFields()) {
      if (validPasswords()) {
        await requestAPI()
      }
    }
  }

  useEffect(() => {
    return () => {
      clearState()
    }
  }, [])

  return (
    <AuthLayout>
      <Row className="flex-column align-items-center my-auto text-center">
        <Col className="mt-5" xs={12} md={11} lg={9} xl={7} xxl={6}>
          <Image
            src="/images/logos/logo.png"
            alt="Logo"
            style={{ maxWidth: '100%' }}
          />
        </Col>
        <Col className="mt-4" xs={12} md={11} lg={9} xl={7} xxl={6}>
          <h1 className="fw-bold">{intl.formatMessage({ id: 'Redefina a sua senha' })}</h1>
        </Col>
        <Col className="mt-5" xs={12} md={11} lg={9} xl={7} xxl={6}>
          <form noValidate autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
          <InputPassword
              className="mt-3"
              placeholder={intl.formatMessage({ id: 'Digite sua nova senha' })}
              value={password}
              onChange={(event: any) => setPassword(event?.target?.value)}
            />
                        <InputPassword
              className="mt-3"
              placeholder={intl.formatMessage({ id: 'Repita sua nova senha' })}
              value={repeat}
              onChange={(event: any) => setRepeat(event?.target?.value)}
            />
            <div className="d-grid mt-3">
              <Button color="primary" disabled={loading} onClick={() => resetingPassword()}>
                {intl.formatMessage({ id: 'Salvar nova senha?' })}
              </Button>
            </div>
            <div className="d-grid mt-3">
              <Link to={generateLinkTo('login')}>
                <FormattedMessage id="Voltar para início"/>
              </Link>
            </div>
          </form>
        </Col>
      </Row>
    </AuthLayout>
  )
}

export default injectIntl(ResetPassword)
