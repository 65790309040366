import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Col, Input, InputPassword, Row } from 'DesignSystem'
import isEmpty from 'lodash/isEmpty'

import { addAlert } from 'Store/Actions/alerts.action'
import { authUser } from 'Store/Actions/User/auth.action'
import { recoverPassword } from 'Store/Actions/User/password.action'
import { createUser } from 'Store/Actions/User/users.action'

import ListButtonFederatedLogin from 'Components/ListButtonFederatedLogin'
import LoadingDefault from 'Components/Loadings/default.component'
import { LocaleContext } from 'Components/Locale'

import useCheckInvite from 'Hooks/API/useCheckInvite.hook'
import useCompanySoft from 'Hooks/API/useCompanySoft.hook'
import useQuery from 'Hooks/useQuery.hook'

import { getContrastYIQ } from 'Helpers/Colors.helpers'
import { generateLinkTo } from 'Helpers/Route.helper'

import { FormS, LinkS, RootS } from './LoginOdash.styled'

const FEDERATED_ACCEPT = ['email', 'google', 'amazon', 'microsoft', 'facebook']

const LoginOdash = () => {
  const query = useQuery()
  const location = useLocation()
  const { invite } = useParams<{invite?: string}>()
  const history = useHistory()
  const dispatch = useDispatch()
  const intl = useIntl()
  const refEmail = useRef<HTMLInputElement>(null)
  const refPassword = useRef<HTMLInputElement>(null)
  const refName = useRef<HTMLInputElement>(null)
  const user = useSelector((state:any) => state.user)
  const slug = query.get('slug')
  const federated = query.get('federated')
  const locale = query.get('locale')
  const isFederated = !!federated && FEDERATED_ACCEPT.some(v => v === federated)
  const isAdmin = query.get('admin') === 'true'
  const isResetPassword = !!useRouteMatch(generateLinkTo('odashForgotPassword'))
  const isInvite = !!useRouteMatch(generateLinkTo('odashInvite'))
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [name, setName] = useState<string>('')
  const { setLocale } = useContext(LocaleContext)
  const { data: companyData, isLoading: isLoadingCompanyData } = useCompanySoft({
    slug: slug || ''
  })
  const { data: inviteData, isLoading: isLoadingInvite } = useCheckInvite({
    invite: invite ?? null
  })

  useEffect(() => {
    setLocale(locale || companyData?.locale || 'pt-BR')
  }, [companyData?.locale])

  const clearState = (clearLoading = true) => {
    setPassword('')
    setName('')
    if (clearLoading) {
      setLoading(false)
    }
  }

  const validFieldsLogin = () => {
    if (!isEmpty(email) && !isEmpty(password)) {
      return true
    } else {
      dispatch(
        addAlert({
          title: 'Preencha os campos corretamente!',
          type: 'error'
        })
      )
    }
  }

  const validFieldsForgotPassword = () => {
    if (!isEmpty(email)) {
      return true
    } else {
      dispatch(
        addAlert({
          title: 'Preencha os campos corretamente!',
          type: 'error'
        })
      )
    }
  }

  const validFieldsRegister = () => {
    if (!isEmpty(email) && !isEmpty(password) && !isEmpty(name)) {
      return true
    } else {
      dispatch(
        addAlert({
          title: 'Preencha os campos corretamente!',
          type: 'error'
        })
      )
    }
  }

  const requestLogin = async () => {
    setLoading(true)

    try {
      dispatch(await authUser({ email, password, slug: (slug || undefined), isAdmin }))
      clearState(false)
    } catch (e:any) {
      dispatch(addAlert({ title: e.message, type: 'error' }))
      setLoading(false)
    }
  }

  const requestRecover = async () => {
    setLoading(true)

    try {
      const resAction = await recoverPassword(email, slug || undefined)
      dispatch(resAction)

      if (resAction?.payload?.type !== 'error') {
        history.push(`${generateLinkTo('odashLogin')}${location.search}`)
      }
    } catch (e:any) {
      dispatch(addAlert({ title: e.message, type: 'error' }))
    } finally {
      setLoading(false)
    }
  }

  const requestRegister = async () => {
    setLoading(true)

    try {
      const resAction = await createUser({
        email,
        password,
        name,
        invite: isInvite ? invite : undefined,
        slug: isInvite && slug ? slug : undefined
      })
      dispatch(resAction)

      if (resAction && 'payload' in resAction && resAction?.payload?.type !== 'error') {
        history.push(`${generateLinkTo('odashLogin')}${location.search}`)
      }
    } catch (e:any) {
      setLoading(false)
      dispatch(
        addAlert({ title: e.message, type: 'error' })
      )
    }
  }

  const login = async (e: any) => {
    e.preventDefault()
    if (validFieldsLogin()) {
      await requestLogin()
    }
  }

  const forgotPassword = async (event: any) => {
    event.preventDefault()
    if (validFieldsForgotPassword()) {
      await requestRecover()
    }
  }

  const register = async (event: any) => {
    event.preventDefault()
    if (validFieldsRegister()) {
      await requestRegister()
    }
  }

  const handleClickRemoveFederated = async (event: any) => {
    event.preventDefault()
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('federated')) {
      queryParams.delete('federated')
      history.replace({
        search: queryParams.toString()
      })
    }
  }

  const getStylePropsButton = () => (
    { backgroundColor: companyData?.color, color: getContrastYIQ(companyData?.color || '') }
  )

  /**
   * Se for tela de recuperar senha, já foca no campo de e-mails
   */
  useEffect(() => {
    refEmail.current?.focus()

    return () => {
      clearState()
    }
  }, [isResetPassword])

  useEffect(() => {
    refName.current?.focus()

    if (isInvite && (!invite || (!isLoadingInvite && inviteData?.status !== 'success'))) {
      dispatch(
        addAlert({
          title: intl.formatMessage({ id: 'Convite inválido!' }),
          type: 'error'
        })
      )
      history.push(`${generateLinkTo('odashLogin')}${location.search}`)
    }

    if (!isLoadingInvite && inviteData?.status === 'success' && inviteData?.email) {
      setEmail(inviteData.email)
    }
  }, [isInvite, isLoadingInvite, inviteData])

  useEffect(() => {
    if (!isEmpty(user.data)) {
      window.localStorage.clear()
      dispatch({ type: 'USER_CLEAR' })
    }
  }, [])

  useEffect(() => {
    if (!slug || (!isLoadingCompanyData && !companyData)) {
      history.push('/')
    }
  }, [slug, companyData, isLoadingCompanyData])

  const view = (() => {
    switch (true) {
      case isResetPassword:
        return (
          loading
            ? <LoadingDefault/> : (
            <form noValidate autoComplete='off' onSubmit={forgotPassword}>
              <p className='text-center'>{intl.formatMessage({ id: 'Insira o e-mail utilizado para acessar seu NeoDash:' })}</p>
              <Input
                className="mt-3"
                placeholder={intl.formatMessage({ id: 'Digite aqui seu e-mail' })}
                value={email}
                onChange={(event: any) => setEmail(event?.target?.value)}
              />
              <Row className="mt-3 w-100">
                <Col className='ps-0'>
                  <Link to={`${generateLinkTo('odashLogin')}${location.search}`}>
                    <Button className='w-100' color="gray" href={generateLinkTo('login')}>
                      <FormattedMessage id="Voltar"/>
                    </Button>
                  </Link>
                </Col>
                <Col className='d-grid pe-0'>
                <Button isLoading={loading} color="primary" style={getStylePropsButton()}>
                  {intl.formatMessage({ id: 'Continuar' })}
                </Button>
                </Col>
              </Row>
            </form>
            )
        )
      case isInvite:
        return (
          (loading || isLoadingInvite)
            ? <LoadingDefault />
            : ((inviteData?.platform !== 'all' && inviteData?.platform !== 'email' && inviteData?.platform !== null) ? null : (
                <form noValidate autoComplete='off' onSubmit={register}>
                  <div className='mb-3'>
                    <Input
                      id="name"
                      type="text"
                      tabIndex={1}
                      ref={refName}
                      placeholder={intl.formatMessage({ id: 'Seu nome' })}
                      value={name}
                      onChange={(event: any) => setName(event?.target?.value)} />
                    </div>
                  <div className='mb-3'>
                    <Input
                      id="email"
                      type="email"
                      tabIndex={2}
                      ref={refEmail}
                      placeholder={intl.formatMessage({ id: 'Seu e-mail' })}
                      value={email}
                      readOnly={true}
                      onChange={(event: any) => setEmail(event?.target?.value)} />
                  </div>
                  <div className='mb-3'>
                    <Input
                      id="password"
                      type="password"
                      tabIndex={3}
                      ref={refPassword}
                      placeholder={intl.formatMessage({ id: 'Sua senha' })}
                      value={password}
                      onChange={(event: any) => setPassword(event?.target?.value)} />
                  </div>
                  <div className="d-grid">
                      <Button color="primary" style={getStylePropsButton()} disabled={loading}>
                        {intl.formatMessage({ id: 'Registrar' })}
                      </Button>
                    </div>
                </form>
              ))
        )
      default:
        return (
          loading
            ? <LoadingDefault/>
            : (isFederated && federated !== 'email' ? null
                : (
                  <form noValidate autoComplete='off' onSubmit={login}>
                    <Input
                      placeholder={intl.formatMessage({ id: 'E-mail' })}
                      value={email}
                      onChange={(event: any) => setEmail(event?.target?.value)}
                    />
                    <InputPassword
                      className="mt-3"
                      placeholder={intl.formatMessage({ id: 'Senha' })}
                      value={password}
                      onChange={(event: any) => setPassword(event?.target?.value)}
                    />
                    <div className="d-flex my-3 align-items-center">
                      {/* <Col>
                        <Switch>
                          <label className="body2 ms-2 fw-bold">{intl.formatMessage({ id: 'Lembre de mim' })}</label>
                        </Switch>
                      </Col> */}
                      <Col className="text-end">
                        <Link
                          className="body2 fw-bold text-decoration-none text-black"
                          to={`${generateLinkTo('odashForgotPassword')}${location.search}`}
                        >
                          {intl.formatMessage({ id: 'Esqueci minha senha' })}
                        </Link>
                      </Col>
                    </div>
                    <div className="d-grid">
                      <Button color="primary" style={getStylePropsButton()} disabled={loading}>
                        {intl.formatMessage({ id: 'Login' })}
                      </Button>
                    </div>
                  </form>
                  ))
        )
    }
  })()

  if (isLoadingCompanyData || !isEmpty(user.data)) {
    return null
  }

  return (
    <RootS color={companyData?.color}>
      <FormS>
        <div>
          <img className='w-100' src={companyData?.icon} alt='images' />
        </div>
        <h2 className='fw-bold my-3' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'dashboard.title' }) }} />
        {view}
        <ListButtonFederatedLogin
          only={isInvite && (inviteData?.platform !== 'all' && inviteData?.platform !== null) ? inviteData?.platform : (isFederated ? federated : null)}
          slug={slug}
          isSignUp={inviteData?.isMigration ? false : isInvite}
          isMigration={inviteData?.isMigration}
          invite={isInvite ? invite : undefined}
          isAdmin={isAdmin}
          color={companyData?.color}
          hasOr={!((isInvite && (inviteData?.platform !== 'all' && inviteData?.platform !== null)) || (isFederated))} />

          {isFederated &&
            <LinkS to="#" onClick={handleClickRemoveFederated} >
              <FormattedMessage id="Logar de outra forma"/>
            </LinkS>}
        <img className='mt-4' src='/images/PBBND.svg' width={120} alt='Neodash' />
      </FormS>
    </RootS>
  )
}

export default LoginOdash
