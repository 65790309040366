import React from 'react'
import ReactDOM from 'react-dom'

import { RootS } from './Support.styled'

const Support = () => {
  return (
    ReactDOM.createPortal(
      <RootS id="botao_suporte" className='support' target="_blank" href="https://wiki.neodash.ai/submit-a-ticket/">
        <img src="/images/icon-headset.svg" alt="Suporte" />
      </RootS>
      , document.getElementById('root') || document.createElement('div'))
  )
}

export default Support
