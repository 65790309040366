import React, { useState } from 'react'

import { RootS } from './CheckboxFilter.styled'

interface CheckboxFilterPropsI {
  label: string,
  checked?: boolean,
  onChange?: (check: boolean) => void
}

const CheckboxFilter = (props: CheckboxFilterPropsI) => {
  const { label, onChange = () => undefined, checked } = props
  const [localChecked, setLocalChecked] = useState<boolean>(checked ?? false)

  const handleOnChange = () => {
    const newValue = !localChecked
    setLocalChecked(newValue)
    onChange(newValue)
  }

  return (
    <RootS>
      <input type='checkbox' checked={checked} onChange={handleOnChange} />
      <label>{label}</label>
    </RootS>
  )
}

export default CheckboxFilter
