import { CaretDown, CaretUp, CheckCircle, Clock, Info, PencilSimple, Warning, XCircle } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { useCancelPlanContentManager } from 'ActionsContentManager/CancelPlan'
import type { DataStartDowngradePlanContentManagerI } from 'ActionsContentManager/DowngradePlan/Hooks/useStartDowngradePlan.hook'
import { useStartSignPlanContentManager } from 'ActionsContentManager/SignPlan'
import { format, parseISO } from 'date-fns'
import { Button } from 'DesignSystem'
import useSWR from 'swr'
import theme from 'Theme'

import { addAlert } from 'Store/Actions/alerts.action'

import CardContentLarge from 'Components/CardContentLarge'
import IconCardBrand from 'Components/IconCardBrand'
import type { IconSVGPropsI } from 'Components/IconSVG'
import IconSVG from 'Components/IconSVG'
import TemplateDefault from 'Components/TemplateDefault'

import { fetcher } from 'Hooks/API/fetcher'
import useUser from 'Hooks/API/useUser.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { MoreInformationAboutSubscriotionS, PlanSubscriptionCanceledS, StatusChangePlanS, StatusSubscriptionCanceledS, SubscriptionInfoS } from './Subscription.styled'

export interface SubscriptionResponseI {
  plan?: any | null,
  invoicesPending: Array<{
    id: string,
    subscription: string,
    next_payment_attempt: string,
    customer: string,
    period_start: string,
    period_end: string,
    status: string,
    amount_due: number,
  }>
}

const Subscription = () => {
  const intl = useIntlShort()
  const intlUtils = useIntl()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [moreInfoIsOpen, setMoreInfoIsOpen] = useState<boolean>(false)
  const { data: dataUser, isLoading: isLoadingUser } = useUser()
  const { start, startDowngrade, content } = useStartSignPlanContentManager()
  const { start: startCancelPlan, content: contentCancelPlan } = useCancelPlanContentManager({
    onWantChangePlan: (initialData: DataStartDowngradePlanContentManagerI) => {
      startDowngrade(initialData)
    },
    onWantChoosePlan: () => {
      start()
    },
    onMutateSubscription: () => {
      mutateSubscription()
    }
  })

  const { error, data: dataSubscription, mutate: mutateSubscription } = useSWR<SubscriptionResponseI>('/subscription', fetcher('GET'))
  const isLoadingData = (isLoadingUser || dataUser?.subscription?.status === 'pending') || (!error && !dataSubscription)

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild()
    }, 500)
  }, [isLoadingData])

  const cancelSubscriptioHandle = async () => {
    startCancelPlan({
      is_trialing: dataSubscription?.plan?.subscription?.status === 'trialing'
    })
  }

  const changePayment = async () => {
    setIsLoading(true)
    const response : any = await fetcher('POST')('/subscription/payment/change')

    if (response.url) {
      window.location.href = response.url
    } else {
      dispatch(
        addAlert({
          title: intl('Assinatura atualizada'),
          type: 'success',
          content: intl('Sua assinatura foi atualizada com sucesso!')
        })
      )
      mutateSubscription()
      setIsLoading(false)
    }
  }

  const changePlan = async () => {
    start({ planID: dataSubscription?.plan?.id })
  }

  const reactivateSubscription = async () => {
    setIsLoading(true)
    await fetcher('POST')('/subscription/reactive')
    mutateSubscription()
    dispatch(
      addAlert({
        title: intl('Assinatura reativada'),
        type: 'success',
        content: intl('Sua assinatura foi reativada com sucesso')
      })
    )
    setIsLoading(false)
  }

  const keepCurrentPlan = async () => {
    setIsLoading(true)
    await fetcher('POST')('/subscription/downgrade/cancel')
    mutateSubscription()
    dispatch(
      addAlert({
        title: intl('Downgrade cancelado'),
        type: 'success',
        content: intl('Seu downgrade foi cancelada com sucesso')
      })
    )
    setIsLoading(false)
  }

  const iconStatus = (() => {
    if (!dataSubscription?.plan?.subscription) {
      return null
    }

    if (dataSubscription?.plan?.subscription.cancel_at_period_end) {
      return <Info size={24} color={theme.colors.alert} />
    }

    switch (dataSubscription?.plan?.subscription.status) {
      case 'past_due':
        return <Warning size={24} color='red' />
      case 'active':
        return <CheckCircle size={24} color='green' />
      case 'trialing':
        return <CheckCircle size={24} color='green' />
      case 'canceled':
        return <XCircle size={24} color='#A77600' />
    }
  })()

  const iconPlan = (() => {
    if (!dataSubscription?.plan?.subscription) {
      return null
    }

    if (dataSubscription?.plan?.subscription?.downgraded_at) {
      return <Info size={16} color={theme.colors.alert} />
    }

    return null
  })()

  if (!isLoadingData && (!dataSubscription?.plan || !dataSubscription?.plan.subscription)) {
    return (
      <TemplateDefault title={intl('Meu plano')}>
        <CardContentLarge style={{ maxWidth: '1100px' }}>
          <h3 className='fw-bold text-center'>{intl('Detalhes do plano')}</h3>
          <div className='w-100 d-flex flex-column align-items-center'>
            <p className='mt-3'>{intl('Você ainda não possui um plano do NeoDash ativo.')}</p>
            <svg className='mt-3 mb-3' xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256" fill="none">
              <path d="M128 224C181.019 224 224 181.019 224 128C224 74.9807 181.019 32 128 32C74.9807 32 32 74.9807 32 128C32 181.019 74.9807 224 128 224Z" stroke="#0A1A28" strokeWidth="8" strokeMiterlimit="10"/>
              <path d="M184 96L152 128" stroke="#0A1A28" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M184 128L152 96" stroke="#0A1A28" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M104 96L72 128" stroke="#0A1A28" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M104 128L72 96" stroke="#0A1A28" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M128 192C134.627 192 140 186.627 140 180C140 173.373 134.627 168 128 168C121.373 168 116 173.373 116 180C116 186.627 121.373 192 128 192Z" fill="#0A1A28"/>
            </svg>
            <Button onClick={() => start()}>{intl('Assinar um plano')}</Button>
          </div>
        </CardContentLarge>
        {content}
      </TemplateDefault>
    )
  }

  const loaderTitle = <ContentLoader width="100" height="30"><rect x="0" y="0" rx="5" ry="5" width="100" height="30"></rect></ContentLoader>
  const planName = dataSubscription?.plan ? dataSubscription?.plan?.name : null
  const planIsTrialing = dataSubscription?.plan ? dataSubscription?.plan?.subscription?.status === 'trialing' : false
  const planInterval = dataSubscription?.plan ? dataSubscription?.plan?.subscription?.interval : null
  const planDowngradeAt = dataSubscription?.plan ? dataSubscription?.plan?.subscription?.downgraded_at : null
  const planCancelAt = dataSubscription?.plan ? dataSubscription?.plan?.subscription?.cancel_at : null
  const planDowngradeRequestedAt = dataSubscription?.plan ? dataSubscription?.plan?.subscription?.downgrade_requested_at : null
  const planStatus = dataSubscription?.plan ? dataSubscription?.plan.subscription?.status : ''
  const planPrice = dataSubscription?.plan && dataSubscription?.plan?.subscription?.price ? intlUtils.formatNumber(dataSubscription?.plan?.subscription.price, {
    style: 'currency',
    currency: dataSubscription?.plan.subscription.currency,
    minimumFractionDigits: 2
  }) : null
  const planNextInvoice = (() => {
    try {
      return format(parseISO(dataSubscription?.plan.subscription.next_invoice), 'dd/MM/yy')
    } catch (e) {
      return intl('Sem definição')
    }
  })()
  const buttonChangePlan = planIsTrialing
    ? (
    <StatusChangePlanS>
      <Button disabled={planIsTrialing} onClick={changePlan}>{intl('Alterar plano')}</Button>
      <span className='text-support'>{intl('A alteração de plano do NeoDash estará disponível após a conclusão do período de testes, em DD/MM/AA', { date: planNextInvoice })}</span>
    </StatusChangePlanS>
      ) : (
    <Button disabled={planStatus === 'past_due' || planIsTrialing} onClick={changePlan}>{intl('Alterar plano')}</Button>
      )

  return (
    <>
      <TemplateDefault title={intl('Meu plano')}>
        <CardContentLarge style={{ maxWidth: '1100px' }}>
          <h3 className='fw-bold text-center'>{intl('Detalhes do plano')}</h3>
          <>
            <SubscriptionInfoS className='mb-4'>
              <table>
                <thead className='mb-2'>
                  <td className='fw-bold text-nowrap'>{intl('Plano')}</td>
                  <td className='fw-bold'>{intl('Status')}</td>
                  <td className='fw-bold'>{intl('Workspaces')}</td>
                  <td className='fw-bold'>{intl('Usuários')}</td>
                  <td className='fw-bold'>{intl('Valor do plano')}</td>
                  <td className='fw-bold text-nowrap'>{intl('Forma de pagamento')}</td>
                </thead>
              <tbody className='mb-2'>
                <tr>
                <td>
                  <PlanSubscriptionCanceledS className='d-flex gap-2 align-items-center justify-content-start' downgraded_at={dataSubscription?.plan?.subscription?.downgraded_at || false}>
                    {isLoadingData ? loaderTitle : <>{iconPlan} {planName} {intl(`interval.${planInterval}`)}</>}
                    {planDowngradeAt ? <span className='text-support'> {intl('A partir de data, seu plano será alterado para o plano devido à solicitação de downgrade realizada em data.', { downgraded_at: format(parseISO(planDowngradeAt), 'dd/MM/yyyy'), downgrade_request: format(parseISO(planDowngradeRequestedAt), 'dd/MM/yyyy') })}</span> : null}
                  </PlanSubscriptionCanceledS>
                </td>
                <td>
                <StatusSubscriptionCanceledS className='d-flex gap-2' cancel_at={planCancelAt}>
                  {dataUser?.subscription?.status === 'pending' ? <><Clock size={24} /></> : null}
                  {isLoadingData ? loaderTitle : <>{iconStatus} <label className='fw-bold'>{dataSubscription?.plan?.subscription ? intl(dataSubscription.plan.subscription.status) : ''}</label></>}
                  {dataSubscription && planCancelAt ? <span className='text-support'> {intl('Seu plano está ativo até data. Você poderá utilizar o sistema e os recursos disponíveis até lá.', { cancel_at: format(parseISO(dataSubscription.plan.subscription.cancel_at), 'dd/MM/yyyy') })}</span> : null}
                </StatusSubscriptionCanceledS>
                </td>
                <td className=''>{isLoadingData ? loaderTitle : <>{dataSubscription?.plan?.subscription ? (dataSubscription.plan.workspaces.count + ' ' + intl('de') + ' ' + dataSubscription.plan.workspaces.of) : 0}</>}</td>
                <td className=''>{isLoadingData ? loaderTitle : <>{dataSubscription?.plan?.subscription ? dataSubscription.plan.users : 0}</>}</td>
                <td className=''>{isLoadingData ? loaderTitle : <>{dataSubscription?.plan?.subscription ? planPrice : null}</>}</td>
                <td className='d-flex gap-2 flex-nowrap align-items-center'>{isLoadingData ? loaderTitle : <><IconCardBrand brand={dataSubscription?.plan?.subscription.default_payment_method.card} /> <span>****</span><span>{dataSubscription?.plan?.subscription ? dataSubscription?.plan?.subscription.default_payment_method.last4 : ''}</span><PencilSimple style={{ cursor: 'pointer' }} size={24} onClick={changePayment}/></>}</td>
                </tr>
                <tr>
                <td>{planCancelAt
                  ? <Button isLoading={isLoading} onClick={reactivateSubscription}>{intl('Reativar')}</Button>
                  : (
                      planStatus === 'past_due'
                        ? <Button onClick={changePayment} className='text-nowrap'>{intl('Regularizar pagamento')}</Button>
                        : null
                    )}{
                    planDowngradeAt ? <Button isLoading={isLoading} onClick={keepCurrentPlan} style={{ whiteSpace: 'nowrap' }}>{intl('Manter meu plano atual')}</Button> : null
                    }
                  {isLoadingData ? loaderTitle : <>{!planCancelAt && !planDowngradeAt && planStatus !== 'past_due' ? buttonChangePlan : null }</>} </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{isLoadingData ? loaderTitle : <>{!dataSubscription?.plan?.subscription.cancel_at ? <><span className='fw-bold'>{intl('Próx. Cobrança:')}</span> {planNextInvoice} </> : null}</>}</td>
                </tr>
              </tbody>
              </table>
            </SubscriptionInfoS>
            <hr className='mb-3 mt-3' style={{ width: '100%', height: '1px', backgroundColor: '#00BDD9', border: 'none' }} />
              <div className='w-100 d-flex flex-row justify-content-between' style={{ cursor: 'pointer' }} onClick={() => setMoreInfoIsOpen(!moreInfoIsOpen)}>
                <label className='fw-bold mb-3'>{intl('Informações do plano')}</label>
                {moreInfoIsOpen ? <CaretUp size={24} /> : <CaretDown size={24} />}
              </div>
            <MoreInformationAboutSubscriotionS isOpen={moreInfoIsOpen}>
              <ul>
                <li className='mb-3'>{intl('Dashboards, usuários e relatórios ilimitados')}</li>
                <li className='mb-3'>{intl('Banco de dados nativo com modelagem e persistência dos seus dados')}</li>
                <li className='mb-3'>{intl('Sem limite de contas de uma mesma conexão')}</li>
                <li className='mb-3 d-flex gap-1'>{intl('Conectores disponíveis:')} <span className='d-flex gap-2 flex-wrap'>{isLoadingData ? loaderTitle : dataSubscription?.plan?.connections.map((c: any, i: any) => (<IconSVG icon={c.platform as IconSVGPropsI['icon']} key={i} data-for='tip-global-permission' data-tip={intl(c.platform)} />))}</span></li>
              </ul>
              <hr className='mb-3 mt-3' style={{ width: '100%', height: '1px', backgroundColor: '#00BDD9', border: 'none' }} />
              {planCancelAt || isLoadingData
                ? null
                : (<div className='section-cancel'>
                  <label className='fw-bold mb-3' >{intl('Cancelamento')}</label>
                  <p className='mb-3 mt-2'>{intl('Caso cancele seu plano, você poderá utilizar o NeoDash até o encerramento da vigência de sua assintura. Após esse período, armazenaremos seus dados por 30 dias.')}</p>
                  <p className='mb-3'>{intl('Se sua assinatura for reativada dentro desse período, será possível restaurar seus dados. Após esse período, todas as informações serão deletadas de nosso banco.')}</p>
                  {dataSubscription?.plan?.subscription ? <a className='cancel-link fw-bold' onClick={cancelSubscriptioHandle}>{intl('Cancelar meu plano')}</a> : null}
                </div>)}

            </MoreInformationAboutSubscriotionS>
          </>
        </CardContentLarge>
      </TemplateDefault>
      {content}
      {contentCancelPlan}
    </>
  )
}

export default Subscription
