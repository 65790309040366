import { Modal } from 'DesignSystem'

import useContentManager from 'Hooks/useContentManager.hook'

import PendingPlanModal from '../Components/PendingPlanModal'

export const usePendingPlanContentManager = (planID?: string) => {
  const { start, content } = useContentManager([
    PendingPlanModal
  ], {
    dialog: Modal
  })

  return { start, content }
}
