import { css } from 'styled-components/macro'

export type SizeComponentI = 'small' | 'big' | 'normal'
export type FontSize = 'normal' | 'large' | 'extra-large'

interface BaseLabelI {
  size: SizeComponentI
}

interface BaseHeightI {
  size?: SizeComponentI
}

interface BaseFontSizeI {
  fontSize?: FontSize
}

const BaseComponentLabel = css<BaseLabelI>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: var(--base-size-component-small);
        `
      case 'big':
        return css`
          font-size: var(--base-size-component-big);
        `
      default:
        return css`
          font-size: var(--base-size-component);
        `
    }
  }}
`

const BaseComponentHeight = css<BaseHeightI>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: var(--base-height-component-small);
        `
      case 'big':
        return css`
          height: var(--base-height-component-big);
        `
      default:
        return css`
          height: var(--base-height-component);
        `
    }
  }}
`

const BaseButtonLabel = css<BaseLabelI>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: var(--button-base-label-small-size);
        `
      case 'big':
        return css`
          font-size: var(--button-base-label-big-size);
        `
      default:
        return css`
          font-size: var(--button-base-label-size);
        `
    }
  }}
`

const BaseButtonHeight = css<BaseHeightI>`
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: var(--button-base-small-height);
        `
      case 'big':
        return css`
          height: var(--button-base-big-height);
        `
      default:
        return css`
          height: var(--button-base-height);
        `
    }
}}
`

const BaseFontSize = css<BaseFontSizeI>`
  ${({ fontSize }) => {
    switch (fontSize) {
      case 'extra-large':
        return css`
          font-size: var(--font-base-size-extra-large);
        `
      case 'large':
        return css`
          font-size: var(--font-base-size-large);
        `
      default:
        return css`
          font-size: var(--font-base-size-normal);
        `
    }
  }}
`

const StyledHelpers = {
  BaseComponentLabel,
  BaseComponentHeight,
  BaseButtonLabel,
  BaseButtonHeight,
  BaseFontSize
}

export default StyledHelpers
