import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { setCreateCompany as _setCreateCompany } from 'Store/Actions/Company'
import { uploadIconCompanyTemp } from 'Store/Actions/Company/uploadIcon.action'

import Button from 'Components/Button'
import UploadFiles from 'Components/Inputs/upload.componet'
import LoadingDefault from 'Components/Loadings/default.component'
import Text from 'Components/Text'

import { getCroppedImg } from 'Helpers/Image.helper'
import { MicroData } from 'Helpers/MicroData.helper'

import { ButtonsS } from './formIcon.styled'

export default function FormIcon () {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  // @ts-ignore: Unreachable code error
  const company = useSelector((state) => state.company.createCompany)
  const [crop, setCrop] = useState(company.positionComponent || { x: 0, y: 0 })
  const [zoom, setZoom] = useState(company.positionComponent?.zoom || 1)
  const { isValidIcon: isValid } = company
  const iconOriginProxied = `${process.env.REACT_APP_API_URL}proxy/img?url=${company.iconOriginal}`

  const setCreateCompany = (values: any) => _setCreateCompany({ ...company, ...values })

  const onCropComplete = useCallback(async (_, croppedAreaPixels) => {
    try {
      dispatch(setCreateCompany({
        icon: await getCroppedImg(iconOriginProxied, croppedAreaPixels),
        positionAreaPixels: croppedAreaPixels,
        positionComponent: { ...crop, zoom },
        isValidIcon: true
      }))
    } catch (e) {
      console.error(e)
    }
  }, [company])

  const handleClickZoom = (zoom: number) => setZoom(zoom < 0.2 ? 0.2 : zoom)

  async function onDropFiles (fileData: any) {
    setIsLoading(true)
    dispatch(setCreateCompany({ isValidIcon: false }))

    try {
      const response = await uploadIconCompanyTemp({ base: fileData?.base })
      dispatch(setCreateCompany({
        iconOriginal: response.data.Location || '',
        isValidIcon: true
      }))
      setTimeout(() => {
        setCrop({ x: 0, y: 0 })
        setZoom(1)
      }, 1000)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
        <UploadFiles label={intl.formatMessage({ id: 'Ícone' })} placeholder={intl.formatMessage({ id: 'Selecione um ícone para sua empresa' })} onDrop={onDropFiles} />
      {isLoading ? <LoadingDefault className='page-new-company__form-icon__loading' /> : null}
      {isValid === true && !isLoading
        ? <>
          <Text className='page-new-company__form-icon__tip' isBold>{intl.formatMessage({ id: 'Mova sua imagem para encaixá-la como preferir' })}</Text>
          <div className='page-new-company__form-icon__crop' data-component-type={MicroData.INFO_FROM_MODEL}>
            <Cropper
              image={iconOriginProxied}
              crop={crop}
              zoom={zoom}
              aspect={4 / 1}
              restrictPosition={false}
              showGrid={true}
              onCropChange={setCrop}
              style={{ containerStyle: { width: '100%', backgroundColor: 'white' } }}
              onCropComplete={onCropComplete}
              objectFit="horizontal-cover"
            />
          </div>
          <ButtonsS data-component-type={MicroData.INFO_FROM_MODEL}>
            <Button size='small' label='+' onClick={() => handleClickZoom(zoom + 0.1)} data-component-type={MicroData.COLUMNS} />
            <Button size='small' label='-' onClick={() => handleClickZoom(zoom - 0.1)} data-component-type={MicroData.COLUMNS} />
          </ButtonsS>
        </>
        : null
      }
    </>
  )
}
