import { Warning } from '@phosphor-icons/react'
import { useEffect } from 'react'

import type { DataStartDowngradePlanContentManagerI } from 'ActionsContentManager/DowngradePlan/Hooks/useStartDowngradePlan.hook'
import { Button } from 'DesignSystem'

import type { IconSVGPropsI } from 'Components/IconSVG'
import IconSVG from 'Components/IconSVG'

import useCompanies from 'Hooks/API/useCompanies.hook'
import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { RootS } from './DowngradeCheckModal.styled'

type DowngradeCheckModalPropsI = AuxStepContentManagerI<DataStartDowngradePlanContentManagerI>

const DowngradeCheckModal = (props: DowngradeCheckModalPropsI) => {
  const { next, close, dataContentManager, setData } = props
  const { data: companies, isLoading: isLoadingCompanies } = useCompanies({ onlyOwner: true })

  useEffect(() => {
    const totalToSelect = dataContentManager.downgradeInfo?.workspaces ? companies.length - dataContentManager.downgradeInfo?.workspaces.to : 0
    setData({
      totalToSelect
    })
  }, [isLoadingCompanies])

  const intl = useIntlShort()
  return (
    <RootS>
      <Warning size={64} color='#F7C347' />
      <h3 className='fw-bold'>{intl('Antes de fazer o downgrade...')}</h3>
      <div className='d-flex flex-column gap-2 mt-2 justify-content-start'>
        <p>{intl('Lembre-se das seguintes mudanças que vão ocorrer:')}</p>
        <ul className='alert fw-bold'>
          <li>{intl('Redução de workspaces de N para N;', { from: dataContentManager.downgradeInfo?.workspaces.from, to: dataContentManager.downgradeInfo?.workspaces.to })}</li>
          {(dataContentManager.downgradeInfo?.connectionsOff || []).length > 0 && (
            <>
              <li>{intl('Perda de acesso aos seguintes conectores:')}</li>
              <li className='mb-3 d-flex gap-1 mt-3'><span className='d-flex gap-2 flex-wrap'>{dataContentManager.downgradeInfo?.connectionsOff.map((c: any, i: any) => (<IconSVG icon={c as IconSVGPropsI['icon']} key={i} />))}</span></li>
            </>
          )}
        </ul>
        <p>{intl('Caso você possua mais de N workspace configurado, será necessário deletá-los até chegar a apenas N workspace ativo.', { to: dataContentManager.downgradeInfo?.workspaces.to })}</p>
        {(dataContentManager.downgradeInfo?.connectionsOff || []).length > 0 && (
          <p>{intl('Se algum desses conectores ativos corresponder aos conectores que você perderá acesso, eles serão automaticamente deletados.')}</p>
        )}
      </div>
      <div className='d-flex flex-row gap-4 mt-3'>
        <Button onClick={close} color='success'>{intl('Manter plano atual')}</Button>
        <Button color='error' disabled={isLoadingCompanies} onClick={() => next()}>{intl('Avançar')}</Button>
      </div>
    </RootS>
  )
}

export default DowngradeCheckModal
