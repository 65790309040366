import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'

import { addAlert } from 'Store/Actions/alerts.action'

import type { CompanyI } from 'Hooks/API/useCompany.hook'

import { apiPUT, apiPOST, apiGET, apiHandle } from 'Services/Api.service'

import { getLocale } from 'Helpers/Locale.helper'
import { flat } from 'Helpers/String.helper'

export const getListCompanies = async () => {
  try {
    const apiRequest = await apiGET<CompanyI>('user/companies')
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

interface FormCompany {
  name: string,
  icon: string,
  slug: string,
  color: string,
  timezone: string,
  locale?: string,
  currency_write?: string,
  currency_read?: string,
}

export const registerCompany = async (inputs: FormCompany) => {
  const apiRequest = await apiPOST<any>('company', inputs)
  const response = await apiRequest
  return { ...response }
}

export const updateDataCompany = async (data:any) => {
  try {
    const apiRequest = await apiPUT<any>(`company/${data.id}`, data)
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const createDB = async (slug:string) => {
  try {
    const apiRequest = await apiPOST<any>('company/create/db', { slug })
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const checkCompanyNameAvailability = async (companyName:string) => {
  try {
    const apiRequest = await apiPOST<any>('company/check/name', { companyName })
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const createCompanyTemp = async (companyTemp : { companyName: string, slug: string, icon: string, color: string, positionAreaPixels: string, positionComponent: string, iconOriginal: string}) => {
  try {
    const apiRequest = await apiPOST<any>('company/temp/createtemp', {
      ...companyTemp,
      position: {
        positionAreaPixels: companyTemp.positionAreaPixels,
        positionComponent: companyTemp.positionComponent
      },
      icon: companyTemp.iconOriginal
    })
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const getCompanyTemp = async () => {
  try {
    const apiRequest = await apiGET<any>('company/temp/gettemp')
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const deleteCompanyTemp = async () => {
  try {
    const apiRequest = await apiPOST<any>('company/temp/deletetemp')
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

interface PayloadSetCreateCompanyInterface {
  companyName: string,
  isValidName: boolean,
  slug: string,
  suggestionSlug: string,
  isValidSlug: string,
  icon: string,
  isValidIcon: boolean,
  color: string,
}

export const setCreateCompany = (payload: PayloadSetCreateCompanyInterface) => {
  const copyPayload = { ...payload }

  if (!isEmpty(copyPayload.companyName)) {
    copyPayload.suggestionSlug = flat(copyPayload.companyName)
  }

  return {
    type: 'SET_CREATE_COMPANY',
    payload: copyPayload
  }
}

export const clearCreateCompany = () => {
  return {
    type: 'CLEAR_CREATE_COMPANY'
  }
}

export const loadCompany = async (id: string) => {
  try {
    const apiRequest = await apiGET<any>(`company/${id}`)
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

const configAPI = (headers = {}) => {
  const locale = getLocale()
  const AcceptLocale = locale === 'en-US' ? 'en' : locale
  const token = localStorage.getItem('odash-app:user') ?? ''

  const config: AxiosRequestConfig = {
    timeout: 10 * 60 * 1000,
    headers: {
      'accept-language': AcceptLocale,
      'content-type': 'application/json',
      accept: 'application/json',
      authorization: `Bearer ${token}`,
      ...headers
    },
    validateStatus: function (status) {
      return status < 500
    }
  }

  const clientAPI: AxiosInstance = axios.create(config)

  return clientAPI
}

export const loadCompanyAccountJobs = async (idCompany: string, typeJobs?: string) => {
  try {
    const apiRequest = await apiGET<any>(typeJobs ? `/company/${idCompany}/jobs/${typeJobs}` : `/company/${idCompany}/jobs`)
    const response = await apiRequest
    return response.data.jobs
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const loadCompanyAccountLogs = async (idCompany: string, typeJobs: string) => {
  try {
    const apiRequest = await apiGET<any>(`/company/${idCompany}/logsjob${typeJobs ? '/' + typeJobs : ''}`)
    const response = await apiRequest
    return response.data.logs
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const loadCompanyAccountLog = async (idCompany: string, idLog: string) => {
  try {
    const apiRequest = await apiGET<any>(`/company/${idCompany}/logjob/${idLog}`)
    const response = await apiRequest
    return response.data.log
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const loadCompanyAccounts = async (id: string) => {
  const response = await apiHandle<any>(`${process.env.REACT_APP_TKM_URL}/company/${id}/accounts?extended=true`, {}, configAPI())
  return response
}

export const loadCompanyAccount = async (idAccount: string) => {
  const response = await apiHandle<any>(`${process.env.REACT_APP_TKM_URL}/account/${idAccount}?extended=true`, {}, configAPI())
  return response
}

export const deleteCompanyAccount = async (company_account: string, platform: string, external_id: string) => {
  const response = await apiHandle<any>(`${process.env.REACT_APP_TKM_URL}/company_account/${company_account}?platform=${platform}&company=${external_id}`, {}, configAPI().delete)
  return response
}
