import { Warning } from '@phosphor-icons/react'

import { usePastDuePlanContentManager } from 'ActionsContentManager/PastDuePlan/Hooks/usePastDuePlanContentManager.hook'
import styled, { css } from 'styled-components/macro'

import useUser from 'Hooks/API/useUser.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

interface RootPropsI {
  backgroundColor?: string,
  color?: string,
}

const RootS = styled.div<RootPropsI>`
  position: relative;
  top: 70px;
  width: 100%;
  height: fit-content;
  min-height: 24px;

  background-color: ${({ backgroundColor }) => backgroundColor || '#FF0000'};
  ${({ theme }) => css(theme.fonts.types.caption1)};
  color: ${({ color }) => color || '#F6F6F6'};
`
const RootInnerS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  width: var(--base-content-width);
  height: 100%;
  margin: 0 auto;

`

const WarningPaymentTrial = () => {
  const intl = useIntlShort()
  const { data: userLocal, isLoading: isLoadingUserLocal } = useUser()
  const { start, content } = usePastDuePlanContentManager()
  const subscriptionIsActive = userLocal?.subscription?.status === 'active'

  if (!isLoadingUserLocal && userLocal?.subscription?.status === 'past_due') {
    return (
      <>
        <RootS backgroundColor='#E4022C'>
          <RootInnerS>
            <Warning size={16} color={'#F6F6F6'} />
            <span>{intl('Presenciamos um problema com os dados de pagamento atuais de seu plano.')} <a className='fw-bold' style={{ cursor: 'pointer' }} onClick={start}>{intl('Clique aqui')}</a> {intl('para verificar e regularizar sua assinatura.')}</span>
          </RootInnerS>
        </RootS>
        {content}
      </>
    )
  }

  if (!isLoadingUserLocal && subscriptionIsActive) {
    return null
  }

  if (!isLoadingUserLocal && userLocal?.isTrialPeriodExpired) {
    return (
      <RootS backgroundColor='#FFE8B0' color={'black'}>
        <RootInnerS>
          <Warning size={16} fill='#F7C347' />
          <span>{intl('Seu período de testes do NeoDash chegou ao fim. Confira nossas opções de planos para fazer sua assinatura e continuar utilizando os recursos disponíveis.')}</span>
        </RootInnerS>
      </RootS>
    )
  }

  return null
}

export default WarningPaymentTrial
