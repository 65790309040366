import styled from 'styled-components/macro'

import Text from 'Components/Text'

export const RootS = styled.div`
  width: 100%;
`

export const TextS = styled(Text)`
  display: block;

  margin: 10px auto;
  text-align: center;
`

export const CropS = styled.div`
  width: 184px;
  height: 92px;

  max-width: 100%;

  position: relative;

  border: 2px solid ${({ theme }) => theme.colors.gray};
  border-radius: 0.5rem;
  overflow: hidden;
`

export const ButtonsS = styled.div`
  display: flex;
  flex-flow: column;

  button {
    cursor: pointer;

    background-color: transparent;
    border: none;
  }
`
