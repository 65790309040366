import { Col, Row } from 'DesignSystem'

import WorkspaceCard from 'Components/WorkspaceCard/WorkspaceCard.component'

interface IWorkspace {
  companies: any[]
  onAfterCreateCompany?: () => void
  canCreateWorkspace?: boolean
}

function ListWorkspaces (props: IWorkspace) {
  return (
    <Row>
      {props.companies.map((workspace, key) => {
        return (
          <Col className='mt-3 d-flex justify-content-center px-2' xs={6} md={4} xl={3} xxl={2} key={key}>
            <WorkspaceCard bgColor={workspace.color} workspaceName={workspace.name} slug={workspace.slug} logoSrc={workspace.icon} workspaceId={workspace.id} />
          </Col>
        )
      })}
    </Row>
  )
}

export default ListWorkspaces
