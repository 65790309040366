import { useIntl } from 'react-intl'

import { Button } from 'DesignSystem'

interface NotFoundSearchPropsI {
  button?: {
    label: string,
    onClick: () => void,
  }
}

const NotFoundSearch = (props: NotFoundSearchPropsI) => {
  const { button } = props
  const intl = useIntl()

  return (
    <div className='d-flex flex-column align-items-center w-100'>
      <h2 className='fw-bold mt-3'>{intl.formatMessage({ id: 'notfound.search' })}</h2>
      <p className='mt-2'>{intl.formatMessage({ id: 'notfound.search.text' })}</p>
      <img className='mt-3' src="/images/smiley-x-eyes.svg" alt="Smiley X Eyes" />
      {button
        ? (
          <Button color="primary" onClick={button.onClick}>
            {button?.label}
          </Button>
          )
        : null
      }
    </div>
  )
}

export default NotFoundSearch
