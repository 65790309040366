import { useIntl } from 'react-intl'

import { Col, Row } from 'DesignSystem'

import type { DataCompanyCreateContentManagerI } from 'Components/FirstSetupFlow'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'

import ColorWorkspaceField from './Fields/Color'
import CurrencyWorkspaceField from './Fields/Currency'
import LanguageWorkspaceField from './Fields/Locale'
import NameWorkspaceField from './Fields/Name'
import SlugWorkspaceField from './Fields/Slug'
import TimezoneWorkspaceField from './Fields/Timezone'

type WorkspaceFormI = AuxStepContentManagerI<DataCompanyCreateContentManagerI>;

interface WorkspaceFormPropsI {
  isEdit?: boolean;
}

function WorkspaceForm ({ dataContentManager, setData, setEnableNext, isEdit = false }: WorkspaceFormI & WorkspaceFormPropsI) {
  const intl = useIntl()
  return (
    <form action="">
      <NameWorkspaceField
        isEdit={isEdit}
        dataContentManager={dataContentManager}
        setEnableNext={setEnableNext}
        setData={setData}
        next={() => null}
        prev={() => null}
        close={() => null}
        currentStep={0}
      />
      {isEdit ? null : (
        <div className='mt-3'>
          <SlugWorkspaceField
            dataContentManager={dataContentManager}
            setEnableNext={setEnableNext}
            setData={setData}
            next={() => null}
            prev={() => null}
            close={() => null}
            currentStep={0}
            />
        </div>
      )}
      <div className="mt-4">
        <ColorWorkspaceField
          dataContentManager={dataContentManager}
          setData={setData}
          next={() => null}
          prev={() => null}
          close={() => null}
          currentStep={0}
        />
      </div>
      <Row className="mt-4">
        <Col xs={12} md={6} className="ps-0 pe-0 pe-lg-1">
          <label className="fw-bold">
            {intl.formatMessage({ id: 'Idioma da empresa' })} <span className="text-error">*</span>:
          </label>
          <LanguageWorkspaceField
            dataContentManager={dataContentManager}
            setData={setData}
            next={() => null}
            prev={() => null}
            close={() => null}
            currentStep={0}
          />
        </Col>
        <Col xs={12} md={6} className="ps-0 ps-lg-1 pe-0 mt-4 mt-lg-0">
          <label className="fw-bold">
            {intl.formatMessage({ id: 'Moeda da empresa' })} <span className="text-error">*</span>:
          </label>
          <CurrencyWorkspaceField
            dataContentManager={dataContentManager}
            setData={setData}
            next={() => null}
            prev={() => null}
            close={() => null}
            currentStep={0}
          />
        </Col>
      </Row>
      {isEdit ? null : (
        <div className="mt-4">
          <label className="fw-bold">
            {intl.formatMessage({ id: 'Fuso horário da empresa' })} <span className="text-error">*</span>:
          </label>
          <TimezoneWorkspaceField
            dataContentManager={dataContentManager}
            setData={setData}
            next={() => null}
            prev={() => null}
            close={() => null}
            currentStep={0}
          />
        </div>
      )}
    </form>
  )
}

export default WorkspaceForm
