import styled, { css } from 'styled-components/macro'

export const RootS = styled.div`
  h3 {
    ${({ theme }) => css(theme.fonts.types.h3)}
  }
`

export const SubscriptionInfoS = styled.div`
  display: flex;
  flex-direction: column;
/*
  span {
    flex: 1;
  } */

  thead, tbody {
    td {
      padding-right: 48px;
    }
  }

  tbody {
    td {
      padding-bottom: 16px;
    }
  }
`

export const MoreInformationAboutSubscriotionS = styled.div<{isOpen: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: start;
  transition: height 0.3s ease-in-out;
  overflow: hidden;

  ${({ isOpen }) => !isOpen && css`
    height: 0px;
  `}

  label {
    ${({ theme }) => css(theme.fonts.types.subtitle2)}
  }

  ul {
    ${({ theme }) => css(theme.fonts.types.body2)}
    padding-left: 15px;
  }

  .cancel-link {
    cursor: pointer;
    color: #E4022C;
    ${({ theme }) => css(theme.fonts.types.subtitle3)}
  }

  .section-cancel {
    p {
      font-size: 0.75rem;
    }
  }
`

export const StatusChangePlanS = styled.div`
  flex: 1;
  height: 27px;
  label {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  position: relative;

  .text-support {
    position: absolute;
    background-color: #FFE8B0;
    border-radius: 1rem;
    top: 160%;
    height: fit-content;
    padding: 10px;
    left: 0;
    width: 300px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    .text-support {
      opacity: 1;
      visibility: visible;
    }
  }
`

interface StatusSubscriptionCardSPropsI {
  cancel_at?: string
}

export const StatusSubscriptionCanceledS = styled.div<StatusSubscriptionCardSPropsI>`
    flex: 1;
    height: 27px;
    label {
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  ${({ cancel_at }) => cancel_at && css`
    width: fit-content;
    background-color: #FFE8B0;
    border-radius: 8px;

    position: relative;

    justify-content: center;
    padding: 2px 16px;


    .text-support {
      position: absolute;
      background-color: #FFE8B0;
      border-radius: 1rem;
      top: 110%;
      height: fit-content;
      padding: 10px;
      left: 0;
      width: 300px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      .text-support {
        opacity: 1;
        visibility: visible;
      }
    }
    `}
    `

interface PlanSubscriptionCardSPropsI {
  downgraded_at?: string
}

export const PlanSubscriptionCanceledS = styled.div<PlanSubscriptionCardSPropsI>`
    flex: 1;
    height: fit-content;
  ${({ downgraded_at }) => downgraded_at && css`
    width: fit-content;
    background-color: #FFE8B0;
    border-radius: 8px;
    padding: 2px 16px;
    position: relative;
    white-space: nowrap;

    justify-content: center;

    .text-support {
      position: absolute;
      background-color: #FFE8B0;
      border-radius: 1rem;
      top: 110%;
      height: fit-content;
      padding: 10px;
      left: 0;
      width: 300px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out;
    }

    &:hover {
      .text-support {
        opacity: 1;
        visibility: visible;
      }
    }
  `}
`
