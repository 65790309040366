import type { FunctionComponent } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

interface ItemsI extends React.HTMLAttributes<HTMLAnchorElement> {
  name?: string;
  to?: string;
  href?: boolean;
}

interface DropdownProps {
  alignment?: 'left' | 'center' | 'right';
  items: ItemsI[];
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`

const DropdownButton = styled.div`
  cursor: pointer;
  border: none;
  text-align: center;
`

const DropdownContent = styled.div<{ alignment?: 'left' | 'center' | 'right' }>`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 10rem;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  z-index: 1;

  ${({ alignment }) => {
    switch (alignment) {
      case 'left':
        return 'left: 0;'
      case 'center':
        return 'left: 50%; transform: translateX(-50%);'
      case 'right':
        return 'right: 0;'
      default:
        return ''
    }
  }}
`

const DropdownItem = styled(Link)`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: #f1f1f1;
  }
`

const DropdownHoverContainer = styled(DropdownContainer)`
  &:hover ${DropdownContent} {
    display: block;
  }
`

const SimpleDropdown: FunctionComponent<DropdownProps> = ({ items, alignment = 'left', children }) => {
  return (
    <DropdownHoverContainer>
      <DropdownButton>{children}</DropdownButton>
      <DropdownContent alignment={alignment}>
        {items.map((item, key) => (
          item.to && item.name && <DropdownItem to={item.to} key={key}>{item.name}</DropdownItem>
        ))}
      </DropdownContent>
    </DropdownHoverContainer>
  )
}

export default SimpleDropdown
