import type { ReactNode } from 'react'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import AppBar from 'Components/AppBar'
import GlobalMenu from 'Components/GlobalMenu'

import { generateLinkTo } from 'Helpers/Route.helper'

interface ContainerInterface {
  children?: ReactNode
}

export default function Container (props:ContainerInterface) {
  /**
   * @TODO: refact this
   * It's a rule to don't show side menu when url is to login/invite/reset-password
   * from a company's login
   */
  const isResetPassword = !!useRouteMatch(generateLinkTo('odashForgotPassword'))
  const isInvite = !!useRouteMatch(generateLinkTo('odashInvite'))
  const isLogin = !!useRouteMatch(generateLinkTo('odashLogin'))

  if (isResetPassword || isInvite || isLogin) {
    return (
      <>
        {props.children}
      </>
    )
  }

  return (
    <>
      <AppBar />
      <GlobalMenu>
        {props.children}
      </GlobalMenu>
    </>
  )
}
