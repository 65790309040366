import type { ReactNode } from 'react'

import { Col, Row } from 'DesignSystem'
import styled from 'styled-components'

import Logo from '../Logo'

const RowAutoHeight = styled(Row)`
  @media (max-width: 768px) {
    height: 80vh;
  }
`

interface ContentProps {
  children: ReactNode
  title?: string
}

function Content ({ children, title } : ContentProps) {
  return (
    <div>
      <Row className='justify-content-center'>
        <Col className="mt-4 mt-lg-0" xs={8} lg={9} xl={7} xxl={6}>
          <Logo />
        </Col>
      </Row>
      <RowAutoHeight className="flex-column align-items-center justify-content-center text-center">
        {title && (
          <Col className="mt-2" xs={12} md={11} lg={9} xl={7} xxl={6}>
            <h2 className="fw-bold">
              {title}
            </h2>
          </Col>
        )}
        <Col className="mt-3" xs={12} md={11} lg={9} xl={7} xxl={6}>
          {children}
        </Col>
      </RowAutoHeight>
    </div>
  )
}

export default Content
