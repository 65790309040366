import type { ReactNode } from 'react'

import type DefaultProps from 'DesignSystem/Types'
import styled from 'styled-components'

interface ColProps extends DefaultProps {
  children: ReactNode;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

function hasSizeProperty (props: ColProps) {
  if (props.xs || props.sm || props.md || props.lg || props.xl || props.xxl) {
    return 'flex: 0 0 auto;'
  }
  return 'flex: 1 0 0%;'
}

const ColStyled = styled.div<ColProps>`
  ${props => props.xs && `width: ${(props.xs * 8.33333333)}%;`}

  ${props => hasSizeProperty(props)}

  @media (min-width: 576px) {
    ${props => props.sm && `width: ${(props.sm * 8.33333333)}%;`}
  }

  @media (min-width: 768px) {
    ${props => props.md && `width: ${(props.md * 8.33333333)}%;`}
  }

  @media (min-width: 992px) {
    ${props => props.lg && `width: ${(props.lg * 8.33333333)}%;`}
  }

  @media (min-width: 1200px) {
    ${props => props.xl && `width: ${(props.xl * 8.33333333)}%;`}
  }

  @media (min-width: 1400px) {
    ${props => props.xxl && `width: ${(props.xxl * 8.33333333)}%;`}
  }
`

function Col (props: ColProps) {
  return <ColStyled {...props}>{props.children}</ColStyled>
}

export default Col
