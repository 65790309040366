import styled, { css } from 'styled-components/macro'

interface MonthAndYearSectionProps {
  color?: string;
  fontWeightMonthAndYear?:
    | 'bold'
    | 'normal'
    | 'bolder'
    | 'lighter'
    | 'initial'
    | 'inherit';
}

export const HeaderSectionS = styled.div`
  width: 100%;
  max-width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  margin-bottom: 1rem;
`

export const MonthAndYearSectionS = styled.div<MonthAndYearSectionProps>`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 10px;
    margin-right: 15px;

    font-size: 1.2rem;
    font-weight: ${(props) => props.fontWeightMonthAndYear || 'bold'};
    color: ${(props) => props.color || '#000'};
  }
`

export const ButtonsSectionS = styled.div`
  display: flex;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    background: transparent;
    border: none;
  }
`

interface ButtonMonthI {
  isRight?: boolean
}

export const ButtonMonthS = styled.div<ButtonMonthI>`
  cursor: pointer;

  position: absolute;
  top: 15px;

  ${props => {
    if (props.isRight) {
      return css`
        right: 0;
      `
    }

    return css`
      left: 0;
    `
  }}

  background-color: transparent;
  border: none;
`
