import type { ReactNode } from 'react'
import React, { useState } from 'react'

import { cloneDeep } from 'lodash'

import Dialog from 'Components/Dialog'

type DialogPropsStepI = {
  dialogProps?: {
    style: any
  }
}

type StepParamsI = Array<React.ComponentType<any> & DialogPropsStepI>;

interface OptionsParamsI<T> {
  onClose?: () => void;
  enableBack?: boolean;
  initialData?: T;
  isFit?: boolean;
  header?: ReactNode;
  footer?: ReactNode | ((currentDialog: number) => ReactNode);
  dialog?: any;
  dialogProps?: any;
}

export interface AuxStepContentManagerI<T> {
  currentStep: number;
  next: (step?: number) => void;
  prev: (step?: number) => void;
  close: () => void;
  setData: (data: T) => void;
  setEnableNext?: (value: boolean) => void;
  dataContentManager: T;
}

const useContentManager = <T, >(
  steps: StepParamsI,
  options: OptionsParamsI<T> = {}
) => {
  const {
    onClose = () => undefined,
    initialData,
    isFit = false,
    header,
    footer,
    dialog = Dialog,
    dialogProps = {}
  } = options
  const [isStarted, setIsStarted] = useState(false)
  const [showFooter, setShowFooter] = useState(true)
  const [enableNext, setEnableNext] = useState(true)
  const [currentStep, setCurrentStep] = useState(0)
  const [sizeDialog, setSizeDialog] = useState(isFit ? 'sm' : 'lg')
  const [currentData, setCurrentData] = useState<T | any>(
    initialData ? cloneDeep(initialData) : {}
  )
  const StepComponent = steps[currentStep]
  const DialogComponent = dialog

  const handleClose = () => {
    onClose()
    setCurrentData(initialData ? cloneDeep(initialData) : {})
    setCurrentStep(0)
    setIsStarted(false)
  }

  const handleNext = (step?: number) => {
    const nextStep = currentStep + 1

    if (step !== undefined && step >= 0 && step <= steps.length - 1) {
      setCurrentStep(step)
      return
    }

    if (nextStep > steps.length - 1) {
      handleClose()
    } else {
      setCurrentStep(nextStep)
    }
  }

  const handlePrev = (step?: number) => {
    const prevStep = currentStep - 1

    if (step !== undefined && step >= 0 && step <= steps.length - 1) {
      setCurrentStep(step)
      return
    }

    if (prevStep < 0) {
      handleClose()
    } else {
      setCurrentStep(prevStep)
    }
  }

  const handleStart = (initialData?: T) => {
    if (initialData) {
      setCurrentData(
        cloneDeep({
          ...currentData,
          ...initialData
        })
      )
    }

    setIsStarted(true)
  }

  const handleSetData = (data: T) => {
    setCurrentData({
      ...currentData,
      ...data
    })
  }

  const propsStepComponent = {
    next: handleNext,
    prev: handlePrev,
    currentStep: currentStep,
    setData: handleSetData,
    dataContentManager: currentData,
    close: handleClose,
    enableNext,
    setEnableNext,
    setSizeDialog,
    setShowFooter
  }

  const content = (
    <DialogComponent
      size={sizeDialog}
      isOpen={isStarted}
      onClose={'noDisplayCloseButton' in StepComponent && StepComponent.noDisplayCloseButton ? null : handleClose}
      {...dialogProps}
      {...(StepComponent.dialogProps || {})}
    >
      {header && header}
      <StepComponent {...propsStepComponent} />
      {showFooter ? (
        <>{typeof footer === 'function' ? footer(currentStep) : footer}</>
      ) : null}
    </DialogComponent>
  )

  return {
    content: isStarted ? content : null,
    next: handleNext,
    prev: handlePrev,
    start: handleStart
  }
}

export default useContentManager
