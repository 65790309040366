import styled, { css } from 'styled-components/macro'

export const LabelS = styled.span`
  opacity: 0;
  position: absolute;
  display: block;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 24px;
  padding: 4px 15px;
  white-space: nowrap;
  transition: opacity 0.2s ease-in-out;
  margin-top: 5px;
  transform: translateX(-35%);
  z-index: 500;

  ${(props) => css(props.theme.fonts.types.body2)}
`

export const RootS = styled.div`
  position: relative;
  cursor: pointer;

  :hover {
    ${LabelS} {
      opacity: 1;
    }
  }
`
