import type { ReactNode } from 'react'

import type DefaultProps from 'DesignSystem/Types'
import styled from 'styled-components'

interface RowProps extends DefaultProps {
  children: ReactNode;
}

const RowStyled = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  & > * {
    box-sizing: border-box;
    flex-shrink: 0;
    max-width: 100%;

    padding-left: 14px;
    padding-right: 14px;
  }
`

function Row (props: RowProps) {
  return <RowStyled {...props}>{props.children}</RowStyled>
}

export default Row
