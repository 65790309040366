import queryString from 'query-string'

export const validateSlugSubdomain = (subdomain: string) => {
  const slugPattern = /^[a-z0-9-]+$/
  return slugPattern.test(subdomain)
}

export const cleanSubdomain = (subdomain: string) => {
  return subdomain.toLowerCase().replace(/[^a-z0-9-]/g, '')
}

export const getSearchURL = (
  key:string,
  stringSearchURL = window.location.search
) => {
  const values = queryString.parse(stringSearchURL)
  return values[key]
}

export const setSearchURL = (
  key:string,
  value:any,
  stringSearchURL = window.location.search
) => {
  const values = queryString.parse(stringSearchURL)
  const newQsValue = queryString.stringify({ ...values, [key]: value })
  return newQsValue
}

interface StorageQuery {
  [key: string]: boolean | string | number | string[] | number[]
}

export const createQuery = () => {
  const storage : StorageQuery = {}

  const addParams = (name: string, valueParam: boolean | string | number | string[] | number[]) => {
    storage[name] = valueParam
  }

  const getValue = () => {
    let value = '?'

    Object.entries(storage).forEach(([key, keyValue], index) => {
      const valueParam = (() => {
        if (typeof keyValue === 'string' || typeof keyValue === 'number' || typeof keyValue === 'boolean') {
          return keyValue
        }

        if (Array.isArray(keyValue)) {
          return keyValue.join('+')
        }
      })()

      if (index > 0) {
        value += '&'
      }

      if (valueParam === '') {
        return value
      }

      value += `${key}=${valueParam}`
      return value
    })

    return value
  }

  return {
    getValue,
    addParams
  }
}
