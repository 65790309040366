import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { Button, Col, Input, InputPassword, Row } from 'DesignSystem'
import AuthLayout from 'Layouts/AuthLayout'
import Content from 'Layouts/AuthLayout/Components/Content'
import { isEmpty } from 'lodash'

import { addAlert } from 'Store/Actions/alerts.action'
import { authUser } from 'Store/Actions/User/auth.action'
import { createUser } from 'Store/Actions/User/users.action'

import ListButtonFederatedLogin from 'Components/ListButtonFederatedLogin'
import TextDiviser from 'Components/TextDiviser/TextDiviser.component'

import useCheckInvite from 'Hooks/API/useCheckInvite.hook'
import useQuery from 'Hooks/useQuery.hook'

import { generateLinkTo, getPath } from 'Helpers/Route.helper'

interface SignupPropsI {
  isWaitingConfirmation?: boolean
}

function Signup ({ isWaitingConfirmation = false }: SignupPropsI = {}) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const nameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const confirmPasswordRef = useRef<HTMLInputElement>(null)
  const user = useSelector((state: any) => state.user)
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [signupWithFederated, setSignupWithFederated] = useState(true)
  const pricePreChosen = query.get('price_pre_chosen')
  const parametersTracking = query.findAll()
  const isInvite = !!useRouteMatch(generateLinkTo('signupWithInvite'))
  const { invite } = useParams<{ invite?: string }>()
  const { data: inviteData, isLoading: isLoadingInvite } = useCheckInvite({
    invite: invite ?? null
  })

  function clearState () {
    setEmail('')
    setName('')
    setPassword('')
    setLoading(false)
  }

  function validFields () {
    if (!isEmpty(email) && !isEmpty(password) && !isEmpty(name) && !isEmpty(confirmPassword) && password === confirmPassword) {
      return true
    } else {
      if (!name) {
        nameRef?.current?.focus()
      } else if (!email) {
        emailRef?.current?.focus()
      } else if (!password) {
        passwordRef?.current?.focus()
      } else if (!confirmPassword) {
        confirmPasswordRef?.current?.focus()
      } else if (password !== confirmPassword) {
        dispatch(
          addAlert({
            title: intl.formatMessage({ id: 'A confirmação da senha está diferente!' }),
            type: 'error'
          })
        )
        confirmPasswordRef?.current?.focus()
        return
      }

      dispatch(
        addAlert({
          title: intl.formatMessage({ id: 'Preencha os campos corretamente!' }),
          type: 'error'
        })
      )
    }
  }

  async function requestAPI () {
    setLoading(true)

    try {
      const resAction = await createUser({
        email,
        password,
        name,
        invite: isInvite ? invite : undefined,
        pricePreChosen: pricePreChosen,
        parametersTracking: parametersTracking
      })
      dispatch(resAction)
      setLoading(false)

      if (
        resAction &&
        'payload' in resAction &&
        resAction?.payload?.type !== 'error'
      ) {
        clearState()

        if (isInvite) {
          dispatch(await authUser({ email, password }))
        }
        redirectPage()
      }
    } catch (e: any) {
      setLoading(false)

      if (e.fields) {
        if (e.fields.name) {
          dispatch(
            addAlert({
              title: intl.formatMessage({ id: 'Nome' }),
              content: e.fields.name,
              type: 'error'
            })
          )
        }

        if (e.fields.email) {
          dispatch(
            addAlert({
              title: intl.formatMessage({ id: 'Email' }),
              content: e.fields.email,
              type: 'error'
            })
          )
        }

        if (e.fields.password) {
          dispatch(
            addAlert({
              title: intl.formatMessage({ id: 'Senha' }),
              content: e.fields.password,
              type: 'error'
            })
          )
        }

        if (e.fields.name) {
          nameRef?.current?.focus()
        } else if (e.fields.email) {
          emailRef?.current?.focus()
        } else if (e.fields.password) {
          passwordRef?.current?.focus()
        }
      } else {
        dispatch(addAlert({ title: e.message, type: 'error' }))
      }
    }
  }

  async function registerUser (): Promise<void> {
    if (validFields()) {
      await requestAPI()
    }
  }

  function redirectPage () {
    if (isInvite) {
      history.replace(`${generateLinkTo('home')}?is_signup=true`)
    } else {
      redirectToLogin()
    }
  }

  function redirectToLogin () {
    history.push(`${generateLinkTo('login')}`)
  }

  useEffect(() => {
    return () => {
      clearState()
    }
  }, [])

  useEffect(() => {
    if (
      isInvite &&
      (!invite || (!isLoadingInvite && inviteData?.status !== 'success'))
    ) {
      dispatch(
        addAlert({
          title: intl.formatMessage({ id: 'Convite inválido!' }),
          type: 'error'
        })
      )
      history.push(`${generateLinkTo('signup')}`)
    }
  }, [isInvite, isLoadingInvite, inviteData])

  useEffect(() => {
    if (!isEmpty(user.data)) {
      window.localStorage.clear()
      dispatch({ type: 'USER_CLEAR' })
      window.location.reload()
    }
  }, [])

  const contentButtonFederated = (
    <ListButtonFederatedLogin
      only={
        isInvite && inviteData?.platform !== 'all' ? inviteData?.platform : null
      }
      messageOrOnStart={signupWithFederated}
      invite={isInvite ? invite : undefined}
      isSignUp
    />
  )

  return (
    <AuthLayout>
      <Content title={intl.formatMessage({ id: 'Cadastre-se' })}>
        {signupWithFederated ? (
          <div>
            {contentButtonFederated}
            <div className="my-4">
              <TextDiviser>{intl.formatMessage({ id: 'ou' })}</TextDiviser>
            </div>
            <Button
              className='w-100'
              color="primary"
              disabled={loading}
              onClick={() => setSignupWithFederated(false)}
            >
              {intl.formatMessage({ id: 'Cadastrar com e-mail' })}
            </Button>
            <div className="mt-3">
              <p className="text-start body2">
                {intl.formatMessage({ id: 'Ao criar minha conta estou de acordo com os' })}{' '}
                <b>
                  <a href="http://neodash.ai/terms" target='_blank' rel="noreferrer">{intl.formatMessage({ id: 'termos de uso' })}</a>
                </b>
                {' '}
                {intl.formatMessage({ id: 'e' })}
                {' '}
                <b>
                  <a href="http://neodash.ai/privacy-security/" target="_blank" rel="noreferrer">
                    {intl.formatMessage({ id: 'política de privacidade.' })}
                  </a>
                </b>
              </p>
            </div>
            <div className="mt-3">
              <p className="text-start body2">
                {intl.formatMessage({ id: 'Já possui uma conta?' })}{' '}
                <b><Link to={getPath('login')}>{intl.formatMessage({ id: 'Faça login no Neodash' })}</Link></b>
              </p>
            </div>
          </div>
        ) : (
          <div>
            <Input
              className=""
              ref={nameRef}
              type="text"
              placeholder={intl.formatMessage({ id: 'Nome' })}
              value={name}
              autocomplete="off"
              onChange={(event: any) => setName(event?.target?.value)}
            />
            <Input
              className="mt-3"
              ref={emailRef}
              type="email"
              placeholder={intl.formatMessage({ id: 'Email' })}
              value={email}
              autocomplete="off"
              onChange={(event: any) => setEmail(event?.target?.value)}
            />
            <InputPassword
              className="mt-3"
              ref={passwordRef}
              type="password"
              placeholder={intl.formatMessage({ id: 'Senha' })}
              value={password}
              autocomplete="off"
              onChange={(event: any) => setPassword(event?.target?.value)}
            />
            <InputPassword
              className="mt-3"
              ref={confirmPasswordRef}
              type="password"
              placeholder={intl.formatMessage({ id: 'Confirme sua senha' })}
              value={confirmPassword}
              autocomplete="off"
              onChange={(event: any) => setConfirmPassword(event?.target?.value)}
            />
            <Row className="justify-content-center mt-3 gap-2">
              <Col className='d-grid p-0'>
                <Button
                  color="gray"
                  onClick={() => setSignupWithFederated(true)}
                  >
                  {intl.formatMessage({ id: 'Voltar' })}
                </Button>
              </Col>
              <Col className='d-grid p-0' xs={7}>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => registerUser()}
                  >
                  {intl.formatMessage({ id: 'Criar minha conta' })}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Content>
    </AuthLayout>
  )
}

export default Signup
