import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { addAlert } from 'Store/Actions/alerts.action'
import { checkCompanyNameAvailability, setCreateCompany } from 'Store/Actions/Company'

import TextField from 'Components/TextField'
import Tooltip from 'Components/Tooltip/tooltip.component'

import useDebounce from 'Hooks/useDebounce.hook'

const ERROR_UNAVAIABLE = 'Este nome já está em uso, por favor escolha outra opção.'
const ERROR_COUNT_NAME = 'O nome deve ter apenas 30 caracteres.'

export default function FormName () {
  const intl = useIntl()
  const dispatch = useDispatch()
  // @ts-ignore
  const company = useSelector((state) => state.company.createCompany)
  const { companyName = '' } = company
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null)
  const [idError, setIdError] = useState(ERROR_UNAVAIABLE)
  const companyNameDebounce = useDebounce(companyName, 500)

  async function checkAvailabilityName (companyName: string) {
    if (companyName === undefined || companyName === '') return

    try {
      const response = await checkCompanyNameAvailability(companyName.trim())
      const isValid = response.status === 'success' && companyName.length <= 30
      setIsAvailable(isValid)

      if (companyName.length > 30) {
        setIsAvailable(false)
        setIdError(ERROR_COUNT_NAME)
      } else {
        setIdError(ERROR_UNAVAIABLE)
      }

      if (companyName.trim() === '') {
        setIsAvailable(null)
        dispatch(setCreateCompany({
          ...company,
          companyName,
          isValidName: false
        }))
      } else {
        dispatch(setCreateCompany({
          ...company,
          companyName,
          isValidName: isValid
        }))
      }
    } catch (e: any) {
      setIsAvailable(false)
      setIdError(ERROR_UNAVAIABLE)
      dispatch(
        addAlert({ title: e.message, type: 'error' })
      )
      dispatch(setCreateCompany({
        ...company,
        companyName,
        isValidName: false
      }))
    }
  }

  function setCompanyName (companyName: string) {
    setIsAvailable(null)
    dispatch(setCreateCompany({
      ...company,
      companyName,
      isValidName: false
    }))
  }

  useEffect(() => {
    checkAvailabilityName(companyNameDebounce)
  }, [companyNameDebounce])

  // const icon = isAvailable ? 'check' : 'error'

  const textField = (
    <TextField
      limitChar={30}
      value={companyName}
      label={intl.formatMessage({ id: 'Nome da sua empresa' })}
      placeholder={intl.formatMessage({ id: 'Digite o nome da sua empresa' })}
      onChange={event => setCompanyName(event?.target?.value)}
      isValid={isAvailable}
    />

  )

  //   <CountText value={companyName} limit={30}>
  //   <div className='page-new-company__form-name__icon-validation'>
  //     <TextField
  //       id="companyName"
  //       type="text"
  //       label={intl.formatMessage({ id: 'Nome da sua empresa' })}
  //       placeholder={intl.formatMessage({ id: 'Digite o nome da sua empresa' })}
  //       defaultValue={companyName}
  //       value={companyName}
  //       onChange={event => setCompanyName(event?.target?.value)} />
  //     {isAvailable !== null
  //       ? <img
  //         className={`page-new-company__form-name__icon-check-input page-new-company__form-name__icon-check-input--${icon}`}
  //         src={`/images/icon-${icon}.svg`}
  //         alt="icon" />
  //       : null
  //     }
  //   </div>
  // </CountText>

  return (
    <form noValidate autoComplete="off" className='shortform'>
      <Tooltip isShowed={!(isAvailable === null || isAvailable === true)} position='bottom' text={intl.formatMessage({ id: idError })}>
        {textField}
      </Tooltip>
    </form>
  )
}
