import styled from 'styled-components/macro'

export const AttributeS = styled.div`
  height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  width: 700px;
`
