import breakpoint from 'styled-components-breakpoint'
import styled, { css } from 'styled-components/macro'

import Card from 'Components/Card'
import Tag from 'Components/Tag'

export const WrapperActionsHeaderS = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TagsS = styled.div`
  display: flex;
  flex-flow: row wrap;

  margin-bottom: 15px;

  ${Tag} {
    margin-right: 5px;
  }
`

export const CardS = styled(Card)`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  ${breakpoint('tablet')`
    flex-direction: row;
  `}
`

interface InfoI {
  isExtended?: boolean
}

export const InfoS = styled.div<InfoI>`
  display: flex;
  flex-direction: column;

  ${props => {
    if (props.isExtended) {
      return css`
        flex: 1 1 auto;
      `
    }
  }}
`

export const NextStepS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CardSingleS = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FilterS = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  margin-right: 10px;
`

export const ListRoutines = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;

`
