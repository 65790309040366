import type { ReactNode } from 'react'

import styled from 'styled-components'

interface ContainerProps {
  children: ReactNode;
  main?: boolean
}

const ContainerStyled = styled.div<ContainerProps>`
  --neo-gutter-x: 1.5rem;
  --neo-gutter-y: 0;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--neo-gutter-x) * 0.5);
  padding-right: calc(var(--neo-gutter-x) * 0.5);

  @media (min-width: 1400px) {
    max-width: ${props => props.main ? '1920px' : '1320px'};
  }
`

function Container (props: ContainerProps) {
  return <ContainerStyled {...props}>{props.children}</ContainerStyled>
}

export default Container
