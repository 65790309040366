import { useIntl } from 'react-intl'

import { Button, Col } from 'DesignSystem'
import styled, { css } from 'styled-components/macro'

const RootS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h3 {
    ${({ theme }) => css(theme.fonts.types.h3)};
  }

  > p {
    ${({ theme }) => css(theme.fonts.types.body1)};
  }
`

const SuccessInviteDialog = ({ prev, next, dataContentManager } : any) => {
  const intl = useIntl()
  return (
    <RootS>
      <h3>{intl.formatMessage({ id: 'Convite enviado!' })}</h3>
      <p>{intl.formatMessage({ id: 'O convite de teste do NeoDash para email@email.com foi enviado com sucesso.' }, { email: dataContentManager.email })}</p>
      <Col className="mt-5 text-center">
        <Button className="ms-3" onClick={prev}>
          {intl.formatMessage({ id: 'Enviar novo convite' })}
        </Button>
      </Col>
    </RootS>
  )
}

export default SuccessInviteDialog
