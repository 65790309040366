import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useIntl } from 'react-intl'

import { subDays, startOfMonth, lastDayOfMonth, subMonths } from 'date-fns'

import Button from 'Components/Button'
import IconSVG from 'Components/IconSVG'
import MultipleCalendar from 'Components/MultipleCalendar'
import Text from 'Components/Text'

import { useWindowSize } from 'Hooks/useAspectRatio.hook'
import useID from 'Hooks/useID.hook'
import useOutsideClick from 'Hooks/useOutsideClick.hook'

import { MicroData } from 'Helpers/MicroData.helper'

import { CardCalendarS, RootS, WrapperActionsS, WrapperS } from './CalendarFilter.styled'

interface CalendarFilterPropsI {
  placeholder?: string,
  onChange?: (rangeDate: Date[]) => void
}

const CalendarFilter = (props: CalendarFilterPropsI) => {
  const { placeholder, onChange = () => undefined } = props

  const intl = useIntl()
  const windowSize = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)
  const [currentDate, setCurrentDate] = useState<Date>()
  const [rangeDate, setRangeDate] = useState<Date[]>([])
  const [positionCalendar, setPositionCalendar] = useState({ top: 0, left: 0 })
  const componentRef = useRef(null)
  const calendarRef = useRef(null)
  const idCalendar = useID('CalendarFilter')
  const idCalendarFilter = useID('CalendarFilerInput')

  const handleOnClick = () => {
    setIsOpen(!isOpen)
  }

  const handleClickLast30Days = () => {
    const currentDate = new Date()
    const last30days = subDays(currentDate, 30)
    setCurrentDate(last30days)
    setRangeDate([last30days, currentDate])
  }

  const handleClickCurrentMoth = () => {
    const firstDay = startOfMonth(new Date())
    const lastDay = lastDayOfMonth(new Date())
    setCurrentDate(new Date())
    setRangeDate([firstDay, lastDay])
  }

  const handleClickLastMonth = () => {
    const lastMonth = subMonths(new Date(), 1)
    const firstDay = startOfMonth(lastMonth)
    const lastDay = lastDayOfMonth(lastMonth)
    setCurrentDate(lastMonth)
    setRangeDate([firstDay, lastDay])
  }

  const handleClickApply = () => {
    setIsOpen(!isOpen)
    onChange(rangeDate)
    setRangeDate([])
  }

  useOutsideClick([componentRef, calendarRef], () => {
    setIsOpen(false)
  })

  useEffect(() => {
    const elementInput = document.getElementById(idCalendarFilter)
    const boundingClient = elementInput?.getBoundingClientRect()

    const elementCalendar = document.getElementById(idCalendar)
    const boundingCLientCalendar = elementCalendar?.getBoundingClientRect()

    const top = (boundingClient?.top ?? 0) + (boundingClient?.height ?? 0)
    const left = (() => {
      const left = boundingClient?.left ?? 0
      const width = boundingCLientCalendar?.width ?? 0

      if ((left + width) > windowSize.innerWidth) {
        return left - (left + width - windowSize.innerWidth) - 20
      } else {
        return left
      }
    })()

    setPositionCalendar({
      top,
      left
    })
  }, [idCalendar, idCalendarFilter, isOpen, windowSize])

  return (
   <WrapperS ref={componentRef}>
      <RootS id={idCalendarFilter} onClick={handleOnClick}>
        <IconSVG icon='calendar' />
        {placeholder ? <Text>{placeholder}</Text> : null}
      </RootS>
      {isOpen
        ? (
            ReactDOM.createPortal(
              <CardCalendarS ref={calendarRef} id={idCalendar} style={{ top: positionCalendar.top, left: positionCalendar.left }}>
                <WrapperActionsS>
                  <Button label={intl.formatMessage({ id: 'Últimos 30 dias' })} type='soft' onClick={handleClickLast30Days} data-component-type={MicroData.ACTION_FROM_MODEL} />
                  <Button label={intl.formatMessage({ id: 'Mês atual' })} type='soft' onClick={handleClickCurrentMoth} data-component-type={MicroData.ACTION_FROM_MODEL} />
                  <Button label={intl.formatMessage({ id: 'Mês anterior' })} type='soft' onClick={handleClickLastMonth} data-component-type={MicroData.ACTION_FROM_MODEL} />
                  <Button label={intl.formatMessage({ id: 'Aplicar' })} onClick={handleClickApply} />
                </WrapperActionsS>
                <MultipleCalendar currentDate={currentDate} rangeDate={rangeDate} onChange={setRangeDate} onChangeCurrentDate={setCurrentDate}/>
              </CardCalendarS>,
              document.getElementById('root') || document.createElement('div')
            )
          )
        : null
      }
    </WrapperS>
  )
}

export default CalendarFilter
