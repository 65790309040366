import React from 'react'

import styled from 'styled-components/macro'

import type { IconNameI } from 'Components/IconSVG/IconSVG.component'

import { passAlongMicroData } from 'Helpers/MicroData.helper'
import type { SizeComponentI } from 'Helpers/Styled.helpers'

import { RootS } from './IconPlatform.styled'

interface IconPlatformPropsI {
  size?: SizeComponentI,
  icon: IconNameI,
  className?: string
}

const IconPlatform = (props: IconPlatformPropsI) => {
  const { icon, className, size } = props

  return (
    <RootS icon={icon} className={className} size={size} {...passAlongMicroData(props)} />
  )
}

export default styled(IconPlatform)``
