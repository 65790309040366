import { useMemo, useState } from 'react'

import useSWR from 'swr'

import { fetcher } from './fetcher'
import type { CompanyI } from './useCompany.hook'

interface OptionsI {
  onlyOwner?: boolean,
  search?: string | null,
  orderBy?: string | null,
}

interface ReturnI {
  data: Array<CompanyFromListI>,
  isLoading: boolean,
  isError: boolean,
  mutate: any,
}

type CompanyFromListI = CompanyI & {
  count_users: number,
  count_accounts: number,
}

const useCompanies = (options: OptionsI = {}) : ReturnI => {
  let query = '?'

  if (options.search) {
    query += `search=${options.search}`
  }

  if (options.orderBy) {
    query += `&orderBy=${options.orderBy}`
  }

  const [isRefreshIntervalEnabled, setIsRefreshIntervalEnabled] = useState(false)

  const { data, error, mutate } = useSWR<Array<CompanyFromListI>>(`user/companies${options.onlyOwner ? '/onlyowner' : ''}${query}`, fetcher('GET'), {
    refreshInterval: isRefreshIntervalEnabled ? 2000 : 60000
  })

  const memoData = useMemo(() => {
    setIsRefreshIntervalEnabled(data?.some(d => !d.is_completed) || false)
    return data
  }, [data, error])

  return {
    data: memoData ?? [],
    isLoading: !error && !memoData,
    isError: error,
    mutate
  }
}

export default useCompanies
