import React from 'react'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'

interface TooltipPermissionGlobalProps {
  id?: string
}

const TooltipPermissionGLobal = (props: TooltipPermissionGlobalProps) => {
  const intl = useIntl()

  return (
    <ReactTooltip className='tooltilp-customize' id={props.id ?? 'tip-global-permission'} getContent={(dataTip) => {
      if (!dataTip) return null

      const permissions : Array<any> | string = (() => {
        try {
          return JSON.parse(dataTip)
        } catch (error) {
          return dataTip
        }
      })()

      if (typeof permissions === 'string') {
        return (
          <div>
            {permissions}
          </div>
        )
      }

      const permissionsPlan = permissions.filter(p => !p.canUse && typeof p.count === 'number')
      const permissionsRole = permissions.filter(p => !p.canUse && !(typeof p.count === 'number'))
      return (
        <div>
          {permissionsRole.length > 0
            ? (
              <>
                {intl.formatMessage({ id: 'Permissões necessárias:' })}
                <br/>
                <br/>
                {permissionsRole.filter(p => !p.canUse).map(p => (
                  <div color="white" key={p.id}>- {intl.formatMessage({ id: `permission.${p.id}` })}</div>
                ))}
              </>
              )
            : null
          }
          {permissionsPlan.length > 0
            ? (
                <>
                  {intl.formatMessage({ id: 'Seu plano atual:' })}
                  <br/>
                  <br/>
                  {permissionsPlan.filter(p => !p.canUse).map(p => (
                    <div color="white" key={p.id}>
                      - {intl.formatMessage({ id: `permission.${p.id}` })}: {p.countEnabled >= 0 ? `${p.count} ${intl.formatMessage({ id: 'de' })} ${p.countEnabled}` : intl.formatMessage({ id: 'Não permite' })}
                    </div>
                  ))}
                </>
              )
            : null
          }
        </div>
      )
    }} />
  )
}

export default TooltipPermissionGLobal
