import React from 'react'

import styled from 'styled-components/macro'

import ErrorBoundary from 'Components/ErrorBoundary'

import { passAlongMicroData } from 'Helpers/MicroData.helper'
import type { SizeComponentI } from 'Helpers/Styled.helpers'

import IconAdmin from '../../../public/images/icon-admin.svg'
import IconAdwords from '../../../public/images/icon-adwords.svg'
import IconAmazon from '../../../public/images/icon-amazon.svg'
import IconAnalytics from '../../../public/images/icon-analytics.svg'
import IconBack from '../../../public/images/icon-back.svg'
import IconCalendar from '../../../public/images/icon-calendar.svg'
import IconCampaignManager from '../../../public/images/icon-campaignmanager.svg'
import IconCardAmex from '../../../public/images/icon-card-amex.svg'
import IconCardCartes from '../../../public/images/icon-card-cartes.svg'
import IconCardDiners from '../../../public/images/icon-card-diners.svg'
import IconCardDiscover from '../../../public/images/icon-card-discover.svg'
import IconCardEftPos from '../../../public/images/icon-card-eftpos.svg'
import IconCardJCB from '../../../public/images/icon-card-jcb.svg'
import IconCardMastercard from '../../../public/images/icon-card-mastercard.svg'
import IconCardUnionPay from '../../../public/images/icon-card-unionpay.svg'
import IconCardVisa from '../../../public/images/icon-card-visa.svg'
import IconCheck from '../../../public/images/icon-check.svg'
import IconChrevon from '../../../public/images/icon-chrevon.svg'
import IconClose from '../../../public/images/icon-close.svg'
import IconCompanyFill from '../../../public/images/icon-company-fill.svg'
import IconCompany from '../../../public/images/icon-company.svg'
import IconConnectionFill from '../../../public/images/icon-connection-fill.svg'
import IconConnection from '../../../public/images/icon-connection.svg'
import IconCopy from '../../../public/images/icon-copy.svg'
import IconCriteo from '../../../public/images/icon-criteo.svg'
import IconDoubleClick from '../../../public/images/icon-double-click.svg'
import IconEdit from '../../../public/images/icon-edit.svg'
import IconEnter from '../../../public/images/icon-enter.svg'
import IconError from '../../../public/images/icon-error.svg'
import IconFacebook from '../../../public/images/icon-facebook.svg'
import IconFilter from '../../../public/images/icon-filter.svg'
import IconFunnel from '../../../public/images/icon-funnel.svg'
import IconGoogle from '../../../public/images/icon-google.svg'
import IconHelpFill from '../../../public/images/icon-help-fill.svg'
import IconHelp from '../../../public/images/icon-help.svg'
import IconHubSpot from '../../../public/images/icon-hubspot.svg'
import IconLinkedin from '../../../public/images/icon-linkedin.svg'
import IconLinx from '../../../public/images/icon-linx.svg'
import IconLock from '../../../public/images/icon-lock.svg'
import IconLog from '../../../public/images/icon-log.svg'
import IconLogo from '../../../public/images/icon-logo.svg'
import IconLogout from '../../../public/images/icon-logout.svg'
import IconMicrosoft from '../../../public/images/icon-microsoft.svg'
import IconPipedrive from '../../../public/images/icon-pipedrive.svg'
import IconPlus2 from '../../../public/images/icon-plus-2.svg'
import IconPlus from '../../../public/images/icon-plus.svg'
import IconRDStation from '../../../public/images/icon-rdstation.svg'
import IconRenew from '../../../public/images/icon-renew.svg'
import IconRoutinesFill from '../../../public/images/icon-routines-fill.svg'
import IconRoutines from '../../../public/images/icon-routines.svg'
import IconSalesforce from '../../../public/images/icon-salesforce.svg'
import IconSearch from '../../../public/images/icon-search.svg'
import IconTaboola from '../../../public/images/icon-taboola.svg'
import IconTikTok from '../../../public/images/icon-tiktok.svg'
import IconTrash from '../../../public/images/icon-trash.svg'
import IconTwitter from '../../../public/images/icon-twitter.svg'
import IconVtex from '../../../public/images/icon-vtex.svg'
import IconYoutube from '../../../public/images/icon-youtube.svg'
import IconZoomIn from '../../../public/images/icon-zoom-in.svg'
import IconZoomOut from '../../../public/images/icon-zoom-out.svg'
import { RootS } from './IconSVG.styled'

export const IconName = {
  'card-amex': IconCardAmex,
  'card-cartes': IconCardCartes,
  'card-diners': IconCardDiners,
  'card-discover': IconCardDiscover,
  'card-eftpos': IconCardEftPos,
  'card-jcb': IconCardJCB,
  'card-mastercard': IconCardMastercard,
  'card-unionpay': IconCardUnionPay,
  'card-visa': IconCardVisa,
  admin: IconAdmin,
  adwords: IconAdwords,
  amazon: IconAmazon,
  'amazon-ads': IconAmazon,
  analytics: IconAnalytics,
  back: IconBack,
  calendar: IconCalendar,
  check: IconCheck,
  chrevon: IconChrevon,
  close: IconClose,
  company: IconCompany,
  'company-fill': IconCompanyFill,
  connection: IconConnection,
  'connection-fill': IconConnectionFill,
  copy: IconCopy,
  criteo: IconCriteo,
  displayVideo360: IconDoubleClick,
  doubleclick: IconDoubleClick,
  campaignmanager: IconCampaignManager,
  edit: IconEdit,
  enter: IconEnter,
  error: IconError,
  facebook: IconFacebook,
  filter: IconFilter,
  funnel: IconFunnel,
  google: IconGoogle,
  help: IconHelp,
  'help-fill': IconHelpFill,
  linkedin: IconLinkedin,
  linx: IconLinx,
  lock: IconLock,
  log: IconLog,
  logo: IconLogo,
  logout: IconLogout,
  microsoft: IconMicrosoft,
  pipedrive: IconPipedrive,
  plus: IconPlus,
  plus2: IconPlus2,
  rdstation: IconRDStation,
  renew: IconRenew,
  routines: IconRoutines,
  'routines-fill': IconRoutinesFill,
  salesforce: IconSalesforce,
  search: IconSearch,
  taboola: IconTaboola,
  tiktok: IconTikTok,
  'tiktok-social': IconTikTok,
  trash: IconTrash,
  twitter: IconTwitter,
  vtex: IconVtex,
  youtube: IconYoutube,
  hubspot: IconHubSpot,
  'zoom-in': IconZoomIn,
  'zoom-out': IconZoomOut
}

export type IconNameI = keyof (typeof IconName)

export interface IconSVGPropsI {
  icon: IconNameI,
  className?: string,
  onClick?: () => void,
  size?: SizeComponentI,
  rotate?: number,
}

const IconSVG = (props: IconSVGPropsI) => {
  const { size = 'normal', rotate = 0 } = props
  const ComponentIcon = IconName[props.icon]

  return (
    <ErrorBoundary>
      <RootS rotate={rotate} size={size} className={props.className} onClick={props.onClick} data-name={props.icon} {...passAlongMicroData(props)}>
        <ComponentIcon/>
      </RootS>
    </ErrorBoundary>
  )
}

export default styled(IconSVG)<IconSVGPropsI>``
