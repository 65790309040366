import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { addAlert } from 'Store/Actions/alerts.action'
import { getCompanyTemp, createCompanyTemp, setCreateCompany, registerCompany, clearCreateCompany } from 'Store/Actions/Company'

import BackButton from 'Components/BackButton'
import Card from 'Components/Container/card.component'
import Icon from 'Components/Icon'
import InputReadOnly from 'Components/Inputs/inputReadOnly.component'
import LoadingDefault from 'Components/Loadings/default.component'
import ProgressBar from 'Components/Loadings/progressBar.component'
import Text from 'Components/Text'
import Title from 'Components/Text/title.component'
import Tour from 'Components/Tour/tour.component'

import { getCroppedImg } from 'Helpers/Image.helper'

import FormColor from './components/formColor.component'
import FormFinish from './components/formFinish.component'
import FormIcon from './components/formIcon.component'
import FormName from './components/formName.component'
import FormUrl from './components/formUrl.component'

interface CreateCompanyInterface {
  step: 'new' | 'url' | 'icon' | 'color' | 'finish'
}

interface ConfigStepInterface {
  percentageProgressBar: number,
  nextStep: CreateCompanyInterface['step'],
  focusOn: string,
  isValidToNext: boolean,
  changeByValue?: string | number,
  // eslint-disable-next-line
  componentForm: () => JSX.Element,
}

export default function CreateCompany () {
  const { step } = useParams<{ step: string }>()
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  // @ts-ignore
  const company = useSelector((state) => state.company.createCompany)

  async function getTempCompanyToInject () {
    const response = await getCompanyTemp()
    const { status } = response
    const { name: companyName, slug, icon, color, position } = response?.companyTemp || {}
    const parsedPosition = position && position !== '{}' ? JSON.parse(position) : {}
    const positionAreaPixels = parsedPosition.positionAreaPixels || { width: 800, height: 200, x: 0, y: 0 }
    let croppedImage : string | null = ''

    if (status === 'success') {
      try {
        if (icon) {
          const iconOriginProxied = `${process.env.REACT_APP_API_URL}proxy/img?url=${icon}`

          croppedImage = await getCroppedImg(
            iconOriginProxied,
            positionAreaPixels
          )
        }
      } catch (e) {
        console.error(e)
      }

      dispatch(setCreateCompany({
        ...company,
        companyName,
        isValidName: !!companyName,
        slug,
        isValidSlug: !!slug,
        icon: croppedImage,
        iconOriginal: icon,
        isValidIcon: !!icon === false ? null : !!icon,
        positionAreaPixels,
        positionComponent: parsedPosition.positionComponent,
        color: color || '#CCCCCC'
      }))

      if (!companyName) history.replace('/companies/new')
      else if (!slug) {
        history.replace('/companies/new')
        history.push('/companies/url')
      } else if (!icon) {
        history.replace('/companies/new')
        history.push('/companies/url')
        history.push('/companies/icon')
      } else if (step === 'color') {
        history.replace('/companies/new')
        history.push('/companies/url')
        history.push('/companies/icon')
        history.push('/companies/color')
      } else {
        history.replace('/companies/new')
        history.push('/companies/url')
        history.push('/companies/icon')
        history.push('/companies/color')
        history.push('/companies/finish')
      }
    } else {
      history.replace('/companies/new')
    }

    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    getTempCompanyToInject()
  }, [])

  const { percentageProgressBar, nextStep, componentForm: ComponentForm, focusOn, isValidToNext, changeByValue } = ((): ConfigStepInterface => {
    switch (step) {
      case 'url':
        return {
          percentageProgressBar: 25,
          nextStep: 'icon',
          focusOn: '.page-new-company__input-read-only',
          isValidToNext: company.isValidSlug,
          componentForm: FormUrl
        }
      case 'icon':
        return {
          percentageProgressBar: 50,
          nextStep: 'color',
          focusOn: '.page-new-company__logo-result',
          isValidToNext: company.isValidIcon,
          componentForm: FormIcon
        }
      case 'color':
        return {
          percentageProgressBar: 75,
          nextStep: 'finish',
          focusOn: '.page-new-company__button-result',
          isValidToNext: true,
          componentForm: FormColor
        }
      case 'finish':
        return {
          percentageProgressBar: 99,
          nextStep: 'finish',
          focusOn: '.page-new-company__name-result',
          isValidToNext: true,
          componentForm: FormFinish
        }
      default:
        return {
          percentageProgressBar: 0,
          nextStep: 'url',
          focusOn: '.page-new-company__name-result',
          isValidToNext: company.isValidName,
          changeByValue: company.companyName,
          componentForm: FormName
        }
    }
  })()

  async function registerCompanyHard () {
    try {
      setIsLoading(true)
      const response = await registerCompany({
        name: company.companyName,
        slug: company.slug,
        icon: company.icon,
        color: company.color,
        timezone: 'America/Sao_Paulo'
      })

      dispatch(clearCreateCompany())
      history.push('/home/company-successful')
      return { id: await response.id }
    } catch (e: any) {
      setIsLoading(false)
      dispatch(
        addAlert({ title: e.message, type: 'error' })
      )
    }
  }

  const handleGoToNextStep = () => {
    if (step === 'finish') {
      registerCompanyHard()
    } else {
      history.push(`/companies/${nextStep}`)
      createCompanyTemp(company)
    }
  }

  const handleBack = () => {
    history.goBack()
    createCompanyTemp(company)
  }

  return (

    <div className="page-new-company">
      <Card isCentered={true} radius='right' className='page-new-company__left-side'>
        <div className='page-new-company__content-main'>
          <div className='page-new-company__header'>
            {percentageProgressBar === 0 ? null : <BackButton onClick={handleBack} label='Voltar' className='page-new-company__button-back' />}
            <Icon name='logo' />
          </div>
          <ProgressBar percentage={percentageProgressBar} className='page-new-company__progress-bar' />
          {step === 'finish'
            ? <Title className='page-new-company__title'>
              <span className='page-new-company__title-highlight'>{intl.formatMessage({ id: 'Falta pouco' })} </span>
              {intl.formatMessage({ id: 'para sua empresa ser criada!' })}
            </Title>
            : <Title className='page-new-company__title'>
              {intl.formatMessage({ id: 'Insira as informações para criar sua empresa' })}
            </Title>

          }

          {isLoading
            ? <LoadingDefault />
            : <ComponentForm />
          }
        </div>
        <div className='page-new-company__footer'>
          {step === 'url'
            ? <a className='page-new-company__form-url__link-disclaimer'>
              {intl.formatMessage({ id: 'Depois que definida não poderá ser mais alterada.' })}
            </a>
            : null}
          <Button onClick={handleGoToNextStep} disabled={!isValidToNext || isLoading} size='large' variant='contained' className='btn btn-light page-new-company__button-next-step'>
            <FormattedMessage id={step === 'finish' ? 'Criar  empresa' : 'Próximo'} />
          </Button>
          <div className='content-links'>
            <Link to="/Home">
              <FormattedMessage id="Voltar para Home" />
            </Link>
          </div>
        </div>
      </Card>
      <div className='page-new-company__right-side' style={{ width: '100%' }}>
        <img src="/images/bg-company-create.svg" className="page-new-company__background" alt="background" />
        {step === 'finish' ? null : <Tour anchor='.page-new-company__right-side' element={focusOn} changeByValue={changeByValue} />}
        <Card isCentered={true} className='page-new-company__card-result'>
          <Text isBold>{intl.formatMessage({ id: 'Visualização rápida:' })}</Text>
          <span className='divider page-new-company__divider' />
          <InputReadOnly className='page-new-company__input-read-only' icon="lock" value={(company.slug || 'tetris_exemplo') + '.' + localStorage.getItem('odash-app:domain-suffix')} />
          <div className={`page-new-company__logo-result ${company.icon ? 'page-new-company__logo-result--with-img' : ''}`}>
            {company.icon ? <img src={company.icon} alt="ops" /> : <Text>{intl.formatMessage({ id: 'Ícone da suaempresa aqui' })}</Text>}
          </div>
          <Title className='page-new-company__name-result'>{company.companyName || 'Nome da sua empresa'}</Title>
          <form noValidate autoComplete="off" className='shortform'>
            <TextField
              id="email"
              type="email"
              placeholder='Insira seu e-mail'
              label={intl.formatMessage({ id: 'E-mail' })} />

            <TextField
              id="password"
              type="password"
              placeholder='Digite a sua senha'
              label={intl.formatMessage({ id: 'Senha' })} />
          </form>
          <Button size='large' variant='contained' className='btn btn-light page-new-company__button-result' style={{ backgroundColor: company.color }}>
            <FormattedMessage id='Acessar plataforma' />
          </Button>

          <div className='content-links page-new-company__links-result'>
            <Link to="">
              <FormattedMessage id="Esqueceu sua senha?" />
            </Link>
            <Link to="">
              <FormattedMessage id="Cadastre-se" />
            </Link>
          </div>
        </Card>
      </div>
    </div>
  )
}
