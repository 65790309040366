import styled, { css } from 'styled-components/macro'

export const RootS = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    ${({ theme }) => css(theme.fonts.types.h3)}
  }

  .alert {
    color: #E4022C;
    margin-left: 20px;
  }
`
