import type { ReactNode } from 'react'
import React from 'react'

import get from 'lodash/get'

type ReactElementWithProps = React.ReactElement<any, string | React.JSXElementConstructor<any>>

export const someChildrenHas = <T, >(children: ReactNode, property: string, value: T) => {
  let hasValue = false

  React.Children.forEach(children, child => {
    if (get(child as ReactElementWithProps, `props.${property}`) === value) {
      hasValue = true
    }
  })

  return hasValue
}
