import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

interface RootI {
  color?: string
}

export const RootS = styled.div<RootI>`
  width: 100%;
  min-height: 100vh;

  position: relative;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  border-top: 6px solid ${props => props.color ?? 'var(--yellow)'};

  form {
    width: 100%;
  }
`

export const FormS = styled.div`
  width: 360px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`

export const LinkS = styled(Link)`
  width: 100%;

  margin: 20px 0 0;

  border-bottom-color: rgb(77, 78, 83);
  outline: rgb(77, 78, 83);

  font-family: Roboto,sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4d4e53;
  text-align: right;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  text-decoration: underline;
  vertical-align: baseline;
`
