import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import IconSVG from 'Components/IconSVG'

import type { SizeComponentI } from 'Helpers/Styled.helpers'

import { ListStyled, InnerButtonStyled, ItemListStyled, RootStyled } from './Filter.styled'

interface FilterPropsI {
  size?: SizeComponentI,
  orderBy?: string | null
  onChangeOrderBy?: (value: string) => void
}

interface TypeFilterI {
  id: string
  value: string
}

const TYPE_FILTERS : Array<TypeFilterI> = [{
  id: 'A - Z',
  value: 'A-Z'
}, {
  id: 'Z - A',
  value: 'Z-A'
}, {
  id: 'Mais recentes',
  value: 'newest'
}, {
  id: 'Mais antigos',
  value: 'oldest'
}]

const Filter = (props: FilterPropsI) => {
  const { size = 'normal' } = props
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClickFilter = (type: TypeFilterI) => {
    const { onChangeOrderBy } = props

    onChangeOrderBy && onChangeOrderBy(type.value)
    setIsOpen(!isOpen)
  }

  return (
    <RootStyled size={size}>
      <InnerButtonStyled onClick={() => setIsOpen(!isOpen)}>
        <IconSVG icon='filter'/>
      </InnerButtonStyled>
      <ListStyled isOpen={isOpen}>
        {TYPE_FILTERS.map(type => (
          <ItemListStyled
            key={type.id}
            isActive={type.value === props.orderBy}
            onClick={() => handleClickFilter(type)}
          >
            <label>{intl.formatMessage({ id: type.id })}</label>
          </ItemListStyled>
        ))}
      </ListStyled>
    </RootStyled>
  )
}

export default Filter
