export const routes = {
  public: {
    welcome: '/welcome',
    signupWithInvite: '/welcome/signup/invite/:invite',
    signup: '/welcome/signup',
    signupSimple: '/signup',
    waitingConfirmation: '/waiting-confirmation',
    activateCode: {
      path: '/activate/:activationCode',
      params: {
        activationCode: 'string'
      }
    },
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: {
      path: '/reset-password/:email/:recoveryCode',
      params: {
        email: 'string',
        recoveryCode: 'string'
      }
    },
    token: '/token',
    odashLogin: '/odash/login',
    odashForgotPassword: '/odash/forgot-password',
    odashInvite: {
      path: '/odash/invite/:invite',
      params: {
        invite: 'string'
      }
    }
  },
  private: {
    companyAccounts: {
      path: '/companies/:id/accounts',
      params: {
        id: 'string'
      }
    },
    companyAccountEdit: {
      path: '/companies/:id/account/:idAccount',
      params: {
        id: 'string',
        idAccount: 'string'
      }
    },
    linkTemporary: '/companies/:id/links-temporary',
    companyEdit: '/companies/:id/edit',
    companyEditStepNew: '/companies/:id/edit/:step/new',
    companyEditStep: '/companies/:id/edit/:step',
    companyConnectionEdit: '/companies/:id/edit/connections/:account_id',
    logsByJobs: '/companies/:id/logs/:typeJobs',
    logsByCompany: '/companies/:id/logs',
    log: '/companies/:id/log/:idLog',
    jobsByType: '/companies/:id/jobs/:typeJobs',
    jobs: '/companies/:id/jobs',
    companyCreateStep: '/companies/:step',
    companies: '/companies',
    connections: '/connections',
    connectionsShow: '/connections/:account_id',
    connectionNew: '/connection/new',
    connectionNewCompany: '/connection/new/:company_id',
    connectionEditCompany: '/connection/edit/:company_id/:account_id',
    connectionEditCompanyNewConnection: '/connection/edit/:company_id/:account_id/new',
    connectionEdit: '/connection/:account_id',
    connectionEditNewConnection: '/connection/:account_id/new',
    routines: '/routines',
    logs: '/logs/:company/:routine',
    home: '/home',
    companySuccessful: '/home/company-successful',
    archiveds: '/archiveds',
    subscription: '/subscription',
    odash: {
      path: '/odash/:company/:slug',
      params: {
        company: 'string'
      }
    },
    odashAdmin: {
      path: '/odash/:company/:slug/admin',
      params: {
        company: 'string'
      }
    },
    superAdmin: '/admin',
    notFound: '/not-found'
  }
}
