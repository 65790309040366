import type { ReactNode } from 'react'
import React from 'react'

import WarningPaymentTrial from 'Components/WarningPaymentTrial'

import { MicroData } from 'Helpers/MicroData.helper'
import { someChildrenHas } from 'Helpers/React.helpers'

import { ContentInnerS, ContentS, HeaderInnerS, HeaderPocketS, HeaderS, RootS, TopExtraS, WrapperTitleS } from './TemplateDefault.styled'

interface TemplateDefaultPropsI {
  title?: string | ReactNode,
  isPocket?: boolean,
  isFullContainer?: boolean,
  leftHeader?: ReactNode,
  rightHeader?: ReactNode,
  children?: ReactNode,
  topExtra?: ReactNode,
  withoutHeader?: boolean
}

const TemplateDefault = (props: TemplateDefaultPropsI) => {
  const { title, leftHeader, rightHeader, children, topExtra, isPocket = false, isFullContainer = false, withoutHeader = false } = props
  const hasSomeHeader = !!(leftHeader || rightHeader)
  const haveSomeChildrenWithFullContainer = someChildrenHas(children, 'isFullContainer', true)

  const headersDefault = (
    <HeaderS className='px-4'>
      <HeaderInnerS>
        {title && (
          <WrapperTitleS>
            {typeof title === 'string' ? <h3 className="fw-bold mb-0">{title}</h3> : title}
          </WrapperTitleS>
        )}
        {leftHeader}
        {rightHeader}
      </HeaderInnerS>
    </HeaderS>
  )

  const headersWhenIsPocket = hasSomeHeader ? (
    <HeaderPocketS isBaseContentWidth={isFullContainer} data-component-type={MicroData.HEADER}>
      {leftHeader}
      {rightHeader}
    </HeaderPocketS>
  ) : null

  const contentDefault = (
    <ContentS haveSomeChildrenWithFullContainer={haveSomeChildrenWithFullContainer} data-component-type={MicroData.MAIN_CONTENT}>
      <ContentInnerS data-component-type={MicroData.CONTENT}>
        {children}
      </ContentInnerS>
    </ContentS>
  )

  const contentWhenIsPocket = (
    <ContentInnerS isBaseContentWidth={isFullContainer} data-component-type={MicroData.CONTENT}>
      {children}
    </ContentInnerS>
  )

  return (
    <RootS>
      {withoutHeader ? null : (isPocket ? headersWhenIsPocket : headersDefault)}
      {topExtra ? <TopExtraS>{topExtra}</TopExtraS> : null}
      <div className={isPocket ? '' : 'px-4'}>
        <WarningPaymentTrial />
        {isPocket ? contentWhenIsPocket : contentDefault}
      </div>
    </RootS>
  )
}

export default TemplateDefault
