import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import usePrevious from 'Hooks/usePrevious.hook'
import useWindowSize from 'Hooks/useWindowSize.hook'

interface TourInteface {
  anchor: string,
  element: string,
  changeByValue?: string | number
}

interface StateConfigElementInteface {
  width: number,
  height: number,
  left: number,
  top: number
}

export default function Tour (props: TourInteface) {
  const { anchor, element, changeByValue } = props
  const windowSize = useWindowSize()
  const previousWidthBrowser = usePrevious(windowSize?.width)
  const previousHeightBrowser = usePrevious(windowSize?.height)
  const previousElement = usePrevious(element)
  const previousValue = usePrevious(changeByValue)
  const [attempt, setAttempt] = useState(0)
  const [hasElement, setHasElement] = useState(false)
  const [configElement, setConfigElement] = useState<StateConfigElementInteface | null>(null)
  const [configAnchor, setConfigAnchor] = useState<StateConfigElementInteface | null>(null)

  useEffect(() => {
    if (hasElement &&
      previousElement === element &&
      previousWidthBrowser === windowSize?.width &&
      previousHeightBrowser === windowSize?.height &&
      String(previousValue).length === String(changeByValue).length) {
      return
    }

    const elementQuery: HTMLElement | null = document.querySelector(element)
    const elementAnchorQuery: HTMLElement | null = document.querySelector(anchor)

    if (elementQuery === null || elementAnchorQuery == null) {
      setTimeout(() => {
        setAttempt(attempt + 1)
      }, 500)
    } else {
      setConfigElement({
        width: (elementQuery.offsetWidth || 0) + 20,
        height: (elementQuery.offsetHeight || 0) + 20,
        left: (elementQuery.getBoundingClientRect().left || 0) - (elementAnchorQuery.getBoundingClientRect().left || 0) - (20 / 2),
        top: (elementQuery.getBoundingClientRect().top || 0) - (elementAnchorQuery.getBoundingClientRect().top || 0) - (20 / 2)
      })
      setConfigAnchor({
        width: elementAnchorQuery.offsetWidth,
        height: elementAnchorQuery.offsetHeight,
        left: elementAnchorQuery.getBoundingClientRect().left,
        top: elementAnchorQuery.getBoundingClientRect().top
      })
      setHasElement(true)
      setAttempt(0)
    }
  }, [attempt, element, anchor, windowSize, changeByValue])

  return ReactDOM.createPortal(
    <div className={`tour tour-${attempt}`} style={{ width: `${configAnchor?.width || '0'}px`, height: `${configAnchor?.height || '0'}px`, left: `${configAnchor?.left || '0'}px`, top: `${configAnchor?.top || '0'}px` }}>
      <div className='tour_focus' style={{ width: `${configElement?.width || '0'}px`, height: `${configElement?.height || '0'}px`, left: `${configElement?.left || '0'}px`, top: `${configElement?.top || '0'}px` }} />
    </div>, document.body)
}
