import { Warning } from '@phosphor-icons/react'

import type { EndTrialsPeriodContentManagerPropsI } from 'ActionsContentManager/EndTrialsPeriod/Hooks/useEndTrialsPeriodContentManager.hook'
import { Button } from 'DesignSystem'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { RootS } from './EndTrialsPeriodEnd.styled'

const EndTrialsPeriodEnd = (props: AuxStepContentManagerI<EndTrialsPeriodContentManagerPropsI>) => {
  const { close, dataContentManager } = props
  const intl = useIntlShort()

  const handleSignAPlan = () => {
    dataContentManager.onStartAPlan()
    close()
  }

  if (dataContentManager.isNearEnd) {
    return (
      <RootS>
        <Warning size={64} color='#F7C347' />
        <h3 className='fw-bold'>{intl('Período de avaliação próximo do fim')}</h3>
        <div className='d-flex flex-row mt-3'>
          <div className='d-flex flex-column'>
            <p>{intl('Seu período de testes do NeoDash está chegando ao fim, portanto esperamos que esteja aproveitando ao máximo essa experiência.')}</p>
            <p className='mt-3'>{intl('Lembrando que você já pode conferir nossas opções de planos disponíveis para fazer sua assinatura e continuar potencializando sua estratégia de marketing com a assertividade que só o NeoDash te proporciona.')}</p>
          </div>
          <img src="/images/image-dash-1.svg" alt="Dash" />
        </div>
        <div className='d-flex flex-row gap-3'>
          <Button onClick={() => close()}>{intl('Continuar avaliação')}</Button>
          <Button onClick={handleSignAPlan}>{intl('Conferir planos')}</Button>
        </div>
      </RootS>
    )
  }

  return (
    <RootS>
        <Warning size={64} color='#F7C347' />
        <h3 className='fw-bold'>{intl('Seu período de avaliação chegou ao fim')}</h3>
        <div className='d-flex flex-row mt-3'>
          <div className='d-flex flex-column'>
            <p>{intl('O período de testes do NeoDash infelizmente acabou. Confira nossas opções de planos para fazer sua assinatura e continuar utilizando todos os recursos que vão te auxiliar a alcançar o potencial máximo de suas estratégias de marketing.')}</p>
            <p className='mt-3 obs'><span className='red'>*</span>{intl('As configurações feitas durante o período de avaliação serão mantidas por 30 dias. Você ainda pode utilizar o NeoDash sem um plano, mas conseguirá visualizar apenas os dados que foram registrados durante a avaliação. Não será possível fazer novas configurações ou editar recursos já configurados.')}</p>
          </div>
          <img src="/images/image-dash-1.svg" alt="Dash" />
        </div>
        <div className='d-flex flex-row gap-3 mt-3'>
          <Button onClick={handleSignAPlan}>{intl('Conferir planos')}</Button>
        </div>
    </RootS>
  )
}

EndTrialsPeriodEnd.dialogProps = {
  style: {
    maxWidth: '758px'
  }
}

export default EndTrialsPeriodEnd
