import React from 'react'
import { useIntl } from 'react-intl'

import platforms from 'Config/plataform.conf'

import IconPlatform from 'Components/IconPlatform'
import type { IconNameI } from 'Components/IconSVG/IconSVG.component'
import SelectFilter from 'Components/SelectFilter'
import Text from 'Components/Text'

import { ItemS } from './SelectPlatformFilter.styled'

interface SelectPlatformFilterPropsI {
  onChange: (id: string) => void
}

const SelectPlatformFilter = (props: SelectPlatformFilterPropsI) => {
  const { onChange } = props
  const intl = useIntl()

  return (
    <SelectFilter
      icon='connection'
      placeholder={intl.formatMessage({ id: 'Selecionar plataforma' })}
      onChange={onChange}
    >
      {platforms.map((platform, index) => (
        <ItemS id={platform.platform} key={`${platform}${index}`} data-search={platform.name}>
          <IconPlatform icon={platform.platform as IconNameI} />
          <Text>{platform.name}</Text>
        </ItemS>
      ))}
    </SelectFilter>
  )
}

export default SelectPlatformFilter
