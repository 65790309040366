import { addAlert } from 'Store/Actions/alerts.action'

import { apiPOST } from 'Services/Api.service'

export const uploadIconCompany = async (data:any) => {
  try {
    const apiRequest = await apiPOST<any>(`company/${data.id}/icon-odash`, data)
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}

export const uploadIconCompanyTemp = async (data:any) => {
  try {
    const apiRequest = await apiPOST<any>('/company/temp/uploadIconRaw', data)
    const response = await apiRequest
    return { ...response }
  } catch (e:any) {
    return addAlert({ title: e.message, type: 'error' })
  }
}
