export const flat = (value: string) : string =>
  value.replace(/[^A-Z0-9]+/ig, '-').toLowerCase()

export const randomString = (strLength?: number, charSet?: string) => {
  const result = []

  strLength = strLength || 15
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  while (strLength--) { // (note, fixed typo)
    result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)))
  }

  return result.join('')
}
