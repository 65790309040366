import React from 'react'

import { addMonths } from 'date-fns'

import Calendar from 'Components/Calendar'
import { oldMonth } from 'Components/Calendar/Calendar.helper'

import { RootS } from './MultipleCalendar.styled'

interface MultipleCalendarPropsI {
  currentDate?: Date,
  rangeDate: Date[],
  onChange?: (rangeDate: Date[]) => void,
  onChangeCurrentDate?: (date: Date) => void,
}

const MultipleCalendar = (props: MultipleCalendarPropsI) => {
  const {
    onChange = () => undefined,
    onChangeCurrentDate = () => undefined,
    rangeDate = [],
    currentDate = new Date()
  } = props
  const nextMonth = addMonths(currentDate, 1)

  const handleOnChange = (rangeDate: Date[]) => {
    onChange(rangeDate)
  }

  const handleOnChangeCurrentDate = (date: Date) => {
    onChangeCurrentDate(date)
  }

  const handleOnChangeCurrentDateNextMonth = (date: Date) => {
    onChangeCurrentDate(oldMonth(date))
  }

  return (
    <RootS>
      <Calendar
        currentDate={currentDate}
        rangeDate={rangeDate}
        onChange={handleOnChange}
        onChangeCurrentDate={handleOnChangeCurrentDate}
        enableRightArrow={false}
      />
      <Calendar
        currentDate={nextMonth}
        rangeDate={rangeDate}
        onChange={handleOnChange}
        onChangeCurrentDate={handleOnChangeCurrentDateNextMonth}
        enableLeftArrow={false}
      />
    </RootS>
  )
}

export default MultipleCalendar
