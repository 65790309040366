import React from 'react'

import styled from 'styled-components/macro'

import type { IconSVGPropsI } from 'Components/IconSVG'
import IconSVG from 'Components/IconSVG'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

import { RootS, RootLinkS, RootAS } from './Button.styled'
import type { RootPropsI } from './Button.styled'

interface BasePropsI {
  label?: string,
  type?: RootPropsI['typeButton'],
  size?: RootPropsI['size'],
  isFit?: RootPropsI['isFit'],
  icon?: IconSVGPropsI['icon'],
  isDisabled?: boolean,
  className?: string,
}

interface BaseWithClickPropsI extends BasePropsI {
  onClick: () => void,
}

interface BaseWithLinkPropsI extends BasePropsI {
  to: string | { pathname: string }
}

interface BaseWithLinkRawPropsI extends BasePropsI {
  href: string
}

type ButtonPropsI = BaseWithClickPropsI | BaseWithLinkPropsI | BaseWithLinkRawPropsI

const Button = (props: ButtonPropsI) => {
  const { label, type = 'primary', size = 'normal', icon, isDisabled = false, className, isFit } = props
  const propsComponentRoot = { typeButton: type, disabled: isDisabled, size, className, isFit }
  const baseChildren = (
    <>
      {icon ? <IconSVG size={size} icon={icon} /> : null}
      {label ? <label>{label}</label> : null}
    </>
  )

  if ((props as BaseWithLinkPropsI).to) {
    return (
      <RootLinkS to={isDisabled ? '#' : (props as BaseWithLinkPropsI).to} {...propsComponentRoot} {...passAlongMicroData(props)}>
        {baseChildren}
      </RootLinkS>
    )
  } else if ((props as BaseWithLinkRawPropsI).href) {
    return (
      <RootAS href={isDisabled ? '#' : (props as BaseWithLinkRawPropsI).href} {...propsComponentRoot} {...passAlongMicroData(props)}>
        {baseChildren}
      </RootAS>
    )
  } else {
    return (
      <RootS onClick={isDisabled ? () => undefined : (props as BaseWithClickPropsI).onClick} {...propsComponentRoot} {...passAlongMicroData(props)}>
        {baseChildren}
      </RootS>
    )
  }
}

export default styled(Button)``
