import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { Button, Col, Row } from 'DesignSystem'

import { generateLinkTo } from 'Helpers/Route.helper'

function Finish ({
  setSizeDialog,
  dataContentManager,
  setShowFooter,
  next
}: any) {
  const intl = useIntl()
  const history = useHistory()
  useEffect(() => {
    setSizeDialog('lg')
    setShowFooter(false)
  }, [setSizeDialog])

  const handleFinishCompanyCreate = async () => {
    history.push(generateLinkTo('companyEditStep', { id: dataContentManager.id, step: 'connections' }))
  }

  return (
    <Row className="text-center flex-column">
      <Col>
        <img src="/images/isotype.png" width={32} alt="Logo" />
      </Col>
      <Col className="mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Workspace criado!' })}</h2>
      </Col>
      <Col className="mt-3">
        <p className="body1 text-center" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'Pode celebrar, seu workspace foi criado! Agora, você poderá configurar suas conexões com as plataformas de mídia para começar a utilizar o NeoDash.' }) }}/>
      </Col>
      <Col className='mt-3 mb-3'>
        <div>
          <img src="images/happy.png" alt="" />
        </div>
        <div className='mt-4'>
          <Button color="primary" onClick={handleFinishCompanyCreate}>
            {intl.formatMessage({ id: 'Configurar conexões' })}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

Finish.noDisplayCloseButton = true

export default Finish
