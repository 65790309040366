import { useEffect } from 'react'

import type { DataCancelPlanContentManagerI } from 'ActionsContentManager/CancelPlan/Hooks/useStartCancelPlan.hook'
import { Button } from 'DesignSystem'
import useSWR from 'swr'

import { fetcher } from 'Hooks/API/fetcher'
import usePlans from 'Hooks/API/usePlans.hook'
import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

interface ResponsePriceCheckI {
  newPlan: null | {
    id: string,
    product_id: string,
    price_id: string
    name: string,
    currency: string,
  },
  saveMoney: number,
  percentageSaveMoney: number,
  currency: string
}

const BeforeCancelModal = (props: AuxStepContentManagerI<DataCancelPlanContentManagerI>) => {
  const { next, close, dataContentManager } = props
  const intl = useIntlShort()
  const { actions: { checkDowngrade } } = usePlans()
  const { data, error } = useSWR<ResponsePriceCheckI>('/subscription/price/check', fetcher('GET'))

  const handleWantChangePlan = async () => {
    if (!data?.newPlan?.id) return
    const response = await checkDowngrade(data?.newPlan?.id || '')

    if (response.isDowngrade && dataContentManager.onWantChangePlan) {
      dataContentManager.onWantChangePlan && dataContentManager.onWantChangePlan({
        planID: data?.newPlan?.id || '',
        isDowngrade: true,
        downgradeInfo: response
      })
    }
    close()
  }

  useEffect(() => {
    if (!data && !error) return

    if (!data?.newPlan) {
      next()
    }
  }, [data, error])

  if (!data && !error) return null
  if (!data?.newPlan) return null

  return (
    <div>
      <h3 className='fw-bold mt-3'>{intl('Antes de cancelar, talvez podemos te ajudar')}</h3>
      <p>{intl('Percebemos que você não usa alguns recursos que estão inclusos em seu plano. Ao invés de cancelar, você poderia fazer um downgrade para o plano "Nome do Plano" e economizar R$00,00.', { newPlan: data?.newPlan?.name, saveMoney: data?.saveMoney, currency: intl(data?.newPlan?.currency || 'brl') })}</p>
      <img src="/images/image-pork.svg" alt="Safe" />
      <div className='d-flex flex-row gap-4 mt-3'>
        <Button onClick={handleWantChangePlan} color='success'>{intl('Trocar meu plano')}</Button>
        <Button color='error' onClick={() => next()}>{intl('Avançar')}</Button>
      </div>
    </div>
  )
}

export default BeforeCancelModal
