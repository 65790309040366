import { Button, Tag } from 'DesignSystem'
import { IconCloseS } from 'DesignSystem/Modal'
import type DefaultProps from 'DesignSystem/Types'
import styled, { css } from 'styled-components'

interface PlanListProps extends DefaultProps {
  active?: boolean;
}

export const PlansRoot = styled.div`
  display: flex;
  margin-top: 1.5rem;
  padding: 1.5rem;
  padding-right: 1rem;
  background-color: ${({ theme }) => (theme.colors.white)};
  border-radius: ${({ theme }) => (theme.metrics.radius)};
  overflow: hidden;
  position: relative;

  @media(max-width: 768px) {
    flex-direction: column;
  }
`

interface HeaderTableI {
  type?: string
  active?: boolean
}

const HeaderTable = styled.div<HeaderTableI>`
  --header-height: ${({ type }) => type === 'month' ? '190px' : '280px'};

  @media (max-width: 768px) {
    height: auto !important;
  }
`

export const QuestionsCardRoot = styled(HeaderTable)`
  --height: 150px;
  height: var(--height);
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => (theme.colors.gray40)};
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: calc(var(--header-height) - var(--height));
  text-decoration: none;

  p {
    color: #006A7A;
  }


  @media(max-width: 768px) {
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: auto;
  }
`

export const PlansListRoot = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  margin-left: 1rem;
  overflow: hidden;
  width: 100%;

  background-color: ${({ theme }) => (theme.colors.white)};

  li:first-child {
    border-bottom-left-radius: 1rem;
  }

  li:last-child {
    border-bottom-right-radius: 1rem;
  }

  @media(max-width: 768px) {
    flex-direction: column;
    margin-left: 0;
    margin-top: 1rem;
  }
`

export const ButtonWithHover = styled(Button)`
`
export const ButtonWithoutHover = styled(Button)`
`

export const PlanItem = styled.li<PlanListProps>`
  display: flex;
  flex: auto 1;
  flex-direction: column;
  max-width: 210px;
  background-color: ${({ theme, active }) => (active ? '#EBFCFF' : theme.colors.white)};
  z-index: 2;

  ${ButtonWithHover} {
    visibility: hidden;
    display: none;
  }

  ${ButtonWithoutHover} {
    visibility: visible;
  }

  /* :hover {
    background-color: #EBFCFF;
    ${ButtonWithHover} {
      visibility: visible;
      display: initial
    }
    ${ButtonWithoutHover} {
      visibility: hidden;
      display: none;
    }
  } */



  @media(max-width: 768px) {
    max-width: 100%;
    border-radius: 16px;
    margin-top: 1rem;
    background-color: ${({ theme, active }) => (active ? '#EBFCFF' : theme.colors.gray40)};
  }
`

export const PlanHeader = styled(HeaderTable)<PlanListProps>`
  height: var(--header-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 1rem;
  z-index: 2;

  h4 {
    ${({ theme }) => css(theme.fonts.types.subtitle4)}
    font-size: 0.7rem;
  }

  h2 {
    display: inline-block;
    font-size: 1.3rem;
  }

  @media(max-width: 768px) {
    h3 {
      font-size: 1.3rem;
      font-weight: bold;
    }
    h2 {
      display: block;
      font-size: 2rem;
      font-weight: bold;
    }
    h4 {
      font-size: 0.9rem;
    }
  }
`

export const PlanGradeItems = styled.div<PlanListProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  transition: height 0.3s;

  @media (max-width: 768px) {
    max-height: ${({ active }) => active ? 'auto' : '0'};
  }
`

export const PlanGradeItem = styled.div<PlanListProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  border-bottom: 1px solid ${({ theme }) => (theme.colors.primary)};
  border-left: 1px solid ${({ theme }) => (theme.colors.primary)};
  text-align: center;
  padding: 1rem;
  z-index: 2;
  gap: 8px;

  &:last-child {
    display: block;
    border-bottom: none;
    height: 250px;
  }

  img {
    width: 16px;
    margin: 0 0.3rem;
  }

  div {
    color: ${({ theme }) => (theme.colors.dark)};
  }

  @media(max-width: 768px) {
    border-left: none;
    justify-content: space-between;
  }
`

export const PlanVantages = styled.ul`
  position: relative;
  padding: 0;
  list-style: none;
  background-color: ${({ theme }) => (theme.colors.primary)};
  z-index: 1;

  li {
    position: relative;
    min-height: 90px;
    display: flex;
    align-items: center;
    z-index: 3;
  }

  ::after {
    content: "";
    background-color: ${({ theme }) => (theme.colors.primary)};

    position: absolute;
    width: 2000px;
    height: calc(100% + 10rem);
    bottom: -10rem;
    left: -2rem;
    z-index: 0;
  }

  @media(max-width: 768px) {
    display: none;
  }
`

export const PlanGradeToggler = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1rem;
  font-weight: bold;
  justify-content: center;

  display: none;

  div {
    align-items: center;
  }

  @media (max-width: 768px) {
    display: flex;
    div {
      display: flex;
    }
  }
`

export const ChoosePaymentPeriod = styled.div`
  display: flex;
  justify-content: center;
  /* position: absolute; */
  /* top: -60px; */
  /* left: 1px; */
  /* right: 1px; */
  margin: 0 auto;
  background-color: #E8E8E8;
  border-radius: 24px;
  width: fit-content;

  @media(max-width: 768px) {
    position: relative;
    margin-top: 1rem;
    top: 0;
  }
`

export const PaymentPeriodButton = styled.button<PlanListProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: ${({ active }) => (active ? '#ADF4FF' : 'transparent')};
  color: ${({ theme }) => theme.colors.dark};
  border: ${({ active }) => (active ? '1px solid #00BDD9' : 'none')};
  border-radius: 24px;
  padding: 0.5rem 2rem;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};

  @media(max-width: 768px) {
    &:first-child {
      border-radius: 16px 0 0 16px;
    }
    &:last-child {
      border-radius: 0 16px 16px 0;
    }
  }

`

export const TagPlans = styled(Tag)`
  display: inline-block;
  padding: 0 0.4rem;
  font-size: 0.7rem;
  text-align: center;
`

export const IconCloseLocalS = styled(IconCloseS)`
  top: 19px;
  z-index: 3;
`
