import { routes } from 'Routes/config'

type PathPublicI = keyof (typeof routes)['public']
type PathPrivateI = keyof (typeof routes)['private']
type PathI = PathPublicI | PathPrivateI

export const getPath = (path: PathI): string => {
  if (path in routes.public) {
    const pathChoosed = routes.public[path as PathPublicI]

    return typeof pathChoosed === 'string' ? pathChoosed : pathChoosed.path
  }

  if (path in routes.private) {
    const pathChoosed = routes.private[path as PathPrivateI]

    return typeof pathChoosed === 'string' ? pathChoosed : pathChoosed.path
  }

  return '/without-route'
}

export const getPaths = (paths: PathI[]): string[] => {
  return paths.map(getPath)
}

export const generateLinkTo = (path: PathI, params?: { [key: string]: string | undefined }) : string => {
  let pathChoosed = (() => {
    if (path in routes.public) {
      const pathChoosed = routes.public[path as PathPublicI]

      return typeof pathChoosed === 'string' ? pathChoosed : pathChoosed.path
    }

    if (path in routes.private) {
      const pathChoosed = routes.private[path as PathPrivateI]

      return typeof pathChoosed === 'string' ? pathChoosed : pathChoosed.path
    }
  })() || '/wihout-link'

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      pathChoosed = pathChoosed.replace(`:${key}`, value ?? '')
    })
  }

  return pathChoosed
}
