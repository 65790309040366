import { groupBy } from 'lodash'
import useSWR from 'swr'

import { createQuery } from 'Helpers/Url.helper'

import { fetcher } from './fetcher'
import type { RoutineI } from './useRoutineDetails.hook'

export interface RoutineGroupedI {
  platform: string,
  company: {
    id: string,
    name: string,
  },
  routines: Array<RoutineI>
}

interface OptionsI {
  companies?: string[],
  platforms?: string[],
  rangeDate?: string[],
  search?: string | null,
  orderBy?: string | null,
}

interface ReturnI {
  data: Array<RoutineGroupedI>,
  isLoading: boolean,
  isError: boolean
}

const useJobs = (options: OptionsI = {}) : ReturnI => {
  const query = createQuery()

  if (options.search) query.addParams('search', options.search)
  if (options.orderBy) query.addParams('orderBy', options.orderBy)
  if (options.companies && options.companies.length > 0) query.addParams('companies', options.companies)
  if (options.platforms && options.platforms.length > 0) query.addParams('plataforms', options.platforms)
  if (options.rangeDate && options.rangeDate.length > 0) query.addParams('rangeDate', options.rangeDate)

  const { data, error } = useSWR<Array<RoutineI>>(`routines${query.getValue()}`, fetcher('GET'))

  const dataGrouped = groupBy(data, (routine: RoutineI) => routine.platform + ' ' + routine.company.id)

  const dataGroupedProcessed = Object.keys(dataGrouped).map((key: string) => {
    return {
      platform: dataGrouped[key][0].platform,
      company: dataGrouped[key][0].company,
      routines: dataGrouped[key]
    }
  })

  return {
    data: dataGroupedProcessed ?? [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useJobs
