import React from 'react'

import type { TextPropsI } from 'Components/Text/Text.component'

import { TextS } from './Title.styled'

type TitlePropsI = TextPropsI

const Title = (props: TitlePropsI) => {
  const { children, ...restProps } = props

  return (
    <TextS {...restProps}>
      {children}
    </TextS>
  )
}

export default Title
