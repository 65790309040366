import React from 'react'
import { useIntl } from 'react-intl'

import { Button } from 'DesignSystem'

import IconSVG from 'Components/IconSVG'

import useQuery from 'Hooks/useQuery.hook'

import { createQuery } from 'Helpers/Url.helper'

export interface ButtonFederatedLoginPropsI {
  platform: 'google' | 'microsoft' | 'amazon' | 'facebook'
  slug?: string | null,
  isAdmin?: boolean,
  color?: string,
  isSignUp?: boolean,
  isMigration?: boolean,
  className?: string,
  invite?: string,
  showMinimal?: boolean
}

const ButtonFederatedLogin = (props: ButtonFederatedLoginPropsI) => {
  const { platform, isSignUp = false, isMigration = false, className, slug, isAdmin = false, invite, showMinimal = false } = props
  const intl = useIntl()
  const urlQuery = useQuery()
  const query = createQuery()
  const parametersTracking = urlQuery.findAll()

  if (slug) query.addParams('slug', slug)
  if (isAdmin) query.addParams('admin', 'true')
  if (invite) query.addParams('invite', invite)
  if (urlQuery.get('price_pre_chosen')) query.addParams('price_pre_chosen', urlQuery.get('price_pre_chosen') as string)
  if (parametersTracking.length) parametersTracking.forEach(({ key, value }) => query.addParams(key, value))

  const link = `${process.env.REACT_APP_API_URL}login/federated/${platform}${query.getValue()}`

  const initialText = (() => {
    switch (true) {
      case isSignUp:
        return intl.formatMessage({ id: 'Registrar com' })
      case isMigration:
        return intl.formatMessage({ id: 'Migrar para' })
      default:
        return intl.formatMessage({ id: 'Logar com' })
    }
  })()

  return (
    <Button href={link} className={className} color="white">
        <IconSVG icon={platform} />
        {!showMinimal && platform && `${initialText} ${intl.formatMessage({ id: platform })}`}
    </Button>
  )
}

export default ButtonFederatedLogin
