import useSWR from 'swr'

import { apiDELETE, apiPUT } from 'Services/Api.service'

import { fetcher } from './fetcher'

export interface AccountI {
  id: string,
  name: string,
  description: string,
  token?: string,
  platform: string,
  long_platform: string,
  companies?: any[],
  external_id?: string,
  token_timestamp?: string,
  token_status?: string,
}

interface OptionsI {
  id?: string
}

interface AccountUpdateI {
  name: string,
  description?: string,
}

const useAccount = (options: OptionsI) => {
  const { id } = options

  const { data, error, mutate } = useSWR<AccountI>(id ? `account/${id}?fulldata=true` : undefined, fetcher('GET'))

  const update = async (params: AccountUpdateI) => {
    await apiPUT(`account/${id}`, {
      name: params.name,
      description: params.description
    })

    mutate()
  }

  const removeAll = async (companies: string[]) => {
    await apiDELETE(`${process.env.REACT_APP_TKM_URL}/company_account/account/${id}?platform=${data?.platform}&company=${companies.join('+')}`)
  }

  const removeOnly = async (company: string) => {
    await apiDELETE(`${process.env.REACT_APP_TKM_URL}/company_account/${company}/${id}?platform=${data?.platform}&company=${company}`)
  }

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    update,
    removeAll,
    removeOnly
  }
}

export default useAccount
