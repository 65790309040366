import styled from 'styled-components/macro'

export const WrapperS = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  overflow: hidden;

  position: relative;

  background-color: #DDDDDC;
`

export const RootS = styled.ul`
  width: 100%;

  display: flex;
  flex-direction: row;
  overflow: scroll;

  position: relative;
  margin: 0;
  padding: 0;

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const TabS = styled.li`
  height: 72px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  padding: 0 44px;

  transition: color 500ms;

  font-family: var(--font-base-family-serif);
  font-weight: bold;
  color: ${(props) => props.tabIndex === 0 ? 'var(--font-white)' : 'var(--font-gray)'};
  white-space: nowrap;
  text-decoration: none;
`

export const IndicatorS = styled.span`
  width: 0;
  height: 100%;

  z-index: 0;

  position: absolute;
  bottom: 0;
  left: 0;

  /* transition: width 500ms, left 500ms; */

  background-color: #EBFCFF;
`
