import { Headset, RocketLaunch, Warning } from '@phosphor-icons/react'

import type { DataCancelPlanContentManagerI } from 'ActionsContentManager/CancelPlan/Hooks/useStartCancelPlan.hook'
import { Button } from 'DesignSystem'
import theme from 'Theme'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
import useIntlShort from 'Hooks/useIntlShort.hook'

import { QuestionsCardRoot } from './FirstModal.styled'

type CancelPlansModalPropsI = AuxStepContentManagerI<DataCancelPlanContentManagerI>

export const FirstModalCancelPlan = (props: CancelPlansModalPropsI) => {
  const { next, close, dataContentManager } = props
  const intl = useIntlShort()

  const handleWantChangePlan = () => {
    dataContentManager.onWantChoosePlan && dataContentManager.onWantChoosePlan()
    close()
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <Warning size={64} color={theme.colors.alert} />
      <h3 className='fw-bold mt-3'>{intl('Deseja mesmo cancelar seu plano?')}</h3>
      <p className='text-start w-100 mt-2'>{intl('O NeoDash é muito mais do que apenas uma ferramenta de governança de dados de mídia.')}</p>
      <p className='text-start w-100 mt-2' dangerouslySetInnerHTML={{ __html: intl('Ele é uma plataforma completa que potencializa sua análise de dados te ajudando a impulsionar sua estratégia de negócios.') }} ></p>
      <div className='d-flex flex-row gap-2 mt-3 w-100'>
        <QuestionsCardRoot>
          <div className='d-flex flex-row gap-2 mt-2'>
            <Headset size={48} />
            <div className='d-flex flex-column mt-1'>
              <label className='fw-bold' style={{ height: dataContentManager.is_trialing ? '' : '40px' }}>{intl('Surgiu algum problema ou dificuldade?')}</label>
              <p className='mt-2' style={{ height: dataContentManager.is_trialing ? '' : '70px' }}>{intl('Temos um time de suporte que pode te ajudar com isso e muito mais!')}</p>
              <Button isFit className='mt-2 mb-2' href='https://wiki.neodash.ai/submit-a-ticket/'>{intl('Entrar em contato')}</Button>
            </div>
          </div>
        </QuestionsCardRoot>
        {dataContentManager.is_trialing ? null : (
          <QuestionsCardRoot>
            <div className='d-flex flex-row gap-2 mt-2'>
              <RocketLaunch size={48} />
              <div className='d-flex flex-column mt-1'>
                <label className='fw-bold d-flex' style={{ height: '40px' }}>{intl('Que tal testar um plano diferente?')}</label>
                <p className='mt-2' style={{ height: '70px' }}>{intl('Não está satisfeito com seu plano atual? Que tal testar outra opção disponível?')}</p>
                <Button className='mt-2 mb-2' onClick={handleWantChangePlan}>{intl('Trocar meu plano')}</Button>
              </div>
            </div>
          </QuestionsCardRoot>
        )}
      </div>
      <div className='d-flex flex-row gap-4'>
        <Button color='success' onClick={close}>{intl('Voltar')}</Button>
        <Button color='error' onClick={() => next()}>{intl('Avançar')}</Button>
      </div>
    </div>
  )
}

FirstModalCancelPlan.dialogProps = {
  style: {
    maxWidth: '571px'
  }
}

export default FirstModalCancelPlan
