import useSWR from 'swr'

import { fetcher } from './fetcher'

export interface RoutineI {
  id: string,
  tags: Array<string>,
  schedule: string,
  next_run: string,
  platform: string,
  company: {
    id: string,
    name: string,
  }
}

interface OptionsI {
  company: string,
  routine: string,
}

interface ReturnI {
  data?: RoutineI,
  isLoading: boolean,
  isError: boolean
}

const useRoutineDetails = (options: OptionsI) : ReturnI => {
  const { data, error } = useSWR<RoutineI>(`company/${options.company}/routines/${options.routine}/details`, fetcher('GET'))

  return {
    data: data ?? undefined,
    isLoading: !error && !data,
    isError: error
  }
}

export default useRoutineDetails
