import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { addAlert } from 'Store/Actions/alerts.action'

import { apiGET } from 'Services/Api.service'

import { generateLinkToInstance } from 'Helpers/Company.helper'
import { generateLinkTo } from 'Helpers/Route.helper'

const Odash = () => {
  const { company, slug } = useParams<{company: string, slug: string}>()
  const matchedIsAdmin = useRouteMatch(generateLinkTo('odashAdmin', { company, slug }))
  const dispatch = useDispatch()
  const intl = useIntl()
  const history = useHistory()

  const generateToken = async () => {
    try {
      const { token } = await apiGET<{token: string}>(`odash/${company}/token`)

      const linkWithToken = (() => {
        if (matchedIsAdmin) {
          return `${generateLinkToInstance({ slug })}/admin/index.php/site/login?api_token=${token}`
        }

        return `${generateLinkToInstance({ slug })}/login?admin_token=${token}`
      })()

      window.location.href = linkWithToken
    } catch (error) {
      dispatch(addAlert({
        type: 'error',
        title: intl.formatMessage({ id: 'Algo de errado!' }),
        content: (error as Error).message
      }))
      history.replace(generateLinkTo('home'))
    }
  }

  useEffect(() => {
    generateToken()
  }, [])

  return (
    null
  )
}

export default Odash
