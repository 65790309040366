import type { Fetcher } from 'swr'
import type { FetcherResponse } from 'swr/dist/types'

import { apiDELETE, apiGET, apiPOST, apiPUT } from 'Services/Api.service'

export const fetcher = <T, >(method: string) : Fetcher<T, string> => (url:string, data = {}, headers = {}) : FetcherResponse<T> => {
  switch (method) {
    case 'POST':
      return apiPOST<T>(url, data, headers)
    case 'PUT':
      return apiPUT<T>(url, data, headers)
    case 'DELETE':
      return apiDELETE<T>(url, data, headers)
    default:
      return apiGET<T>(url, data, headers)
  }
}
