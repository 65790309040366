import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import moment from 'moment'

import { loadCompanyAccountLogs } from 'Store/Actions/Company'

import LoadingDefault from 'Components/Loadings/default.component'

import useQuery from 'Hooks/useQuery.hook'

import { generateLinkTo } from 'Helpers/Route.helper'

import HeaderButton from './components/headerButton'

const CompanyAccountLogs = () => {
  const params = useParams<{ id: string, typeJobs: string }>()
  const query = useQuery()
  const [logs, setLogs] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { id: company, typeJobs } = params

  const loadLogs = async () => {
    const logs = await loadCompanyAccountLogs(company, typeJobs)
    setLogs(logs)
    setLoading(false)
  }

  useEffect(() => {
    loadLogs()
    setLoading(true)
  }, [])

  if (loading) {
    return (
      <div className='page-accounts'>
        <LoadingDefault />
      </div>
    )
  }

  return (
    <div className='page-accounts'>
      <HeaderButton tokenManager account={query.get('account')} company={company} />
      <br/>
      <table className='table'>
        <thead>
          <tr>
            <th/>
            <th>ID</th>
            <th>Começou</th>
            <th>Finalizou</th>
            <th>Duração</th>
            <th>Status</th>
            <th>Plataforma</th>
          </tr>
        </thead>
        <tbody>

            {logs.map((log, index) =>
            <tr key={index} className={log.message.includes('error') ? 'table-danger' : ''}>
              <td><Link to={`${generateLinkTo('log', { id: company, idLog: log._id })}?${query.get('account') ? `account=${query.get('account')}` : ''}&${typeJobs ? `typeJobs=${typeJobs}` : ''}`}>Detalhar log</Link></td>
              <td>{log._id}</td>
              <td>{moment(log.started).format('DD/MM/YY hh:mm:ss')}</td>
              <td>{moment(log.finished).format('DD/MM/YY hh:mm:ss')}</td>
              <td>{log.duration}</td>
              <td>{log.message.includes('error') ? 'falhado' : 'sucesso'}</td>
              <td>{log.content.platform}</td>
              </tr>)}

        </tbody>
      </table>
    </div>
  )
}

export default CompanyAccountLogs
