import { useEffect, useState } from 'react'

import { Modal } from 'DesignSystem'

import useUser from 'Hooks/API/useUser.hook'
import useContentManager from 'Hooks/useContentManager.hook'

import * as localStorage from 'Helpers/Localstorage.helper'

import EndTrialsPeriodEnd from '../Components/EndTrialsPeriodEnd'

export interface EndTrialsPeriodContentManagerPropsI {
  isNearEnd?: boolean,
  onStartAPlan: () => void
}

export const useEndTrialsPeriodContentManager = ({ onStartAPlan }: { onStartAPlan: () => void }) => {
  const { data: user, isLoading } = useUser()
  const [isStarted, setIsStarted] = useState(false)
  const { start, content } = useContentManager<EndTrialsPeriodContentManagerPropsI>([
    EndTrialsPeriodEnd
  ], {
    dialog: Modal,
    dialogProps: {
    },
    onClose: () => {
      if (!user) return
      if (user.isOnTrialPeriod && user.isTrialPeriodNextToEnd) {
        localStorage.setItem('odash-app:notify-end-trials-period-next-to-end', 'true')
      }

      if (user.isOnTrialPeriod && user.isTrialPeriodExpired) {
        localStorage.setItem('odash-app:notify-end-trials-period', 'true')
      }
    }
  })

  const handleStart = () => {
    setIsStarted(true)
  }

  useEffect(() => {
    if (!isStarted) return
    if (!user) return
    if (isLoading) return
    if (user.ignore_payments) return

    const flagNotifyEndTrialsPeriodNextToEnd = localStorage.getItem('odash-app:notify-end-trials-period-next-to-end') === 'true'
    const flagNotifyEndTrialsPeriod = localStorage.getItem('odash-app:notify-end-trials-period') === 'true'

    if (user.isOnTrialPeriod && user.isTrialPeriodNextToEnd && !flagNotifyEndTrialsPeriodNextToEnd) {
      start({
        onStartAPlan,
        isNearEnd: true
      })
    }

    if (user.isOnTrialPeriod && user.isTrialPeriodExpired && !flagNotifyEndTrialsPeriod) {
      start({
        onStartAPlan,
        isNearEnd: false
      })
    }
  }, [user, isLoading, isStarted])

  return {
    start: handleStart,
    content
  }
}
