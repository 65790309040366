
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import IconSVG from 'Components/IconSVG'
import type { IconNameI } from 'Components/IconSVG/IconSVG.component'

import { LinkS, TextS, WrapperIconS } from './ItemMenu.styled'

export interface ItemMenuPropsI {
  icon: IconNameI | React.ReactNode,
  name: string,
  path?: string | string[],
  linkTo?: string,
  onAction?: () => void,
  target?: string,
}

const ItemMenu = (props: ItemMenuPropsI) => {
  const { onAction = () => undefined } = props
  const matched = useRouteMatch(props.path ?? '/without-link')
  const [isActive, setIsActive] = useState(!!matched)

  const history = useHistory()

  useEffect(() => {
    setIsActive(!!matched)
  }, [history, matched])

  const handleClick = () => {
    onAction()
  }

  const handleMouseEnter = () => {
    setIsActive(true)
  }

  const handleMouseLeave = () => {
    setIsActive(!!matched)
  }

  const icon: IconNameI = isActive ? `${props.icon}-fill` as IconNameI : props.icon as IconNameI

  return (
    <LinkS to={{ pathname: props.linkTo }} target={props.target ?? '_self'} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} isActive={isActive}>
      <WrapperIconS isActive={isActive}>
        <IconSVG icon={icon} />
      </WrapperIconS>
      <TextS isActive={isActive}>{props.name}</TextS>
    </LinkS>
  )
}

export default ItemMenu
