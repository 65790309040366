import type { ReactElement } from 'react'
import React from 'react'

interface TooltipInterface {
  children?: ReactElement | ReactElement[],
  text: string,
  position: 'top' | 'bottom',
  isShowed: boolean,
}

export default function Tooltip (props: TooltipInterface) {
  const { children, position, text, isShowed } = props

  const element = (
    <div className={`tooltip tooltip--${position}`}>
      <div className='tooltip__balloon'>{text}</div>
    </div>
  )

  return (
    <>
      {position === 'top' && isShowed ? element : null}
      {children}
      {position === 'bottom' && isShowed ? element : null}
    </>
  )
}
