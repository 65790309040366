// import groupBy from 'lodash/groupBy'
// import toPairs from 'lodash/toPairs'
// import uniqBy from 'lodash/uniqBy'
import useSWR from 'swr'

import { createQuery } from 'Helpers/Url.helper'

import { fetcher } from './fetcher'
import type { CompanyI } from './useCompany.hook'

export interface AccountI {
  id: string,
  account_name: string | null,
  description: string | null,
  platform: 'adwords' | 'analytics' | 'displayVideo360' | 'facebook' | 'twitter' | 'vtex' |
   'linkedin' | 'microsoft' | 'criteo' | 'linx' | 'taboola' | 'amazon' | 'amazon-ads',
  long_platform: string,
  token: any,
  token_timestamp: string,
  token_expiration: string,
  external_id: string,
  company_account: string,
  companies: Array<CompanyI>,
  emails: Array<string>,
  name: string,
  token_status: string,
}

// const agroupByExternalID = (accounts: Array<AccountI> = []) : Array<AccountI> => {
//   const groupedByExternalID = toPairs(groupBy(accounts, 'external_id'))
//   const unifiedAccounts = groupedByExternalID
//     .map(([externalID, accounts]) => accounts.reduce((acc, cur, index) => {
//       if (index === 0) return cur
//       acc.companies.push(...cur.companies)
//       acc.companies = uniqBy(acc.companies, 'id')
//       return acc
//     }))

//   return unifiedAccounts
// }

interface UseAccountsParamsI {
  isGroupedByPlatform?: boolean,
  search?: string,
  companies?: string[],
  platforms?: string[],
  withFullData?: boolean,
}

export type RawAccountListI = Array<AccountI> | undefined

export type GroupedByPlatformAccountListI = Array<[string, Array<AccountI>]> | undefined

type UseAccountsReturnI = {
  data: RawAccountListI,
  isLoading: boolean,
  isError: any,
  mutate: any,
}

const useAccounts = (params: UseAccountsParamsI = {}) : UseAccountsReturnI => {
  const { search = '', companies, platforms, withFullData = false } = params
  const query = createQuery()

  query.addParams('extended', true)
  query.addParams('fulldata', !!withFullData)

  if (search) {
    query.addParams('search', search)
  }

  if (companies) {
    query.addParams('companies', companies)
  }

  if (platforms) {
    query.addParams('platforms', platforms)
  }

  const { data, error, mutate } = useSWR<Array<AccountI>>(`${process.env.REACT_APP_TKM_URL}/accounts${query.getValue()}`, fetcher('GET'))

  // if (isGroupedByPlatform) {
  //   return {
  //     data: groupedDataByPlatform ?? [],
  //     isLoading: !error && !data,
  //     isError: error,
  //     isGroupedByPlatform,
  //     mutate
  //   }
  // } else {
  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error,
    mutate
  }
  // }
}

export default useAccounts
