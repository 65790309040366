import type { ReactNode } from 'react'
import React from 'react'

import { Diviser, RootS } from './TextDiviser.styled'

interface TextDiviserPropsI {
  children?: ReactNode
}

const TextDiviser = (props: TextDiviserPropsI) => {
  const { children } = props
  return (
    <RootS>
      <Diviser />
      {children}
      <Diviser />
    </RootS>
  )
}

export default TextDiviser
