import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'

// @TODO: quando puder, mate esse componente

interface UploadFilesInterface {
  label: string,
  placeholder?: string,
  size?: {
    width?: number,
    height?: number,
    minWidth?: number,
    minHeight?: number,
  }
  onDrop: (acceptedFiles: any) => void,
  onValidate?: (isValid: boolean) => void,
}

function UploadFiles (props: UploadFilesInterface) {
  const intl = useIntl()
  const [isValid, setIsValid] = useState<boolean | null>(null)
  const { label, placeholder, size, onValidate } = props
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      const preview = URL.createObjectURL(acceptedFiles[0])
      const blob = await fetch(preview).then(res => res.blob())
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = function () {
        const img = document.createElement('img')
        img.onload = () => {
          if (size && onValidate) {
            let isValid = true

            if (size.width && size.width !== img.width) isValid = false
            if (size.height && size.height !== img.height) isValid = false
            if (size.minHeight && size.minHeight > img.height) isValid = false
            if (size.minWidth && size.minWidth > img.width) isValid = false

            if (isValid) props.onDrop({ preview, base: reader.result, ...acceptedFiles[0] })

            onValidate(isValid)
            setIsValid(isValid)
          } else if (onValidate) {
            onValidate(true)
            setIsValid(true)
            props.onDrop({ preview, base: reader.result, ...acceptedFiles[0] })
          } else {
            setIsValid(true)
            props.onDrop({ preview, base: reader.result, ...acceptedFiles[0] })
          }
        }
        img.src = reader.result as string
      }
    }
  })

  return (
    <section className="container input-upload">
      <label className='input-upload__label' >{label}</label>
      <div {...getRootProps({ className: 'input-upload__dropzone' })}>
        <input {...getInputProps()} />
        <span className='input-upload__input'>{intl.formatMessage({ id: 'Escolha arquivo' })}</span>
        <div className='input-upload__placeholder'>{placeholder}</div>
        {isValid !== null ? <img className='input-upload__icon' src={`/images/icon-${isValid ? 'check' : 'error'}.svg`} alt="ops" /> : null}
      </div>

    </section>
  )
}

export default UploadFiles
