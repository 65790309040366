import useSWR from 'swr'

import { fetcher } from './fetcher'

export interface InviteI {
  email: string,
  platform: string,
  creation: string,
  link: string,
  company?: {
    id: string,
    name: string,
  }
}

interface ReturnI {
  data: Array<InviteI>,
  isLoading: boolean,
  isError: boolean
}

const useInvites = () : ReturnI => {
  const { data, error } = useSWR<Array<InviteI>>('console/invites', fetcher('GET'))

  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useInvites
