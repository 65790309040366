import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { ColorField } from 'DesignSystem'

import type { DataCompanyCreateContentManagerI } from 'Components/FirstSetupFlow'

import type { AuxStepContentManagerI } from 'Hooks/useContentManager.hook'
// import usePredominantColor from 'Hooks/usePredominantColor'

type NameCompanyCreateDialogPropsI =
  AuxStepContentManagerI<DataCompanyCreateContentManagerI>;

const ColorWorkspaceField = ({
  setData,
  dataContentManager
}: NameCompanyCreateDialogPropsI) => {
  const { color } = dataContentManager
  const intl = useIntl()
  // const {predominantColor} = usePredominantColor(icon && icon)
  function setColor (color: string) {
    setData({
      color: color
    })
  }

  useEffect(() => {
    setData({
      color
    })
  }, [])

  return (
    <div className='d-flex flex-column align-items-start' style={{ position: 'relative' }}>
      <label className="fw-bold mb-2">
        {intl.formatMessage({ id: 'Cor:' })}{' '}
        <span className="text-error">*</span>:
      </label>
      <ColorField
        placeholder={intl.formatMessage({ id: 'Escolha uma cor' })}
        value={color}
        onChange={setColor}
      />
      <small className="caption1 mt-3">
        {intl.formatMessage({ id: 'Essa será a cor de alguns elementos da sua interface.' })}
      </small>
    </div>
  )
}

export default ColorWorkspaceField
