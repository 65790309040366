import React from 'react'
import { useLocation } from 'react-router-dom'

export default function useQuery (): URLSearchParams & { findAll: (key_start_with?: string | Array<string>) => { key: string, value: string }[] } {
  const { search } = useLocation()

  // @ts-ignore
  return React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(search)

    // @ts-ignore
    urlSearchParams.findAll = (key_start_with: string | Array<string>) => {
      // find all keys that start with key_start_with and put them in an array
      const keys = Array.from(urlSearchParams.keys()).filter(key => !key_start_with ? true : (typeof key_start_with === 'string' ? key.startsWith(key_start_with) : key_start_with.some(k => key.startsWith(k))))

      // return an array of objects with the key and value
      return keys.map(key => ({ key, value: urlSearchParams.get(key) }))
    }

    return urlSearchParams
  }, [search])
}
