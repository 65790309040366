import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { Col, Row } from 'DesignSystem'
import LoadingDots from 'DesignSystem/LoadingDots'

function WaitingToSave ({
  next,
  prev,
  setSizeDialog,
  dataContentManager,
  setShowFooter,
  setData
}: any) {
  const intl = useIntl()

  useEffect(() => {
    setSizeDialog('sm')
    setShowFooter(false)
    setTimeout(() => {
      next()
    }, 500)
  }, [])

  return (
    <Row className="flex-column">
      <Col className="text-center mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Quase lá...' })}</h2>
        <p className='text-start body2'>
          {intl.formatMessage({ id: 'Estamos salvando todas as suas configurações e dentro de instantes tudo estará funcionando...' })}
        </p>
      </Col>
      <Col className='pt-3'>
        <LoadingDots />
      </Col>
    </Row>
  )
}

export default WaitingToSave
