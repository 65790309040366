import { useStartDowngradePlan } from 'ActionsContentManager/DowngradePlan'
import type { DataStartDowngradePlanContentManagerI } from 'ActionsContentManager/DowngradePlan/Hooks/useStartDowngradePlan.hook'
import { Modal } from 'DesignSystem'

import PlansModal from 'Components/PlansModal'

import useContentManager from 'Hooks/useContentManager.hook'

export interface DataStartSignPlanContentManagerI {
  planID?: string,
  onStartDowngrade?: (data: DataStartDowngradePlanContentManagerI) => void
}

export const useStartSignPlanContentManager = (planID?: string) => {
  const { start: startDowngrade, content: contentDowngrade } = useStartDowngradePlan(planID)
  const { start, content } = useContentManager<DataStartSignPlanContentManagerI>([
    PlansModal
  ], {
    initialData: {
      planID,
      onStartDowngrade: (data) => startDowngrade(data)
    },
    dialog: Modal,
    dialogProps: {
      size: 'xlg',
      noPadding: true
    }
  })

  return {
    start,
    startDowngrade,
    content: (
      <>
        {content}
        {contentDowngrade}
      </>
    )
  }
}
