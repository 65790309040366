import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { Button, Input, Select } from 'DesignSystem'
import styled, { css } from 'styled-components/macro'

import { addAlert } from 'Store/Actions/alerts.action'

import { apiPOST } from 'Services/Api.service'

import { validateEmail } from 'Helpers/Email.helpers'

const RootS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h3 {
    ${({ theme }) => css(theme.fonts.types.h3)};
  }

  > p {
    ${({ theme }) => css(theme.fonts.types.body1)};
  }
`

const WrapperInputS = styled.div`
  width: 100%;
`

const InviteDialog = ({ next, setData } : any) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [daysTest, setDaysTest] = useState(30)
  const [locale, setLocale] = useState('pt-BR')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [isValidForm, setIsValidForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [nameError] = useState(false)
  const [emailError, setEmailError] = useState('')
  const locales = [
    { value: 'pt-BR', content: 'Português' },
    { value: 'en', content: 'English' }
  ]

  useEffect(() => {
    if (name === '' || email === '' || !isValidEmail || locale === '') {
      setIsValidForm(false)
      return
    }

    setIsValidForm(true)
  }, [name, email, locale, isValidEmail])

  useEffect(() => {
    const validEmail = email === '' ? true : !!validateEmail(email)
    setIsValidEmail(validEmail)

    if (validEmail) {
      setEmailError('')
    } else {
      setEmailError(intl.formatMessage({ id: 'E-mail inválido' }))
    }

    setData({ email })
  }, [email])

  const handleSend = async () => {
    setIsLoading(true)
    const response: any = await apiPOST('invite', {
      name,
      email,
      locale,
      days_test: daysTest
    })

    if (response.status === 'success') {
      next()
    } else {
      dispatch(addAlert({ title: intl.formatMessage({ id: 'E-mail já existe!' }), type: 'error' }))
    }

    setIsLoading(false)
  }

  return (
    <RootS>
      <h3 className='fw-bold'>{intl.formatMessage({ id: 'Convite NeoDash' })}</h3>
      <p>{intl.formatMessage({ id: 'Preencha os campos abaixo para enviar um convite de testes do NeoDash para um novo usuário:' })}</p>
      <WrapperInputS className="mt-3">
        <label className="fw-bold">
          {intl.formatMessage({ id: 'Nome do usuário:' })}{' '}
          <span className="text-error">*</span>:
        </label>
        <Input
          error={false}
          className="mt-2"
          placeholder={intl.formatMessage({ id: 'Insira o nome do usuário' })}
          value={name}
          onChange={({ target }: any) => setName(target.value)}
          isValid={false}
          isLoading={false}
        />
        {nameError && (
          <small className='text-error'>{nameError}</small>
        )}
      </WrapperInputS>
      <WrapperInputS className="mt-3">
        <label className="fw-bold">
          {intl.formatMessage({ id: 'E-mail:' })}{' '}
          <span className="text-error">*</span>:
        </label>
        <Input
          error={false}
          className="mt-2"
          placeholder={intl.formatMessage({ id: 'Insira o e-mail do usuário' })}
          value={email}
          onChange={({ target }: any) => setEmail(target.value)}
          isValid={false}
          isLoading={false}
        />
        {emailError !== '' && !isValidEmail && (
          <small className='text-error'>{emailError}</small>
        )
        }
      </WrapperInputS>
      <WrapperInputS className="mt-3">
        <label className="fw-bold">
          {intl.formatMessage({ id: 'Idioma' })} <span className="text-error">*</span>:
        </label>
        <Select
          className="mt-2"
          value={locale}
          onChange={({ target }: any) => setLocale(target.value)}
        >
          {locales.map((opt, key) => (
            <option value={opt.value} key={key}>
              {opt.content}
            </option>
          ))}
        </Select>
      </WrapperInputS>
      <WrapperInputS className="mt-3">
        <label className="fw-bold">
          {intl.formatMessage({ id: 'Dias de teste:' })}{' '}
          <span className="text-error">*</span>:
        </label>
        <Input
          error={false}
          className="mt-2"
          placeholder={intl.formatMessage({ id: '30' })}
          value={daysTest}
          onChange={({ target }: any) => setDaysTest(target.value)}
          isValid={false}
          isLoading={false}
        />
      </WrapperInputS>
      <Button disabled={!isValidForm} isLoading={isLoading} className="mt-4" color="primary" onClick={() => handleSend()}>
        {intl.formatMessage({ id: 'Enviar' })}
      </Button>
    </RootS>
  )
}

export default InviteDialog
