import React from 'react'
import { FormattedMessage } from 'react-intl'

interface ProgressBarInterface {
  percentage: number,
  withLabel?: boolean,
  className?: string,
}

export default function ProgressBar (props : ProgressBarInterface = { percentage: 0 }) {
  const { percentage, withLabel = true } = props
  const className = ['progress-bar_component', props.className].join(' ')

  return (
    <div className={className}>
        <div className='progress-bar_bar'>
            <div className='progress-bar_background' />
            <div className='progress-bar_progress' style={{ width: `${percentage}%` }} />
        </div>
        {withLabel ? (
            <label className="progress-bar_label">
            <FormattedMessage id="components.progressBar.soManyCompleted" values={{ percentage }} />
            </label>
        ) : null}
    </div>
  )
}
