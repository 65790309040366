import styled from 'styled-components/macro'

export const RootS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  label {
    margin-left: 10px;

    font-family: "Manrope";
  }
`
