import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { loadCompany } from 'Store/Actions/Company'

import { generateLinkTo } from 'Helpers/Route.helper'

import HeaderButton from './components/headerButton'

const CompanyLinkTemporary = () => {
  const { id } = useParams<{id: string}>()
  const [company, setCompany] = useState<{slug: string, name: string}>()

  const loadCompanyRedux = async () => {
    const response = await loadCompany(id)
    setCompany(response)
  }

  useEffect(() => {
    loadCompanyRedux()
  }, [])

  return (
    <div className='page-company-link-temporary' style={{ color: 'black' }}>
      <p>DISCLAIMER: Esssa página é apenas para fins de teste</p>
      <p>DISCLAIMER: Pode acontecer de alguns links do odash/admin não funcionar, porque volta e meia a gente limpa alguns
        dominios no route do AWS.
      </p>
      <br/>

      <HeaderButton />

      <br/>
      <div>
        <h2 className='h5'>Nome: {company?.name}</h2>
      </div>

      <div>
        <h2 className='h5'>Slug: {company?.slug}</h2>
      </div>

      <Link to={generateLinkTo('companyAccounts', { id: id })}>Gerenciamento de token</Link>
      <br/>
      <a href={`https://${company?.slug || ''}.${localStorage.getItem('odash-app:domain-suffix')}`} target="_blank" rel='noreferrer'>Odash</a>
      <br/>
      <a href={`https://${company?.slug || ''}.${localStorage.getItem('odash-app:domain-suffix')}/admin`} target="_blank" rel='noreferrer'>Odash - Admin</a>

    </div>
  )
}

export default CompanyLinkTemporary
