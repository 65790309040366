import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import permissions from 'Constants/permissions.constants'
import { Button, Col, Row } from 'DesignSystem'

import { addAlert } from 'Store/Actions/alerts.action'

import CardContentLarge from 'Components/CardContentLarge'
import UploadImageField from 'Components/UploadImageField'
import WorkspaceForm from 'Components/WorkspaceForm'

import useCheckPermission from 'Hooks/API/useCheckPermission.hook'
import useCompany from 'Hooks/API/useCompany.hook'

export default function EditWorkspaceForm () {
  const { id } = useParams<{id: string}>()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { data, update } = useCompany({ id })
  const [name, setName] = useState(data?.name)
  const [color, setColor] = useState(data?.color)
  const [icon, setIcon] = useState<string>(data?.icon ?? '')
  const [locale, setLocale] = useState(data?.locale)
  const [currency_read, setCurrencyRead] = useState(data?.currency_read)
  const [hasValueDifferent, setHasValueDifferent] = useState(false)
  const [loadingLocal, setLoadingLocal] = useState(false)
  const [iconChanged, setIconChanged] = useState(0)
  const { data: checkPermissionToEditCompany } = useCheckPermission([permissions.EDIT_COMPANY], id)

  const setData = (data: any) => {
    if (data.name) setName(data.name)
    if (data.color) setColor(data.color)
    if (data.icon) setIcon(data.icon)
    if (data.locale) setLocale(data.locale)
    if (data.currency_read) setCurrencyRead(data.currency_read)
  }

  const handleSubmit = async () => {
    if (!(name || color || icon || locale || currency_read)) return

    setLoadingLocal(true)

    await update({
      name,
      color,
      icon: iconChanged > 1 ? icon : undefined,
      locale,
      currency_read
    })

    setIconChanged(1)

    setLoadingLocal(false)

    dispatch(
      addAlert({
        title: intl.formatMessage({ id: 'Empresa alterada com sucesso!' }),
        type: 'success'
      })
    )
    setHasValueDifferent(false)
  }

  const propsSubmit = checkPermissionToEditCompany.some(p => !p.canUse)
    ? {
        isDisabled: true,
        'data-for': 'tip-global-permission',
        'data-tip': JSON.stringify(checkPermissionToEditCompany)
      }
    : {}

  useEffect(() => {
    setName(data?.name)
    setColor(data?.color)
    setLocale(data?.locale)
    setCurrencyRead(data?.currency_read)
    setIcon(data?.icon ?? '')
  }, [data])

  useEffect(() => {
    if (name !== data?.name ||
        color !== data?.color ||
        (iconChanged > 1 && icon !== '') ||
        locale !== data?.locale ||
        currency_read !== data?.currency_read) {
      setHasValueDifferent(true)
    } else {
      setHasValueDifferent(false)
    }
  }, [name, color, icon, locale, currency_read, iconChanged])

  return (
    <div>
      <h2 className="fw-extrabold mb-3">{intl.formatMessage({ id: 'workspace.edit.title' })}</h2>
      <CardContentLarge>
          <Row className="flex-column">
            <Col>
              <Row className="mt-3">
                <Col xs={12} lg={4}>
                  <label className="fw-bold">
                    {intl.formatMessage({ id: 'Logo' })}{' '}
                    <span className="text-error">*</span>:
                  </label>
                  <div className="mt-2">
                    <UploadImageField
                      label="Imagem"
                      initialImage={data?.icon ?? ''}
                      sizeImage={{
                        minWidth: 100,
                        minHeight: 100
                      }}
                      isCropped
                      aspect={2 / 1}
                      withoutInitialAreaPixels={true}
                      positionArePixels={{
                        width: 184,
                        height: 92,
                        x: 0,
                        y: 0
                      }}
                      onClickChangeLogo={() => setIconChanged(iconChanged + 2)}
                      onChange={(newImg) => {
                        setIconChanged(iconChanged + 1)
                        setIcon(newImg || '')
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <WorkspaceForm
                    currentStep={0}
                    isEdit
                    dataContentManager={{
                      name,
                      color,
                      locale,
                      currency_read
                    }}
                    setData={setData}
                    prev={() => null}
                    next={() => null}
                    close={() => null}
                  />
                </Col>
              </Row>
            </Col>
        </Row>
      </CardContentLarge>
      <div className='mt-4'>
        <Button isLoading={loadingLocal} disabled={!hasValueDifferent} onClick={handleSubmit} {...propsSubmit}>
          {intl.formatMessage({ id: 'Salvar alterações' })}
        </Button>
      </div>
    </div>
  )
}
