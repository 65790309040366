import type { FC } from 'react'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import type StoreInterface from 'Interfaces/store.interface'
import styled from 'styled-components'

import { addAlert } from 'Store/Actions/alerts.action'

import useQuery from 'Hooks/useQuery.hook'

import ItemAlert from './item.component'

const AlertsWrapper = styled.div`
  z-index: 10001;

  position: fixed;
  top: 4rem;
  right: 2rem;

  & > * {
    margin-top: 1rem;
  }
`

const Alerts: FC = () => {
  const alerts = useSelector((state: StoreInterface) => state.alerts)
  const query = useQuery()
  const dispatch = useDispatch()
  const intl = useIntl()
  const error = query.get('error')

  useEffect(() => {
    if (error) {
      dispatch(
        addAlert({
          title: intl.formatMessage({ id: `error.${error}` }),
          type: 'error'
        })
      )
    }
  }, [error])

  return alerts?.items?.length ? (
    <AlertsWrapper>
      {alerts?.items.map((item, key) => (
        <ItemAlert
          key={key}
          index={key}
          title={item.title}
          severity={item.type}
        >
          {item?.content && <FormattedMessage id={item?.content} />}
        </ItemAlert>
      ))}
    </AlertsWrapper>
  ) : null
}

export default Alerts
