import type { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    transparent: 'transparent',
    transparent_hover: 'transparent',
    transparent_text: '#006A7A',
    transparent_text_hover: '#006A7A',
    primary: '#00BDD9',
    primary_light: '#ADF4FF',
    primary_light_hover: '#EBFCFF',
    primary_hover: '#006A7A',
    secondary: '#DFDB47',
    darkCelurean: '#06252B',
    dark: '#0A1A28',
    black: '#1A1A1A',
    white: '#FCFCFC',
    white_hover: '#EBFCFF',
    error: '#C72C2C',
    error_hover: '#C72C2C',
    error_text: '#F6F6F6',
    success: '#24813B',
    success_hover: '#24813B',
    success_text: '#F6F6F6',
    alert: '#F7C347',
    hover: '#EBFCFF',
    gray: '#C6C6C5',
    gray_hover: '#7C7C79',
    gray_nickel_dark_hover: '#7C7C79',
    gray80: '#D7D7D6',
    gray60: '#DDDDDC',
    gray40: '#E8E8E8',
    gray20: '#F2F2F2'
  },
  metrics: {
    space1: '0.25rem',
    space2: '0.5rem',
    space3: '1rem',
    space4: '1.25rem',
    space5: '1.5rem',
    space6: '3rem',
    radius: '1.625rem'
  },
  fonts: {
    family: {
      default: 'sans-serif',
      primary: 'Manrope'
    },
    types: {
      h1: {
        fontSize: '3rem',
        lineHeight: '3.5rem'
      },
      h2: {
        fontSize: '2rem',
        lineHeight: '2.5rem'
      },
      h3: {
        fontSize: '1.5rem',
        lineHeight: '2rem'
      },
      h4: {
        fontSize: '1rem',
        lineHeight: '1.5rem'
      },
      subtitle1: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem'
      },
      subtitle2: {
        fontSize: '1rem',
        lineHeight: '1.5rem'
      },
      subtitle3: {
        fontSize: '0.75rem',
        lineHeight: '1.25rem'
      },
      subtitle4: {
        fontSize: '0.625rem',
        lineHeight: '1rem'
      },
      body1: {
        fontSize: '1rem',
        lineHeight: '1.5rem'
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: '1.375rem'
      },
      body3: {
        fontSize: '0.75rem',
        lineHeight: '1.25rem'
      },
      button: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem'
      },
      buttonSM: {
        fontSize: '0.875rem',
        lineHeight: '1.375rem'
      },
      caption1: {
        fontSize: '0.625rem',
        lineHeight: '1.25rem'
      },
      caption2: {
        fontSize: '0.5rem',
        lineHeight: '1rem'
      }
    }
  }
}

export default theme
