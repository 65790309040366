import React from 'react'

import IconSVG from 'Components/IconSVG'

import { passAlongMicroData } from 'Helpers/MicroData.helper'

import type { RootI } from './TextField.styled'
import { CounterS, LabelS, RootS, WrapperIconValidationS } from './TextField.styled'

interface TextFieldPropsI {
  value?: string,
  label: string,
  size?: RootI['size'],
  placeholder?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  isValid?: boolean | null,
  limitChar?: number
}

const TextField = (props: TextFieldPropsI) => {
  const { value, label, placeholder, onChange, isValid, limitChar = 0, size = 'normal' } = props
  const icon = isValid ? 'check' : 'error'
  const currentSize = value?.length || 0

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    onChange && onChange(event)
  }

  return (
    <RootS size={size} {...passAlongMicroData(props)}>
      <WrapperIconValidationS size={size} isValid={isValid}>
        <LabelS size={size}>{label}</LabelS>
        <input
          id="companyName"
          type="text"
          placeholder={placeholder}
          defaultValue={value}
          value={value}
          onChange={handleOnChange} />
        {typeof isValid === 'boolean' ? <IconSVG icon={icon} /> : null}
      </WrapperIconValidationS>
      {limitChar ? (
        <CounterS>
          {currentSize}/{limitChar}
        </CounterS>
      ) : null}
    </RootS>
  )
}

export default TextField
