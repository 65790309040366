import styled, { css } from 'styled-components/macro'

import IconSVGComponent from 'Components/IconSVG/IconSVG.component'

export const RootS = styled.div`
  position: relative;
  width: 280px;
  height: 302px;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  h4 {
    ${({ theme }) => css(theme.fonts.types.h4)};
  }

  ${IconSVGComponent} {
    width: 96px;
    height: 96px;
    margin-top: 41px;

    svg {
      width: 96px;
      height: 96px;
    }
  }

`

export const WrapperButtonS = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 0;
  width: 100%;

  button {
    cursor: pointer;
    padding: 20px 0;
    background-color: ${({ theme }) => theme.colors.gray60};
    border: none;
    flex: 1;
  }

  button + button {
    border-left: 1px solid ${({ theme }) => theme.colors.gray};
  }
`
