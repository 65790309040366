import useSWR from 'swr'

import { fetcher } from './fetcher'

export interface CompanyI {
  id: string,
  name: string,
  slug: string,
}

interface ReturnI {
  data: Array<CompanyI>,
  isLoading: boolean,
  isError: boolean
}

const useCompaniesConsole = () : ReturnI => {
  const { data, error } = useSWR<Array<CompanyI>>('console/companies', fetcher('GET'))

  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error
  }
}

export default useCompaniesConsole
