import { CreditCard } from '@phosphor-icons/react'

import styled from 'styled-components'

import IconSVG from 'Components/IconSVG'

interface IconCardBrandProps {
  brand: string
}

const IconSVGS = styled(IconSVG)`
  width: 32px;
  height: 32px;

  svg {
    width: 32px;
    height: 32px;
  }
`

const IconCardBrand = ({ brand }: IconCardBrandProps) => {
  try {
    // @ts-ignore
    return <IconSVGS icon={`card-${brand}`} />
  } catch (error) {
    return <CreditCard size={32} />
  }
}

export default IconCardBrand
