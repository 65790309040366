import type { HTMLAttributes } from 'react'

import type DefaultProps from 'DesignSystem/Types'
import styled, { css } from 'styled-components'

interface ButtonProps extends DefaultProps, HTMLAttributes<HTMLButtonElement> {
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'gray' | 'white' | 'error' | 'success' | 'transparent';
  disabled?: boolean;
  to?: string;
  href?: string;
  isLoading?: boolean;
  isFit?: boolean;
  onAbort?: () => null;
  type?: 'button' | 'submit' | 'reset';
}

const ButtonStyled = styled.button<ButtonProps>`
  ${({ theme }) => css(theme.fonts.types.body1)};

  ${({ isFit }) => isFit && css`
    width: fit-content;
  `}

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  padding: ${({ theme }) => theme.metrics.space1} ${({ theme }) => theme.metrics.space5};

  background-color: ${({ theme, color }) => color !== undefined && theme.colors[color]};
  border: none;
  border-radius: ${({ theme }) => theme.metrics.radius};

  ${({ color, theme }) => {
    if (color === 'white') {
      return css({
        border: `1px solid ${theme.colors.gray}`
      })
    }
  }}

  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};

  font-weight: 600;
  color: ${({ theme, color }) => {
    if (`${color}_text` in theme.colors && color !== undefined) {
      // @ts-ignore
      return theme.colors[`${color}_text`] ?? theme.colors.dark
    }

    return theme.colors.dark
  }};

  line-height: 32px;

  transition: background-color 0.5s linear, color 0.1s linear;

  :hover {
    background-color: ${({ theme, color }) => color !== undefined && theme.colors[`${color}_hover`]};
    color: ${({ theme, color }) => color !== 'white' ? theme.colors.white : theme.colors.dark};
  }

  span {
    margin: 0.5rem;
  }

  .spinner {
    animation: rotate 1.5s infinite step-start ;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    10% {
      transform: rotate(36deg);
    }

    20% {
      transform: rotate(72deg);
    }

    30% {
      transform: rotate(108deg);
    }

    40% {
      transform: rotate(144deg);
    }

    50% {
      transform: rotate(180deg);
    }

    60% {
      transform: rotate(216deg);
    }

    70% {
      transform: rotate(252deg);
    }

    80% {
      transform: rotate(288deg);
    }

    90% {
      transform: rotate(324deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-height: 800px) {
    padding: 0 ${({ theme }) => theme.metrics.space5};
  }
`

const LinkStyled = styled(ButtonStyled).attrs({ as: 'a' })`
  display: inline-block;
  text-decoration: none;
`

function Button ({ children, color = 'primary', isLoading, disabled, ...props }: ButtonProps) {
  const isDisabled = isLoading || disabled

  const Content = () => (
    <div className="button d-flex justify-content-center align-items-center fw-bold gap-2">
      {isLoading ? <img src="/images/icon-spinner.svg" alt="spinner" className="spinner" /> : null}
      {children}
    </div>
  )

  const onClickLink = (e: any) => {
    e.preventDefault()

    if (!disabled) {
      if (props.onClick) {
        props.onClick(e)
      }
      window.location.href = props.href || ''
    }
  }

  if (props.href) {
    return (
      <LinkStyled color={color} disabled={isDisabled} {...props} onClick={onClickLink}>
        <Content />
      </LinkStyled>
    )
  }
  return (
    <ButtonStyled color={color} disabled={isDisabled} {...props}>
      <Content />
    </ButtonStyled>
  )
}

export default styled(Button)``
