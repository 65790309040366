import React from 'react'

interface IconPropsI {
  className?: string,
  name: string,
  type?: string,
  onClick?: () => void,
}

const Icon = (props: IconPropsI) => {
  return (
    <img className={props.className} src={`/images/${props.name}.${props.type || 'svg'}`} alt="icon" onClick={props.onClick} />
  )
}

export default Icon
