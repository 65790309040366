import styled from 'styled-components'

import Text from 'Components/Text'

export const TextS = styled(Text)`
  font-family: var(--font-base-family-sans);
  font-weight: bold;
  color: var(--font-dark);
  white-space: nowrap;
`
