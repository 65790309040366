import type { ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import IconSVG from 'Components/IconSVG'
import type { IconNameI } from 'Components/IconSVG/IconSVG.component'

import useOutsideClick from 'Hooks/useOutsideClick.hook'

import { MicroData } from 'Helpers/MicroData.helper'

import { InputS, ItemS, RootS, WrapperItemsS, WrapperS } from './SelectFilter.styled'

interface SelectFilterPropsI {
  icon: IconNameI,
  placeholder?: string,
  children?: ReactNode,
  onChange?: (id: string) => void,
}

type ReactElementWithProps = React.ReactElement<any, string | React.JSXElementConstructor<any>>

const SelectFilter = (props: SelectFilterPropsI) => {
  const { icon, placeholder, children, onChange = () => undefined } = props
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState<RegExp>(/.*/)
  const inputRef = useRef<HTMLInputElement>(null)
  const componentRef = useRef(null)

  const handleClickToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOnSelect = (child: ReactElementWithProps) => {
    const { props } = child

    onChange(props.id)
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleOnChangeInput = (element: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(new RegExp(`.*${element.target.value}.*`, 'i'))
  }

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }

    if (!isOpen) {
      setSearch(/.*/i)
    }
  }, [isOpen, inputRef])

  useOutsideClick([componentRef], () => {
    setIsOpen(false)
  })

  const filteredChildren = React.Children.map(children, (child) => {
    const { 'data-search': dataSearch } = (child as ReactElementWithProps).props

    if (search.test(dataSearch)) {
      return child
    }

    return null
  })

  return (
    <WrapperS ref={componentRef}>
      <RootS onClick={handleClickToggle}>
        <IconSVG icon={icon} />
        <InputS placeholder={placeholder} ref={inputRef} onChange={handleOnChangeInput}/>
      </RootS>
      <WrapperItemsS isOpen={isOpen}>
        {React.Children.map(filteredChildren, (child) => {
          return (
            <ItemS data-component-type={MicroData.HEAVY_MODEL} onClick={() => handleClickOnSelect(child as ReactElementWithProps)}>
              {child}
            </ItemS>
          )
        })}
      </WrapperItemsS>
    </WrapperS>
  )
}

export default SelectFilter
