import useSWR from 'swr'

import { fetcher } from './fetcher'

export interface CompanySoftI {
  id: string,
  name: string,
  icon: string,
  color: string,
  locale: string,
}

interface OptionsI {
  slug: string
}

const useCompanySoft = (options: OptionsI) => {
  const { slug } = options

  const { data, error } = useSWR<CompanySoftI>(`company/${slug}/getconfig`, fetcher('GET'))

  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}

export default useCompanySoft
