import { Code } from '@phosphor-icons/react'

import { Tag } from 'DesignSystem'

function DevTag () {
  return (
    <Tag>
      <Code size={24}/>
      <span className='ms-1'>
        Dev
      </span>
    </Tag>
  )
}

export default DevTag
