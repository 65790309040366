import Image from 'Components/Image'

function Logo () {
  return (
    <Image
      src="/images/logos/logo.png"
      alt="Logo"
      style={{ maxWidth: '100%' }}
    />
  )
}

export default Logo
