import { useHistory } from 'react-router-dom'

import useSWR from 'swr'

import { apiGET, apiPOST } from 'Services/Api.service'

import { fetcher } from './fetcher'

export interface PlanI {
  id: string,
  name: string,
  product_id: string,
  connectionsEnabled: Array<string>,
  countWorkspacesEnabled: number,
  countConnectionsEnabled: number,
  permissions: Array<{
    id: string,
    platform: string,
    name: string,
    scope: string,
    count_enabled: number,
  }>,
  prices: Array<{
    id: string,
    currency: string,
    amount: number,
    amount_decimal: string,
    interval: string,
    interval_count: number,
    discount?: number
    amountWithoutDiscount?: number
  }>,
  isActive?: boolean,
  isUpgrade?: boolean,
  isDowngrade?: boolean,
  investmentQuota?: {
    tiers: Array<{
      unit_amount: number,
      up_to: number,
    }>
  }
}

interface DowngradeCheckI {
  isDowngrade: boolean,
  workspaces: {
    from: number,
    to: number
  },
  connectionsOff: Array<string>
}

interface ReturnI {
  data: Array<PlanI>,
  isLoading: boolean,
  isError: boolean,
  actions: {
    signAPlan: (priceID: string) => Promise<void>,
    checkDowngrade: (planID: string) => Promise<DowngradeCheckI>
  }
}

const usePlans = () : ReturnI => {
  const history = useHistory()
  const { data, error } = useSWR<Array<PlanI>>('plans/all', fetcher('GET'))

  const signAPlan = async (priceID: string) => {
    const { url } = await apiPOST<any>('subscription/checkout', { id: priceID, redirect: false })

    if (url) {
      window.location.href = url
    } else {
      history.push('/subscription')
    }
  }

  const checkDowngrade = async (planID: string) => {
    const response = await apiGET<DowngradeCheckI>(`subscription/downgrade/check?plan=${planID}`)
    return response
  }

  return {
    data: data ?? [],
    isLoading: !error && !data,
    isError: error,
    actions: {
      signAPlan,
      checkDowngrade
    }
  }
}

export default usePlans
