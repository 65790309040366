import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'

import ImageSelectIconSvg from 'Components/ImageSelectIconSvg'

import { RootS, RootSelector } from './UploadField.styled'

interface UploadFieldI {
  label: string;
  placeholder?: string;
  sizeImage?: {
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
  };
  startOpen?: boolean;
  onDrop: (acceptedFiles: any) => void;
  onValidate?: (isValid: boolean) => void;
}

const UploadField = (props: UploadFieldI) => {
  const intl = useIntl()
  const [isValid, setIsValid] = useState<boolean | null>(null)
  const { sizeImage, onValidate, startOpen } = props

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 1000000,
    onDropRejected: async (rejectedFiles) => {
      setIsValid(false)
      onValidate && onValidate(false)
    },
    onDrop: async (acceptedFiles) => {
      const preview = URL.createObjectURL(acceptedFiles[0])
      const blob = await fetch(preview).then((res) => res.blob())
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = function () {
        const img = document.createElement('img')
        img.onload = () => {
          if (sizeImage && onValidate) {
            let isValid = true

            if (sizeImage.width && sizeImage.width !== img.width) {
              isValid = false
            }
            if (sizeImage.height && sizeImage.height !== img.height) {
              isValid = false
            }
            if (sizeImage.minHeight && sizeImage.minHeight > img.height) {
              isValid = false
            }
            if (sizeImage.minWidth && sizeImage.minWidth > img.width) {
              isValid = false
            }

            if (isValid) {
              props.onDrop({
                preview,
                base: reader.result,
                ...acceptedFiles[0]
              })
            }

            setIsValid(isValid)
            onValidate(isValid)
          } else if (onValidate) {
            props.onDrop({ preview, base: reader.result, ...acceptedFiles[0] })
            onValidate(true)
          }
        }
        img.src = reader.result as string
      }
    }
  })

  useEffect(() => {
    // @ts-ignore
    if (getInputProps()?.ref?.current && startOpen) {
      // @ts-ignore
      getInputProps().ref.current.click()
    }
  }, [])

  return (
    <RootS {...getRootProps({ className: 'input-upload__dropzone' })}>
      <RootSelector error={isValid === false}>
        <input {...getInputProps()} />
        <ImageSelectIconSvg />
      </RootSelector>
      {isValid === false ? (
        <small className="caption1 text-error">
          {intl.formatMessage({ id: 'A imagem selecionada é grande demais. Por favor, selecione outra imagem.' })}
        </small>
      ) : (
        <small className="caption1">
          {intl.formatMessage({ id: 'Faça upload de uma imagem em formato JPG ou PNG com até 184x92px.' })}
        </small>
      )}
    </RootS>
  )
}

export default UploadField
