import React from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import { deleteCompanyAccount } from 'Store/Actions/Company'

export interface AccountInterface {
  id: string | number,
  name: string,
  platform: string,
  external_id: string,
  token_status: string,
  company_account: string,
}

interface CompanyAccountRowInterface {
  account: AccountInterface,
  company: string,
  onUpdate?: () => void,
}

export const CompanyAccountRow = (props: CompanyAccountRowInterface) => {
  const { company, onUpdate } = props
  const handleUnlink = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    const { account } = props

    await deleteCompanyAccount(account.company_account, account.platform, company)

    onUpdate && onUpdate()
  }

  const { id, platform, external_id, token_status, name } = props.account

  return (
    <tr>
      <td>
        <Link to={`/company/${company}/account/${id}`}>
          {external_id}
        </Link>
      </td>
      <td>{name || '--'}</td>
      <td>{platform}</td>
      <td>
        {token_status === 'ok' && (
          <span className='text-success'>
            Token ativo
          </span>
        )}

        {token_status === 'invalid' && (
          <span className='text-danger'>
            Token inválido
          </span>
        )}

        {token_status === 'unknown' && (
          <span className='text-warning'>
            Não foi possível determinar
          </span>
        )}
      </td>
      <td className='text-right'>
        <Button onClick={(e) => handleUnlink(e)}>Desvincular conta</Button>
      </td>
    </tr>
  )
}

export default CompanyAccountRow
