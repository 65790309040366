import type { ReactNode } from 'react'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import type { Color } from '@material-ui/lab'
import { Alert, AlertTitle } from '@material-ui/lab'

import { remodeAlert } from 'Store/Actions/alerts.action'

interface AlertInterface {
  index: number,
  title: string,
  severity: Color,
  children?: ReactNode
}

export default function ItemAlert (props:AlertInterface) {
  const { index, severity = 'info', title = '', children } = props
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(remodeAlert(index))
    }, 1000 * 5)
  }, [])

  function onClose () {
    dispatch(remodeAlert(index))
  }

  return (
    <Alert onClose={onClose} variant="filled" severity={severity}>
      <AlertTitle><FormattedMessage id={title}/></AlertTitle>
      {children}
    </Alert>
  )
}
