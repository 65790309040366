import React from 'react'
import DataGrid from 'react-data-grid'

import useCompaniesConsole from 'Hooks/API/useCompaniesConsole.hook'

const Companies = () => {
  const { data: companiesRow } = useCompaniesConsole()

  const columns = [{
    key: 'id',
    name: 'ID'
  }, {
    key: 'name',
    name: 'Nome'
  }, {
    key: 'slug',
    name: 'Slug'
  }]

  return (<DataGrid
    columns={columns}
    rowGetter={(i: number) => companiesRow[i]}
    rowsCount={companiesRow.length}
    minHeight={400} />)
}

export default Companies
