import type { CSSProperties, ReactElement } from 'react'
import React, { useEffect, useState } from 'react'

interface ImageInterface {
    src: string,
    alt: string,
    style?: CSSProperties
}

export default function Image (props:ImageInterface): ReactElement {
  const [animate, setAnimate] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true)
    }, 300)
  }, [])

  return (
    <img className={`default ${animate ? 'animated' : ''}`}
         src={props.src}
         alt={props.alt}
         style={props.style}
         />
  )
}
