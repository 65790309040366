import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { Button, Col, Row } from 'DesignSystem'

import UploadImageField from 'Components/UploadImageField/UploadImageField.component'
import WorkspaceForm from 'Components/WorkspaceForm'

function CreateWorkspace ({
  next,
  prev,
  setSizeDialog,
  dataContentManager,
  enableNext,
  setEnableNext,
  setShowFooter,
  setData
}: any) {
  const intl = useIntl()
  useEffect(() => {
    setSizeDialog('lg')
    setShowFooter(true)
  }, [])

  // console.log('dataContentManager', dataContentManager)

  const isDisabled = !(dataContentManager.isValidSlug && dataContentManager.isValidName && dataContentManager.icon)

  return (
    <Row className="flex-column">
      <Col className="text-center">
        <img src="/images/isotype.png" width={32} alt="Logo" />
      </Col>
      <Col className="text-center mt-3">
        <h2 className="fw-extrabold">{intl.formatMessage({ id: 'Cadastre seu Workspace' })}</h2>
      </Col>
      <Col className="mt-3">
        <p className="body1">
          {intl.formatMessage({ id: 'Vamos começar configurando os dados básicos do seu workspace, como o nome dele, que pode ser o da sua empresa, agência, marca ou produto, a URL para acessar seu Workspace NeoDash, e a logo.' })}

        </p>
      </Col>
      <Col>
        <Row className="mt-3">
          <Col xs={12} lg={4}>
            <label className="fw-bold">
              {intl.formatMessage({ id: 'Logo' })}{' '}
              <span className="text-error">*</span>:
            </label>
            <div className="mt-2">
              <UploadImageField
                label="Imagem"
                sizeImage={{
                  minWidth: 100,
                  minHeight: 100
                }}
                zoom={dataContentManager.zoom}
                initialImage={dataContentManager.iconOriginal}
                isCropped
                aspect={2 / 1}
                positionArePixels={dataContentManager.positionAreaPixels || {
                  width: 184,
                  height: 92,
                  x: 0,
                  y: 0
                }}
                onChange={(newImg, positionAreaPixels, zoom) => setData({
                  icon: newImg,
                  positionAreaPixels,
                  zoom
                }) }
                onChangeCache={(newImg) => setData({ iconOriginal: newImg }) }
              />
            </div>
          </Col>
          <Col>
            <WorkspaceForm
              dataContentManager={dataContentManager}
              setData={setData}
              setEnableNext={setEnableNext}
              prev={() => null}
              next={() => null}
              close={() => null}
              currentStep={0}
            />
          </Col>
        </Row>
      </Col>
      <Col className="mt-5 text-center">
        <Button color="gray" onClick={prev}>
          {intl.formatMessage({ id: 'Voltar' })}
        </Button>
        <Button className="ms-3" onClick={next} disabled={isDisabled}>
          {intl.formatMessage({ id: 'Avançar' })}
        </Button>
      </Col>
    </Row>
  )
}

export default CreateWorkspace
