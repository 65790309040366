import breakpoint from 'styled-components-breakpoint'
import styled from 'styled-components/macro'

import { LinkS, TextS } from './Components/ItemMenu.styled'

export const MenuWrapperS = styled.div`
  width: var(--base-menu-width);
  height: 100vh;

  position: fixed;

  padding: 0 0.5rem;

  display: none;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  overflow: hidden;
  z-index: 100;

  ${breakpoint('tablet')`
    display: flex;
  `}

  /* position: sticky; */
  top: 80px;
  left: 0;

  transition: width 500ms;

  background-color: ${(props) => props.theme.colors.gray40};
  border-bottom-right-radius: 20px;

  ${breakpoint('tablet')`
    :hover {
      width: 240px;

      display: flex;

      ${TextS} {
        display: block;
      }
    }
  `}

`

export const RootS = styled.div`
  min-height: 100%;

  display: flex;
  flex-direction: row;

  position: relative;

  background-color: ${(props) => props.theme.colors.gray40};

  &.menu-mobile {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 50px;
    bottom: 0px;
    z-index: 100000;
    min-height: 0px;

    z-index: 100000;

    ${MenuWrapperS} {
      display: flex;
      flex-direction: row;
      height: 50px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      position: unset;
      width: 100vw;
    }

    ${LinkS} {
      flex-direction: column;
      height: 50px;
      align-items: center;
      justify-content: center;
    }


    ${breakpoint('tablet')`
      display: none;
  `}
  }
`

export const HeaderMenuS = styled.div`
  height: var(--base-header-height);

  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 44px;
    height: 44px;
  }
`

export const ContentS = styled.div`
  width: 100%;
  /* height: calc(100vh - var(--base-app-bar-height)); */

  background-color: ${(props) => props.theme.colors.gray20};
  margin-left: 0;


  ${breakpoint('tablet')`
    margin-left: var(--base-menu-width);
  `}

  margin-top: 80px;

  display: flex;
  flex-direction: column;
`
