import type { ColorsInterface, FontTypesInterface } from 'styled'
import type { CSSObject } from 'styled-components'
import { css } from 'styled-components'

interface GenerateCSSProps {
  prefix: string;
  properties: string[];
}

function generateCssWithSizes ({
  prefix,
  properties
}: GenerateCSSProps): CSSObject {
  const sizes = [0, 0.25, 0.5, 1, 1.5, 2, 3]
  const cssObject: any = {}
  sizes.forEach((size, idx) => {
    const property = `.${prefix}-${idx}`
    cssObject[property] = {}
    properties.forEach((p) => {
      cssObject[property][p] = `${size}rem !important`
    })
  })
  return cssObject
}

function generateFontTypesCSSClasses (obj: FontTypesInterface) {
  let classes = ''
  Object.entries(obj).forEach(function ([key, value]) {
    classes += `
      .${key} {
        ${css(value)}
      }
    `
  })
  return classes
}

function generateFontColorsCSSClasses (obj: ColorsInterface) {
  let classes = ''
  Object.entries(obj).forEach(function ([key, value]) {
    classes += `
      .text-${key} {
        color: ${value} !important;
      }
    `
  })
  return classes
}

export { generateCssWithSizes, generateFontTypesCSSClasses, generateFontColorsCSSClasses }
