import { Link } from 'react-router-dom'

import styled from 'styled-components/macro'

import Card from 'Components/Card'

export const CardS = styled(Card)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`

export const LinkS = styled(Link)`
  text-decoration: underline;
`
