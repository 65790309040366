import styled from 'styled-components/macro'

import Dialog from 'Components/Dialog/Dialog.component'

import type { AccountI } from 'Hooks/API/useAccounts.hook'
import useContentManager from 'Hooks/useContentManager.hook'

import InviteDialog from '../Components/InviteDialog.component'
import SuccessInviteDialog from '../Components/SuccessInviteDialog.component'

export interface DataDeleteConnectionContentManagerI {
  connection: AccountI,
  idCompany?: string,
}

const DialogS = styled(Dialog)`
  width: 500px;
  height: auto;
`

const useInviteUserContentManager = () => {
  const { start, next, prev, content } = useContentManager<DataDeleteConnectionContentManagerI>([
    InviteDialog,
    SuccessInviteDialog
  ], {
    isFit: true,
    dialog: DialogS
  })

  return {
    start,
    next,
    prev,
    content
  }
}

export default useInviteUserContentManager
